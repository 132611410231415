<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" @getName="getName" />

			<div class="userinfo">
				<p class="subhead">发布数据产品</p>

				<Form ref="form" :model="data" :rules="rules" class="formInformation" :show-message="showMessage">
					<FormItem prop="name">
						<div class="item">
							<label><span class="reRed">*</span>数据产品名称</label>
							<div class="baseRight">
								<Input v-model="data.name" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<FormItem>
						<div class="item">
							<label><span class="reRed">*</span>数据产品类型</label>
							<div class="baseRight">
								<Select v-model="type" @on-change="selectType" :disabled="readonly">
									<Option v-for="item in realizationType" :value="item.value" :key="item.value">
										{{ item.label }}
									</Option>
								</Select>
							</div>
						</div>
					</FormItem>
					<!-- 股权融资 -->
					<FormItem prop="round" v-if="type==6" key="round">
						<div class="item">
							<label><span class="reRed">*</span>轮次</label>
							<div class="baseRight">
								<Input v-model="data.round" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<FormItem prop="industry" v-if="type==6" key="industry">
						<div class="item">
							<label><span class="reRed">*</span>行业</label>
							<div class="baseRight">
								<Input v-model="data.industry" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<FormItem prop="address" v-if="type==6" :rules="rules.address" key="address">
						<div class="item">
							<label><span class="reRed">*</span>地址</label>
							<div class="baseRight">
								<Input v-model="data.address" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<!-- 股权融资 -->

					<!-- 数据资产质押融资 -->
					<FormItem prop="rate" v-if="type==5||type==3||type==2" key="rate">
						<div class="item">
							<label><span class="reRed">*</span>{{type==3?'客户买入到期收益率':type==2?'票面利率':'年利率'}}</label>
							<div class="baseRight">
								<Input type="number" class="productUnit" v-model="data.rate" placeholder="请输入" :disabled="readonly">
								<template #append>
									<span>%</span>
								</template>
								</Input>
							</div>
						</div>
					</FormItem>
					<FormItem prop="term" v-if="type==5||type==3||type==2" key="term">
						<div class="item">
							<label><span class="reRed">*</span>{{type==3?'期限':type==2?'发行期限':'贷款期限'}}</label>
							<div class="baseRight">
								<Input v-model="data.term" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<FormItem prop="loan" v-if="type==5">
						<div class="item">
							<label><span class="reRed">*</span>贷款额度</label>
							<div class="baseRight">
								<Input v-model="data.loan" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<!-- 数据资产质押融资 -->

					<!-- 数据资产债券融资 -->
					<FormItem prop="residueTerm" v-if="type==3" key="residueTerm">
						<div class="item">
							<label><span class="reRed">*</span>剩余期限</label>
							<div class="baseRight">
								<Input v-model="data.residueTerm" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<!-- 数据资产债券融资 -->

					<FormItem prop="scene" v-if="type!=6&&type!=5&&type!=4&&type!=3&&type!=2&&type!=1">
						<div class="item">
							<label><span class="reRed">*</span>应用场景</label>
							<div class="baseRight">
								<Input v-model="data.scene" placeholder="请输入" :disabled="readonly" style="width: 82%;" />
								<span style="margin-left: 30px;color: #282828;">标签使用"|"分隔</span>
							</div>
						</div>
					</FormItem>
					<FormItem prop="price" :rules="type==6||type==5||type==4||type==1||disabled?[{ required: false }]:rules.price"
						v-if="type!=6&&type!=5&&type!=4" key="price">
						<div class="item">
							<label for="price">
								<span class="reRed"
									:style="{'visibility':type!=1?'inherit':'hidden'}">*</span>{{type==3?'交易全价':type==2?'发行金额':'价格'}}
							</label>
							<div class="baseRight">
								<Input id="price" class="productUnit" type="number" v-model="data.price" placeholder="请输入"
									:disabled="disabled">
								<template #append>
									<span>元</span>
								</template>
								</Input>
							</div>
						</div>
					</FormItem>
					<FormItem v-if="type!=6&&type!=5&&type!=4&&type!=3&&type!=2">
						<div class="item" style="margin-top: -20px;">
							<label></label>
							<div class="baseRight">
								<Checkbox v-model="negotiable" @on-change="optionalDiscussion" :disabled="readonly">面议</Checkbox>
							</div>
						</div>
					</FormItem>
					<FormItem>
						<div class="item">
							<label><span class="reRed">*</span>交付方式</label>
							<div class="baseRight">
								<CheckboxGroup v-model="deliveryMethod">
									<Checkbox label="自行交付" @on-change="optionalDeliveryMethod" disabled style="top: 7px;">自行交付</Checkbox>
								</CheckboxGroup>
							</div>
						</div>
					</FormItem>
					<FormItem prop="profile">
						<div class="item">
							<label><span class="reRed">*</span>产品简介</label>
							<div class="baseRight">
								<Input v-model="data.profile" placeholder="请输入" :disabled="readonly" />
							</div>
						</div>
					</FormItem>
					<FormItem>
						<div class="item">
							<label><span class="reRed">*</span>产品详细介绍</label>
							<div class="baseRight">
								<Editor id="editor" ref="editor" :selector="selector" :info="data.introduction" @getContent="getContent"></Editor>
							</div>
						</div>
					</FormItem>
					<FormItem>
						<div class="item">
							<label><span class="reRed">*</span>产品LOGO</label>
							<div class="baseRight" style="display: flex;align-items: center;">
								<div class="upBox">
									<img v-if="data.logo" :src="data.logo" alt="产品LOGO" />
									<Icon v-else type="ios-add" size="60" color="#BFBFBF" />

									<Upload class="upload" :action="action" name="upfile" :data="imgData" :headers="headers"
										:show-upload-list="false" accept="image/png,image/jpg,image/jpeg,image/gif" :max-size="2048"
										:on-exceeded-size="handleMaxSize" :on-success="(value)=>upLogo(value,'logo')" :disabled="readonly">
										<div class="occupied"></div>
									</Upload>
								</div>
								<span class="infoAlert">
									<img src="../../assets/img/myCenter/alert.png" style="vertical-align: middle;" />
									<i>格式为JPG、JPEG、PNG，大小不超过2MB</i>
								</span>
							</div>
						</div>
					</FormItem>
					<FormItem class="operationBtn" v-if="!readonly">
						<button type="button" class="buttonFill" @click="save">立即提交</button>
						<button type="button" class="buttonBorderTransparency" @click="cancel">取消</button>
					</FormItem>

				</Form>

			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import Editor from '@/components/richText/editor.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapActions,
		mapMutations
	} from 'vuex'

	import {
		Modal,
		Input,
		DIV,
		Switch
	} from 'iview'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
			Editor,

		},
		props: {
			name: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入产品名称',
						trigger: 'blur'
					}]
				}
			},
			scene: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入应用场景',
						trigger: 'blur'
					}]
				}
			},
			price: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入产品价格',
						trigger: 'blur'
					}]
				}
			},
			profile: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入产品简介',
						trigger: 'blur'
					}]
				}
			},
			round: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入轮次',
						trigger: 'blur'
					}]
				}
			},
			industry: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入行业',
						trigger: 'blur'
					}]
				}
			},
			address: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入地址',
						trigger: 'blur'
					}]
				}
			},
			rate: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入年利率',
						trigger: 'blur'
					}]
				}
			},
			term: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入贷款期限',
						trigger: 'blur'
					}]
				}
			},
			loan: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入贷款额度',
						trigger: 'blur'
					}]
				}
			},
			residueTerm: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入剩余期限',
						trigger: 'blur'
					}]
				}
			}



		},
		computed: {
			rules() {
				return {
					name: this.name,
					scene: this.scene,
					price: this.price,
					profile: this.profile,
					round: this.round,
					industry: this.industry,
					address: this.address,
					rate: this.rate,
					term: this.term,
					loan: this.loan,
					residueTerm: this.residueTerm

				}
			}
		},
		data() {
			return {
				selector: new Date().getTime(),
				readonly: this.$route.query.readonly == 1 ? true : false,
				action: this.domain.testUrl + this.domain.dataRealization + 'dataAssetUpload/uploadFile',
				imgData: {
					type: 6
				},
				headers: {
					Authorization: localStorage.userToken,
					SecurityCode: window.channel,
					ChannelId: window.channelId
				},
				searchTitle: {},
				realizationType: [],
				type: '',
				negotiable: '', //面议
				disabled: this.$route.query.readonly == 1 ? true : false,
				modal: false,
				deliveryMethod: ['自行交付'],
				data: {
					name: '', //产品名称/项目名称
					type: '',
					introduction: '', //产品/项目详细介绍
					scene: '', //应用场景
					price: '', // 价格/交易日全价/发行金额
					discuss: '', //面议
					deliveryMethod: '自行交付', //交付方式
					profile: '', //产品简介/描述 
					logo: '', //产品logo
					round: '', //轮次
					industry: '', //行业
					address: '', //地址
					rate: '', //年利率/客户买入到期收益率/票面利率
					term: '', // 贷款期限/期限/发行期限
					loan: '', //贷款额度
					residueTerm: '' //剩余期限

				},
				showMessage: false

			}
		},
		created() {
			this.getDataProduct()
		},
		mounted() {


		},
		methods: {
			...mapActions([
				'getProductInfo'
			]),
			nameHint(rule, value, callback) {
				console.log(this.type == 5 && !value)
				if (this.type == 5 && !value) {
					callback(new Error('请输入年利率'));
				} else {
					callback();
				}
			},
			selectType() {
				console.log('rrrrrr------', this.type)
				// this.showMessage = false
			},
			getDataProductTypeList() {
				this.$post(this.domain.dataRealization + 'dataProductType/getDataProductTypeList', qs.stringify({}))
					.then((res) => {
						this.realizationType = []
						res.data.content.map(el => {
							this.realizationType.push({
								value: el.type,
								label: el.name
							})
						})

						this.type = this.data.type ? this.data.type : this.realizationType[0].value
					})
			},
			getDataProduct() {
				this.selector = new Date().getTime()
				if (this.$route.query.id) {
					this.$post(this.domain.dataRealization + 'dataProduct/getDataProduct', qs.stringify({
							productId: this.$route.query.id
						}))
						.then((res) => {
							this.data = res.data.content
							if (res.data.content.discuss == '面议') {
								this.negotiable = true
								this.disabled = true
							}
							this.data.price=this.data.price?res.data.content.price.toString():''
							this.getDataProductTypeList()

							// this.$refs.editor.setContent(res.data.content.introduction)
						})
				} else {
					this.getDataProductTypeList()
					this.data.introduction=''
				}

			},
			getName(data) {
				console.log(data)
				this.searchTitle = data
			},
			getContent(value) {
				this.data.introduction = value; //富文本中的内容
				console.log('--------富文本中的内容-------------')
				console.log(value)
			},
			optionalDiscussion(value) {
				console.log(value)
				this.disabled = value
				this.data.discuss = value ? '面议' : ''
			},
			optionalDeliveryMethod(value) {
				console.log(value)
				this.data.deliveryMethod = value ? '自行交付' : ''
			},
			save() {
				console.log(this.$refs.form.$children)

				this.$refs.form.validate((valid) => {
					console.log(valid)
					if (valid) {
						console.log('可以保存')

						if (!this.data.introduction) {
							this.alert('请输入产品详细介绍')
							return false
						}

						if (!this.data.logo) {
							this.alert('请上传产品LOGO')
							return false
						}

						this.data.type = this.type

						console.log(this.data)

						this.$post(this.domain.dataRealization + 'dataProduct/saveDataProduct', qs.stringify({
								productInfo: JSON.stringify(this.data)
							}))
							.then((res) => {
								if (res.data.code == 200) {
									console.log('保存产品的响应值---------', res.data.content)

									this.$router.push({
										path: 'productManagement'
									})
								} else {
									this.$Message.error(res.data.message)
								}
							});
					} else {
						for (let i in this.$refs.form.$children) {
							if (this.$refs.form.$children[i].validateState !== 'success') {
								window.scrollTo({
									top: this.$refs.form.$children[i].$el.offsetTop,
									behavior: 'smooth'
								})
								break;
							}
						}
					}
				})


			},
			cancel() {
				this.$router.push({
					path: 'productManagement'
				})
			}



		},



	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}

	.formInformation {
		width: 95%;

		.baseRight {
			width: 79%;


		}
	}

	/deep/ .ivu-form-item-error-tip {
		width: 77.5%;
	}

	/deep/ .ivu-select-single .ivu-select-selection,
	/deep/ .ivu-select-placeholder,
	/deep/ .ivu-select-selected-value {
		height: 40px !important;
		line-height: 40px !important;
	}

	/deep/ .ivu-select-selection {
		border-color: #BFBFBF;
	}

	/deep/ .ivu-checkbox-inner {
		top: -3px;
		border-color: #BFBFBF;
	}

	/deep/ .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
		background-color: #2d8cf0;
		border-color: #2d8cf0;
	}

	/deep/ .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after {
		border-color: #fff;
	}
</style>