<template>
	<div id="main">
		<div id="signUp" v-if="$store.state.user.isLogining">
			<div
				:class="[$store.state.user.subjectColor.mainColor=='#00bfbf'?'registerGreen':$store.state.user.subjectColor.mainColor=='#f59a23'?'registerOrange':$store.state.user.subjectColor.mainColor=='#d9001b'?'registerRed':'registerBlue']">
				<div>
					<p>立即体验，在数据变现系统创造未来</p>
					<button @click="signUp">立即注册</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import navFooterInfo from '@/common/navFooterInfo'

	import {
		mapActions
	} from 'vuex'

	export default {
		mixins: [navFooterInfo],
		data() {
			return {
				show: false
			}
		},
		mounted() {
			this.isLogining()
		},
		methods: {
			...mapActions([
				'getIsLogining'
			]),
			signUp() {
				this.$router.push({
					path: '/register',
				});
			},
			isLogining() {
				if (localStorage.userToken) {
					this.$post(this.domain.dataRealization + 'dataCustomer/isLogin', {})
						.then((res) => {
							var isLogining = !res.data.content
							this.getIsLogining({
								isLogining
							})
							
							if(res.data.content){
								this.getCustomerInfo()
							}

						});
				} else {
					var isLogining = true
					this.getIsLogining({
						isLogining
					})
				}

			}
		}
	}
</script>

<style scoped lang="less">
	#signUp {
		// padding-top: 75px;

		.registerGreen {
			background: url(../../assets/img/footerSignUp/registerGreen.jpg) center center no-repeat;
		}

		.registerOrange {
			background: url(../../assets/img/footerSignUp/registerOrange.jpg) center center no-repeat;
		}

		.registerRed {
			background: url(../../assets/img/footerSignUp/registerRed.jpg) center center no-repeat;
		}

		.registerBlue {
			background: url(../../assets/img/footerSignUp/registerBlue.jpg) center center no-repeat;
		}

		>div {
			width: 100%;
			height: 200px;
			background-size: cover !important;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			p {
				width: 100%;
				font-size: 28px;
				font-weight: bold;
				text-align: center;
				color: var(--mainColor);
				margin-bottom: 14px;
				margin-top: 5px;
			}

			button {
				width: 180px;
				height: 40px;
				border: 2px solid var(--mainColor);
				font-size: 16px;
				color: var(--mainColor);
				background: transparent;
				cursor: pointer;
				display: block;
				margin: 0 auto;
			}
			
			button:hover{
				background: var(--mainColor);
				color:#fff;
			}
		}
	}
</style>