<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" />

			<div class="userinfo">
				<p class="subhead">实名认证</p>
				<div class="certificationAudit">
					<div class="audit_refuse" v-if="$store.state.user.customerInfo.auditStatus==2">
						<img src="../../assets/img/myCenter/underReview.png" />
						<div>
							<p class="auditStatusAlert" style="color: #EE9016;">认证审核中</p>
							<p class="contactWay">请耐心等待审核结果，如有疑问请联系客服人员</p>
						</div>
						<button class="buttonBorderTransparency" @click="revamp">查看填报信息</button>
					</div>
					<div class="audit_refuse" v-else>
						<img src="../../assets/img/myCenter/failAudit.png" />
						<div>
							<p class="auditStatusAlert" style="color: red;">您的认证审核未通过！</p>
							<p class="contactWay">您可修改信息，重新提交审核</p>
							<button class="buttonBorderTransparency" @click="revamp">立即修改</button>
						</div>
						<div class="notPassingReason">
							<i>审核未通过原因：</i>
							<p>
							<pre>{{auditConent}}</pre>
							</p>
						</div>
					</div>

				</div>
			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import qs from 'qs'
	import axios from 'axios'

	import './personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,

		},
		data() {
			return {
				auditStatus: 0,
				auditConent: ''

			}
		},
		mounted() {
			this.getAuthInfo()

		},
		methods: {
			revamp() {
				this.$router.push({
					path: 'certification'
				})
			},
			getAuthInfo() {
				this.$post(this.domain.dataRealization + 'authentication/getAuthInfo', qs.stringify({}))
					.then((res) => {
						this.auditConent = res.data.content.auditInfo
					});
			}


		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;

		>div:last-child {
			width: 926px;

			.info {}
		}
	}

	.certificationAudit {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;

		>div {
			width: 90%;
			margin: 0 auto;
		}

		img {
			width: 213px;
			display: block;
			margin: 0 auto;
		}

		.auditStatusAlert {
			width: 100%;
			font-size: 18px;
			margin-top: 30px;
		}

		.contactWay {
			width: 100%;
			font-size: 14px;
			color: #282828;
			margin-top: 15px;
		}

		button {
			width: 160px;
			height: 40px;
			border-radius: 6px;
			margin-top: 30px;
		}

		.notPassingReason {
			width: 100%;
			display: flex;
			margin-top: 20px;

			>i {
				width: 113px;
				font-size: 14px;
				color: #282828;

			}

			>i+p {
				width: calc(100% - 113px);
				font-size: 14px;
				color: #282828;
				padding-bottom: 30px;

				pre {
					width: 100%;
					line-height: 22px;
					margin: 0;
					text-align: justify;
					white-space: pre-wrap;
					/* 允许文本自动换行 */
				}
			}
		}

	}
</style>