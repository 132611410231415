<template>
	<div>
		<ul class="contribution">
			<i></i>
			<li>
				<div>序号</div>
				<div>资产类型</div>
				<div class="mark">
					<p>按资产对企业收入贡献影响程度的大小对资产重要性进行打分</p>
					<p style="font-size: 12px;">(1分为非常不重要，10分为非常重要)</p>
				</div>
			</li>
			<li v-for="(item,i) in data">
				<div>{{i+1}}</div>
				<div>{{item.assetTypeName}}</div>
				<div class="mark">
					<p v-for="f in 10"
						:class="['score',f==item.contributionDegree?'select':'',f<item.contributionDegree?'background':'']"
						@click="setScore(i,f)">
						<i>{{f}}分</i>
						<span></span>
						<i v-if="f==1||f==10">{{f}}分</i>
					</p>
				</div>
			</li>
			<i></i>
		</ul>
	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: {
				type: String
			},
			jumpAddress: String, //保存之后的跳转地址


		},
		data() {
			return {
				contributionType: 1, // 贡献类型（1：组合无形资产， 2：无形资产-数据资源）
				data: [{ //contributionDegree：得分    hasAnyAsset：是否有相关资产（0：没有，1：有）
					assetTypeName: '货币资金',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '固定资产',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '无形资产',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}],

			}
		},
		mounted() {
			this.getContribution()
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			getContribution() {
				this.$post(this.url + 'dataContribution/getContribution', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						contributionType: this.contributionType
					}))
					.then((res) => {
						if (res.data.content.length > 0) {
							this.data.forEach(itemA => {
								const itemB = res.data.content.find(itemB => itemB.assetTypeName === itemA.assetTypeName);
								if (itemB) {
									itemA.contributionDegree = itemB.contributionDegree;
									itemA.id = itemB.id
								}
							})
						}

					})
			},
			setScore(i, f) {
				this.data[i].contributionDegree = f
				this.pageModify('true');
			},
			save() {
				var contributionDegree = []

				for (var i = 0; i < this.data.length; i++) {
					if (this.data[i].contributionDegree > 0) {
						contributionDegree.push(this.data[i])
					}
				}

				if(contributionDegree.length>0){
					var unselected = this.findMissingObjects(this.data, contributionDegree)
					if (unselected.length > 0) {
						this.showDialogBar(true, 'notEmpty-info', '请对'+unselected[0].assetTypeName+'进行打分')
						return false;
					}
				}
			


				// if (this.data[0].contributionDegree == 0) {
				// 	this.showDialogBar(true, 'notEmpty-info', '请对流动资产进行打分')
				// 	return false;
				// }
				// if (this.data[1].contributionDegree == 0) {
				// 	this.showDialogBar(true, 'notEmpty-info', '请对有形非流动资产进行打分')
				// 	return false;
				// }
				// if (this.data[2].contributionDegree == 0) {
				// 	this.showDialogBar(true, 'notEmpty-info', '请对组合无形资产进行打分')
				// 	return false;
				// }


				this.$post(this.url + 'dataContribution/saveContribution', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						contribution: JSON.stringify(this.data),
						reportType: this.type,
						contributionType: this.contributionType
					}))
					.then((res) => {
						this.$router.push({
							name: this.jumpAddress,
							query: {
								reportId: res.data.content
							}
						})

					})


			},
			findMissingObjects(arr1, arr2) {
				const missing = [];
				arr1.forEach(obj1 => {
					if (!arr2.some(obj2 => obj2.assetTypeName == obj1.assetTypeName)) {
						missing.push(obj1);
					}
				});
				return missing;
			},
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
			}



		}

	}
</script>

<style>
</style>