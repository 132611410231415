import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/home.vue'
import login from '@/views/login/login.vue'
import register from '@/views/login/register.vue' // 注册
import registration from '@/views/login/registration.vue' // 注册协议
import disclaimer from '@/views/login/disclaimer.vue' // 免责声明
import confidentiality from '@/views/login/confidentiality.vue' // 隐私政策
import forgetPassword from '@/views/login/forgetPassword.vue' // 忘记密码
import notFound from '@/views/status/notFound.vue'
import serverError from '@/views/status/serverError.vue'
import aboutUs from '@/views/aboutUs/index.vue' //关于我们
import newsInformation from '@/views/newsInformation/index.vue' //新闻资讯
import informationDetails from '@/views/newsInformation/informationDetails.vue' //新闻资讯详情


import dataEvaluationIntroduction from '@/views/dataEvaluation/introductionPage.vue' //数据评价介绍页
import dataValuationIntroduction from '@/views/dataValuation/introductionPage.vue' //数据估值介绍页
import dataDiscoveryIntroduction from '@/views/dataDiscovery/introductionPage.vue' //数据变现介绍页
import dataCashProductDetails from '@/views/dataDiscovery/dataCashProductDetails.vue' //数据变现产品详情页
import placeOrder from '@/views/dataDiscovery/placeOrder.vue' //数据变现下单页

import myCenter from '@/views/myCenter/myCenter.vue' //个人中心
import evaluationList from '@/views/dataEvaluation/productList.vue' //个人中心(数据评价报告列表)
import valuationList from '@/views/dataValuation/productList.vue' //个人中心(数据估值报告列表)
import myOrder from '@/views/myCenter/myOrder/index.vue' //我的订单
import orderDetails from '@/views/myCenter/myOrder/orderDetails.vue' //我的订单的订单详情页
import certification from '@/views/myCenter/certification.vue' // 实名认证
import certificationAudit from '@/views/myCenter/certificationAudit.vue' // 实名认证审核状态页面
import productManagement from '@/views/myCenter/productManagement.vue' // 数据产品管理
import releaseProduct from '@/views/myCenter/releaseProduct.vue' // 数据产品管理中添加数据产品页面
import commoditiesTrading from '@/views/myCenter/transaction/commoditiesTrading.vue' // 数据产品交易
import tradingParticulars from '@/views/myCenter/transaction/tradingParticulars.vue' // 数据产品交易订单详情
import myIntegral from '@/views/myCenter/myIntegral.vue' // 我的积分
import securitySettings from '@/views/myCenter/qss/securitySettings.vue' // 安全设置
import changePassword from '@/views/myCenter/qss/changePassword.vue' // 安全设置修改密码
import modifyTel from '@/views/myCenter/qss/modifyTel.vue' // 安全设置修改手机号

// 数据估值
import information from '@/views/filled/information.vue' //基本信息
import softIndex from '@/views/filled/softIndex.vue' //软性指标
import yearForecast from '@/views/filled/yearForecast.vue' // 财务指标   年预测界面
import carryingValue from '@/views/filled/carryingValue.vue' // 财务指标   账面净值
import incomeForecast from '@/views/filled/incomeForecast.vue' // 财务指标   收入预测表
import payment from '@/views/filled/payment.vue' //  估值报告
import examineAuditStatus from '@/views/getInfo/examineAuditStatus.vue' // 查看信息  审核状态
import examineInformation from '@/views/getInfo/examineInformation.vue' // 查看信息  基本信息
import examineSoftInformation from '@/views/getInfo/examineSoftInformation.vue' // 查看信息  软性指标
import examineFinancialIndex from '@/views/getInfo/examineFinancialIndex.vue' // 查看信息  财务指标
import reportDV from '@/views/report/reportDV.vue' // 数据估值报告

// 数据估值（专业）
import dataInfoSpecialty from '@/views/dataValuation/specialty/information.vue' //基本信息
import dataSoftSpecialty from '@/views/dataValuation/specialty/softIndex.vue' //软性指标
import dataLiabilitiesSpecialty from '@/views/dataValuation/specialty/balanceSheet.vue' //资产负债表
import dataProfitSpecialty from '@/views/dataValuation/specialty/profitStatement.vue' //利润表
import dataAssetSpecialty from '@/views/dataValuation/specialty/dataAsset.vue' //数据资产
import dataIncomeForecast from '@/views/dataValuation/specialty/incomeForecast.vue' //收入预测
import dataNetProfitForecast from '@/views/dataValuation/specialty/netProfitForecast.vue' //净利润预测
import dataAccountingPolicy from '@/views/dataValuation/specialty/accountingPolicy.vue' // 会计政策统计表(选填) 
import dataIASpecialty from '@/views/dataValuation/specialty/intangibleAssets.vue' //组合无形资产-数据资源贡献程度
import dataPIASpecialty from '@/views/dataValuation/specialty/portfolioIntangibleAssets.vue' //组合无形资产贡献程度
import dataPaymentSpecialty from '@/views/dataValuation/specialty/payment.vue' //预支付页面

import dataAuditSpecialty from '@/views/dataValuation/specialty/getInfo/examineAuditStatus.vue' //查看信息  审核状态
import memberbasicTitle from '@/views/dataValuation/specialty/getInfo/examineInformation.vue' // 查看信息  基本信息
import viewSoftIndicators from '@/views/dataValuation/specialty/getInfo/examineSoftInformation.vue' // 查看信息  软性指标
import viewContributionLevel from '@/views/dataValuation/specialty/getInfo/examineContribution.vue' // 查看信息  贡献程度
import examineAssetLiability from '@/views/dataValuation/specialty/getInfo/examineAssetLiability.vue' // 查看信息 资产负债表
import examineProfit from '@/views/dataValuation/specialty/getInfo/examineProfit.vue' // 查看信息 利润表
import examineDataAsset from '@/views/dataValuation/specialty/getInfo/examineDataAsset.vue' // 查看信息 数据资产
import examineIncomeForecast from '@/views/dataValuation/specialty/getInfo/examineIncomeForecast.vue' // 查看信息 收入预测
import examineNetProfitForecast from '@/views/dataValuation/specialty/getInfo/examineNetProfitForecast.vue' // 查看信息 净利润预测
import examineAccountingPolicy from '@/views/dataValuation/specialty/getInfo/examineAccountingPolicy.vue' // 查看信息 会计政策统计表
import dataReportSpecialty from '@/views/dataValuation/specialty/report.vue' // 专业版报告 

// 数据估值（快速）
import dataValuationInfoFast from '@/views/dataValuation/fast/information.vue' //基本信息
import dataValuationSoftFast from '@/views/dataValuation/fast/softIndex.vue' //软性指标
import dataValuationLiabilitiesFast from '@/views/dataValuation/fast/balanceSheet.vue' //资产负债表
import dataValuationProfitFast from '@/views/dataValuation/fast/profitStatement.vue' //利润表
import dataResourceFast from '@/views/dataValuation/fast/dataResourceFast.vue' //数据资源
import dataValuationIAFast from '@/views/dataValuation/fast/intangibleAssets.vue' //组合无形资产-数据资源贡献程度
import dataValuationPIAFast from '@/views/dataValuation/fast/portfolioIntangibleAssets.vue' //组合无形资产贡献程度
import dataValuationPaymentFast from '@/views/dataValuation/fast/payment.vue' //预支付页面
import dataValuationAuditFast from '@/views/dataValuation/fast/getInfo/examineAuditStatus.vue' //查看信息  审核状态
import dataValuationEInfoFast from '@/views/dataValuation/fast/getInfo/examineInformation.vue' // 查看信息  基本信息
import dataValuationESoftFast from '@/views/dataValuation/fast/getInfo/examineSoftInformation.vue' // 查看信息  软性指标
import dataValuationEFinancialFast from '@/views/dataValuation/fast/getInfo/examineFinancialIndex.vue' // 查看信息  财务指标
import dataValuationEContributionFast from '@/views/dataValuation/fast/getInfo/examineContribution.vue' // 查看信息  贡献程度
import dataValuationReportFast from '@/views/dataValuation/fast/report.vue' // 数据估值快速报告


// 数据评价
import basicInformationEvaluation from '@/views/dataEvaluation/filled/information.vue' //数据评价（基本信息）
import evaluationSoftIndicators from '@/views/dataEvaluation/filled/softIndex.vue' //数据评价（软性指标）
import evaluationValuationReport from '@/views/dataEvaluation/filled/payment.vue' //数据评价（估值报告）
import reportEvaluation from '@/views/dataEvaluation/report/reportEvaluation.vue' // 数据评价报告

// 数商
import numberQuotient from '@/views/numberQuotient/index.vue' // 数商测试题页面
import quotientTestResults from '@/views/numberQuotient/quotientTestResults.vue' // 数商测试结果页面
import digitalModelEvaluation from '@/views/digitalModelEvaluation/index.vue' // 数字化企业商业模式测试题页面
import businessEvaluationResults from '@/views/digitalModelEvaluation/testResults.vue' // 数字化企业商业模式测试结果页面

import refresh from '@/views/refresh/refresh.vue' //中转的空白页面


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'Home',
		meta: {
			title: '首页',
			name: 'Home',
			setupType: 1
		},
		component: Home
	},
	{
		path: '/refresh',
		name: 'refresh',
		component: refresh,
		meta: {
			title: ''
		}
	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			title: '登录'
		}
	}, {
		path: '/register',
		name: 'register',
		component: register,
		meta: {
			title: "注册"
		}
	}, {
		path: '/registration',
		name: 'registration',
		component: registration,
		meta: {
			title: "心流网注册协议"
		}
	}, {
		path: '/disclaimer',
		name: 'disclaimer',
		component: disclaimer,
		meta: {
			title: "免责声明"
		}
	}, {
		path: '/confidentiality',
		name: 'confidentiality',
		component: confidentiality,
		meta: {
			title: "隐私政策"
		}
	}, {
		path: '/forgetPassword',
		name: 'forgetPassword',
		component: forgetPassword,
		meta: {
			title: "忘记密码"
		}
	},
	{
		path: '/notFound',
		name: 'notFound',
		component: notFound,
		meta: {
			title: '404错误'
		}
	}, {
		path: '/serverError',
		name: 'serverError',
		component: serverError,
		meta: {
			title: '500错误'
		}
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: aboutUs,
		meta: {
			title: '关于我们',
			setupType: 6
		}
	},
	{
		path: '/newsInformation',
		name: 'newsInformation',
		component: newsInformation,
		meta: {
			title: '新闻资讯',
			setupType: 5
		}
	},
	{
		path: '/informationDetails',
		name: 'informationDetails',
		component: informationDetails,
		meta: {
			title: '资讯详情',
			setupType: 5
		}
	},
	{
		path: '/numberQuotient',
		name: 'numberQuotient',
		component: numberQuotient,
		meta: {
			title: '数商测试'
		}
	},
	{
		path: '/quotientTestResults',
		name: 'quotientTestResults',
		component: quotientTestResults,
		meta: {
			title: '数商测试结果'
		}
	},
	{
		path: '/digitalModelEvaluation',
		name: 'digitalModelEvaluation',
		component: digitalModelEvaluation,
		meta: {
			title: '数字化企业商业模式测评'
		}
	},
	{
		path: '/businessEvaluationResults',
		name: 'businessEvaluationResults',
		component: businessEvaluationResults,
		meta: {
			title: '数字化企业商业模式测评结果'
		}
	},
	{
		path: '/dataEvaluationIntroduction',
		name: 'dataEvaluationIntroduction',
		component: dataEvaluationIntroduction,
		meta: {
			title: '数据评价',
			name: 'dataEvaluationIntroduction',
			setupType: 2
		}
	},
	{
		path: '/dataValuationIntroduction',
		name: 'dataValuationIntroduction',
		component: dataValuationIntroduction,
		meta: {
			title: '数据估值',
			name: 'dataValuationIntroduction',
			setupType: 3
		}
	},
	{
		path: '/dataDiscoveryIntroduction',
		name: 'dataDiscoveryIntroduction',
		component: dataDiscoveryIntroduction,
		meta: {
			title: '数据变现',
			name: 'dataDiscoveryIntroduction',
			setupType: 4
		}
	},
	{
		path: '/dataCashProductDetails',
		name: 'dataCashProductDetails',
		component: dataCashProductDetails,
		meta: {
			title: '数据变现详细信息',
			name: 'dataDiscoveryIntroduction'
		}
	},
	{
		path: '/placeOrder',
		name: 'placeOrder',
		component: placeOrder,
		meta: {
			title: '订单信息',
			name: 'dataDiscoveryIntroduction'
		}
	},
	{
		path: '/myCenter',
		name: 'myCenter',
		component: myCenter,
		meta: {
			title: '个人中心',
			name: 'myCenter'
		}
	},
	{
		path: '/evaluationList',
		name: 'evaluationList',
		component: evaluationList,
		meta: {
			title: '数据评价报告',
			name: 'evaluationList'
		}
	},
	{
		path: '/valuationList',
		name: 'valuationList',
		component: valuationList,
		meta: {
			title: '数据估值报告',
			name: 'valuationList'
		}
	},
	{
		path: '/myOrder',
		name: 'myOrder',
		component: myOrder,
		meta: {
			title: '我的订单',
			name: 'myOrder'
		}
	},
	{
		path: '/orderDetails',
		name: 'orderDetails',
		component: orderDetails,
		meta: {
			title: '订单详情',
			name: 'myOrder'
		}
	},
	{
		path: '/certification',
		name: 'certification',
		component: certification,
		meta: {
			title: '实名认证',
			name: 'certification'
		}
	},
	{
		path: '/certificationAudit',
		name: 'certificationAudit',
		component: certificationAudit,
		meta: {
			title: '实名认证',
			name: 'certification'
		}
	},
	{
		path: '/productManagement',
		name: 'productManagement',
		component: productManagement,
		meta: {
			title: '数据产品管理',
			name: 'productManagement'
		}
	},
	{
		path: '/releaseProduct',
		name: 'releaseProduct',
		component: releaseProduct,
		meta: {
			title: '发布数据产品',
			name: 'productManagement'
		}
	},
	{
		path: '/commoditiesTrading',
		name: 'commoditiesTrading',
		component: commoditiesTrading,
		meta: {
			title: '数据产品交易',
			name: 'commoditiesTrading'
		}
	},
	{
		path: '/tradingParticulars',
		name: 'tradingParticulars',
		component: tradingParticulars,
		meta: {
			title: '数据产品交易',
			name: 'commoditiesTrading'
		}
	},
	{
		path: '/myIntegral',
		name: 'myIntegral',
		component: myIntegral,
		meta: {
			title: '我的积分',
			name: 'myIntegral'
		}
	},
	{
		path: '/securitySettings',
		name: 'securitySettings',
		component: securitySettings,
		meta: {
			title: '安全设置',
			name: 'securitySettings'
		},
	},
	{
		path: '/changePassword',
		name: 'changePassword',
		component: changePassword,
		meta: {
			title: '修改密码',
			name: 'securitySettings'
		},
	},
	{
		path: '/modifyTel',
		name: 'modifyTel',
		component: modifyTel,
		meta: {
			title: '修改手机号',
			name: 'securitySettings'
		},
	},
	{
		path: '/information',
		name: 'information',
		component: information,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/softIndex',
		name: 'softIndex',
		component: softIndex,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/yearForecast',
		name: 'yearForecast',
		component: yearForecast,
		meta: {
			title: '财务指标',
		}
	},
	{
		path: '/carryingValue',
		name: 'carryingValue',
		component: carryingValue,
		meta: {
			title: '账面净值',
		}
	},
	{
		path: '/incomeForecast',
		name: 'incomeForecast',
		component: incomeForecast,
		meta: {
			title: '收入预测表',
		}
	},
	{
		path: '/payment',
		name: 'payment',
		component: payment,
		meta: {
			title: '估值报告',
		}
	},
	{
		path: '/examineAuditStatus',
		name: 'examineAuditStatus',
		component: examineAuditStatus,
		meta: {
			title: '审核状态',
		}
	},
	{
		path: '/examineInformation',
		name: 'examineInformation',
		component: examineInformation,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/examineSoftInformation',
		name: 'examineSoftInformation',
		component: examineSoftInformation,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/examineFinancialIndex',
		name: 'examineFinancialIndex',
		component: examineFinancialIndex,
		meta: {
			title: '财务指标',
		}
	},
	{
		path: '/reportDV',
		name: 'reportDV',
		component: reportDV,
		meta: {
			title: '数据估值报告',
		}
	},
	{
		path: '/basicInformationEvaluation',
		name: 'basicInformationEvaluation',
		component: basicInformationEvaluation,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/evaluationSoftIndicators',
		name: 'evaluationSoftIndicators',
		component: evaluationSoftIndicators,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/evaluationValuationReport',
		name: 'evaluationValuationReport',
		component: evaluationValuationReport,
		meta: {
			title: '评价报告',
		}
	},
	{
		path: '/reportEvaluation',
		name: 'reportEvaluation',
		component: reportEvaluation,
		meta: {
			title: '评价报告',
		}
	},
	// 数据估值快速版
	{
		path: '/dataValuationInfoFast',
		name: 'dataValuationInfoFast',
		component: dataValuationInfoFast,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/dataValuationSoftFast',
		name: 'dataValuationSoftFast',
		component: dataValuationSoftFast,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/dataValuationLiabilitiesFast',
		name: 'dataValuationLiabilitiesFast',
		component: dataValuationLiabilitiesFast,
		meta: {
			title: '资产负债表',
		}
	},
	{
		path: '/dataValuationProfitFast',
		name: 'dataValuationProfitFast',
		component: dataValuationProfitFast,
		meta: {
			title: '利润表',
		}
	},
	{
		path:'/dataResourceFast',
		name: 'dataResourceFast',
		component: dataResourceFast,
		meta: {
			title: '数据资源',
		}
	},
	{
		path: '/dataValuationPIAFast',
		name: 'dataValuationPIAFast',
		component: dataValuationPIAFast,
		meta: {
			title: '组合无形资产贡献程度',
		}
	},
	{
		path: '/dataValuationIAFast',
		name: 'dataValuationIAFast',
		component: dataValuationIAFast,
		meta: {
			title: '组合无形资产-数据资源贡献程度',
		}
	},
	{
		path: '/dataValuationPaymentFast',
		name: 'dataValuationPaymentFast',
		component: dataValuationPaymentFast,
		meta: {
			title: '估值报告',
		}
	},
	{
		path: '/dataValuationAuditFast',
		name: 'dataValuationAuditFast',
		component: dataValuationAuditFast,
		meta: {
			title: '审核状态',
		}
	},
	{
		path: '/dataValuationEInfoFast',
		name: 'dataValuationEInfoFast',
		component: dataValuationEInfoFast,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/dataValuationESoftFast',
		name: 'dataValuationESoftFast',
		component: dataValuationESoftFast,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/dataValuationEFinancialFast',
		name: 'dataValuationEFinancialFast',
		component: dataValuationEFinancialFast,
		meta: {
			title: '财务指标',
		}
	},
	{
		path: '/dataValuationEContributionFast',
		name: 'dataValuationEContributionFast',
		component: dataValuationEContributionFast,
		meta: {
			title: '贡献程度',
		}
	},
	{
		path: '/dataValuationReportFast',
		name: 'dataValuationReportFast',
		component: dataValuationReportFast,
		meta: {
			title: '报告(快速版)',
		}
	},
	// 数据估值专业版
	{
		path: '/dataInfoSpecialty',
		name: 'dataInfoSpecialty',
		component: dataInfoSpecialty,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/dataSoftSpecialty',
		name: 'dataSoftSpecialty',
		component: dataSoftSpecialty,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/dataLiabilitiesSpecialty',
		name: 'dataLiabilitiesSpecialty',
		component: dataLiabilitiesSpecialty,
		meta: {
			title: '资产负债表',
		}
	},
	{
		path: '/dataProfitSpecialty',
		name: 'dataProfitSpecialty',
		component: dataProfitSpecialty,
		meta: {
			title: '利润表',
		}
	},
	{
		path: '/dataAssetSpecialty',
		name: 'dataAssetSpecialty',
		component: dataAssetSpecialty,
		meta: {
			title: '数据资产',
		}
	},
	{
		path: '/dataIncomeForecast',
		name: 'dataIncomeForecast',
		component: dataIncomeForecast,
		meta: {
			title: '收入预测',
		}
	},
	{
		path: '/dataNetProfitForecast',
		name: 'dataNetProfitForecast',
		component: dataNetProfitForecast,
		meta: {
			title: '净利润预测',
		}
	},
	{
		path: '/dataAccountingPolicy',
		name: 'dataAccountingPolicy',
		component: dataAccountingPolicy,
		meta: {
			title: '会计政策统计表',
		}
	},
	{
		path: '/dataIASpecialty',
		name: 'dataIASpecialty',
		component: dataIASpecialty,
		meta: {
			title: '组合无形资产-数据资源贡献程度',
		}
	},
	{
		path: '/dataPIASpecialty',
		name: 'dataPIASpecialty',
		component: dataPIASpecialty,
		meta: {
			title: '组合无形资产贡献程度',
		}
	},
	{
		path: '/dataPaymentSpecialty',
		name: 'dataPaymentSpecialty',
		component: dataPaymentSpecialty,
		meta: {
			title: '估值报告',
		}
	},
	{
		path: '/dataAuditSpecialty',
		name: 'dataAuditSpecialty',
		component: dataAuditSpecialty,
		meta: {
			title: '审核状态',
		}
	},
	{
		path: '/memberbasicTitle',
		name: 'memberbasicTitle',
		component: memberbasicTitle,
		meta: {
			title: '基本信息',
		}
	},
	{
		path: '/viewSoftIndicators',
		name: 'viewSoftIndicators',
		component: viewSoftIndicators,
		meta: {
			title: '软性指标',
		}
	},
	{
		path: '/viewContributionLevel',
		name: 'viewContributionLevel',
		component: viewContributionLevel,
		meta: {
			title: '贡献程度',
		}
	},
	{
		path: '/examineAssetLiability',
		name: 'examineAssetLiability',
		component: examineAssetLiability,
		meta: {
			title: '资产负债表',
		}
	},
	{
		path: '/examineProfit',
		name: 'examineProfit',
		component: examineProfit,
		meta: {
			title: '利润表',
		}
	}, {
		path: '/examineDataAsset',
		name: 'examineDataAsset',
		component: examineDataAsset,
		meta: {
			title: '数据资产',
		}
	},
	{
		path: '/examineIncomeForecast',
		name: 'examineIncomeForecast',
		component: examineIncomeForecast,
		meta: {
			title: '收入预测',
		}
	},
	{
		path: '/examineNetProfitForecast',
		name: 'examineNetProfitForecast',
		component: examineNetProfitForecast,
		meta: {
			title: '净利润预测',
		}
	},
	{
		path: '/examineAccountingPolicy',
		name: 'examineAccountingPolicy',
		component: examineAccountingPolicy,
		meta: {
			title: '会计政策统计表',
		}
	},
	{
		path:'/dataReportSpecialty',
		name:'dataReportSpecialty',
		component: dataReportSpecialty,
		meta: {
			title: '报告（专业版）',
		}
	}
	


]

const router = new VueRouter({
	routes
})

export default router