<template>
	<div class="main">
		<Header />
		<div id="formWidth">
			<div class="fillinTitle">软性指标</div>
			<fillinNav />
			<softNav ref="softNav" :getSoftIndex="softIndexProblem" @softIndexSelected="updateSoftIndex" />
			<div class="fillinSubtitle">
				{{softIndexProblem.contentName}}
			</div>
			<div class="fillinMain" id="xqBox">
				<div class="fillinContent">
					<ul>
						<li v-for="(item,index) in softIndexProblem.contentDetails">
							<p class="position-tit-container">
								<span>{{index+1}}</span>
								<span>{{item.content}}</span>
								<span class="desc-container" v-if="item.canExplain">
									<span></span>
									<div class="desc-main">
										<p v-html="item.explaination.replace(/\\n/g,'<br>')"></p>
										<span></span>
									</div>
								</span>
							</p>
							<div class="options-container">
								<ul>
									<li v-for="(answer, a) in item.options" @click="selectTopic(index,a)"
										:class="[answer.chooseFlag=='1'?'active':'']" ref="soft">
										<label :for="answer.id" :class="answer.chooseFlag=='1'?'yes':'no'">{{answer.content}}</label>
										<div class="desc-container" v-if="answer.canExplain!=false">
											<span></span>
											<div class="desc-main">
												<p v-html="answer.explaination.replace(/\\n/g,'<br>')"></p>
												<span></span>
											</div>
										</div>
									</li>
								</ul>

							</div>

						</li>
					</ul>
				</div>
				<div class="saveInfo" @click="save">下一步</div>
			</div>

		</div>


		<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content="" @confirmJump="clickConfirmJump()"
			@closeMaskInfoJump="closeMaskInfoJump()" @cacheTrueMask="clickCacheTrueMaskJump()"
			@cacheMask="clickCacheMaskJump()"></dialogBar>

	</div>
</template>

<script>
	import Header from '@/common/header.vue'
	import fillinNav from '../../common/fillin/fillinNav.vue'
	import softNav from '../../common/fillin/softNav.vue'
	import dialogBar from './popue.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		components: {
			Header,
			fillinNav,
			softNav,
			dialogBar
		},
		data() {
			return {
				isSave: false,
				softIndexProblem: {},
				selectedQuestion: [],
				myAlert: false,
				popButonType: '',
				markedWords: '',
				softJArray: [],
				missingValue: 0, //哪个题没被选中
			}
		},
		watch: {
			softIndexProblem: {
				handler: function() {
					console.log('getCompanyNameList改变了')
				},
				deep: true
			}
		},
		mounted() {


		},
		methods: {
			...mapMutations(['pageModify']),
			updateSoftIndex(selectedSoftIndex) {
				this.isSave = false
				this.pageModify('false');
				this.selectedQuestion = []

				this.$nextTick(function() {
					if (this.$refs.soft) {
						this.$refs.soft.forEach((item, i) => {
							console.log(parseInt(item.offsetHeight / 22))
							if (parseInt(item.offsetHeight / 22) > 1) {
								item.style.borderRadius = '150px';
								// item.style.paddingTop = '10px';
							}
						})
					}
				})

				if (JSON.parse(selectedSoftIndex).contentDetails[0].options[0].isCache === "0" && !sessionStorage
					.isHistoryCache) {
					this.myAlert = true;
					this.popButonType = 'cacheType';
					this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';
					return false
				}
				console.log('更新')
				this.softIndexProblem = JSON.parse(selectedSoftIndex) // 更新父组件的数据
				console.log(this.softIndexProblem)


				JSON.parse(selectedSoftIndex).contentDetails.map((el, i) => {
					el.options.map((item, j) => {
						if (item.chooseFlag == '1') {
							this.selectedQuestion.push(item.questionId)
						}
					})
				})


			},
			selectTopic(i, j) {
				this.isSave = false
				this.pageModify('true');
				this.softIndexProblem.contentDetails[i].options.map((el, b) => {
					if (j == b) {
						this.$set(this.softIndexProblem.contentDetails[i].options[j], 'chooseFlag', '1')
					} else {
						el.chooseFlag = 0
					}
				})

				$('.position-tit-container').removeClass('no-choose-red')

				this.selectedQuestion.push(this.softIndexProblem.contentDetails[i].options[j].questionId)
				this.$forceUpdate()
				// 缓存 ======================
				this.$nextTick(() => {
					this.getSaveData()
					//调用缓存共用方法
					if (!this.softIndexProblem.contentDetails[i].options[j].isCache) {
						this.method.caching("softJArray", this.softJArray, this.$route.query.type, '', this)
					}
				})
				// 缓存 =======================

			},
			findFirstMissingValue(a, b) {
				var isContained = b.find(objB => !a.some(objA => objA == objB.id));
				console.log(isContained)
				if (isContained) {
					return b.findIndex(obj => obj.id === isContained.id);
				} else {
					return isContained
				}
			},
			save() {
				let newList = Array.from(new Set(this.selectedQuestion))

				console.log(this.softIndexProblem.contentDetails, newList)

				const missingValue = this.findFirstMissingValue(newList, this.softIndexProblem.contentDetails);
				if (missingValue !== undefined) {
					console.log(`数组 A 中第一个未包含在数组 B 中的值是: ${missingValue}`);
					this.missingValue = missingValue
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '您有必填项未填写，请填写完成后再保存';
					return false

				} else {
					console.log('数组 A 包含数组 B 中的所有值');
					if (!this.isSave) {
						this.isSave = true
						this.saveSoft()
					}

				}

			},
			getSaveData() {
				var softJArray = []
				this.softIndexProblem.contentDetails.map((el, i) => {
					el.options.map((els, j) => {
						if (els.chooseFlag == '1') {
							softJArray.push({
								questionId: els.questionId,
								optionId: els.id,
								contentType: this.softIndexProblem.contentType
							})
						}
					})
				})
				// console.log(softJArray)
				this.softJArray = JSON.stringify(softJArray)

			},
			saveSoft() {
				this.getSaveData()
				console.log(this.softJArray)
				this.$post(this.domain.valuationProject + 'question/saveSoftIndex', qs.stringify({
						stage: '',
						reportType: this.$route.query.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						softJArray: this.softJArray,
						version: 2
					}))
					.then((res) => {
						if (this.softIndexProblem.contentType == 76) {
							this.$router.push({
								path: '/yearForecast',
								query: {
									type: this.$route.query.type,
									reportId: res.data.content
								}
							})
						} else {
							var params = {
								index: this.softIndexProblem.contentType,
								softIndexProblem: this.softIndexProblem.contentDetails,
								nextModule: true
							}
							this.$refs.softNav.updateChildData(params);
							this.$router.push({
								path: '/softIndex',
								query: {
									type: '13',
									reportId: res.data.content
								}
							});
							var refxqBox = document.querySelector("#xqBox")
							refxqBox.scrollTop = 0
						}

					})
			},
			getQuestion() {
				this.$Spin.show(); //全局加载中
				var url = this.domain.valuationProject + 'question/getQuestion';
				this.$post(url, qs.stringify({
						stage: sessionStorage.stage,
						reportType: this.$route.query.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: sessionStorage.versions
					}))
					.then((res) => {
						this.$Spin.hide();
						this.softIndexProblem = res.data.content[0]

						this.selectedQuestion = []
						this.softIndexProblem.contentDetails.map((el, i) => {
							this.softIndexProblem.contentDetails[i]['id'] = el.options[0].questionId
							el.options.map((item, j) => {
								if (item.chooseFlag == '1') {
									this.selectedQuestion.push(item.questionId)
								}
							})
						})
					})
			},
			// 关闭弹出层  点击确定回调
			clickConfirmJump() {
				console.log('跳转首页');

			},
			closeMaskInfoJump() {
				console.log('点击软关系字')
				$('.position-tit-container').eq(this.missingValue).addClass('no-choose-red').parent('li').siblings().find(
					'.position-tit-container').removeClass('no-choose-red')
				var refxqBox = document.querySelector("#xqBox")
				refxqBox.scrollTop = $('.position-tit-container').eq(this.missingValue)[0].offsetTop
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getQuestion();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				var this_ = this
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type, '', this, 'softJArray')
				setTimeout(function() {
					this_.getQuestion();
					this_.$refs.softNav.getQuestion(1);
				}, 100)
			},



		}
	}
</script>

<style scoped src="../../assets/css/data.css"></style>
<style scoped lang="less">
	.fillinMain {
		width: 39%;
		padding-right: 0;
		padding-left: 11px;
	}

	.fillinContent {
		>ul {

			>li {
				margin-bottom: 20px;
			}
		}
	}

	.options-container li {
		width: 104%;
		padding: 7px 9px 7px 60px;
	}

	.desc-container>span {
		width: 15px;
		height: 15px;
	}

	@media screen and (max-width:1500px) and (max-height:700px) {
		.options-container label {
			font-size: 18px;
		}

		.position-tit-container>span {
			font-size: 20px;
		}

		.desc-container .desc-main>p {
			font-size: 16px;
		}

	}
</style>