<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" />

			<div class="userinfo">
				<div class="change">
					<p class="title">修改手机号</p>
					<!-- identityAuthentication -->
					<ul class="tabs">
						<li v-for="(item,index) in step" :key="index" :class="[item.checked?'active':'']">
							<img v-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
								:src="item.checked?item.icon2:item.notIcon2" alt="" />
							<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
								:src="item.checked?item.icon3:item.notIcon3" alt="" />
							<img v-else-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
								:src="item.checked?item.icon4:item.notIcon4" alt="" />
							<img v-else :src="item.checked?item.icon:item.notIcon" />
							<p class="name">{{item.name}}</p>
							<div class="line" v-if="index!=2">
								<span
									:style="{'background':item.finish?$store.state.user.subjectColor.mainColor:hexToRgb($store.state.user.subjectColor.mainColor, '0.2')}"></span>
								<img v-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
									:src="item.finish?require('@/assets/img/myCenter/triangled2.png'):require('@/assets/img/myCenter/triangle2.png')" />
								<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
									:src="item.finish?require('@/assets/img/myCenter/triangled3.png'):require('@/assets/img/myCenter/triangle3.png')" />
								<img v-else-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
									:src="item.finish?require('@/assets/img/myCenter/triangled4.png'):require('@/assets/img/myCenter/triangle4.png')" />
								<img v-else
									:src="item.finish?require('@/assets/img/myCenter/triangled.png'):require('@/assets/img/myCenter/triangle.png')" />
								<span
									:style="{'background':item.finish?$store.state.user.subjectColor.mainColor:hexToRgb($store.state.user.subjectColor.mainColor, '0.2')}"></span>
							</div>
						</li>
					</ul>

					<!--身份认证  -->
					<div v-if="slideIndex==0" class="operation">
						<div class="safetyTips">
							<img v-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
								src="../../../assets/img/myCenter/safetyShield2.png" alt="" />
							<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
								src="../../../assets/img/myCenter/safetyShield3.png" alt="" />
							<img v-else-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
								src="../../../assets/img/myCenter/safetyShield4.png" alt="" />
							<img v-else src="../../../assets/img/myCenter/safetyShield.png" alt="" />
							<p>为确保账户安全，需对您的操作进行验证</p>
						</div>
						<div class="account" style="margin-bottom: 20px;">
							<span>手机号码</span>
							<span>{{$store.state.user.userName.slice(0,3)+'****'+$store.state.user.userName.slice(7)}}</span>
						</div>
						<Form ref="telForm" :model="data" :rules="rules" class="formInformation">
							<FormItem prop="code">
								<div class="item">
									<label for="code"><span class="reRed">*</span>短信验证码</label>
									<div class="baseRight" style="display: flex;justify-content: space-between;">
										<Input id="code" v-model="data.code" placeholder="请输入验证码" style="width: 410px;" />
										<Button type="primary" class="getCode buttonFill" :disabled="!!cooling"
											@click="getCaptcha($store.state.user.userName)">
											{{ cooling ? "重新发送(" + cooling + ")" : "获取验证码" }}
										</Button>
									</div>
								</div>
							</FormItem>
							<FormItem>
								<button type="button" class="buttonFill save" @click="saveTel">下一步</button>
							</FormItem>
						</Form>
					</div>

					<!-- 设置操作 -->
					<div v-else-if="slideIndex==1" class="operation">
						<div class="account" style="margin-bottom: 20px;">
							<span>当前手机号码</span>
							<span>{{$store.state.user.userName.slice(0,3)+'****'+$store.state.user.userName.slice(7)}}</span>
						</div>
						<Form ref="newTelForm" :model="data2" :rules="rules2" class="formInformation">
							<FormItem prop="phoneNo">
								<div class="item">
									<label for="newPhone"><span class="reRed">*</span>新手机号</label>
									<div class="baseRight">
										<Input type="number" id="newPhone" v-model="data2.phoneNo" placeholder="请输入" />
									</div>
								</div>
							</FormItem>
							<FormItem prop="code">
								<div class="item">
									<label for="code"><span class="reRed">*</span>短信验证码</label>
									<div class="baseRight" style="display: flex;justify-content: space-between;">
										<Input id="code" v-model="data2.code" placeholder="请输入验证码" style="width: 410px;" />
										<Button type="primary" class="getCode buttonFill" :disabled="!!cooling"
											@click="getCaptcha(data2.phoneNo)">
											{{ cooling ? "重新发送(" + cooling + ")" : "获取验证码" }}
										</Button>
									</div>
								</div>
							</FormItem>
							<FormItem>
								<button type="button" class="buttonFill save" @click="saveNewTel">确定修改</button>
							</FormItem>
						</Form>
					</div>

					<div v-else class="operation" style="text-align: center;">
						<img src="../../../assets/img/myCenter/succeed.png" style="margin-top: 70px;" />
						<p style="margin-top: 25px;font-size: 16px;color: #282828;">新手机号修改成功</p>
					</div>


				</div>
			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import qs from 'qs'
	import axios from 'axios'
	import '../personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
		},
		data() {
			return {
				slideIndex: 0,
				cooling: '',
				step: [{
					icon: require('@/assets/img/myCenter/identityChecked.png'),
					icon2: require('@/assets/img/myCenter/identityChecked2.png'),
					icon3: require('@/assets/img/myCenter/identityChecked3.png'),
					icon4: require('@/assets/img/myCenter/identityChecked4.png'),
					notIcon: require('@/assets/img/myCenter/identityChecked.png'),
					notIcon2: require('@/assets/img/myCenter/identityChecked2.png'),
					notIcon3: require('@/assets/img/myCenter/identityChecked3.png'),
					notIcon4: require('@/assets/img/myCenter/identityChecked4.png'),
					checked: true, //展示选中的当前页
					finish: false, //是否完成
					name: '身份认证'
				}, {
					icon: require('@/assets/img/myCenter/setChecked.png'),
					icon2: require('@/assets/img/myCenter/setChecked2.png'),
					icon3: require('@/assets/img/myCenter/setChecked3.png'),
					icon4: require('@/assets/img/myCenter/setChecked4.png'),
					notIcon: require('@/assets/img/myCenter/set.png'),
					notIcon2: require('@/assets/img/myCenter/set2.png'),
					notIcon3: require('@/assets/img/myCenter/set3.png'),
					notIcon4: require('@/assets/img/myCenter/set4.png'),
					checked: false, //展示选中的当前页
					finish: false, //是否完成
					name: '设置操作'
				}, {
					icon: require('@/assets/img/myCenter/finishChecked.png'),
					icon2: require('@/assets/img/myCenter/finishChecked2.png'),
					icon3: require('@/assets/img/myCenter/finishChecked3.png'),
					icon4: require('@/assets/img/myCenter/finishChecked4.png'),
					notIcon: require('@/assets/img/myCenter/notFinish.png'),
					notIcon2: require('@/assets/img/myCenter/notFinish2.png'),
					notIcon3: require('@/assets/img/myCenter/notFinish3.png'),
					notIcon4: require('@/assets/img/myCenter/notFinish4.png'),
					checked: false, //展示选中的当前页
					finish: false, //是否完成
					name: '完成'
				}],
				data: {
					code: '' //身份认证中验证码
				},
				data2: {
					phoneNo: '', //设置操作中新手机号
					code: '' //设置操作中验证码
				}

			}
		},
		props: {
			newPhone: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
						message: '手机号格式不正确',
						trigger: 'blur'
					}]
				}
			},
			code: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				}
			}

		},
		computed: {
			rules() {
				return {
					code: this.code
				}
			},
			rules2() {
				return {
					phoneNo: this.newPhone,
					code: this.code
				}
			}
		},
		mounted() {


		},
		methods: {
			saveTel() {
				this.$refs.telForm.validate((valid) => {
					if (valid) {
						var obj = {
							phoneNoOrMail: this.$store.state.user.userName,
							code: this.data.code
						}
						this.$post(this.domain.dataRealization + "dataCustomer/checkIdentifyingCode", qs.stringify(obj)).then((
							res) => {
							var data = res.data;
							if (data.code == 200) {
								this.slideIndex = 1
								this.step[0].finish = true
								this.step[1].checked = true
							} else {
								this.$Message.error(res.data.message)
							}
							console.log('步骤------------',this.step)
						})

					}
				})

			},
			saveNewTel() {
				this.$refs.newTelForm.validate((valid) => {
					if (valid) {
						this.$post(this.domain.dataRealization + "secure/modifyPhoneNo", qs.stringify(this.data2))
							.then((
								res) => {
								var data = res.data;
								if (data.code == 200) {
									this.slideIndex = 2
									this.step[1].finish = true
									this.step[2].checked = true
								} else {
									this.$Message.error(res.data.message)
								}
							})

					}
				})
			}

		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}

	.tabs {
		width: 90%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 38px auto 60px;

		li {
			text-align: center;
			position: relative;

			.name {
				font-size: 16px;
				color: #282828;
				margin-top: 5px;
			}

			.line {
				width: 170px;
				height: 28px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: absolute;
				left: 128px;
				top: 50%;
				margin-top: -28px;


				span {
					width: 70px;
					height: 3px;
				}
			}
		}

		li.active {
			.name {
				color: var(--mainColor);
			}

			.line {

				span {
					// background: var(--mainColor) !important;
				}
			}
		}
	}

	.operation {
		width: 94%;
	}

	.safetyTips {
		text-align: center;

		p {
			font-size: 16px;
			color: #282828;
			text-align: center;
			margin-top: 20px;
		}
	}
</style>