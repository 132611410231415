import qs from 'qs'
import axios from 'axios'

import {
	mapActions,
	mapMutations
} from 'vuex'

import {
	Modal,
	Input,
	DIV,
	Switch
} from 'iview'

export default {
	data() {
		return {
			headerWidth: 0
		}
	},
	setup() {
		// 切换主题方式1：修改全局CSS变量
		let changeTheme = (color, copigmentation) => {
			document.documentElement.style.setProperty("--mainColor", color); //给变量设置对应颜色
			document.documentElement.style.setProperty("--complementaryColors", copigmentation); //给变量设置对应颜色
		};
		return {
			changeTheme
		};
	},
	methods: {
		...mapActions([
			'getNavLogo',
			'getFooterLogo',
			'getGeneralSetting',
			'getSubjectColor',
			'getIsLogining',
			'getCustomer',
			'getsAvatar'
		]),
		splitArrayAt(arr, index) {
			if (index < 0 || index > arr.length) {
				throw new Error('Index out of bounds');
			}
			return {
				firstPart: arr.slice(0, index),
				secondPart: arr.slice(index)
			};
		},
		showNav(list) {
			// console.log(this.$refs.nav.offsetWidth)
			if (this.$refs.nav) {
				let totalWidth = window.innerWidth > 1200 ? window.innerWidth - 600 : 650;
				let itemsPerRow = 0;
				let currentWidth = 0;

				this.headerWidth = totalWidth
				console.log('totalWidth-------', totalWidth)

				list.forEach(item => {
					currentWidth += item.barName.length * 14 + 34;
					if (currentWidth <= totalWidth) {
						itemsPerRow++;
					}
				});

				console.log(`每行最多可容纳 ${itemsPerRow} 个元素`);
				if (itemsPerRow >= list.length) {
					this.navList = list
				} else {
					this.moreNav = this.splitArrayAt(list, itemsPerRow - 1);
					this.moreNav.firstPart.push({
						barName: '更多',
						overtop: true,
						barStatus: 1,
						linkType: 1,
						link: ''
					})
					console.log(this.moreNav)
				}
			}

		},
		getNav() {
			var element = document.getElementsByTagName('html')[0]
			// var element = document.getElementById('app')
			// element.style.display = 'none'
			// element.style.display = 'none'

			this.$Loading.config({
				color: this.$store.state.user.subjectColor.mainColor
			});
			this.$Loading.start();
			this.$post(this.domain.dataRealization + 'barSetup/getBarList', qs.stringify({
					channelCode: window.channel,
				}))
				.then((res) => {
					// element.style.display = 'block'
					this.$Loading.finish();

					this.navList = []
					res.data.content.map(el => {
						if (el.barStatus == 1) {
							this.navList.push(el)
						}
					})
					console.log(this.navList)
					this.navList.map(el => {
						if (el.setupType == 1 && el.linkType != 1) el.link = 'Home'
						else if (el.setupType == 2 && el.linkType != 1) el.link = 'dataEvaluationIntroduction'
						else if (el.setupType == 3 && el.linkType != 1) el.link = 'dataValuationIntroduction'
						else if (el.setupType == 4 && el.linkType != 1) el.link = 'dataDiscoveryIntroduction'
						else if (el.setupType == 5 && el.linkType != 1) el.link = 'newsInformation'
						else if (el.setupType == 6 && el.linkType != 1) el.link = 'aboutUs'

					})

					this.showNav(this.navList)

					if (res.data.code == '401') {
						var isLogining = true
						this.getIsLogining({
							isLogining
						})
					}

				});
		},
		getLogo() {
			this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
					channelCode: window.channel,
					setupType: 7
				}))
				.then((res) => {
					var setupContent = JSON.parse(res.data.content.setupContent)
					const navLogo = setupContent.webLogo
					const footerLogo = setupContent.footerLogo
					const generalSetting = setupContent
					this.getGeneralSetting({
						generalSetting
					})

					for (var i = 0; i < setupContent.themeColor.length; i++) { //设置主题色
						if (setupContent.themeColor[i].checked) {

							const subjectColor = setupContent.themeColor[i]
							this.getSubjectColor({
								subjectColor
							})
							this.changeTheme(setupContent.themeColor[i].mainColor, setupContent.themeColor[i]
								.complementaryColors) //设置主题色
							console.log('主题色----', setupContent.themeColor[i].mainColor)
							break
						}
					}

				});
		},
		getCustomerInfo() { //查用户个人信息
			this.$post(this.domain.dataRealization + 'dataCustomer/getCustomerInfo', qs.stringify({}))
				.then((res) => {
					const data = res.data.content
					const headPortrait = res.data.content.headerAddr ? this.domain.testUrl + this.domain.dataRealization + res
						.data.content.headerAddr : ''
					this.getCustomer({
						data
					})

					this.getsAvatar({
						headPortrait
					})


				});
		}



	}
}