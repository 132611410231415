<template>
	<div>
		<!-- <p class="unit">单位：元</p> -->

		<div class="yearLimit">
			<label for="netWorth">
				无形资产账面净值
				<i style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</i>
				<span class="reRed">*</span>
			</label>
			<div class="baseRight">
				<input type="number" id="netWorth" autocomplete="off" v-model="netWorth"
					@blur="displayThousandths($event,netWorth,'netWorth')" @wheel="method.disableWheel($event)">
				<span class="showInput" @click="inputFocus($event)">
					{{netWorth!=''?this.method.formateNum(netWorth,2):''}}
				</span>
				<span class="measure">元</span>
			</div>
		</div>

		<div v-for="(bus,index) in singlenessBusiness">
			<div class="yearLimit" style="margin-bottom: 20px;">
				<label for="totalYear">预计未来经济寿命年限<span class="reRed">*</span></label>
				<div class="baseRight">
					<input type="number" id="totalYear" autocomplete="off" v-model="bus.serviceLife"
						@blur="changeYear(bus.serviceLife)" @wheel="method.disableWheel($event)">
					<span class="measure">年</span>
				</div>
				<p v-text="'（2年≤年限≤10年）'"></p>
			</div>


			<div class="resourceRditBox resourceFrame" v-if="isShowRedact">
				<i></i>
				<ul>
					<li>
						<label for="companyName">数据资源名称<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="companyName" class="required" data-value='false' v-model="redact.resourceName"
								@focus="focus($event)" @change="change" autocomplete="off" maxlength="30">
						</div>
					</li>
					<li>
						<div class="originalNetWorth">
							<label>数据资源账面原值</label>
							<div class="baseRight">
								<input type="number" autocomplete="off" v-model="redact.originalValue"
									@blur="displayThousandths($event,redact.originalValue,'originalValue')" @focus="focus($event)"
									@change="change" @wheel="method.disableWheel($event)">
								<div class="showInput" @click="inputFocus($event)">
									{{redact.originalValue?method.formateNum(redact.originalValue,2):''}}
								</div>
								<span class="measure">元</span>
							</div>
						</div>

						<div class="originalNetWorth">
							<label>数据资源账面净值<span class="reRed">*</span>
								<span
									style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</span>
							</label>
							<div class="baseRight">
								<input type="number" autocomplete="off" v-model="redact.netValue"
									@blur="displayThousandths($event,redact.netValue,'netValue')" @focus="focus($event)" @change="change"
									@wheel="method.disableWheel($event)">
								<div class="showInput" @click="inputFocus($event)">
									{{redact.netValue?method.formateNum(redact.netValue,2):''}}
								</div>
								<span class="measure">元</span>
							</div>
						</div>
					</li>
					<li>
						<label>数据资源介绍<span class="reRed">*</span></label>
						<div class="baseRight text-area-container">
							<Input v-model="redact.resDescribe" type="textarea" :maxlength="maxLength" @input="updateCount"
								placeholder="请简要描述数据资源产品/服务情况，包括不限于其功能、特点、使用场景和潜在用途等方面。" />
							<span class="char-count">{{ currentCount }}/{{ maxLength }}</span>
						</div>
					</li>
				</ul>

				<div class="saveBox2">
					<span data-isClick="false" @click="cancelRedact('')">取消</span>
					<span data-isClick="false" @click="addSave('',index)">确定</span>
				</div>

				<i></i>
			</div>

			<div class="resourceList">
				<p class="addBtn" v-if="isShowAdd" @click="addSet">添加</p>
				<ul>
					<li v-for="(item,i) in bus.dataResources">

						<div class="resourceRditBox listEditing resourceFrame" ref="editData">
							<i></i>
							<ul>
								<li>
									<label for="companyName">数据资源名称<span class="reRed">*</span></label>
									<div class="baseRight empty">
										<input type="text" id="companyName" class="required" data-value='false'
											v-model="redact.resourceName" @focus="focus($event)" @change="change" autocomplete="off"
											maxlength="30">
									</div>
								</li>
								<li>
									<div class="originalNetWorth">
										<label>数据资源账面原值</label>
										<div class="baseRight">
											<input type="number" autocomplete="off" v-model="redact.originalValue"
												@blur="displayThousandths($event,redact.originalValue,'originalValue')" @focus="focus($event)"
												@change="change" @wheel="method.disableWheel($event)">
											<div class="showInput" @click="inputFocus($event)">
												{{redact.originalValue?method.formateNum(redact.originalValue,2):''}}
											</div>
											<span class="measure">元</span>
										</div>
									</div>

									<div class="originalNetWorth">
										<label>数据资源账面净值<span class="reRed">*</span>
											<span
												style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</span>
										</label>
										<div class="baseRight">
											<input type="number" autocomplete="off" v-model="redact.netValue"
												@blur="displayThousandths($event,redact.netValue,'netValue')" @focus="focus($event)"
												@change="change" @wheel="method.disableWheel($event)">
											<div class="showInput" @click="inputFocus($event)">
												{{redact.netValue?method.formateNum(redact.netValue,2):''}}
											</div>
											<span class="measure">元</span>
										</div>
									</div>
								</li>
								<li>
									<label>数据资源介绍<span class="reRed">*</span></label>
									<div class="baseRight text-area-container">
										<Input v-model="redact.resDescribe" type="textarea" :maxlength="maxLength" @input="updateCount"
											placeholder="请简要描述数据资源产品/服务情况，包括不限于其功能、特点、使用场景和潜在用途等方面。" />
										<span class="char-count">{{ currentCount }}/{{ maxLength }}</span>
									</div>
								</li>
							</ul>

							<div class="saveBox2">
								<span data-isClick="false" @click="cancelRedact(i)">取消</span>
								<span data-isClick="false" @click="addSave(i,index)">确定</span>
							</div>

							<i></i>
						</div>

						<div class="detailedDisplay resourceFrame">
							<i></i>
							<p class="operate">
								<span @click="edit(i,index)">编辑</span>
								<span @click="del(i,index)">删除</span>
							</p>
							<div>
								<span>数据资源名称：</span>
								<span>{{item.resourceName}}</span>
							</div>

							<div>
								<p>
									<span>数据资源账面原值：</span>
									<span>{{item.originalValue?method.formateNum(item.originalValue,2)+'元':''}}</span>
								</p>
								<p>
									<span>数据资源账面净值：
										<i
											style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</i>
									</span>
									<span>{{method.formateNum(item.netValue,2)}}元</span>
								</p>
							</div>
							<div>
								<span>数据资源介绍：</span>
								<span>{{item.resDescribe}}</span>
							</div>
							<i></i>
						</div>
					</li>
					<li class="totalNetWorth" v-if="bus.dataResources.length>0">
						<span>数据资源账面净值合计</span>
						<span>{{productSum[2].value?method.formateNum(productSum[2].value,2):''}}</span>
					</li>
				</ul>


			</div>

		</div>





		<Modal v-model="model" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../../../assets/img/fillin/unsave.png">
			<p>确定是否删除</p>
		</Modal>

	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	import {
		merge
	} from 'lodash'


	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址

		},
		data() {
			return {
				year: new Date().getFullYear(),
				model: false,
				createTime: '',
				netWorth: '', //无形资产
				iaBookValue: '', //会计政策统计表中无形资产账面净值总计
				redact: {
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '', //数据资源介绍
				},
				singlenessBusiness: [{
					seq: 1,
					valuationReportId: "",
					businessName: "", //业务线名称
					serviceLife: "", //年限
					dataResources: [
						// 	{
						// 	name: '',
						// 	originalValue: '', //原值
						// 	netValue: '' //净值
						// },
					]
				}],
				productSum: [{
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}],
				isSave: false,
				businessIdx: 0,
				resourcesIdx: 0,
				resourceType: 0, // 0：单一  1：多种
				hint: false,
				maxLength: 200, //介绍最大长度
				currentCount: 0, //介绍当前输入多少字数
				isShowRedact: true, //是否展示编辑框
				isShowAdd: false //是否展示添加按钮


			}
		},
		mounted() {
			this.getDataResources()
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			updateCount() {
				this.isSave = false
				this.currentCount = this.redact.resDescribe.length;
				// 如果需要，可以在这里添加额外的逻辑，如超过最大字数时阻止输入等  
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.focus();
			},
			resourceName() {
				this.isSave = false
			},
			changeYear(value) {
				this.isSave = false
				if (value > 10 || value < 2) {
					this.showDialogBar(true, 'notEmpty', '年限不正确')
				}

			},
			change() {
				this.isSave = false
				this.pageModify('true');
			},
			summation() {
				var originalValue = 0 //原值总和
				var netValue = 0 //净值总和

				this.singlenessBusiness.map((el, s) => {
					el.dataResources.map((item, k) => {
						originalValue += Number(item.originalValue)
						netValue += Number(item.netValue)
					})
				})

				this.productSum[1].value = originalValue
				this.productSum[2].value = netValue
			},
			displayThousandths: function(e, val, key, i, j) {
				this.isSave = false
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val.length < 20) {
					console.log('符合')

				} else {
					console.log('走l')

					if (key == 'netWorth') {
						this.netWorth = ''
					} else {
						this.singlenessBusiness[i].dataResources[j][key] = ''
					}

					this.$Message.error({
						background: true,
						content: '请输入合理数据'
					});
					return false;
				}

				this.summation()
				e.currentTarget.nextSibling.style.display = 'inline-block';

				//调用缓存共用方法
				// if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0')) {
				// 	this.method.caching("financialBookValue", JSON.stringify(this.data), this.$route.query.type, '', this)
				// }
			},
			setEditBoxShow(i) {
				this.$refs.editData.map((el, j) => {
					if (j == i) {
						this.$refs.editData[i].style.display = 'block' //展示当前的元素的编辑框
						this.$refs.editData[i].nextSibling.style.display = 'none'
					} else {
						this.$refs.editData[j].style.display = 'none' //展示当前的元素的编辑框
						this.$refs.editData[j].nextSibling.style.display = 'block'
					}
				})
			},
			setEditBoxHide(i) {
				this.$refs.editData.map((el, j) => {
					if (j == i) {
						this.$refs.editData[i].style.display = 'none' //展示当前的元素的编辑框
						this.$refs.editData[i].nextSibling.style.display = 'block'
					} else {
						this.$refs.editData[j].style.display = 'none' //展示当前的元素的编辑框
						this.$refs.editData[j].nextSibling.style.display = 'block'
					}
				})
			},
			edit(i, index) { //编辑
				this.isShowAdd = false //隐藏添加按钮
				this.isShowRedact = false

				this.setEditBoxShow(i)


				this.redact = this.convertData(this.singlenessBusiness[index].dataResources[i]) //赋值展示到页面
				this.updateCount() //更新数据介绍的文字输入的总数

			},
			del(i, j) {
				this.model = true
				this.businessIdx = j
				this.resourcesIdx = i
			},
			submitDialog() {
				this.singlenessBusiness[this.businessIdx].dataResources.splice(this.resourcesIdx, 1);

				if (this.singlenessBusiness[this.businessIdx].dataResources.length == 0) {
					this.isShowRedact = true
					this.emptyData()
				}

				this.summation()
				this.saveInterface()
			},
			convertData(obj) {
				const newData = JSON.parse(JSON.stringify(obj));;
				return newData
			},
			emptyData() {
				var obj = {
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '', //数据资源介绍
				}
				this.redact = this.convertData(obj)
			},
			addSet() {
				this.isShowRedact = true //显示初始的编辑框
				this.isShowAdd = false //隐藏添加按钮
				this.emptyData()
			},
			getDataResources() {
				this.$post(this.url + 'dataResources/getDataResources', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						resourceType: this.resourceType
					}))
					.then(res => {
						if (!res.data.content.netWorth && sessionStorage.netWorth) {
							this.netWorth = sessionStorage.netWorth
						} else {
							this.netWorth = this.method.numRound(res.data.content.netWorth)
							sessionStorage.netWorth = this.netWorth
						}
						this.iaBookValue = res.data.content.iaBookValue ? res.data.content.iaBookValue : ''
						this.createTime = res.data.content.createTime


						if (res.data.content.single) {
							this.singlenessBusiness = []
							res.data.content.single.map(el => {
								if (el.businessName == 'productSum') {
									this.productSum = JSON.parse(el.dataResources)
								} else {
									el.dataResources = JSON.parse(el.dataResources)
									if (el.dataResources.length > 0) {
										this.isShowRedact = false
										this.isShowAdd = true
									}
									this.singlenessBusiness.push(el)
								}
							})


						}

						console.log('单一数据-------', this.singlenessBusiness)

					})

			},
			save() {
				var this_ = this
				var shouldBreak = false; // 标志变量  

				if (this.netWorth == '') {
					this.showDialogBar(true, 'notEmpty-info', '无形资产账面净值为必填');
					return false
				}


				for (var i = 0; i < this.singlenessBusiness.length && !shouldBreak; i++) {
					for (var j = 0; j < this.singlenessBusiness[i].dataResources.length && !shouldBreak; j++) {
						if (!this.singlenessBusiness[i].dataResources[j].name) {
							this.showDialogBar(true, 'notEmpty', '请输入数据资源名称');
							shouldBreak = true; // 设置标志变量为true，以跳出循环  
							break; // 跳出内层循环  
						} else if (this.singlenessBusiness[i].dataResources[j].netValue == '' || this.singlenessBusiness[i]
							.dataResources[j].netValue <= 0) {
							this.showDialogBar(true, 'notEmpty', '账面净值不能小于0');
							shouldBreak = true;
							break;
						} else if (this.singlenessBusiness[i].serviceLife > 10 || this.singlenessBusiness[i].serviceLife < 2) {
							this.showDialogBar(true, 'notEmpty', '年限不正确');
							shouldBreak = true;
							break;
						}
					}

					if (shouldBreak) {
						break; // 如果需要，这里可以再加一个break来确保跳出外层循环  
					}
				}

				// 这里的代码会在满足条件并跳出循环后执行  
				if (shouldBreak) {
					return false
				}

				if (this.method.numRound(this.productSum[2].value) <= 0) {
					this.showDialogBar(true, 'notEmpty-info', '账面净值不能小于0');
					return false
				}

				if (!this.netWorth) {
					this.showDialogBar(true, 'notEmpty-info', '无形资产账面净值为必填项');
					return false
				}

				if (this.method.numRound(this.productSum[2].value) > this.method.numRound(this.netWorth)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实账面净值的合计应与无形资产账面净值一致');
					return false
				}

				if (this.productSum[1].value && this.method.numRound(this.productSum[2].value) > this.method.numRound(this
						.productSum[1].value)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实账面净值的合计应与账面原值的合计一致');
					return false
				}

				if (this.method.numRound(this.iaBookValue) > this.method.numRound(this.netWorth)) {
					this.hint = true
					this.showDialogBar(true, 'notEmpty-info', '请核实无形资产账面净值应与会计政策统计表中无形资产账面净值统计表的总计一致');
					return false
				}


				sessionStorage.netWorth = this.method.numRound(this.netWorth)

				console.log('可以保存----', this.singlenessBusiness)
				this.saveInterface()

			},
			saveInterface(goUrl) {
				sessionStorage.netWorth = this.method.numRound(this.netWorth)
				var this_ = this
				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'dataResources/saveDataResources', qs.stringify({
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							dataResources: JSON.stringify(this.singlenessBusiness),
							productSum: JSON.stringify(this.productSum),
							netWorth: this.netWorth,
							reportType: this.type,
							resourceType: this.resourceType
						}))
						.then(res => {
							this.isSave = false
							if (res.data.code == 200) {
								if (goUrl) {
									setTimeout(function() {
										this_.$router.push({
											name: this_.jumpAddress,
											query: {
												reportId: res.data.content
											}
										})
									}, 500)
								} else {
									if (!this.$route.query.reportId) {
										this.$router.push({
											query: merge({}, {
												'reportId': res.data.content
											})
										})
									}

								}

							}

						})
				}
			},
			cancelRedact(i) {
				this.emptyData()

				if (i === '') {
					if (this.singlenessBusiness[0].dataResources.length > 0) {
						this.isShowAdd = true
						this.isShowRedact = false
					}
				} else {
					this.isShowRedact = false
					this.isShowAdd = true
					this.setEditBoxHide(i)
				}

			},
			addSave(i, index) {

				console.log('转换数据---', this.convertData(this.redact))

				if (this.netWorth == '') {
					this.showDialogBar(true, 'notEmpty-info', '无形资产账面净值为必填');
					return false
				}

				if (this.singlenessBusiness[index].serviceLife > 10 || this.singlenessBusiness[index].serviceLife < 2) {
					this.showDialogBar(true, 'notEmpty', '年限不正确');
					return false
				}

				if (!this.redact.resourceName) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源名称');
					return false
				}

				if (!this.redact.netValue) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源账面净值');
					return false
				} else if (this.redact.netValue <= 0) {
					this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值不能小于0');
					return false
				}

				if (!this.redact.resDescribe) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源介绍');
					return false
				}

				if (this.redact.netValue && this.redact.originalValue && this.method.numRound(this.redact.netValue) > this.method
					.numRound(this.redact
						.originalValue)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实数据资源账面净值应与数据资源账面原值一致');
					return false
				}

				if (this.method.numRound(this.productSum[2].value) > this.method.numRound(this.netWorth)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实账面净值的合计应与无形资产账面净值一致');
					return false
				}


				if (i === '') {
					this.singlenessBusiness[index].dataResources.unshift(this.redact)
				} else {
					this.$refs.editData[i].style.display = 'none'
					this.$refs.editData[i].nextSibling.style.display = 'block'
					this.singlenessBusiness[index].dataResources[i] = this.convertData(this.redact)
				}
				this.isShowRedact = false
				this.isShowAdd = true


				this.summation() //计算合计
				this.saveInterface()
				console.log('点击了确定', this.singlenessBusiness)
				console.log('合计------', this.productSum)
			},
			nextStep() {
				var this_ = this
				if (this.method.numRound(this.iaBookValue) > this.method.numRound(this.netWorth)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实无形资产账面净值应与会计政策统计表中无形资产账面净值统计表的总计一致');
					this.hint = true
					return false
				} else {
					this.saveInterface(true)
				}


			},


			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
				if (this.hint) {
					this.saveInterface()
				}
			}


		}

	}
</script>

<style scoped lang="less">
	.yearLimit {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px 0;

		>p {
			font-size: 14px;
			color: #fff;
		}

		label {
			width: 268px;
			font-weight: bold;
			font-size: 16px;
			color: #fff;
		}

		input,
		.baseRight,
		.showInput {
			width: 268px;
		}

		input {
			width: 82%;
			height: 30px;
			background: #1237C6;
			border: 1px solid #29FFFC;
			font-size: 14px;
			color: #fff;
			padding: 0 26px 0 10px;
		}

		input:focus {
			border-color: #fff;
		}

		.showInput {
			width: 80%;
			height: 27px;
			line-height: 28px;
			background: #1237C6;
			padding: 0 6px;
			color: #fff;
			font-size: 14px;
			position: absolute;
			top: 2px;
			left: 2px;
			overflow: hidden;
		}

		.measure {
			width: 25px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 11px;
			color: #fff;
			font-size: 14px;
		}

		.baseRight {
			height: 30px;
			position: relative;
		}
	}
</style>