<template>
	<div>
		<Carousel loop :height="380" arrow="never" dots="none">
			<CarouselItem>
				<div class="banner">
					<div>
						<p class="pageType">个人中心</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
	</div>
</template>

<script>
		export default {
			mounted() {
				this.$nextTick(()=>{
					window.scrollTo(0, document.querySelector('.my').offsetTop);
				})
			}
		}
</script>

<style scoped lang="less">
	.banner {
		background: url(../../assets/img/myCenter/banner.jpg) center center no-repeat;
	}
</style>