import { render, staticRenderFns } from "./softIndex.vue?vue&type=template&id=291c185f&scoped=true"
import script from "./softIndex.vue?vue&type=script&lang=js"
export * from "./softIndex.vue?vue&type=script&lang=js"
import style0 from "./softIndex.vue?vue&type=style&index=0&id=291c185f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291c185f",
  null
  
)

export default component.exports