<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" @getName="getName" />

			<div class="userinfo">
				<Search :titleInfo="searchTitle" :url="domain.evaluation+'valuationReport/getDataValuationListByCustomerPage'"
					:parameter="parameter" :allStatus="allStatus" @getList="getList" />

				<List type="14" :listData="records" :parameter="parameter" :total="total" />

			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import Search from '@/components/listSearch/index.vue'
	import List from '@/components/valuationList/index.vue'
	import qs from 'qs'
	import axios from 'axios'
	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
			Search,
			List

		},
		data() {
			return {
				searchTitle: {},
				name: '',
				records: [], //auditStatus:   1：审核通过   2：审核中   0：未通过     payStatus:支付状态  1：已支付        
				currentPageNumber: 1, //当前页码
				total: 0, //总页数
				pageSize: 5, //每页条数
				parameter: {
					pageNum: 1,
					status: 3,
					pageSize: 5,
					name: '',
					reportType: 14,
				},
				allStatus: [{ //支付状态：3：全部；0：未支付；1：已支付；
					state: '全部状态',
					statusCode: 3
				}, {
					state: '未支付',
					statusCode: 0
				}, {
					state: '已支付',
					statusCode: 1
				}],
				myAlert: false,


			}
		},
		mounted() {
			this.findDataProduct()
		},
		methods: {
			getName(data) { //获取搜索栏的信息
				console.log(data)
				this.searchTitle = data
			},
			getList(data) { //获取列表信息
				this.records = data.records
				this.parameter.name = data.name
				this.parameter.status = data.status
				this.total = data.total
			},
			pageChange(data) { //切换页数
				console.log(data)
				this.parameter.pageNum = data
				this.findDataProduct()
			},
			findDataProduct() {
				this.$post(this.domain.evaluation + 'valuationReport/getDataValuationListByCustomerPage', qs.stringify({
						pageNum: this.parameter.pageNum,
						status: this.parameter.status,
						pageSize: this.pageSize,
						name: this.parameter.name,
						reportType: this.parameter.reportType,
					}))
					.then((res) => {
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			},


		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}
</style>