import sensitiveWord from '../js/sensitiveWord.json'
import qs from 'qs'
export default {
	filterSensitiveWords(e, dataKey, whetherFinancial) { //过滤敏感词    e:dom对象  dataKey：基本信息key   whetherFinancial:是否是财务报表
		var that = this;
		var sensitiveWordsList = sensitiveWord.sensitiveWord;
		var sensitiveWordsFlag = false;
		for (var i = 0; i < sensitiveWordsList.length; i++) {
			if (e.value.indexOf(sensitiveWordsList[i]) != -1) {
				sensitiveWordsFlag = true;
			}
			var len = sensitiveWordsList[i].length;
			var str = '';
			for (var l = 0; l < len; l++) {
				str += '*'
			}
			var r = new RegExp(sensitiveWordsList[i], "ig");
			e.value = e.value.replace(r, str);
		}
		if (whetherFinancial == 'statement') {
			dataKey[0] = e.value;
		} else {
			dataKey[e.getAttribute('id')] = e.value;
		}
		return sensitiveWordsFlag
	},
	// 报告中估值结果单位转换及千分位展示(最大值、最小值)
	valuationResultConversion(min, max) {
		// 最小值
		if (Math.abs(min) / 10000 > 1) {
			if (Math.sign(min) == -1) { //值为负数
				min = '-' + this.formateNumTwo((Math.abs(min) / 10000).toFixed(2)) + '亿元'
			} else {
				min = this.formateNumTwo((Math.abs(min) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(min) == -1) { ////值为负数
				min = '-' + this.formateNumTwo(Math.abs(min).toFixed(2)) + '万元';
			} else {
				min = this.formateNumTwo(Math.abs(min).toFixed(2)) + '万元';
			}
		}

		// 最大值
		if (Math.abs(max) / 10000 > 1) {
			if (Math.sign(max) == -1) { //值为负数
				max = '-' + this.formateNumTwo((Math.abs(max) / 10000).toFixed(2)) + '亿元'
			} else {
				max = this.formateNumTwo((Math.abs(max) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(max) == -1) { ////值为负数
				max = '-' + this.formateNumTwo(Math.abs(max).toFixed(2)) + '万元';
			} else {
				max = this.formateNumTwo(Math.abs(max).toFixed(2)) + '万元';
			}
		}
		return min + '~' + max
	},
	resultConversion(data) { //单个数据换算单位和千分位展示
		if (Math.abs(data) / 10000 > 1) {
			if (Math.sign(data) == -1) { //值为负数
				data = '-' + this.formateNumTwo((Math.abs(data) / 10000).toFixed(2)) + '亿元'
			} else {
				data = this.formateNumTwo((Math.abs(data) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(data) == -1) { //值为负数
				data = '-' + this.formateNumTwo(Math.abs(data).toFixed(2)) + '万元';
			} else {
				data = this.formateNumTwo(Math.abs(data).toFixed(2)) + '万元';
			}
		}
		return data
	},
	switchNum: function(min, max) {
		var unit = '';
		// 最小值
		if (Math.abs(min) / 10000 > 1) {
			if (Math.sign(min) == -1) { //值为负数
				min = (Math.abs(min) / 10000).toFixed(2)
			} else {
				min = (Math.abs(min) / 10000).toFixed(2)
			}
		} else {
			if (Math.sign(min) == -1) { ////值为负数
				min = Math.abs(min).toFixed(2)
			} else {
				min = Math.abs(min).toFixed(2)
			}
		}

		// 最大值
		if (Math.abs(max) / 10000 > 1) {
			if (Math.sign(max) == -1) { //值为负数
				max = (Math.abs(max) / 10000).toFixed(2)
			} else {
				max = (Math.abs(max) / 10000).toFixed(2)
			}
			unit = '亿元'
		} else {
			if (Math.sign(max) == -1) { ////值为负数
				max = Math.abs(max).toFixed(2)
			} else {
				max = Math.abs(max).toFixed(2)
			}
			unit = '万元'
		}
		return {
			min: min,
			max: max,
			unit: unit
		}
	},
	technologyNum: function(data) {
		var unit = '';
		if (Math.abs(data) / 10000 > 1) {
			if (Math.sign(data) == -1) { //值为负数
				data = (Math.abs(data) / 10000).toFixed(2)
			} else {
				data = (Math.abs(data) / 10000).toFixed(2)
			}
			unit = '亿元'
		} else {
			if (Math.sign(data) == -1) { ////值为负数
				data = Math.abs(data).toFixed(2)
			} else {
				data = Math.abs(data).toFixed(2)
			}
			unit = '万元'
		}
		return {
			data: data,
			unit: unit
		}
	},
	technologyNumInterval(data) {
		var unit = '';
		let min = data.split('-')[0]
		let max = data.split('-')[1]
	
		if (Math.abs(max) / 10000 > 1) {
	
			min = (Math.abs(min) / 10000).toFixed(2)
			max = (Math.abs(max) / 10000).toFixed(2)
	
			unit = '亿元'
		} else {
			min = Math.abs(min).toFixed(2)
			max = Math.abs(max).toFixed(2)
	
			unit = '万元'
		}
		return {
			data: min + '-' + max,
			unit: unit
		}
	},
	/**
	 * @func 数字格式化      千分位
	 * @param {string} str   需要格式化的数字
	 * @param {number} num   小数的位数
	 * @returns {string} str
	 */
	formateNumTwo(str, num) {
		var isnegative = true; // 表示正负数；false：小于0
		var value;
		if (isNaN(parseFloat(str))) {
			return;
		}
		if (parseFloat(str) < 0) {
			isnegative = false;
			value = str.split("-")[1]
		} else {
			value = str;
		}
		value = value.replace(/^\s+|\s+$/g, '');
		var right = value.split(".")[1] ? value.split(".")[1] : "0",
			rightValue;
		var left = value.split(".")[0].split("").reverse();
		if (right.length == num) {
			rightValue = right;
		} else if (right.length < num) {
			var i = 0,
				rightValue = right;
			while (num - right.length - i > 0) {
				rightValue += "0";
				i++;
			}

		} else {
			rightValue = right.substr(0, num)
		}
		var total = new Array();
		for (var i = 0; i < left.length; i++) {
			total.push(left[i]);
			if ((i + 1) % 3 == 0 && (i + 1) != left.length) {
				total.push(",");
			}
		}
		if (isnegative) {
			return num == 0 ? total.reverse().join("") : total.reverse().join("") + "." + rightValue;
		} else {
			return num == 0 ? "-" + total.reverse().join("") : "-" + total.reverse().join("") + "." + rightValue;
		}
	},
	formateNum(str) {
		var value, isnegative = true;
	
		str = str ? str.toString() : ''
	
		if (str === "") {
			return "-";
		}
		if (isNaN(parseFloat(str))) {
			return;
		}
		if (parseFloat(str) < 0) {
			isnegative = false;
			value = (Math.round(str.split("-")[1] * 100) / 100)
			value = value.toString()
		} else {
			str = Math.round(str * 100) / 100
			value = str.toString();
		}
		value = value.replace(/^\s+|\s+$/g, '');
		//	console.log(value,typeof value)
		var right = value.split(".")[1] ? value.split(".")[1].substr(0, 2).length > 1 ? value.split(".")[1].substr(0, 2) :
			value.split(".")[1].substr(0, 2) + "0" : "00";
		var left = value.split(".")[0].split("").reverse(); // 将左边的字符反转 ，数组
		var total = new Array();
		for (var i = 0; i < left.length; i++) {
			total.push(left[i]);
			if ((i + 1) % 3 == 0 && (i + 1) != left.length) {
				total.push(",");
			}
		}
		if (isnegative) {
			return total.reverse().join("") + "." + right;
		} else {
			return "-" + total.reverse().join("") + "." + right;
		}
	},
	numRound(data) {
		return Number(Number(data).toFixed(2))
	},
	// 填写页面缓存 (没有报告id没有数据和有报告id没有数据这两种情况进行缓存)
	caching(category, info, reportType, stage, _this) {
		// 缓存接口调用===========
		var cacheIntervalTime = 2000; //缓存时间间隔秒数  （目前是5秒）
		var cacheCurrentTime = Date.now(); //当前时间
		if (sessionStorage.cacheTime) {
			if (Number(cacheCurrentTime) > (Number(sessionStorage.cacheTime) + cacheIntervalTime)) {
				sessionStorage.cacheTime = Date.now();
				// 缓存接口调用===========
				var url = '';
				// 临时  http://192.168.12.120:8089/
				url = _this.domain.ajaxUrl + 'cache/saveCache'
				var params = {
					category: category,
					info: info,
					reportType: reportType,
					stage: stage
				};
				_this.$post(url, qs.stringify(params))
					.then((res) => {
						// console.log(res)
					});
				// console.log('当前时间超过上次保存的时间5秒')
			} else {
				// console.log('当前时间没超过上次保存的时间5秒')
			}
		} else {
			sessionStorage.cacheTime = Date.now();
		}
	},
	cacheInfo(category, info, reportType, stage, _this) {
		var url = '';
		// 临时  http://192.168.12.120:8089/
		url = _this.domain.ajaxUrl + 'cache/saveCache'
		var params = {
			category: category,
			info: info,
			reportType: reportType,
			stage: stage
		};
		_this.$post(url, qs.stringify(params))
			.then((res) => {
				// console.log(res)
			});
	},
	// 清缓存调用
	clearCaching(type, stage, _this, getinfo) {
		var url = '';
		url = _this.domain.ajaxUrl + 'cache/deleteCache'
		var params = {
			reportType: type,
			stage: stage,
		};
		_this.$post(url, qs.stringify(params))
			.then((res) => {
				// 获取getinfo接口
				if (getinfo == 'getBaseInfo') {
					_this.getBaseInfo()
				} else if (getinfo == 'getBalanceSheet') {
					_this.getBalanceSheet()
				} else if (getinfo == 'getProfit') {
					_this.getProfit()
				} else if (getinfo == 'getQuestion') {
					_this.getQuestion()
				} else if (getinfo == 'getOperatingReceipt') {
					_this.getOperatingReceipt()
				} else if (getinfo == 'getAccountingPolicy') {
					_this.getAccountingPolicy()
				}

			});
	},
	transform() {
		// 获取元素
		var element = document.getElementById('formWidth');
		var width = 1870;
		var height = 1027;

		// 计算缩放比例，假设你想要元素在一屏内完全显示
		if (window.innerWidth < 1200) { //1290 是给两边各留20px的距离
			var scale = Math.min(1160 / width, (window.innerHeight - 90) / height);
		} else {
			var scale = Math.min((window.innerWidth - 40) / width, (window.innerHeight - 80) / height);
		}

		var calculateHeight = -(scale * element.offsetHeight / 2 - 165) + 'px'

		// 应用缩放和偏移 calc(-50% - 40px)  translate(-50%, -50%)
		// element.style.transform = 'translate(-50%,-50%) scale(' + scale + ')';

		element.style.transform = 'scale(' + scale + ') translate(-50%,-50%)';

		var labelElements = document.getElementsByTagName('label');

		for (var i = 0; i < labelElements.length; i++) {
			// labelElements[i].style.fontSize = 1/scale+'em';
		}

	},
	disableWheel(evt) {
		console.log('禁用滚轮')
		// 禁用滚轮
		evt = evt || window.event
		if (evt.preventDefault) {
			// Firefox + Chrome + Safari
			evt.preventDefault()
			evt.stopPropagation()
		} else {
			// IE
			evt.cancelBubble = true
			evt.returnValue = false
		}
		return false
	},
	ratioEquality(originalWidth, originalHeight,
		presentWidth) { //等比设置元素的宽高  originalWidth:原始宽   originalHeight：原始高   presentWidth：现在的宽
		const height = (originalHeight * presentWidth) / originalWidth

		// console.log(presentWidth)
		// console.log('新高度------',height)

		return height

	},
	ratioEqualityHeight(originalWidth, originalHeight, presentHeight) {
		const width = (presentHeight / originalHeight) * originalWidth

		return width

	},
	scrollY(box){	//Y轴滚动
		var scrollY,pointX,pointY,top=0,moveX,moveY;
		var move=false;
		var box=$(box);
		var H=box.height();
		var W=box.width();
		var cententH=box.children().height();
		var btnH=H/cententH*H;
		var scrollY='<div class="scrollboxs" style="width:14px;position:absolute;top:10%;right:26%;z-index:0;border: 2px solid #30ECEE;border-radius: 10px;overflow: hidden;height:'+H+'px;">';
			scrollY+='<div id="scrollbtn" style="width:100%;height:'+btnH+'px;margin:0 auto;position:relative;cursor:pointer" tops="0">';
			scrollY+='<div style="background-color:#30ECEE;position:absolute;width:100%;left:0;height:100%;border-radius:10px"></div>';
			scrollY+='</div></div>';
		box.parent().css({"position":"relative"});
		
		console.log(H,cententH)
		
		if(H/cententH<1){
			$(".scrollboxs").remove();
			box.before(scrollY);
		}else{
			$(".scrollboxs").remove();
		}
		$("#scrollbtn").hover(function(){
			$(this).children().css({
				"background-color":"pink",
			});
			
		},function(){
			$(this).children().css({
				"background-color":"#30ECEE",
			});
			
		});
		function addscroll(){
			box.scroll(function(){
				scrollY=$(this).scrollTop();
				$("#scrollbtn").css({
					"margin-top":scrollY/cententH*H
				});
				top=scrollY/cententH*H;
				$("#scrollbtn").attr("tops",top);
			});
		}
		addscroll();
		$("#scrollbtn").mousedown(function(e){
			pointX = e.pageX;
			//这里可以得到鼠标Y坐标
			pointY = e.pageY;
			move=true;
			top=$("#scrollbtn").attr("tops")*1;
			$(this).focus();
		});
		$(document).mousemove(function(e){
			e.stopPropagation();
			if(move==true){
				box.unbind();
				moveX =e.pageX-pointX;
				//这里可以得到鼠标Y坐标
				moveY =e.pageY-pointY;
				$("#scrollbtn").css({
					"margin-top":top+moveY
				});
				$("#scrollbtn").attr("tops",top+moveY);
				box.scrollTop((top+moveY)/H*cententH);
				if(top+moveY>H-btnH){
					$("#scrollbtn").css({
						"margin-top":H-btnH
					});
					$("#scrollbtn").attr("tops",H-btnH);
				}else if(top+moveY<0){
					$("#scrollbtn").css({
						"margin-top":0
					});
					$("#scrollbtn").attr("tops",0);
				}
			}
		});
		$(document).mouseup(function(e){
			move=false;
			addscroll();
		});
	}







}