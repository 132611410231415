<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='2' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>财务指标</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">

							<professionalFinancenNav :stage="stage" :type="type" :versions="versions" :currentFinance='0' />

							<SpecialtyBalanceSheet ref="element" :stage="stage" :type="type" :versions="versions" :url="url"
								jumpAddress="dataProfitSpecialty" />

							<div class="saveBox">
								<router-link v-if="this.$route.query.reportId" tag="span" class="last"
									:to="{ path: '/dataSoftSpecialty', query: { reportId:this.$route.query.reportId,soft: 4 } }">上一步</router-link>
								<router-link v-else tag="span" class="last" :to="{ path: '/dataSoftSpecialty', query: { soft: 4 } }">上一步</router-link>
								<span data-isClick="false" @click="save">下一步</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import professionalFinancenNav from '../nav/professionalFinancenNav.vue'
	import SpecialtyBalanceSheet from '@/components/dataValuation/financialStatement/specialtyAssetLiability.vue'
	import qs from 'qs'
	import axios from 'axios';

	export default {
		components: {
			Header,
			fillinNav,
			professionalFinancenNav,
			SpecialtyBalanceSheet

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '13',
				versions: '2'

			}
		},
		mounted() {

		},
		methods: {
			save() {
				this.$refs.element.save()
			}


		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
</style>