import { render, staticRenderFns } from "./profitStatement.vue?vue&type=template&id=09d64461&scoped=true"
import script from "./profitStatement.vue?vue&type=script&lang=js"
export * from "./profitStatement.vue?vue&type=script&lang=js"
import style0 from "./profitStatement.vue?vue&type=style&index=0&id=09d64461&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09d64461",
  null
  
)

export default component.exports