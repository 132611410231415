<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE" :style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='0' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>基本信息</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">

							<Basic ref="basicInfo" :stage="stage" :type="type" :versions="versions" :url="url"
								jumpAddress="dataValuationSoftFast" />


							<div class="saveInfo" @click="save">信息确认无误，保存</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import Basic from '@/components/dataValuation/basicInformation/index.vue'
	import qs from 'qs'
	import axios from 'axios';

	export default {
		components: {
			Header,
			fillinNav,
			Basic

		},
		data() {
			return {
				width: window.innerWidth,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '15',
				versions: '2'

			}
		},
		mounted() {

		},
		methods: {
			save() {
				this.$refs.basicInfo.save()
			}


		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
	
</style>