<template>
  <div>
    <!-- :key="tinymceFlag" -->
    <textarea :id="'dom'+domSelector" v-model="info"></textarea>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: String,
        required: true
      },
      selector: {
        type: Number,
        required: false
      }
    },
    data() {
      return {
        tinymceFlag: 0,
        editor: null,
        domSelector: this.selector ? this.selector : new Date().getTime()
      };
    },
    watch: {
      info: {
        immediate: true, // 在初始加载时也会触发监听函数
        deep: true,
        handler(value) {
          this.$nextTick(() => {
            console.log('监听富文本的值----', value)
            console.log(this.editor)
            try {
              if (this.editor && typeof this.editor === 'object') {
                console.log('重新设置--',value)
                this.editor.setContent(value);
              } else {
                // 编辑器实例不存在或不为对象时的处理逻辑
                console.warn("Editor instance is not ready or is not an object.");
              }
            } catch (error) {
              console.error("An error occurred while setting content:", error);
            }
          });
        }
      },
      selector: {
        immediate: true, // 在初始加载时也会触发监听函数
        deep: true,
        handler(value) {
          this.$nextTick(() => {
            try {
              if (this.editor && typeof this.editor === 'object') {
                console.log('selector--------',this.info)
                this.editor.setContent(this.info);
              } else {
                // 编辑器实例不存在或不为对象时的处理逻辑
                console.warn("Editor instance is not ready or is not an object.");
              }
            } catch (error) {
              console.error("An error occurred while setting content:", error);
            }
          });
        }
      }
    },
    mounted() {
      var this_ = this

      this_.init()

    },
    methods: {
      init() {
        var this_ = this
        tinymce.init({
          selector: `#dom${this.domSelector}`,
          language: 'zh-Hans',
          height: '400',
          // toolbar: 'undo redo | formatselect | ' +
          // 	'bold italic backcolor | alignleft aligncenter ' +
          // 	'alignright alignjustify | bullist numlist outdent indent | ' +
          // 	'removeformat',
          toolbar: 'fontselect styleselect fontsizeselect | undo redo | bold italic underline strikethrough subscript superscript removeformat forecolor backcolor lineheight align outdent indent | bullist numlist | charmap emoticons | link', // 工具栏配置
          font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
          fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 26px 36px 48px 56px', // 工具栏自定义字体大小选项
          image_advtab: true, // 为上传图片窗口添加高级属性 需引入插件image
          plugins: 'advlist autolink code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools insertdatetime link lists media nonbreaking noneditable pagebreak paste preview save searchreplace tabfocus table textcolor visualblocks visualchars wordcount charmap',
          readonly: this.$route.query.readonly == 1 ? true : false, //只读模式
          setup: editor => {
            this.editor = editor;
            console.log('lllllllllllllll')
            // this.tinymceFlag = 2
            editor.on('init', function(e) {
              console.log('init--------', editor.getContent(), '-------', this_.info)
              if (!editor.getContent()) {
                // editor.setContent(this_.$store.state.user.productInfo.introduction ? this_.$store.state.user
                //   .productInfo.introduction : this_.info);

                editor.setContent(this_.info)

              }
            });

            editor.on('blur', () => {
              this.$emit('getContent', editor.getContent())
            });
          },
          file_picker_types: 'image',
          file_picker_callback: function(callback, value, meta) {
            if (meta.filetype == "image") {
              let input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = function() {
                let reader = new FileReader();
                reader.onload = function(e) {
                  callback(e.target.result, {
                    alt: ''
                  });
                };
                reader.readAsDataURL(this.files[0]);
                input.remove();
              };
              input.click();
            }
          }


        });
      }

    },
    beforeDestroy() {
      if (this.editor && typeof this.editor === 'object') {
        // this.editor.destroy();
      }
    }
  };
</script>
