<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" category='利润表' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">利润表</p>
							<div class="specialtyFinance profitSpecialty fillWith">
								<p class="unit">单位：元</p>
								<div>
									<ul class="yeaAccount">
										<i></i>
										<li>
											<span>项目</span>
											<span v-for="item in yearList">{{item}}度</span>
										</li>
										<i></i>
									</ul>

									<div class="tabulation">
										<ul class="professionalForm">
											<li>
												<span class="trOne">一、营业收入</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.income[i]" @blur="((ev)=>{blur(ev,profit,'income',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('income',i)?method.formateNum(profit.income[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">减：营业成本</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.cost[i]" @blur="((ev)=>{blur(ev,profit,'cost',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('cost',i)?method.formateNum(profit.cost[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">税金及附加</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.taxAdditional[i]"
														@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('taxAdditional',i)?method.formateNum(profit.taxAdditional[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">销售费用</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.saleCost[i]"
														@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('saleCost',i)?method.formateNum(profit.saleCost[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">管理费用</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.manageCost[i]"
														@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('manageCost',i)?method.formateNum(profit.manageCost[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">研发费用</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.researchCost[i]"
														@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('researchCost',i)?method.formateNum(profit.researchCost[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">财务费用</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.financeCost[i]"
														@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('financeCost',i)?method.formateNum(profit.financeCost[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrFour">其中：利息支出</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.interestCost[i]"
														@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('interestCost',i)?method.formateNum(profit.interestCost[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrFive">利息收入</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.interestIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'interestIncome',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('interestIncome',i)?method.formateNum(profit.interestIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">加：其他收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.otherBenefits[i]"
														@blur="((ev)=>{blur(ev,profit,'otherBenefits',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('otherBenefits',i)?method.formateNum(profit.otherBenefits[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">投资收益(损失以"-"号填列)</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.investmentIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'investmentIncome',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('investmentIncome',i)?method.formateNum(profit.investmentIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrFour">其中：对联营企业和合营企业的投资收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.enterpriseIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'enterpriseIncome',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('enterpriseIncome',i)?method.formateNum(profit.enterpriseIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrFive">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.financialAssetsBenefits[i]"
														@blur="((ev)=>{blur(ev,profit,'financialAssetsBenefits',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('financialAssetsBenefits',i)?method.formateNum(profit.financialAssetsBenefits[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">净敞口套期收益（损失以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.netExposureHedgeIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'netExposureHedgeIncome',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('netExposureHedgeIncome',i)?method.formateNum(profit.netExposureHedgeIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">公允价值变动收益（损失以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.fairValueIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'fairValueIncome',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('fairValueIncome',i)?method.formateNum(profit.fairValueIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">信用减值损失（损失以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.creditImpairmentLoss[i]"
														@blur="((ev)=>{blur(ev,profit,'creditImpairmentLoss',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('creditImpairmentLoss',i)?method.formateNum(profit.creditImpairmentLoss[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">资产减值损失（损失以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.assetLoss[i]"
														@blur="((ev)=>{blur(ev,profit,'assetLoss',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('assetLoss',i)?method.formateNum(profit.assetLoss[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">资产处置收益（损失以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.assetDisposalIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'assetDisposalIncome',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('assetDisposalIncome',i)?method.formateNum(profit.assetDisposalIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">二、营业利润（亏损以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.businessProfit[i]" disabled>
													<span class="showInput">
														{{judgingCondition('businessProfit',i)?method.formateNum(profit.businessProfit[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">加：营业外收入</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.outsideIncome[i]"
														@blur="((ev)=>{blur(ev,profit,'outsideIncome',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('outsideIncome',i)?method.formateNum(profit.outsideIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">减：营业外支出</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.outsideExpenditure[i]"
														@blur="((ev)=>{blur(ev,profit,'outsideExpenditure',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('outsideExpenditure',i)?method.formateNum(profit.outsideExpenditure[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">三、利润总额（亏损总额以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.incomeTax[i]" disabled>
													<span class="showInput">
														{{judgingCondition('incomeTax',i)?method.formateNum(profit.incomeTax[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">减：所得税费用</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.incomeTax[i]"
														@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('incomeTax',i)?method.formateNum(profit.incomeTax[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">四、净利润（净亏损以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.netProfit[i]" disabled>
													<span class="showInput">
														{{judgingCondition('netProfit',i)?method.formateNum(profit.netProfit[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">(一) 按经营持续性分类</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="profitTrThree">1.持续经营净利润（净亏损以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.goingConcernNetProfit[i]"
														@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('goingConcernNetProfit',i)?method.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">2.终止经营净利润（净亏损以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.discontinuedOperationsNetProfit[i]"
														@blur="((ev)=>{blur(ev,profit,'discontinuedOperationsNetProfit',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('discontinuedOperationsNetProfit',i)?method.formateNum(profit.discontinuedOperationsNetProfit[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">(二) 按所有权归属分类</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="profitTrThree">1.归属于母公司股东的净利润（净亏损以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.parentCompanyNetProfit[i]"
														@blur="((ev)=>{blur(ev,profit,'parentCompanyNetProfit',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('parentCompanyNetProfit',i)?method.formateNum(profit.parentCompanyNetProfit[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">2.少数股东损益（净亏损以"-"号填列）</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.minorityStockLegalRight[i]"
														@blur="((ev)=>{blur(ev,profit,'minorityStockLegalRight',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('minorityStockLegalRight',i)?method.formateNum(profit.minorityStockLegalRight[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">五、其他综合收益的税后净额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.ociNetTax[i]" disabled>
													<span class="showInput">
														{{judgingCondition('ociNetTax',i)?method.formateNum(profit.ociNetTax[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">(一)归属于母公司所有者的其他综合收益的税后净额 </span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.parentCompanyOciNetTax[i]" disabled>
													<span class="showInput">
														{{judgingCondition('parentCompanyOciNetTax',i)?method.formateNum(profit.parentCompanyOciNetTax[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">1.不能重分类进损益的其他综合收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.notReclassifiedOci[i]" disabled>
													<span class="showInput">
														{{judgingCondition('notReclassifiedOci',i)?method.formateNum(profit.notReclassifiedOci[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(1)重新计量设定受益计划变动额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.remeasureBenefitAmount[i]"
														@blur="((ev)=>{blur(ev,profit,'remeasureBenefitAmount',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('remeasureBenefitAmount',i)?method.formateNum(profit.remeasureBenefitAmount[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(2)权益法下不能转损益的其他综合收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.notTransferOci[i]"
														@blur="((ev)=>{blur(ev,profit,'notTransferOci',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('notTransferOci',i)?method.formateNum(profit.notTransferOci[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(3)其他权益工具投资公允价值变动</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.otherEquityFairValue[i]"
														@blur="((ev)=>{blur(ev,profit,'otherEquityFairValue',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('otherEquityFairValue',i)?method.formateNum(profit.otherEquityFairValue[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(4)企业自身信用风险公允价值变动</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.enterpriseCreditRiskFairValue[i]"
														@blur="((ev)=>{blur(ev,profit,'enterpriseCreditRiskFairValue',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('enterpriseCreditRiskFairValue',i)?method.formateNum(profit.enterpriseCreditRiskFairValue[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrThree">2.将重分类进损益的其他综合收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.reclassifiedOci[i]" disabled>
													<span class="showInput">
														{{judgingCondition('reclassifiedOci',i)?method.formateNum(profit.reclassifiedOci[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(1)权益法下可转损益的其他综合收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.transferOci[i]"
														@blur="((ev)=>{blur(ev,profit,'transferOci',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('transferOci',i)?method.formateNum(profit.transferOci[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(2)其他债权投资公允价值变动</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.otherClaimsFairValue[i]"
														@blur="((ev)=>{blur(ev,profit,'otherClaimsFairValue',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('otherClaimsFairValue',i)?method.formateNum(profit.otherClaimsFairValue[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(3)金融资产重分类计入其他综合收益的金额 </span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.financialAssetsOciAmount[i]"
														@blur="((ev)=>{blur(ev,profit,'financialAssetsOciAmount',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('financialAssetsOciAmount',i)?method.formateNum(profit.financialAssetsOciAmount[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(4)其他债权投资信用减值准备</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.otherClaimsCreditImpairment[i]"
														@blur="((ev)=>{blur(ev,profit,'otherClaimsCreditImpairment',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('otherClaimsCreditImpairment',i)?method.formateNum(profit.otherClaimsCreditImpairment[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(5)现金流量套期储备</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.cashFlowHedgingReserve[i]"
														@blur="((ev)=>{blur(ev,profit,'cashFlowHedgingReserve',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('cashFlowHedgingReserve',i)?method.formateNum(profit.cashFlowHedgingReserve[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrSix">(6)外币财务报表折算差额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.foreignCurrencyBalance[i]"
														@blur="((ev)=>{blur(ev,profit,'foreignCurrencyBalance',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('foreignCurrencyBalance',i)?method.formateNum(profit.foreignCurrencyBalance[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">(二)归属于少数股东的其他综合收益的税后净额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.minorityStockOciNetTax[i]"
														@blur="((ev)=>{blur(ev,profit,'minorityStockOciNetTax',i)})"
														@wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('minorityStockOciNetTax',i)?method.formateNum(profit.minorityStockOciNetTax[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">六、综合收益总额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.totalComprehensiveIncome[i]" disabled>
													<span class="showInput">
														{{judgingCondition('totalComprehensiveIncome',i)?method.formateNum(profit.totalComprehensiveIncome[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">(一)归属于母公司所有者的综合收益总额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.parentCompanyTci[i]" disabled>
													<span class="showInput">
														{{judgingCondition('parentCompanyTci',i)?method.formateNum(profit.parentCompanyTci[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trTwo">(二)归属于少数股东的综合收益总额</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.minorityStockTci[i]" disabled>
													<span class="showInput">
														{{judgingCondition('minorityStockTci',i)?method.formateNum(profit.minorityStockTci[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="trOne">七、每股收益</span>
												<span v-for="(item,i) in yearList"></span>
											</li>
											<li>
												<span class="profitTrTwo">(一)基本每股收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.basicEps[i]"
														@blur="((ev)=>{blur(ev,profit,'basicEps',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('basicEps',i)?method.formateNum(profit.basicEps[i],2):'-'}}
													</span>
												</span>
											</li>
											<li>
												<span class="profitTrTwo">(二)稀释每股收益</span>
												<span v-for="(item,i) in yearList">
													<input type="number" v-model="profit.dilutedEps[i]"
														@blur="((ev)=>{blur(ev,profit,'dilutedEps',i)})" @wheel="method.disableWheel($event)">
													<span class="showInput">
														{{judgingCondition('dilutedEps',i)?method.formateNum(profit.dilutedEps[i],2):'-'}}
													</span>
												</span>
											</li>
										</ul>

										<p class="line"></p>
									</div>

								</div>

							</div>



						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				yearList: ['xxxx年', 'xxxx年'],
				id: '',
				isCacheValue: '',
				profit: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []

				},


			}
		},
		mounted() {
			this.init()
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.profit[key][i] === 0) {
					return true
				} else if (this.profit[key][i] != 0 && this.profit[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur() {

			},
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();
						this.yearList = res.data.content.liabilities.year;

						this.profit = JSON.parse(res.data.content.profit.profit)


					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
</style>