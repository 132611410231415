<template>
	<div class="financeNav">
		<ul>
			<li v-for="(item,index) in list" :key="index" @click="financing(index)">
				<div :class="[slideIndex === index?'softActive':'']">
					<img class="bj" v-if="slideIndex === index" :src="item.selectedIcon" />
					<img class="bj" v-else :src="item.isAccomplish?item.accomplishIcon:item.icon" />
					<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">{{item.name}}
					</p>
				</div>
				<img class="arrows" v-if="index!=list.length-1" src="../../assets/img/finance/arrows.png" />
			</li>
		</ul>
		<img src="../../assets/img/softIndex/softPedestal.png" />


		<Modal v-model="myAlert" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../assets/img/fillin/unsave.png">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>

	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		data() {
			return {
				slideIndex: 0,
				skip: 0,
				myAlert: false,
				list: [{
					name: '年预测',
					isAccomplish: false,
					icon: require('@/assets/img/finance/annualForecastBlue.png'),
					selectedIcon: require('@/assets/img/finance/annualForecastOrange.png'),
					accomplishIcon: require('@/assets/img/finance/annualForecastGreen.png'),
					url: '/yearForecast'
				}, {
					name: '账面净值',
					isAccomplish: false,
					icon: require('@/assets/img/finance/carryingValueBlue.png'),
					selectedIcon: require('@/assets/img/finance/carryingValueOrange.png'),
					accomplishIcon: require('@/assets/img/finance/carryingValueGreen.png'),
					url: '/carryingValue'
				}, {
					name: '收入预测表',
					isAccomplish: false,
					icon: require('@/assets/img/finance/incomeForecastBlue.png'),
					selectedIcon: require('@/assets/img/finance/incomeForecastOrange.png'),
					accomplishIcon: require('@/assets/img/finance/incomeForecastGreen.png'),
					url: '/incomeForecast'
				}]
			}
		},
		mounted() {
			var this_ = this
			if (this.$route.path == '/yearForecast') {
				this.slideIndex = 0
			} else if (this.$route.path == '/carryingValue') {
				this.slideIndex = 1
			} else if (this.$route.path == '/incomeForecast') {
				this.slideIndex = 2
			}

			this.init()

		},
		methods: {
			financing(i) {
				if (this.$store.state.user.currentPageModify == 'true' && this.$store.state.user.currentPageSave == false && i != this.slideIndex) {
					this.myAlert = true;
					this.skip = i;
				} else {
					this.slideIndex = i
					this.goUrl(i)
				}
			},
			goUrl(i) {
				if (this.$route.query.reportId) {
					this.$router.push({
						path: this.list[i].url,
						query: {
							type: this.$route.query.type,
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						path: this.list[i].url,
						query: {
							type: this.$route.query.type
						}
					})
				}
			},
			submitDialog() {
				this.myAlert = false;
				this.goUrl(this.skip)
			},
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'dataFinance/getFinancialForecast', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.list[0].name = res.data.content.year + '年预测'
						if (this.$store.state.user.checkStatus) {
							setTimeout(function() {
								console.log(this_.$store.state.user.checkStatus)
								this_.list[0].isAccomplish = this_.$store.state.user.checkStatus.financialForecastFlag
								this_.list[1].isAccomplish = this_.$store.state.user.checkStatus.dataBookValueFlag
								this_.list[2].isAccomplish = this_.$store.state.user.checkStatus.dataIncomeFlag
							}, 100)
						}
					})
			},


		}
	}
</script>

<style scoped lang="less">
	.financeNav {
		width: 46%;
		// background-color: pink;
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			bottom: -36px;
		}

		>ul {
			width: 88%;
			margin: 20px auto 0;
			display: flex;
			justify-content: space-between;

			li {
				width: 33.3%;
				height: 103px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				position: relative;

				.arrows {
					position: absolute;
					bottom: 20px;
					right: 41px;
				}

				.bj {
					width: 247px;
					position: relative;
				}

				div {
					position: relative;
					z-index: 2;
					overflow: hidden;


					p {
						width: 160px;
						text-align: center;
						font-size: 18px;
						font-weight: bold;
						color: #fff;
						position: absolute;
						top: 13px;
						left: 88px;
					}
				}

			}

		}

	}

	@media screen and (max-width:1500px) and (max-height:700px) {
		.financeNav>ul li div p {
			font-size: 20px;
		}
	}
</style>