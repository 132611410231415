<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" @getName="getName" />

			<div class="userinfo">
				<p class="subhead">
					<span>订单详情</span>
					<button class="buttonFill" @click="confirmOrder">返回我的订单</button>
				</p>
				<div class="orderDetails"
					:style="{'border':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5')}">
					<p class="orderID"
						:style="{'background':hexToRgb($store.state.user.subjectColor.mainColor, '0.1'),'borderBottom':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5')}">
						订单编号：{{data.id}}
					</p>
					<div>
						<ul class="orderStep">
							<li>
								<p class="stepTitle">1</p>
								<p class="stepDescription">下单时间</p>
								<p class="time">{{data.createTime}}</p>
								<p class="line"
									:style="{'borderColor':data.confirmTime?$store.state.user.subjectColor.mainColor:'#999'}"></p>
							</li>
							<li>
								<p class="stepTitle"
									:style="{'background':data.confirmTime?$store.state.user.subjectColor.mainColor:'#999'}">2</p>
								<p class="stepDescription">确认时间</p>
								<p class="time">{{data.confirmTime?data.confirmTime:''}}</p>
								<p class="line"
									:style="{'borderColor':data.completeTime?$store.state.user.subjectColor.mainColor:'#999'}"></p>
							</li>
							<li>
								<p class="stepTitle"
									:style="{'background':data.completeTime?$store.state.user.subjectColor.mainColor:'#999'}">3</p>
								<p class="stepDescription">完成</p>
								<p class="time">{{data.completeTime?data.completeTime:''}}</p>
							</li>
						</ul>
						<p class="orderInfo">订单信息</p>
						<div class="orders">
							<p class="orderInfoTitle">产品信息</p>
							<ul class="productOrderDetails">
								<li>
									<span>产品名称：</span>
									<span>{{data.productName}}</span>
								</li>
								<li>
									<span>产品编号：</span>
									<span>{{data.productId}}</span>
								</li>
								<li>
									<span>交易供方：</span>
									<span>{{data.companyName}}</span>
								</li>
								<li>
									<span>交付渠道：</span>
									<span>{{data.deliveryMethod}}</span>
								</li>
								<li v-if="data.type==3">
									<span>客户买入到期收益率：</span>
									<span style="width: calc(100% - 141px)">{{data.rate}}</span>
								</li>
								<li v-if="data.type==3">
									<span>期限：</span>
									<span>{{data.term}}</span>
								</li>
								<li v-if="data.type==3">
									<span>剩余期限：</span>
									<span>{{data.residueTerm}}</span>
								</li>
								<li v-if="data.type==3">
									<span>到期日期：</span>
									<span>{{data.termTime}}</span>
								</li>
								<li v-if="data.type==2">
									<span>票面利率：</span>
									<span>{{data.rate}}</span>
								</li>
								<li v-if="data.type==2">
									<span>发行期限：</span>
									<span>{{data.term}}</span>
								</li>
								
								<li v-if="data.type!=6&&data.type!=5&&data.type!=4">
									<span>产品价格：</span>
									<span v-if="data.discuss">{{data.discuss}}</span>
									<span v-else>
										<i style="font-size: 20px;color: #E83323;font-weight: bold;">{{method.formateNum(data.price,2)}}</i>
										<i style="color: #E83323;font-weight: bold;vertical-align:bottom;">元/次</i>
									</span>
								</li>
								<li v-if="data.type==6">
									<span>轮次：</span>
									<span>{{data.round}}</span>
								</li>
								<li v-if="data.type==6">
									<span>行业：</span>
									<span>{{data.industry}}</span>
								</li>
								<li v-if="data.type==6">
									<span>地址：</span>
									<span>{{data.address}}</span>
								</li>
								<li v-if="data.type==5">
									<span>年利率：</span>
									<span>{{data.rate}}</span>
								</li>
								<li v-if="data.type==5">
									<span>贷款期限：</span>
									<span>{{data.term}}</span>
								</li>
								<li v-if="data.type==5">
									<span>贷款额度：</span>
									<span>{{data.loan}}</span>
								</li>
								
							</ul>
							<p class="orderInfoTitle">联系方式</p>
							<ul class="buyLeads">
								<li>
									<span>联系人：</span>
									<span>{{data.contacts?data.contacts:''}}</span>
								</li>
								<li>
									<span>联系方式：</span>
									<span>{{data.phone?data.phone:''}}</span>
								</li>
								<li v-if="data.discuss">
									<span>产品价格：</span>
									<span>{{data.discuss}}</span>
								</li>
								<li class="totalPrices" v-if="!data.discuss&&data.type!=6&&data.type!=5&&data.type!=4">
									<p :style="{'background':hexToRgb($store.state.user.subjectColor.mainColor, '0.3')}">
										<span>价格</span>
										<span>数量</span>
										<span>总价</span>
									</p>
									<p>
										<span>￥{{method.formateNum(data.price,2)}}/次</span>
										<span>{{data.num}}</span>
										<span>￥{{data.totalPrice}}</span>
									</p>
								</li>
							</ul>


						</div>


					</div>
				</div>
			</div>

		</div>

		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import Editor from '@/components/richText/editor.vue'
	import qs from 'qs'
	import axios from 'axios'

	import '../personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
			Editor,

		},
		data() {
			return {
				searchTitle: {},
				data: {
					phone: ''
				}

			}
		},
		mounted() {
			this.getProductOrder()

		},
		methods: {
			getName(data) {
				console.log(data)
				this.searchTitle = data
			},
			getProductOrder() { //orderType（订单状态0：等待确认，1：已确认，2：已完成，3：已取消）
			
				this.$post(this.domain.dataRealization + 'productOrder/getProductOrder', qs.stringify({
						orderId:this.$route.query.id.substr(0, this.$route.query.id.indexOf('-'))
					}))
					.then((res) => {
						if (res.data.code == 200) {
							this.data = res.data.content
						}
					});
			},
			confirmOrder() { //确认订单
				this.$router.push({
					name: 'myOrder',
					params: {
						num: this.$route.query.id.substr(this.$route.query.id.indexOf('-')+1,this.$route.query.id.length - 1)
					}
				})
			},

		}

	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}
	.subhead{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.subhead span{
		font-size: 18px;
		font-weight: bold;
		color: #282828;
	}
	.subhead button{
		width: 160px;
		height: 40px;
		line-height: 40px;
		border-radius: 6px;
		font-size: 16px;
	}
</style>