<template>
	<div>
		<div class="tools">
			<button @click="pdf()">导出PDF</button>
		</div>
		<div id="pageContent">
			<!-- 封面 -->
			<div class="homePage page">
				<div class="content" id="homePage">
					<div class="logoBox">
						<!-- <h1 id="logo"></h1> -->
						<!-- <img v-if="!channel" id="logo" :src="pageInfo.logo" crossOrigin="anonymous" alt="线上估值" /> -->
						<img v-if="!channel" id="logo" :src="pageInfo.logo" alt="线上估值" />
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<div class="homePageLine"></div>
					<div class="reportType">数据估值报告</div>
					<h6>（快速版）</h6>
					<h4>报告编号：{{pageInfo.reportNumber}}</h4>
					<div class="page-one-footer">
						<div style="width:94px;"></div>
						<p>
							<span>{{pageInfo.channelName}}保留对此报告的最终解释权</span>
						</p>
						<div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime.split(' ')[0]) : ''"></div>
					</div>
				</div>
			</div>
			<!-- 盖章页 -->
			<div class="page">
				<!--内容-->
				<div class="content page-content" id="page-stamp">
					<p class="stamp">
						依据《中共中央、国务院关于构建数据基础制度更好发挥数据要素作用的意见》、《企业数据资源相关会计处理暂⾏规定》 （财会〔2023〕11号） 、《数据资产评估指导意见》（中评协〔2023〕17
						号）、《企业会计准则》和《中小微企业估值规范》等政策规章，基于数据资产估值模型和估值指标体系，贵公司数据资产在估值基准日的市场价值为（人民币）：
					</p>
					<div class="detail_result">
						<div>
							<h4 v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</h4>
						</div>
					</div>
					<div class="stamptime">
						<p>{{pageInfo.channelName}}</p>
						<p
							v-text="pageInfo.updateTime ? dateFormate(pageInfo.createTime.split(' ')[0]) : dateFormate(pageInfo.createTime.split(' ')[0])">
						</p>
					</div>

				</div>
			</div>

			<!--目录页-->
			<div class="page">
				<div class="content page-catalogue" id="page-catalogue">
					<h2>目录</h2>
					<div class="generalize">
						<div>
							<p>1</p>
							<div></div>
							<p>基本信息</p>
							<span class="dottedLine"></span>
							<span class="pageNumber pageNumActive">01</span>
						</div>
						<div>
							<p>2</p>
							<div></div>
							<p>综合得分</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">02</span>
						</div>
						<div>
							<p>3</p>
							<div></div>
							<p>软性指标</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">03</span>
						</div>
						<ul>
							<li>
								<p>基础指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">03</span>
							</li>
							<li>
								<p>风险指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">04</span>
							</li>
							<li>
								<p>质量指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">05</span>
							</li>
							<li>
								<p>流通指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">06</span>
							</li>
							<li>
								<p>经济指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">07</span>
							</li>
						</ul>
						<div>
							<p>4</p>
							<div></div>
							<p>数据资源</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">08</span>
						</div>
						<div>
							<p>5</p>
							<div></div>
							<p>MLM估值</p>
							<span class="dottedLine" style="width: 721px;"></span>
							<span
								class="pageNumber pageNumActive">{{8+resourcePaging.length<10?'0'+(8+resourcePaging.length):8+resourcePaging.length}}</span>
						</div>
						<div>
							<p>6</p>
							<div></div>
							<p>估值结果</p>
							<span style="width: 735px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">
								{{9+resourcePaging.length<10?'0'+(9+resourcePaging.length):9+resourcePaging.length}}
							</span>
						</div>
						<div>
							<p>7</p>
							<div></div>
							<p>附件</p>
							<span class="dottedLine" style="width: 784px;"></span>
							<span class="pageNumber pageNumActive">{{10+resourcePaging.length}}</span>
						</div>

						<!-- <div v-if="channel">
							<p>8</p>
							<div></div>
							<p>股交所简介</p>
							<span style="width: 711px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">{{pagesNumber.length+12}}</span>
						</div> -->

						<div>
							<p>{{channel?9:8}}</p>
							<div></div>
							<p>免责声明</p>
							<span style="width: 735px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">{{13+resourcePaging.length}}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 基本信息 -->
			<div class="page">
				<div class="content page-content" id="page-two">
					<div class="baseInfo">
						<div class="valueResultTitle">
							<img src="../../../assets/img/report/icon1.png" class="icon_">
							<span>基本信息</span>
						</div>
						<div class="baseInfoCont">
							<span id="companyName" v-text="pageInfo.baseInfo.companyName"></span>
							<ul>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span id="industry"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>详细地址：</span>
									<span id="companyLocation"
										v-text="pageInfo.baseInfo.address ? pageInfo.baseInfo.address : '暂无数据'"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span id="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span id="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '暂无数据'"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span id="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
								<li>
									<span>注册资本：</span>
									<span id="establishmentDate"
										v-text="pageInfo.baseInfo.regCapital ? method.formateNum(pageInfo.baseInfo.regCapital,2)+'万元' : '暂无数据'"></span>
								</li>
								<li>
									<span>数据类型：</span>
									<span id="dataResourceType" v-text="dataType ? dataType : '暂无数据'"></span>
								</li>
								<li>
									<span>数据量级：</span>
									<span v-text="pageInfo.dataLevel?pageInfo.dataLevel.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>数据资源所处周期：</span>
									<span v-text="pageInfo.dataPeriod?pageInfo.dataPeriod.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>权属情况：</span>
									<span v-text="pageInfo.dataOwnership?pageInfo.dataOwnership.optionName.substring(2):'暂无数据'"></span>
								</li>
							</ul>
						</div>
						<div class="equityStructure">
							<div class="valueResultTitle">
								<img src="../../../assets/img/report/icon3.png" class="icon_">
								<span>股权结构</span>
							</div>
							<div v-if="pageInfo.baseInfo.holderRation">
								<img src="../../../assets/img/report/chart.png" class="piePedestal" alt="饼图">
								<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation"
									v-if="initStatus" />
							</div>
							<div v-else style="padding: 20px 0 0 74px;font-size: 20px;color:#000;">暂无数据</div>

						</div>
					</div>
					<div class="pagination">01</div>
				</div>
			</div>
			<!-- 软性指标 -->
			<div class="page">
				<div class="content page-content" id="page-three">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon5.png" class="icon_">
						<span>综合得分</span>
					</div>
					<p class="scoreDescription">
						综合得分是评估数据价值的重要标准，包括数据的基础指标、风险指标、经济指标、质量指标、流通指标、财务指标等维度。通过采用层次分析、聚类分析和专家打分等多种方法计算各项指标的得分及权重，对数据进行全面分析和评估，帮助企业更准确地识别和挖掘数据的潜在价值，为业务决策和创新提供有力的数据支持。
					</p>
					<div class="softIndexScore">
						<img src="../../../assets/img/report/synthesisScore.png" />
						<div>
							<p>
								<span>{{Number(pageInfo.totalScore).toFixed(2)}}分</span>
								<span>综合得分</span>
							</p>
						</div>
					</div>
					<ul class="indexScore">
						<li>
							<div class="valueResultTitle">
								<img src="../../../assets/img/report/icon17.png" class="icon_">
								<span>软性指标</span>
							</div>
							<div class="indicators">
								<img src="../../../assets/img/report/softIndicators.png" />
								<p v-for="(item,i) in question">
									<span>{{item.name}}</span>
									<span>{{item.value}}分</span>
								</p>
							</div>
						</li>
						<li>
							<div class="valueResultTitle">
								<img src="../../../assets/img/report/icon22.png" class="icon_">
								<span>财务指标</span>
							</div>
							<div class="financeIndicators">
								<img src="../../../assets/img/report/financeIndicators.png" />
								<p v-for="(item,i) in financial">
									<span>{{item.name}}</span>
									<span>{{item.value}}分</span>
								</p>
							</div>
						</li>
					</ul>


					<div class="pagination">02</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-four">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon6.png" class="icon_">
						<span>{{question[0].name}} &nbsp;{{question[0].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/report/basicIndex.png" />
						<ul class="basicIndexScore">
							<li style="left: 17px;">
								<p>{{pageInfo.industry[0][0].value}}分</p>
								<p>{{pageInfo.industry[0][0].name}}</p>
							</li>
							<li style="right: 17px;">
								<p>{{pageInfo.industry[0][1].value}}分</p>
								<p>{{pageInfo.industry[0][1].name}}</p>
							</li>
						</ul>
						<div>
							<img src="../../../assets/img/report/basicIndexIcon.svg" />
							<p>{{question[0].name}}</p>
						</div>
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[0]">{{item.description}}</p>
					</div>
					<div class="pagination">03</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-five">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon7.png" class="icon_">
						<span>{{question[1].name}} &nbsp;{{question[1].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 106px;">
						<img src="../../../assets/img/report/riskIndicator.png" />
						<ul class="basicIndexScore riskIndicatorScore">
							<li style="left: 17px;">
								<p>{{pageInfo.industry[1][0].value}}分</p>
								<p>{{pageInfo.industry[1][0].name}}</p>
							</li>
							<li style="right: 17px;">
								<p>{{pageInfo.industry[1][1].value}}分</p>
								<p>{{pageInfo.industry[1][1].name}}</p>
							</li>
						</ul>
						<div>
							<img src="../../../assets/img/report/riskIndicatorIcon.png" />
							<p>{{question[1].name}}</p>
						</div>
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[1]">{{item.description}}</p>
					</div>
					<div class="pagination">04</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-six">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon8.png" class="icon_">
						<span>{{question[2].name}} &nbsp;{{question[2].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 129px;">
						<img src="../../../assets/img/report/qualityIndex.png" />
						<ul class="qualityIndexScore">
							<li>
								<div>
									<div>
										<p>{{pageInfo.industry[2][0].name}}</p>
										<p>{{pageInfo.industry[2][0].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore1.png" />
							</li>
							<li style="top: 258px;left: -60px;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][1].name}}</p>
										<p>{{pageInfo.industry[2][2].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore2.png" />
							</li>
							<li style="top: 394px;left: 54%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][2].name}}</p>
										<p>{{pageInfo.industry[2][2].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore3.png" />
							</li>
							<li style="top: 258px;right: -64%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][3].name}}</p>
										<p>{{pageInfo.industry[2][3].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore4.png" />
							</li>
							<li style="left: 108%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][4].name}}</p>
										<p>{{pageInfo.industry[2][4].value}}分</p>
									</div>
								</div>
								<img src="../../../assets/img/report/qualityIndexScore5.png" />
							</li>
						</ul>
						<div>
							<p>{{question[2].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 180px;">
						<p v-for="(item,index) in pageInfo.industry[2]">{{item.description}}</p>
					</div>
					<div class="pagination">05</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eight">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon11.png" class="icon_">
						<span>{{question[3].name}} &nbsp;{{question[3].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/report/costIndex.png">
						<ul class="costIndexScore">
							<li style="left: 0px;">
								<p>{{pageInfo.industry[3][0].value}}分</p>
								<p>{{pageInfo.industry[3][0].name}}</p>
							</li>
							<li style="right: 40%;top: 307px;">
								<p>{{pageInfo.industry[3][1].value}}分</p>
								<p>{{pageInfo.industry[3][1].name}}</p>
							</li>
							<li style="right: 0px;">
								<p>{{pageInfo.industry[3][2].value}}分</p>
								<p>{{pageInfo.industry[3][2].name}}</p>
							</li>
						</ul>
						<div>
							<div>
								<img src="../../../assets/img/report/indicatorCosts.png">
								<p>{{question[3].name}}</p>
							</div>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 50px;">
						<p v-for="(item,index) in pageInfo.industry[3]">{{item.description}}</p>
					</div>
					<div class="pagination">06</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-seven">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon9.png" class="icon_">
						<span>{{question[4].name}} &nbsp;{{question[4].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 100px;margin-bottom: 0;">
						<img src="../../../assets/img/report/economicIndicators.png">
						<ul class="economicScore">
							<li style="left: -70px;">
								<span>{{pageInfo.industry[4][0].name}}</span>
								<span>{{pageInfo.industry[4][0].value}}分</span>
							</li>
							<li style="right: -70px;">
								<span>{{pageInfo.industry[4][1].name}}</span>
								<span>{{pageInfo.industry[4][1].value}}分</span>
							</li>
							<li style="left: -122px;top: 140px;">
								<span>{{pageInfo.industry[4][2].name}}</span>
								<span>{{pageInfo.industry[4][2].value}}分</span>
							</li>
							<li style="right: -122px;top: 140px;">
								<span>{{pageInfo.industry[4][3].name}}</span>
								<span>{{pageInfo.industry[4][3].value}}分</span>
							</li>
						</ul>
						<div>
							<p>{{question[4].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 28px;">
						<p v-for="(item,index) in pageInfo.industry[4]">{{item.description}}</p>
					</div>
					<div class="pagination">07</div>
				</div>
			</div>

			<div class="page" v-for="(page,p) in resourcePaging">
				<div class="content page-content dataResourcePage" :id="'page-resource'+p">
					<div v-for="(item,i) in page.data">
						<div class="valueResultTitle">
							<img src="../../../assets/img/report/icon18.png" class="icon_">
							<span>数据资源：{{item.resourceName}}</span>
						</div>
						<p class="resDescribe">{{item.resDescribe}}</p>

						<div class="dataAsset">
							<p class="units" style="margin-bottom: 5px;">单位：元</p>
							<p class="dataAssetNameSpecialty">
								<span>数据资源名称</span>
								<span>账面原值</span>
								<span>
									账面净值
									<i
										style="font-size: 16px;">（{{pageInfo.createTime?getLastDayOfPreviousMonthInChinese(pageInfo.createTime.split(' ')[0]):'xxxx年xx月xx日'}}）</i>
								</span>
							</p>
							<ul>
								<li>
									<span>{{item.resourceName}}</span>
									<span>{{item.originalValue?method.formateNum(item.originalValue,2):''}}</span>
									<span>{{item.netValue?method.formateNum(item.netValue,2):''}}</span>
								</li>
							</ul>
						</div>
						<div class="numberBox">
							<span class="valuationMethod">估值结果：</span>
							<img v-for="(num, index) in scienceTechnology[p].number[i].value" :key="index + 1"
								:src="require('../../../assets/img/report/'+num.val+'.png')"
								:style="{marginTop:(num.val=='11'?'52px':'0'),width:(num.val=='11'?'5px':'36px')}" alt="估值结果数字">
							<p class="unit" style="font-size: 24px;top: 18px;">
								{{method.technologyNum(item.valuation).unit}}
							</p>
						</div>
					</div>


					<div class="pagination">{{8+p<10?'0'+(8+p):8+p}}</div>
				</div>
			</div>


			<div class="page">
				<div class="content page-content" id="page-nine">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon12.png" class="icon_">
						<span>MLM估值</span>
					</div>
					<p style="font-size: 20px;color: #282828;line-height: 42px;padding: 30px 0 0 74px;text-align: justify;">
						MLM（Machine Learning Method，机器学习法）是指通过PCA主成分析、对比学习、知识图谱、混合专家模型等多种机器学习/深度学习方法。
					</p>
					<div class="profitData" id="profitData">
						<svg width='480' height='403'>
							<text class="rotateYear" :x="profitDataYearX[index]" y="76" transform='rotate(-17 280 100)'
								v-for="(item, index) in boxhead">{{item}}</text>
							<text class="rotateData" :x="profitDataIncomeX[index]" y="140" transform='rotate(-17 280 100)'
								v-for="(item, index) in income" :key="index">{{index==0?item:method.formateNum(item, 2)}}</text>
							<text class="rotateDataBlue" :x="profitDataNetAssetX[index]" :y="profitDataNetAssetY[index]"
								transform='rotate(-16 280 100)' v-for="(item, index) in netAsset"
								:key="index+'1'">{{index==0?item:method.formateNum(item, 2)}}</text>
							<text class="rotateData" :x="profitDataNetProfitX[index]" y="224" transform='rotate(-16 280 100)'
								v-for="(item, index) in netProfit" :key="index+'2'">{{index==0?item:method.formateNum(item, 2)}}</text>

							<text class="rotateDataBlue" x="97" y="258" transform='rotate(-17 280 100)'
								style="font-weight: bold;">公司息税折旧</text>
							<text class="rotateDataBlue" x="97" y="276" transform='rotate(-17 280 100)'
								style="font-weight: bold;">摊销前利润</text>

							<text class="rotateDataBlue" x="288" y="270"
								transform='rotate(-17 280 100)'>{{method.formateNum(amortizeProfit[1], 2)}}</text>

						</svg>
					</div>
					<img src="../../../assets/img/report/mlm.svg" alt="MLM估值" style="margin: -48px auto 0;display: block;">

					<div class="machineLearningValuationResults specialNumeric">
						<img src="../../../assets/img/report/technologyBox.png" alt="机器学习估值结果">
						<div class="numberBox">
							<span class="valuationMethod">MLM估值合计金额：</span>
							<img v-for="(item, index) in machineLearningValuationResults" :key="index + 1"
								:src="require('../../../assets/img/report/'+item.val+'.png')"
								:style="{marginTop:(item.val=='11'?'52px':'0'),width:(item.val=='11'?'5px':'36px')}" alt="估值结果数字">
							<p class="unit" style="font-size: 24px;top: 18px;">
								{{method.technologyNum(pageInfo.relativeValuationValue).unit}}
							</p>
						</div>
					</div>
					<ul class="interpretation">
						<li>
							1、模型基于上市和非上市公司数据，选取合适的估值、统计分析方法标注形成数据集，对估值模型训练。
						</li>
						<li>
							2、深度学习估值模型利用神经网络，通过采用梯度下降法最小化损失函数对网络中的权重参数逐层反向调节，通过对训练数据随时间的推移不断学习并提高准确性，利用计算科学智能地计算数据资产价值。
						</li>
					</ul>
					<div class="pagination">
						{{8+resourcePaging.length<10?'0'+(8+resourcePaging.length):8+resourcePaging.length}}
					</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-twelve">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon15.png" class="icon_">
						<span>估值结果</span>
					</div>

					<div class="valuationResultDV">
						<div class="numberBox">
							<div>
								<div v-for="(item, index) in valueResultNumber" :key="index + 1" class="locationUnit">
									<p class="unit" v-if="item.val==12" style="margin-right: 12px;">
										{{method.technologyNum(pageInfo.minValue).unit}}
									</p>
									<img :src="require('../../../assets/img/report/'+item.val+'.png')"
										:style="{marginTop:item.top,width:(item.val=='11'?'5px':item.val=='12'?'20px':'46px')}"
										alt="估值结果数字">
								</div>
								<p class="unit">{{method.technologyNum(pageInfo.maxValue).unit}}</p>
							</div>

						</div>
					</div>
					<img src="../../../assets/img/report/valuationResultFast.png">
					<div class="pagination">{{9+resourcePaging.length}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-accessory">
					<p class="accessoryTitle">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}资产负债表</i>
						<i>单位：元</i>
					</p>

					<LibTableComponent :liaTableInfo="liabilities" v-if="initStatus" :year="pageInfo.year" :versions="0" />

					<p class="accessoryTitle" style="margin-top: 120px;">
						<i>附件1</i>
						<i>{{pageInfo.baseInfo.companyName}}利润表</i>
						<i>单位：元</i>
					</p>
					<ProfitTableComponent :profitTableInfo="profit" v-if="initStatus" :year="pageInfo.year" :versions="0" />

					<div class="pagination">{{10+resourcePaging.length}}</div>
				</div>
			</div>

			<!-- <div class="page" v-if="channel">
				<div class="content page-content" id="page-stock">
					<img class="introduce-img" :src="pageInfo.introImage" alt="公司简介" />
					<div class="pagination">{{pagesNumber.length+12}}</div>
				</div>
			</div> -->

			<div class="page">
				<div class="content page-content" id="page-MLMIntroduction">
					<p class="accessoryTitle">
						<i>附件2</i>
						<i>MLM估值介绍</i>
						<i></i>
					</p>

					<div class="MLMIntroduction">
						<p>
							机器学习估值法是指采用集成学习算法、长短期记忆网络、对比学习和知识图谱等技术，结合8项诺贝尔经济学奖成果和先进的金融工程实践，从多个角度对股权及数据资产价值进行量化。现仅以集成学习算法为例概要说明机器学习方法的估值原理。
						</p>
						<p>
							首先，利用变分推断对公司的财务报表数据进行学习，以提取关键特征和因子变量，将数据映射到潜在空间中，并通过潜在变量的分布来描述数据的特征，从而实现数据的降维和特征提取。这为后续的估值提供了有用的信息，减少了对人工特征工程的依赖，提高了模型的泛化能力。
						</p>
						<p>
							采集A股、纳斯达克和港股历史年度的上市公司三大财务报表数据和资产定价因子数据，包括国债利率、74个行业部门虚拟变量、成长因子（营收增长率、净利润增长率等）、财务杠杆因子（负债比率、权益比率、长期负债比率等）、动量因子（现金流动量因子、销售额动量因子等）以及其他资产的定价因子和NE(净利润)/BVE(净资产)、NE(净利润)/Rev(营业收入)等指标，并对其进行清理和筛选，以处理可能存在的错误、缺失值或异常值。这包括检查和修复财务报表中的数据错误，排除不完整或不准确的信息。对于数据缺失值采用同一资产数据的前向值或后向值进行填充。对于极值使用3σ原则，计算数据的均值和标准差，确定数据的上下限，然后对超出上下限的数据进行剔除。
						</p>
						<p>变分推断模型由编码器和解码器两部分组成。编码器将输入数据映射到潜在空间中的概率分布，而解码器则将潜在空间中的样本映射回原始数据空间，从而实现数据的重构和特征提取。</p>
						<p>
							编码器将输入数据x映射到潜在空间中的概率分布 q(z|x)，其中 z 是潜在变量（latent variable）。假设潜在空间的分布是高斯分布，参数化为均值 μ 和方差σ2。编码器的输出为潜在空间中的均值向量
							μ 和方差向量σ2，用于描述输入数据在潜在空间中的分布。
						</p>
						<p style="font-weight: bold;">编码器的表达式如下：μ,logσ2=Encoder(x)</p>
						<p>解码器也由多层神经网络组成，其结构与编码器相似。解码器的目标是将潜在空间中的样本映射回原始数据空间，重构输入数据。</p>
						<p>
							得到了这些潜在的因子变量之后，可以将它们作为集成算法的输入数据，结合其他市场因子、行业因子等信息，用于估值的预测。集成算法可以综合考虑多个因子对估值的影响，并通过组合多个模型的预测结果来提高估值的准确性和鲁棒性。
						</p>
						<p>集成算法中设决策树的输出为 Yi ，对于输入样本 Xi
							，可以通过从树的根节点开始逐步遍历到叶子节点，根据节点的特征和阈值进行划分，最终得到叶子节点的输出值。使用预测误差作为特征选择的依据。这里采用平方差作为误差的评价，即</p>
						<img style="width: 182px;" src="../../../assets/img/report/MILFormula.png" alt="" />
						<p>这个过程可以用以下公式表示：</p>
						<img src="../../../assets/img/report/MILFormula2.png" alt="" />
						<p>其中：m是叶子节点的数量。</p>
						<p class="secondIndent">Rm是第 m个叶子节点的区域， I(⋅) 是指示函数，表示样本 Xi​ 是否属于区域 Rm。</p>
						<p class="secondIndent">cm是第 m个叶子节点的输出值。</p>
						<img src="../../../assets/img/report/MILFormula3.png" alt="" />
						<p>通过集成算法，训练机器学习模型得到不同企业特征对估值因子的影响，得到估值因子的预测模型。</p>
						<p>其次，采用混合专家模型等技术，来综合考虑多个模型的预测结果。这些算法能够有效降低模型的方差，提高模型的鲁棒性和预测能力。通过集成多个模型的预测结果，从而能够更全面地评估股权的价值，提高估值结果的准确性和可靠性。
						</p>
					</div>
					<div class="pagination">{{11+resourcePaging.length}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-formula">
					<p class="accessoryTitle">
						<i>附件3</i>
						<i>计算公式</i>
						<i></i>
					</p>
					<div class="formula-container">
						<h3>1、财务指标公式</h3>
						<p>
							<span>◆</span>
							<span>资产负债率=负债合计/资产总计×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>流动比率=流动资产合计/流动负债合计</span>
						</p>
						<p>
							<span>◆</span>
							<span>EBIT利息保障倍数=EBIT/利息费用</span>
						</p>
						<p>
							<span>◆</span>
							<span>毛利率=（营业收入-营业成本）/营业收入×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>净利润率=净利润/营业收入×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</span>
						</p>
						<p>
							<span>◆</span>
							<span>应收账款周转率=营业收入/平均应收账款</span>
						</p>
						<p>
							<span>◆</span>
							<span>存货周转率=营业成本/平均存货</span>
						</p>
					</div>
					<div class="formula-container">
						<h3>2、估值方法公式</h3>
						<p>
							<span>◆</span>
							<span>流动性折价率（BSM模型）</span>
						</p>
						<img style="width: 274px;" src="../../../assets/img/report/valuationFormula.png" alt="" />
						<p>
							<span></span>
							<span>其中：P：卖方期权价值</span>
						</p>
						<p class="thereinto">S：标的资产当前价值</p>
						<p class="thereinto">X：期权约定价值（执行价格）</p>
						<p class="thereinto">T：期权合约到期时间（单位：年）</p>
						<p class="thereinto">r： 无风险利率</p>
						<p class="thereinto">σ：股票收益率的年化波动率</p>
					</div>
					<p class="accessoryTitle" style="margin-top: 67px;margin-bottom: 33px;">
						<i>附件4</i>
						<i>参考文件</i>
						<i></i>
					</p>
					<div class="references">
						<p>1、《中共中央、国务院关于构建数据基础制度更好发挥数据要素作用的意见》</p>
						<p>2、《企业数据资源相关会计处理暂⾏规定》 （财会〔2023〕11号）</p>
						<p>3、《数据资产评估指导意见》（中评协〔2023〕17 号）</p>
						<p>4、《数字经济及其核心产业统计分类（2021）》（国家统计局令第33号）</p>
						<p>5、《企业绩效评价标准值》</p>
						<p>6、《企业会计准则》</p>
						<p>7、《中小微企业估值规范》（T/CECC 013-2021）</p>
						<p>8、《私募投资基金非上市股权投资估值指引》</p>
					</div>

					<div class="pagination">{{12+resourcePaging.length}}</div>
				</div>
			</div>


			<div class="page">
				<div class="content page-content" id="disclaimer">
					<div class="page-last">
						<img src="../../../assets/img/report/map@2x.png" alt="线上估值" />
						<div class="flow-statement">
							<img :src="pageInfo.logo" alt="线上估值" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../../assets/img/report/statement-01@2x.svg" alt="icon"></p>
										<p>估值声明</p>
										<p class="dian dian_"><img src="../../../assets/img/report/statement-01@2x.svg" alt="icon"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数据库，根据通用的行业业绩及财务预测逻辑和规则，对企业估值所需要的必要参数进行预测和估算，企业提供资料的完整度和准确性对最终估值结果有重要影响。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../../assets/img/report/statement-01@2x.svg" alt="icon"></p>
										<p>免责声明</p>
										<p class="dian dian_"><img src="../../../assets/img/report/statement-01@2x.svg" alt="icon"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对数据资产价值衡量的参考，非抵押、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div class="pagination">{{13+resourcePaging.length}}</div>
				</div>
			</div>



		</div>


		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import '@/assets/css/loading.css'
	import PdfOnload from '@/utils/pdf'
	import common from '@/views/dataValuation/common'
	import Draw3DPieCanvas from '@/common/echarts/draw3DPieCanvas.vue'
	import Histogram from '@/common/echarts/histogram.vue'
	import LibTableComponent from '@/components/reportComponent/liaTableComponent.vue'
	import ProfitTableComponent from '@/components/reportComponent/profitTableComponent.vue'

	import * as Html2Canvas from 'html2canvas'



	export default ({
		components: {
			Draw3DPieCanvas,
			Histogram,
			LibTableComponent,
			ProfitTableComponent

		},
		mixins: [common],
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
				channel: this.$route.query.channel ? this.$route.query.channel : '',
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					createTime: 'xxxx.xx.xx',
					baseInfo: {
						companyName: '',
						establishDate: '',
					},
					reportNumber: '',
					year: [],
					absoluteValuation: {
						ggm: ''
					},
					industry: '',
					firstPageImage: '', //合作公司的logo
					introImage: '', //合作公司的简介
					liaTableInfo: {}
				},
				valueResultNumber: [], //估值结果的数字
				question: [{
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}],
				financial: [],
				boxhead: ['项目', 'xxxx年预测(万元)'],
				income: ['公司营业收入', 0],
				netAsset: ['公司净资产', 0],
				netProfit: ['公司净利润', 0],
				amortizeProfit: ['公司息税折旧摊销前利润', 0],
				profitDataYearX: ['125', '260'],
				profitDataIncomeX: ['130', '305'],
				profitDataNetAssetX: ['120', '302'],
				profitDataNetAssetY: ['184', '182'],
				profitDataNetProfitX: ['110', '295'],
				machineLearningValuationResults: [], //机器学习估值法结果特殊数字(ML估值法)
				revenueContributionResults: [], //收入贡献法估值
				incomeForecast: {
					data: [],
					year: []
				},
				dataType: '', //数据类型
				portfolio: [],
				netBookValue: [], //账面净值
				summation: 0, //账面净值合计
				netBookValuePaging: [],
				pagesNumber: [],
				liabilities: {
					receivables: [],
					inventory: [],
					totalCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					totalNonLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					totalOwnershipInterest: [],
					netWorthCombination: [], //组合无形资产
					dataResources: [] //数据资源
				},
				profit: {
					income: [],
					cost: [],
					interestCost: [],
					totalProfit: [],
					grossProfit: [],
					incomeTax: [],
					netProfit: []
				},
				dataResource: [],
				resourcePaging: [],
				scienceTechnology: [],
				base64: ''


			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			},
			charts() {
				if (this.incomeForecast.data.length > 0) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			computationalPaging(allData, pageSize) { //pageSize：每页最多几个元素
				let pagination = []
				let pageCount = Math.ceil(allData.length / pageSize);

				// 遍历所有页  
				for (let i = 0; i < pageCount; i++) {
					// 计算当前页的起始索引  
					let startIndex = i * pageSize;
					// 计算当前页的结束索引（注意不要超出数据长度）  
					let endIndex = Math.min(startIndex + pageSize, allData.length);

					// 截取当前页的数据  
					let currentPageData = allData.slice(startIndex, endIndex);

					// 将当前页的数据添加到分页数组中  
					pagination.push({
						data: currentPageData
					});
				}

				return pagination

			},
			base64Encode() {
				let img = new Image()
				let imgObj = document.getElementById('inputImg').files[0] // 获取file域
				img.src = URL.createObjectURL(imgObj) // 将file的blob作为图片的URL
				img.onload = function() { // onload事件确保图片加载完成后再执行转换任务
					let canvas = document.createElement('canvas')
					canvas.width = img.width
					canvas.height = img.height
					canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height)
					let base64 = canvas.toDataURL()
					console.log(base64) // 在控制台输出 Data URI
					document.getElementById('showImg').src = base64 // 图片展示
				}
			},


			init() {
				var that = this;
				this.$post(this.domain.valuationProject + 'valuationReport/getDataValuationReport', qs.stringify({
						reportId: this.reportId,
						channel: this.channel
					}))
					.then((res) => {
						var data = res.data;
						console.log(data)
						if (data.code == 200 && data.content) {
							that.pageInfo = data.content;


							// Html2Canvas(document.getElementById('imageId')).then(function(canvas) {
							// 	var imageData = canvas.toDataURL();
							// 	that.base64=imageData
							// 	console.log('图片-------',imageData)
							// });


							// 数据类型
							if (data.content.baseInfo.dataResourceType) {
								JSON.parse(data.content.baseInfo.dataResourceType).map((el, i) => {
									if (i == JSON.parse(data.content.baseInfo.dataResourceType).length - 1) {
										this.dataType += el
									} else {
										this.dataType += el + '、'
									}
								})
							}


							// 设置估值结果页特殊数字
							var minArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.min.split('');
							var maxArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.max.split('');
							minArr.push('12')
							var newArr = minArr.concat(maxArr);
							this.specialNumeric(newArr, "valueResultNumber")

							// 股权结构
							if (that.pageInfo.baseInfo.holderRation) {
								var holderArr = [];
								var newHolderRation = JSON.parse(that.pageInfo.baseInfo.holderRation);
								var deep = [50, 70];
								for (var i = 0; i < newHolderRation.length; i++) {
									holderArr.push({
										name: newHolderRation[i].name,
										y: Number(newHolderRation[i].value),
										sliced: false,
										selected: true
									})
								}
								that.pageInfo.baseInfo.holderRation = holderArr;
							}


							// 软性指标
							this.question = JSON.parse(that.pageInfo.question)
							this.financial = JSON.parse(that.pageInfo.financial)
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry)



							// 机器学习估值法结果特殊数字(ML估值法)
							var machineLearning = this.method.technologyNum(that.pageInfo
								.relativeValuationValue).data.split('');
							if (that.pageInfo.relativeValuationValue < 0) {
								var Minus = 2;
							} else {
								var Minus = 1;
							}
							this.specialNumeric(machineLearning, "machineLearningValuationResults", Minus)

							this.boxhead[1] = this.pageInfo.baseInfo.createTime.substring(0, 4) + '年预测(万元)'
							this.income[1] = this.pageInfo.baseInfo.income
							this.netAsset[1] = this.pageInfo.baseInfo.netAsset
							this.netProfit[1] = this.pageInfo.baseInfo.netProfit
							this.amortizeProfit[1] = this.pageInfo.baseInfo.amortizeProfit


							// 资产负债表
							this.pageInfo.year.push(this.getLastDayOfPreviousMonthInChinese(this.pageInfo.baseInfo.createTime
								.split(' ')[0]))

							this.pageInfo.liaTableInfo = JSON.parse(this.pageInfo.liaTableInfo)
							var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
							var returnliabilitiesKey = Object.keys(this.pageInfo.liaTableInfo); //后台数据返回的资产负债表对象的 key

							liabilitiesKey.forEach((elem, index) => {
								returnliabilitiesKey.forEach((item, i) => {
									that.pageInfo.year.forEach((yearItem, y) => {
										if (elem == item) {
											that.liabilities[elem][y] = that.pageInfo.liaTableInfo[elem][y];
										} else if (!that.pageInfo.liaTableInfo[elem]) {
											if (that.pageInfo.year.length == 3) {
												that.liabilities[elem] = ['', '', '']
											}
										}
									})
								})
							})


							// 利润表
							this.pageInfo.profitTableInfo = JSON.parse(this.pageInfo.profitTableInfo)

							var profitKey = Object.keys(this.profit); //获取利润表对象的 key
							var returnProfit = Object.keys(this.pageInfo.profitTableInfo) //后台数据返回的利润表对象的 key
							profitKey.forEach((elem, index) => {
								returnProfit.forEach((item, i) => {
									that.pageInfo.year.forEach((yearItem, y) => {
										if (elem == item) {
											that.profit[elem][y] = that.pageInfo.profitTableInfo[elem][y] ? that.pageInfo
												.profitTableInfo[elem][y] : '';
										} else if (!that.profit[elem]) {
											if (that.pageInfo.year.length == 2) {
												that.profit[elem] = ['', '']
											} else if (that.pageInfo.year.length == 3) {
												that.profit[elem] = ['', '', '']
											}
										}
									})
								})
							})


							// 数据资源
							console.log('数据资源----', JSON.parse(this.pageInfo.dataResource))
							this.dataResource = JSON.parse(this.pageInfo.dataResource)
							// for (var i = 0; i < 4; i++) {
							// 	this.dataResource.push({
							// 		valuationReportId: "",
							// 		resourceName: '数据资源x-' + i,
							// 		originalValue: '', //原值
							// 		netValue: '', //净值
							// 		valuation: 2365,
							// 		resDescribe: '数据资产的数据处理自动化率在50%到80%之间，显示了一定程度的自动化程度，企业在数据处理中采用了一些自动化工具和流程，提高了工作效率。' //数据资源介绍
							// 	})
							// }

							this.resourcePaging = this.computationalPaging(this.dataResource, 2)

							this.resourcePaging.map((el, j) => {
								this.scienceTechnology.push({
									number: []
								})
								el.data.map((item, i) => {
									this.scienceTechnology[j].number.push({
										valuation: item.valuation,
										value: []
									})

									this.groupSpecialNumeric(this.method.technologyNum(item.valuation).data.split(''),
										'scienceTechnology', j, i, 1)
								})
							})



							console.log('分页后的数据--', this.resourcePaging)

							console.log('科学数字----', this.scienceTechnology)

						}

					})
			},
			// 特殊数字的转换
			specialNumeric: function(data, key, minus) { //minus==1 代表正数   minus==2代表负数
				if (minus == 2) {
					this[key].push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, i) => {
					if (val == ".") {
						this[key].push({
							val: "11",
							top: "62px",
						})
					} else {
						this[key].push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			groupSpecialNumeric: function(data, key, j, i, minus) { //minus==1 代表正数   minus==2代表负数
				if (minus == 2) {
					this[key][j].number[i].value.push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, k) => {
					if (val == ".") {
						this[key][j].number[i].value.push({
							val: "11",
							top: "62px",
						})
					} else {
						// console.log('kk---', this[key][j].number[i].value)

						this[key][j].number[i].value.push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 得分校对
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value + '分';
						}
					};
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '数据估值报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				this.pdfArr = [];
				// this.pdfArr.push('homePage','page-one')
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				console.log(this.pdfArr)
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		}


	})
</script>

<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.page {
		width: 100%;
		height: 1754px;
		position: relative;
		line-height: normal;
		vertical-align: middle;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}

	.page-content {
		padding-top: 114px;
		padding-left: 110px;
		padding-right: 110px;
	}

	.pagination {
		width: 52px;
		height: 54px;
		line-height: 54px;
		font-size: 20px;
		color: #fff;
		text-align: center;
		position: absolute;
		bottom: 37px;
		left: 50%;
		margin-left: -26px;
		background: url(../../../assets/img/report/pageNumber.png) bottom center no-repeat;
		background-size: 100% 100%;
	}

	/* 封面 */

	#homePage {
		overflow: hidden;
		background: url(../../../assets/img/report/pageIndex_bg.png)no-repeat center center;
	}

	.logoBox {
		overflow: hidden;
	}

	.homePage h1 {
		width: 293px;
		height: 99px;
		background: url(../../../assets/img/report/flowData.png)no-repeat center center;
		background-size: 100%;
		margin: 81px 0 0 75px;
		float: left;
	}

	#logo {
		width: 293px;
		margin: 81px 0 0 75px;
	}

	.stock-logo {
		margin: 81px 0 0 75px;
		display: flex;
		align-items: center;
	}

	.stock-logo .logoXian {
		width: 2px;
		height: 99px;
		background: #7F7F7F;
		margin: 0 20px;
	}

	.stock-logo img {
		height: 80px;
	}

	#page-stock {
		padding: 0;
	}

	#page-stock>.introduce-img {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
	}

	.homePage h4 {
		font-size: 24px;
		font-weight: 400;
		line-height: 100%;
		color: #000;
		text-align: center;
		margin: 42px auto 0;
		text-align: center;
	}

	.homePage h6 {
		font-size: 38px;
		font-weight: 400;
		color: #1416F4;
		line-height: 100%;
		margin-top: 20px;
		text-align: center;
	}

	.homePage .content {
		border: none;
	}

	.companyName {
		font-size: 50px;
		font-weight: bold;
		color: #000;
		text-align: center;
		margin: 118px 0 40px 0;
	}

	.homePage>div>div.homePageLine {
		width: 796px;
		height: 6px;
		background: #1416F4;
		margin: 0 auto;
	}

	.homePage .reportType {
		font-size: 130px;
		font-weight: bold;
		color: #1416F4;
		margin: 60px 0 10px 0;
		text-align: center;
	}

	.homePage h3 {
		font-size: 34px;
		font-weight: bold;
		color: #388BFF;
		text-align: center;
		margin-bottom: 22px;
	}

	.page-one-footer {
		position: absolute;
		bottom: 50px;
		height: 21px;
		width: 1100px;
		margin: 0 34px;
		display: flex;
		justify-content: space-between;
	}

	.page-one-footer>p {
		font-size: 18px;
		color: #282828;
		line-height: 1;
	}

	.page-one-footer>p>span:last-of-type {
		color: #0D0D0D;
	}

	.page-one-footer>div.date {
		font-size: 18px;
		line-height: 1;
		color: #0D0D0D;
	}

	/* 新目录 */

	.page-catalogue h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 172px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.page-catalogue .generalize>div {
		width: 980px;
		height: 54px;
		margin: 34px auto 0px;
		display: flex;
		align-items: center;
	}

	.page-catalogue .generalize>div p:first-child {
		font-size: 32px;
	}

	.page-catalogue .generalize p {
		font-size: 24px;
		font-weight: bold;
		color: #0F060B;
		margin-right: 11px;
		line-height: 54px;
	}

	.page-catalogue .generalize>div>div {
		width: 6px;
		height: 30px;
		background: #1416F4;
		float: left;
		margin: 0 28px 0 0;
	}

	.page-catalogue .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 733px;
		margin: 0 20px 0;
		background: url(../../../assets/img/report/catalogue_.png)left center repeat;
	}

	.page-catalogue .generalize div>.dottedLine {
		margin: 0 20px 0;
	}

	.page-catalogue .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
	}

	.page-catalogue .generalize ul {
		margin: 0 0 57px 194px;
	}

	.page-catalogue .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(980px - 64px);
		display: flex;
		align-items: center;
	}

	.page-catalogue .generalize ul li p {
		font-size: 18px;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.page-catalogue .generalize ul li .dottedLine {
		width: 760px;
	}

	.page-catalogue .generalize ul.flowChart li .dottedLine {
		width: 797px;
	}

	// 基本信息
	.valueResultTitle {
		display: flex;
		// align-items: center;
	}

	.icon_ {
		height: 52px;
		margin-right: 25px;
		vertical-align: middle;
	}

	.icon_+span {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		line-height: 52px;
	}

	#companyName {
		font-size: 24px;
		font-weight: bold;
		color: #000000;
		margin-bottom: 20px;
	}

	.baseInfoCont,
	.productCont,
	.groupCont,
	.mainBusinessCont,
	.situationCont {
		width: 100%;
		overflow: hidden;
		padding: 20px 0 67px;
		margin-left: 74px;
	}

	.situationCont ul li span,
	.baseInfoCont ul li span,
	.productCont ul li span,
	.groupCont ul li span {
		font-size: 20px;
		font-weight: 400;
		color: #010000;
		line-height: 38px;
	}

	.mainBusinessCont>p {
		font-size: 20px;
		color: #282828;
		line-height: 40px;
		text-align: justify;
	}

	.equityStructure {
		position: relative;
	}

	.piePedestal {
		width: 600px;
		position: absolute;
		top: 352px;
		left: 50%;
		margin-left: -289px;
	}

	// 盖章页
	.stamp {
		width: 68%;
		line-height: 41px;
		margin: 10% auto;
		font-size: 20px;
		font-weight: 400;
		text-indent: 2em;
		margin-top: 31%;
		text-align: justify;
		color: #282828;
	}

	.detail_result {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		margin-top: 54px;
		/* overflow: hidden; */
	}

	.detail_result h4 {
		float: left;
		height: 80px;
		margin-top: 6px;
		border-top: 3px solid #1416F4;
		border-bottom: 3px solid #1416F4;
		font-size: 38px;
		color: #1416F4;
		line-height: 80px;
		padding: 0 34px;
		font-weight: bold;
	}

	.stamptime {
		width: 68%;
		margin: 10% auto;
		font-size: 20px;
		text-align: right;
		line-height: 39px;
		margin-top: 29%;
	}

	.stamptime>p {
		font-size: 22px;
		margin-top: 29px;
		line-height: normal;
		color: #282828;
	}

	.detail_result>div {
		position: relative;
	}

	// 软性指标
	.softIndexScore {
		width: 1023px;
		height: 816px;
		background-size: contain;
		margin: 0 auto 30px;
		position: relative;

		>img {
			position: absolute;
			left: 0;
			top: 23px;
		}

		div {
			width: 378px;
			height: 378px;
			margin: 0 auto;
			transform: translateY(44%);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			span {
				width: 100%;
				color: #1416F4;
				text-align: center;
				font-weight: bold;
			}

			span:first-child {
				font-size: 53px;
				margin: 18px 0 10px;
			}

			span:last-child {
				font-size: 28px;
			}
		}
	}

	.indexScore {
		display: flex;
		justify-content: space-between;

		li:last-child {
			width: 45%;
		}

		li {
			width: 48%;

			.indicators {
				position: relative;
				margin-top: 50px;
				text-align: center;

				p {
					position: absolute;
					text-align: center;

					span {
						font-size: 20px;
						color: #282828;
						display: block;
					}

					span:last-child {
						font-weight: bold;
					}
				}

				p:nth-of-type(1) {
					top: -41px;
					left: 52%;
				}

				p:nth-of-type(2) {
					top: 108px;
					right: 0;
				}

				p:nth-of-type(3) {
					bottom: -25px;
					right: 11%;
				}

				p:nth-of-type(4) {
					bottom: -25px;
					left: 11%;
				}

				p:nth-of-type(5) {
					top: 108px;
					left: 0;
				}

			}

			.financeIndicators {
				position: relative;
				margin-top: 50px;
				text-align: center;

				p {
					position: absolute;
					text-align: center;

					span {
						font-size: 20px;
						color: #282828;
						display: block;
					}

					span:last-child {
						font-weight: bold;
					}
				}

				p:nth-of-type(1) {
					top: -41px;
					left: 52%;
				}

				p:nth-of-type(2) {
					top: 143px;
					right: 0;
				}

				p:nth-of-type(3) {
					bottom: -29px;
					right: 26%;
				}

				p:nth-of-type(4) {
					top: 143px;
					left: 0;
				}

			}

		}
	}

	.scoreDescription {
		width: 880px;
		font-size: 20px;
		color: #282828;
		line-height: 35px;
		text-align: justify;
		margin: 20px auto 0;
	}

	.guidelines {
		p {
			width: 880px;
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			margin: 0 auto;
			text-indent: 2em;
			text-align: justify;
		}
	}

	.indicator {
		display: inline-block;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 76px;
		margin-bottom: 70px;

		>ul {
			width: 100%;
			position: absolute;
		}

		>div {
			position: absolute;
		}
	}

	.basicIndexScore+div {
		width: 100%;
		top: 60px;
		left: 0;
		text-align: center;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
			margin-top: 11px;
		}
	}

	.basicIndexScore {
		top: 0;

		li {
			width: 221px;
			height: 256px;
			background: url(../../../assets/img/report/basicIndexScore.png) center center no-repeat;
			position: absolute;
			top: 115px;

			p {
				width: 100%;
				text-align: center;
			}

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #1416F4;
				margin-top: 59px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 26.5%;
			}
		}
	}

	.riskIndicatorScore+div {
		top: -38px;
	}

	.riskIndicatorScore {
		li {
			width: 262px;
			height: 155px;
			background: url(../../../assets/img/report/riskIndicatorScore.png) center center no-repeat;
			top: 30px;

			p {
				color: #000 !important;
			}

			p:first-child {
				margin-top: -27px;
			}

			p:last-child {
				bottom: 77%;
			}
		}
	}

	.qualityIndexScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 49px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.qualityIndexScore {
		top: -103px;
		left: -138px;

		li {
			position: absolute;

			>div {
				width: 158px;
				height: 86px;
				background: url(../../../assets/img/report/riskIndicatorGrade.png) center center no-repeat;
				margin: 0 auto 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				p {
					text-align: center;
					font-size: 18px;
					margin-top: 5px;
					color: #000;
				}

				p:last-child {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}

	.economicScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: -45px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.economicScore {
		top: -22px;

		li {
			width: 250px;
			height: 70px;
			background: url(../../../assets/img/report/economicIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			span {
				line-height: 70px;
				color: #1416F4;
			}

			span:first-child {
				font-size: 20px;
			}

			span:last-child {
				font-size: 24px;
				font-weight: bold;
				margin-left: 10px;
			}
		}
	}

	.circulationScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 7px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.circulationScore {
		top: 28px;

		li {
			width: 228px;
			height: 254px;
			background: url(../../../assets/img/report/circulationIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				color: #1416F4;
				margin-top: 71px;
				font-weight: bold;
			}

			p:last-child {
				width: 100%;
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 19%;
			}
		}
	}

	.costIndexScore+div {
		width: 288px;
		height: 288px;
		text-align: center;
		position: absolute;
		top: 18px;
		left: 296px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		p {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
			color: #fff;
			margin-top: 27px;
		}
	}

	.costIndexScore {
		top: 189px;

		li {
			width: 160px;
			height: 160px;
			background: url(../../../assets/img/report/costIndexIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				margin-top: 56px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				margin-top: 8px;
			}
		}
	}

	.interpretation {
		width: 850px;
		margin: -15px 0 0 96px;

		li {
			font-size: 16px;
			color: #282828;
			line-height: 34px;
			text-indent: -1.6em;
			text-align: justify;
		}
	}

	.profitData {
		width: 511px;
		height: 402px;
		background: url(../../../assets/img/report/forecast.png)center center repeat;
		background-size: 100% 100%;
		margin: 0 auto;
		position: relative;
		top: -26px;
	}

	.profitData svg:nth-of-type(1) {
		position: relative;
		// top: -14px;
	}

	.arrows {
		width: 60px;
		height: 75px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -17px;
	}

	.arrows+img {
		height: 388px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -40px;
	}

	.rotateYear {
		font-size: 18px;
		fill: #fff;
		font-weight: bold;
	}

	.rotateData {
		width: 50px;
		font-size: 14px;
		fill: #fff;
		text-anchor: middle;
	}

	.rotateDataBlue {
		width: 50px;
		font-size: 14px;
		fill: #1416F4;
		text-anchor: middle;
	}

	.rotateData:nth-of-type(3),
	.rotateDataBlue:nth-of-type(5),
	.rotateData:nth-of-type(7) {
		font-weight: bold;
	}

	.specialNumeric>img {
		display: block;
		margin: 0 auto;
	}

	.machineLearningValuationResults {
		width: 880px;
		height: 443px;
		padding-top: 30px;
		position: relative;
		top: -26px;
		background: url(../../../assets/img/report/machineLearningValuationResults.png) center center repeat;
		background-size: 100% 100%;
		margin: 37px auto 0;
	}

	.locationUnit {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.numberBox img {
		margin-right: 4px;
	}

	.numberBox img:last-child {
		// margin-right: 0;
	}

	.numberBox p.unit {
		width: 56px;
		font-size: 28px;
		font-weight: bold;
		color: #1416F4;
		position: relative;
		top: 20px;
	}

	.numberBox p.unit+img {
		margin-right: 17px !important;
	}

	.machineLearningValuationResults .numberBox {
		width: 636px;
		height: 135px;
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.valuationMethod {
		font-size: 22px;
		font-weight: bold;
		color: #000;
	}

	.revenueContributionInterpretation {
		margin-top: 60px;
		margin-left: 74px;

		li {
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			text-align: justify;
		}
	}

	.revenueContributionInterpretation+.numberBox {
		width: 520px;
		height: 119px;
		background: url(../../../assets/img/report/absoluteValuationResult.png) center center no-repeat;
		margin: 75px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contribution1 {
		width: 242px;
		height: 196px;
		margin: 45px auto;
		position: relative;
	}

	.contribution1::before {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		top: -46px;
		transform: translateX(-50%);
	}

	.contribution1::after {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		bottom: -45px;
		transform: translateX(-50%);
	}

	.contribution2 {
		width: 693px;
		height: 82px;
		border-top: 3px solid #1416F4;
		margin: 0 auto;
		position: relative;

		ul {
			display: flex;
			position: relative;

			li {
				position: relative;

				>div {
					width: 196px;
					height: 201px;
					background: url(../../../assets/img/report/contributionIcon.png) center center no-repeat;
					margin-top: 82px;
					margin-bottom: 45px;
					position: relative;
				}

				p {
					width: 180px;
					height: 66px;
					line-height: 66px;
					background: #EEEEFF;
					border: 2px solid #1416F4;
					font-size: 20px;
					color: #282828;
					text-align: center;
				}

				>img {
					width: 29px;
					transform: rotate(-90deg);
					position: absolute;
					right: -24px;
					bottom: 7px;
				}

				>div::before {
					content: '';
					display: block;
					width: 47px;
					height: 85px;
					background: url(../../../assets/img/report/contribution2.png) center center no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: 50%;
					top: -85px;
					transform: translateX(-50%);
				}

				>div::after {
					content: '';
					display: block;
					width: 3px;
					height: 47px;
					background: #1416F4;
					position: absolute;
					left: 50%;
					bottom: -45px;
					transform: translateX(-50%);
				}

			}

			>li:first-child {
				left: -96px;
			}

			>li:last-child {
				right: -8px;
			}
		}
	}

	#incomeStatement {
		width: 100%;
		height: 550px;
		margin: 50px auto 0;
	}

	.revenueForecastingBase {
		position: absolute;
		bottom: 0;
	}

	.valuationResultDV {
		width: 890px;
		height: 294px;
		background: url(../../../assets/img/report/valuationResultDVBox.png) center center no-repeat;
		margin: 111px auto 0;

		.numberBox {
			width: 817px;
			height: 151px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
			top: 106px;

			>div {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					margin-right: 5px;
				}

				span {
					font-size: 28px;
					font-weight: bold;
					color: #1416F4;
					margin-top: 40px;
				}
			}

			>p {
				width: 100%;
				font-size: 32px;
				font-weight: bold;
				color: #000000;
				text-align: center;
			}
		}
	}

	.valuationResultDV+img {
		margin-top: -20px;
	}


	.netBookValue {
		width: 950px;
		height: 608px;
		margin: 0 auto;
		position: relative;

		thead {
			th {
				width: 474px;
				height: 71px;
				line-height: 71px;
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
			}

			>th:first-child {
				background: url(../../../assets/img/report/dataAssetName.png) center center no-repeat;
				margin-right: 3px;
			}

			>th:last-child {
				background: url(../../../assets/img/report/bookValue.png) center center no-repeat;
			}

		}

		tbody {
			border: 2px solid #2426F4;
			position: absolute;
			top: 79px;

			tr {
				td {
					width: 474px;
					height: 54px;
					line-height: 54px;
					text-align: center;
					font-size: 20px;
					color: #000000;
					border-bottom: 2px solid #2426F4;
				}

				>td:first-child {
					padding-right: 3px;
				}
			}

			>tr:last-child {
				td {
					border: none;
				}
			}

			tr.summation {
				td {
					font-weight: bold;
				}
			}

		}
	}


	// 免责声明
	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 50px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 351px;
		margin: 0 auto;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 50px auto 30px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		/*width: 100%;*/
		width: 135px;
		height: 1px;
		display: block;
		/*float: left;*/
		position: absolute;
		top: -1px;
		margin-top: 10px;
		background: #1416F4;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 3px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 22px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
		font-weight: bold;
	}

	.statement-tit>p.dian {
		width: 11px;
		height: 11px;
		float: left;
		margin: -2px 0 0 136px;
	}

	.statement-tit>p.dian img {
		width: 100%;
		position: relative;
		top: -2px;
	}

	.statement-tit>p.dian_ {
		margin: -2px 0 0 0;
	}

	.statement-main {
		width: 977px;
		padding: 10px 20px;
		background: #E3EBFF;
		font-size: 18px;
		color: #282828;
		line-height: 32px;
		text-indent: 2em;
		text-align: justify;
		border-radius: 10px;
	}

	.statement-main div {
		margin: 24px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 0 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		// text-align-last: justify;
		// text-align: justify;
		// text-justify: distribute-all-lines;
	}

	.accessoryTitle {
		display: flex;
		justify-content: space-between;
	}

	.accessoryTitle i:nth-child(1) {
		font-size: 24px;
		color: #282828;
	}

	.accessoryTitle i:nth-child(2) {
		font-size: 24px;
		color: #282828;
		font-weight: bold;
	}

	.accessoryTitle i:nth-child(3) {
		font-size: 20px;
		color: #282828;
	}

	.analysis {
		width: 880px;
		height: 466px;
		margin: 30px auto;
		background-size: contain !important;
		display: flex;
		align-items: center;

		>p {
			width: 326px;
			height: 323px;
			line-height: 323px;
			font-size: 30px;
			font-weight: bold;
			text-align: center;
			color: #282828;
			margin-top: 37px;
			margin-left: 64px;
		}

		>div {


			>p {
				height: 100px;
				line-height: 100px;
				font-size: 20px;
				color: #282828;
				text-align: center;
				margin-top: 10px;
			}
		}
	}

	.repaymentAnalysis {
		background: url(../../../assets/img/report/analysisThree.png) center center no-repeat;

		>div {
			width: 400px;
			height: 354px;
			padding: 26px 0 0;
		}
	}


	.payoffProfile {
		background: url(../../../assets/img/report/analysisTwo.png) center center no-repeat;

		>div {
			width: 400px;
			height: 298px;
			padding: 18px 0 0;

			>p {
				height: 118px;
				line-height: 118px;
				margin-top: 20px;
			}
		}
	}

	.growthAnalysis {
		width: 880px;
		height: 503px;
		background: url(../../../assets/img/report/analysisGrowth.png) center center no-repeat;
		background-size: contain;
		margin: 30px auto;

		ul {
			width: 84%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;

			li:nth-child(1),
			li:nth-child(3) {
				width: 228px;
				height: 269px;
				background: url(../../../assets/img/report/growthScore.png) center center no-repeat;
				background-size: contain;
				text-align: center;

				>p:nth-child(1) {
					height: 180px;
					line-height: 180px;
					font-size: 24px;
					color: #1416F4;
					font-weight: bold;
				}

				>p:nth-child(2) {
					width: 62%;
					height: 41px;
					margin: 7px auto 0;
					font-size: 18px;
					color: #fff;
					line-height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

			}

			li:nth-child(2) {
				width: 228px;
				height: 228px;
				line-height: 228px;

				>p {
					font-size: 30px;
					font-weight: bold;
					color: #282828;
					text-align: center;
				}
			}
		}
	}

	// .dataResourcePage {}

	.dataResourcePage .numberBox {
		width: 880px;
		height: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 81px;
		background-size: contain !important;
	}

	.dataResourcePage>div:nth-child(1) .numberBox {
		background: url(../../../assets/img/dataResource/valuationResult.png) center center no-repeat;
	}

	.dataResourcePage>div:nth-child(2) .numberBox {
		background: url(../../../assets/img/dataResource/valuationResult2.png) center center no-repeat;
	}

	.resDescribe {
		width: 880px;
		line-height: 42px;
		font-size: 20px;
		color: #282828;
		text-indent: 2em;
		text-align: justify;
		// padding-left: 66px;
		margin: 28px auto 0;
	}

	.units {
		font-size: 20px;
		color: #282828;
		text-align: right;
	}

	.dataAsset {
		width: 880px;
		margin: 30px auto 29px;

		.businessName {
			width: 910px;
			height: 68px;
			line-height: 80px;
			background: url(../../../../src/assets/img/report/businessName.png) center center no-repeat;
			background-size: 100% 100%;
			padding-left: 57px;

			span {
				width: 47%;
				font-weight: bold;
				font-size: 22px;
				color: #fff;
			}
		}

		.dataAssetNameSpecialty {
			width: 100%;
			height: 68px;
			background: url(../../../../src/assets/img/report/dataAssetNameSpecialty.png) center center no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;

			span:not(:last-child) {
				border-right: 1px solid #1416F4;
			}

			span {
				width: 33%;
				height: 44px;
				line-height: 44px;
				text-align: center;
				font-weight: bold;
				font-size: 20px;
				color: #fff;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		>ul {
			border: 1px solid #1416F4;
			border-top: none;
			margin-top: 10px;



			li {
				border-top: 1px solid #1416F4;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				>span {
					width: 33%;
					height: 58px;
					line-height: 58px;
					font-size: 18px;
					color: #282828;
					padding: 0 20px;
				}

				>span:not(:first-child) {
					text-align: right;
					border-left: 1px solid #1416F4;
				}

				.yearLimit {
					width: 100%;
					height: 58px;
					line-height: 58px;
					align-items: center;
					padding-left: 20px;
					display: flex;
					border-top: 1px solid #1416F4;

					>span:first-child {
						font-size: 18px;
						color: #282828;
						font-weight: bold;
					}

					>span:last-child {
						width: 228px;
						height: 38px;
						border: 1px solid #1416F4;
						font-size: 18px;
						color: #282828;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;

						i:first-child {
							font-weight: bold;
						}

					}
				}

			}

		}

	}

	.MLMIntroduction {
		margin-top: 38px;

		p {
			font-size: 18px;
			color: #282828;
			line-height: 36px;
			text-indent: 2em;
		}

		.secondIndent {
			margin-left: 54px;
		}

		img {
			width: 233px;
			margin: 6px 0 6px 38px;
		}
	}

	.formula-container {
		margin-top: 38px;

		h3 {
			font-size: 20px;
			color: #282828;
			font-weight: bold;
		}

		p {
			margin: 16px 0;

			span:first-child {
				font-size: 14px;
				color: #1416F4;
				margin-right: 5px;
			}

			span:last-child {
				font-size: 18px;
				color: #282828;
			}
		}

		.thereinto {
			font-size: 18px;
			color: #282828;
			margin-left: 59px;
		}
	}

	.references p {
		font-size: 18px;
		color: #282828;
		line-height: 38px;
	}
</style>