<template>
	<div>
		<Header />
		<Banner name="数据变现" :banner="require('@/assets/img/realization/banner.png')" />
		<div class="mainContent">
			<Crumbs :list="crumbsList" />
			<div class="detailedInformation" v-if="data.type=='6'" style="padding-right: 30px;">
				<div class="projectDetails">
					<ul>
						<li>
							<div>
								<img :src="data.logo" alt="" />
							</div>
						</li>
						<li>
							<p class="name">{{data.name}}</p>
							<p class="specific">
								<span>
									<i class="iconfont icon-inning" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>轮次：{{data.round}}</span>
							</p>
							<p class="specific">
								<span>
									<i class="iconfont icon-xingye" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>行业：{{data.industry}}</span>
							</p>
							<p class="specific">
								<span>
									<i class="iconfont icon-dizhi" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>地址：{{data.address}}</span>
							</p>
							<button class="buttonFill" @click="order(data.id)">联系项目方</button>
						</li>
					</ul>
					<img src="../../assets/img/realization/equityFinancing.png" />
				</div>
			</div>
			<div class="detailedInformation" v-else-if="data.type=='5'||data.type=='2'">
				<ul>
					<li>
						<div>
							<img :src="data.logo" alt="" />
						</div>
					</li>
					<li>
						<p class="name">{{data.name}}</p>
						<p class="specific" v-if="data.type=='5'">
							<span>
								<i class="iconfont icon-shangshengjiantou"
									:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>年利率：{{data.rate}}</span>
						</p>
						<p class="specific" v-if="data.type=='5'">
							<span>
								<i class="iconfont icon-a-9xinyongqixianedushenpi"
									:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>贷款期限：{{data.term}}</span>
						</p>
						<p class="specific" v-if="data.type=='5'">
							<span>
								<i class="iconfont icon-mianfeitixianedu"
									:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>贷款额度：{{data.loan}}</span>
						</p>
						<!-- 数据资产证券化 -->
						<p class="specific" v-if="data.type=='2'">
							<span>
								<i class="iconfont icon-xiangmujine" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>发行金额：{{method.formateNum(data.price,2)}}</span>
						</p>
						<p class="specific" v-if="data.type=='2'">
							<span>
								<i class="iconfont icon-a-piaomianxuanzhong"
									:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>票面利率：{{data.rate}}</span>
						</p>
						<p class="specific" v-if="data.type=='2'">
							<span>
								<i class="iconfont icon-yingyeqixian" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>发行期限：{{data.term}}</span>
						</p>
						<button class="buttonFill" @click="order(data.id)">立即申请</button>
					</li>
				</ul>
				<img src="../../assets/img/realization/fileIcon.png" />
			</div>
			<div class="detailedInformation" v-else-if="data.type=='4'||data.type=='1'">
				<ul>
					<li>
						<div>
							<img :src="data.logo" alt="" />
						</div>
					</li>
					<li>
						<p class="name">{{data.name}}</p>
						<p class="describe">{{data.profile}}</p>
						<button class="buttonFill" @click="order(data.id)" style="position: absolute;bottom: 39px;">立即申请</button>
					</li>
				</ul>
				<img src="../../assets/img/realization/fileIcon.png" />
			</div>
			<div class="detailedInformation" v-else-if="data.type=='3'">
				<ul>
					<li>
						<div>
							<img :src="data.logo" alt="" />
						</div>
					</li>
					<li>
						<p class="name" style="margin-bottom: 18px;">{{data.name}}</p>
						<p class="name">{{method.formateNum(data.price,2)}}</p>
						<p class="specific">
							<span>
								<i class="iconfont icon-shouyi"
									:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'14px'}"></i>
							</span>
							<span>客户买入到期收益率：{{data.rate}}</span>
						</p>
						<p class="specific">
							<span>
								<i class="iconfont icon-a-9xinyongqixianedushenpi"
									:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>期限：{{data.term}}</span>
						</p>
						<p class="specific">
							<span>
								<i class="iconfont icon-shengyuliang" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>剩余期限：{{data.residueTerm}}</span>
						</p>
						<p class="specific">
							<span>
								<i class="iconfont icon-history"
									:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'16px'}"></i>
							</span>
							<span>到期日期：{{data.termTime}}</span>
						</p>
						<button class="buttonFill" @click="order(data.id)">立即申请</button>
					</li>
				</ul>
				<img src="../../assets/img/realization/fileIcon.png" />
			</div>

			<div class="detailedInformation" v-else>
				<ul>
					<li>
						<div>
							<img :src="data.logo" alt="" />
						</div>
					</li>
					<li>
						<p class="name">{{data.name}}</p>
						<p class="specific">
							<span>
								<i class="iconfont icon-chanpinbianhao" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>产品编号：{{data.id}}</span>
						</p>
						<p class="specific">
							<span>
								<i class="iconfont icon-gongsixinxi" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
							</span>
							<span>公司名称：{{data.companyName}}</span>
						</p>
						<p class="describe">{{data.profile}}</p>
						<div class="scene">
							<span>应用场景：</span>
							<p>
								<span v-for="item in data.scene.split('|')"
									:style="{'background':hexToRgb($store.state.user.subjectColor.mainColor, '0.2')}">
									{{item}}
								</span>
							</p>
						</div>
						<p class="price" v-if="data.discuss">
							<i>价格：</i>
							<i style="font-size: 24px;">{{data.discuss}}</i>
						</p>
						<p class="price" v-else>
							<i>价格：</i>
							<i>{{method.formateNum(data.price,2)}}</i>
							<i>元/次</i>
						</p>
						<button class="buttonFill" @click="order(data.id)">立即下单</button>
					</li>
				</ul>
				<img src="../../assets/img/realization/fileIcon.png" />
			</div>
			<div class="productPresentation">
				<p>详细介绍</p>
				<div class="introduction textEditContent" v-html="data.introduction"></div>
			</div>
		</div>

		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import SignUp from '@/components/signUp/index.vue'
	import Banner from '@/components/threeLevelPage/detailsBanner.vue'
	import Crumbs from '@/components/crumbs/index.vue'
	import publicMethod from '@/views/publicMethod'

	import qs from 'qs'
	import axios from 'axios'

	import {
		mapActions,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			Banner,
			Crumbs
		},
		data() {
			return {
				crumbsList: [{
					name: '首页',
					path: '/'
				}, {
					name: '数据变现',
					path: '/dataDiscoveryIntroduction'
				}],
				data: {
					scene: '[]'
				}

			}
		},
		mounted() {
			this.getDataProduct()

		},
		methods: {
			...mapActions([
				'getDataRealizationtTypeIndex'
			]),
			getDataProduct() {
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProduct', qs.stringify({
						productId: this.$route.query.productId
					}))
					.then((res) => {
						console.log(res.data)

						this.data = res.data.content
						this.crumbsList.push({
							name: res.data.content.typeName,
							path: '/dataDiscoveryIntroduction'
						}, {
							name: '详细信息',
							path: ''
						})

						this.getDataProductTypeList()

					});
			},
			getDataProductTypeList() {
				this.$post(this.domain.dataRealization + 'dataProductType/getDataProductTypeList', qs.stringify({}))
					.then((res) => {
						console.log(res.data)
						res.data.content.map((el, index) => {
							if (el.type == this.data.type) {
								this.getDataRealizationtTypeIndex({
									index
								})
							}
						})

					});

			},
			order(id) {
				this.$router.push({
					name: 'placeOrder',
					query: {
						id: id
					}
				})
			}



		}

	}
</script>

<style scoped lang="less">
	.detailedInformation {
		width: 100%;
		min-height: 260px;
		padding: 39px 0 39px 39px;
		background: #F3F7FA;
		border: 2px solid #FFFFFF;
		box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
		position: relative;
		box-sizing: border-box;
		margin-top: 30px;

		>img {
			position: absolute;
			bottom: 0;
			right: 0;
		}

		ul {
			display: flex;


			>li:first-child {
				display: flex;
				align-items: center;
				
				div{
					width: 400px;
					height: 180px;
					background: #FFFFFF;
					border: 1px solid #D2D2D2;
					margin-right: 50px;
					padding: 10px;
					box-sizing: border-box;
					
					>img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						vertical-align: middle;
					}
				}
				
			}

			>li:last-child {
				width: 57%;

				button {
					width: 140px;
					height: 30px;
					margin-top: 20px;
				}

				.name {
					width: 100%;
					font-size: 20px;
					font-weight: bold;
					color: #282828;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-bottom: 25px;
				}

				.specific {
					line-height: 20px;
					margin-bottom: 8px;

					span:first-child {
						width: 20px;

						.iconfont {
							font-size: 20px;
						}
					}

					span:last-child {
						font-size: 14px;
						font-weight: 400;
						color: #282828;
						margin-left: 10px;
					}
				}

				.describe {
					font-size: 14px;
					font-weight: 400;
					color: #282828;
					line-height: 24px;
					text-align: justify;
					margin: 12px 0 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
				}

				.scene {
					>span {
						font-size: 14px;
						font-weight: bold;
						color: #282828;
						line-height: 26px;
						vertical-align: top;
					}

					>p {
						width: 80%;
						display: inline-block;

						span {
							padding: 7px 14px;
							font-size: 12px;
							color: var(--mainColor);
							border: 1px solid var(--mainColor);
							border-radius: 4px;
							margin-right: 8px;
							margin-bottom: 15px;
						}
					}

				}

				>.price {
					display: flex;
					align-items: center;
					color: #FF0000;

					i:first-child {
						font-size: 14px;
						color: #282828;
					}

					i:nth-of-type(2) {
						font-size: 24px;
						font-weight: bold;
						color: #FF0000;
					}

					i:last-child {
						font-size: 18px;
						font-weight: bold;
						color: #FF0000;
					}
				}
			}
		}

		.projectDetails {
			display: flex;
			justify-content: space-between;
			align-items: center;

			>ul {
				width: 67%;

				>li:last-child {
					width: 67%;
				}
			}
		}

	}

	.productPresentation {
		width: 100%;
		box-shadow: 0px 0px 13px 0px rgba(86, 136, 205, 0.23);
		padding: 30px;
		box-sizing: border-box;
		margin-top: 62px;

		>p {
			width: 100%;
			font-size: 20px;
			font-weight: bold;
			color: #282828;
			padding-bottom: 9px;
			border-bottom: 1px solid #CACACA;
		}

		>.introduction {
			margin-top: 30px;
			padding-left: 20px;

			img {
				max-width: 100%;
			}
		}
	}
</style>