import { render, staticRenderFns } from "./informationDetails.vue?vue&type=template&id=d7096804&scoped=true"
import script from "./informationDetails.vue?vue&type=script&lang=js"
export * from "./informationDetails.vue?vue&type=script&lang=js"
import style0 from "./informationDetails.vue?vue&type=style&index=0&id=d7096804&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7096804",
  null
  
)

export default component.exports