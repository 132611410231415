<template>
	<div>
		<Carousel loop :height="380" arrow="never" dots="none">
			<CarouselItem>
				<div class="banner" :style="{'background':'url('+banner+') center center no-repeat'}">
					<div>
						<p class="pageType">{{name}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
	</div>
</template>

<script>
	export default {
		props: {
			banner: {
				type: String
			},
			name: {
				type: String
			}
		},
		mounted() {

		}
	}
</script>

<style scoped lang="less">
	.banner {
		background-size: cover !important;
	}
</style>