<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="450" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner" :style="{'background':'url('+item.imgPath+') center center no-repeat'}">
					<div>
						<p class="pageType">{{item.productName}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div class="textEditContent" v-html="pageSetup.introduction"></div>

		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					bannerList: [],
					superiority: [],
					productArchitecture: {},
					reportExample: []
				},

			}
		},
		mounted() {
			this.getInfo(6)
		},
		methods: {

		}


	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
	}
</style>