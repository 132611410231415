<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='1' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>软性指标</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">

							<SoftIndex ref="soft" :stage="stage" :type="type" :versions="versions" :url="url"
								jumpAddress="dataValuationLiabilitiesFast" backAddress="dataValuationInfoFast" />

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import SoftIndex from '@/components/dataValuation/softIndex/index.vue'
	import qs from 'qs'
	import axios from 'axios';


	export default {
		components: {
			Header,
			fillinNav,
			SoftIndex

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '15',
				versions: '2',
				str: '元素填充率：（<div class="terminology"><i>元素</i><i>是组成数据源中记录或者数据项的最小单元</i></div>填充率=<div class="terminology"><i>数据集</i><i>是指数据记录汇聚的数据形式</i></div>赋值的元素数量/元素总数量）'

			}
		},
		mounted() {

		},
		computed: {


		},
		methods: {

		}



	}
</script>


<style scoped lang="less">
@import "~@/assets/css/dataValuationNew.less";
	
	
</style>