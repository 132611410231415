<template>
	<div>
		<div style="margin-top: 61px;">
			<i class="iconfont icon-dizhi" style="color: #282828;margin-right: 5px;"></i>
			<Breadcrumb separator=">" style="width: 80%;display: inline-block;">
				<BreadcrumbItem :to="item.path" v-for="(item,index) in list" :key="index" v-if="index!=list.length-1">{{item.name}}</BreadcrumbItem>
				<BreadcrumbItem v-else>{{list[list.length-1].name}}</BreadcrumbItem>
			</Breadcrumb>
		</div>
		
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array
			}
		},
		mounted() {

		}
	}
</script>

<style scoped lang="less">

</style>