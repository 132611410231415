<template>
	<div id="main" v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="450" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner" :style="{'background':'url('+item.imgPath+') center center no-repeat'}">
					<div>
						<p class="englishName">{{item.englishName}}</p>
						<p class="productName">{{item.productName}}</p>
						<p class="banner_text">{{item.describe}}</p>
						<button class="buttonFill" @click="nowFill(14)">立即测评</button>
						<button class="buttonBorder" @click="openPdf(item.pdf)">查看报告示例</button>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent" style="margin-bottom: 0;">
			<div>
				<p class="moduleTitle">{{pageSetup.advantageName}}</p>
				<ul class="advantage">
					<li v-for="(item,index) in pageSetup.superiority">
						<img :src="item.imgPath" alt="" />
						<p>{{item.title}}</p>
						<p>{{item.describe}}</p>
					</li>
				</ul>
			</div>
			<div>
				<p class="moduleTitle">{{pageSetup.frameworkName}}</p>
				<div class="framework">
					<img :src="pageSetup.productArchitecture.imgPath" />
				</div>
			</div>
		</div>
		<div class="reportExampleList">
			<p class="moduleTitle">{{pageSetup.reportExampleName}}</p>
			<div>
				<div class="theSwiper">
					<swiper :options="swiperOption">
						<swiper-slide v-for="(item,index) in pageSetup.reportExample">
							<img :src="item.imgPath" alt="" />
						</swiper-slide>
					</swiper>
					<!-- 添加自定义按钮容器 -->
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</div>
			</div>
		</div>
		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/swiper.css";


	import './common.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			swiper,
			swiperSlide
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					bannerList: [],
					superiority: [],
					productArchitecture: {},
					reportExample: []
				},
				swiperOption: {
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
					slidesPerView: 3, //页面中的板块数量
					spaceBetween: 47, //板块间隔
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				},

			}
		},
		mounted() {
			this.getInfo(2)

		},
		methods: {
			nowFill(type) {
				this.$router.push({
					path: type == 13 ? 'information' : 'basicInformationEvaluation',
					query: {
						type: type
					},
				});
			},
		}
	}
</script>

<style scoped lang="less">


</style>