<template>
	<div>
		<div class="softNav">
			<ul>
				<li v-for="(item,index) in list" :key="index" @click="tabs(index)">
					<div :class="[slideIndex === index?'softActive':'']">
						<img v-if="slideIndex === index" :src="item.selectedIcon" />
						<img v-else :src="item.isAccomplish?item.accomplishIcon:item.icon" />
						<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">
							{{item.contentName}}
						</p>
					</div>
					<img v-if="index!=list.length-1" src="../../../assets/img/finance/arrows.png" />
				</li>
			</ul>
		</div>


		<Modal v-model="model" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' :src="require('@/assets/img/fillin/unsave.png')">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>

	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		props: {
			currentFinance: {
				type: Number
			},
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},

		},
		data() {
			return {
				slideIndex: this.currentFinance,
				skip: 0,
				model: false,
				list: [{
					contentName: '资产负债表',
					isAccomplish: false,
					icon: require('@/assets/img/finance/assetBlue.png'),
					selectedIcon: require('@/assets/img/finance/assetOrange.png'),
					accomplishIcon: require('@/assets/img/finance/assetGreen.png'),
					url: 'dataValuationLiabilitiesFast'
				}, {
					contentName: '利润表',
					isAccomplish: false,
					icon: require('@/assets/img/finance/profitBlue.png'),
					selectedIcon: require('@/assets/img/finance/profitOrange.png'),
					accomplishIcon: require('@/assets/img/finance/profitGreen.png'),
					url: 'dataValuationProfitFast'
				}]


			}
		},
		computed: {
			checkStatus() {
				return this.$store.state.user.checkStatus;
			}
		},
		watch: {
			checkStatus: {
				handler: function(newValue, oldValue) {
					this.$set(this.list[0], 'isAccomplish', newValue.liabilitiesInfoFlag)
					this.$set(this.list[1], 'isAccomplish', newValue.profitInfoFlag)

				},
				deep: true
			}

		},
		mounted() {


		},
		computed: {
			...mapState(['currentPageModify', 'currentPageSave', 'checkStatus']),
		},
		methods: {
			...mapMutations(['pageModify']),
			tabs(i) {
				this.skip = i;
				if (this.currentPageModify == 'true' && this.currentPageSave == false) {
					this.model = true;
					this.pageModify('false');
				} else {
					this.slideIndex = i
					if (this.$route.query.reportId) {
						this.$router.push({
							name: this.list[i].url,
							query: {
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							name: this.list[i].url,
						})
					}
				}
			},
			submitDialog() {
				this.model = false;
				this.slideIndex = this.skip
				this.pageModify('false');
				if (this.$route.query.reportId) {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						path: this.list[this.skip].url,
					})
				}
			},

		}

	}
</script>

<style scoped>
	/deep/ .softNav {
		width: 288px;
		margin: 0 auto;
		position: relative;
	}

	/deep/ .softNav>img {
		position: absolute;
		bottom: -36px;
	}

	/deep/ .softNav>ul {
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	/deep/ .softNav>ul>li {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	/deep/ .softNav>ul>li>img {
		width: 19px;
		margin-left: 61px;
	}

	/deep/ .softNav>ul>li div {
		position: relative;
		z-index: 2;
		text-align: center;
	}

	/deep/ .softNav>ul>li div img {
		width: 11vh;
		max-width: 77px;
	}

	/deep/ .softNav>ul>li div p {
		width: 100%;
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
	}
</style>