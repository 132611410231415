<template>
	<div>
		<Header />
		<Banner name="订单信息" :banner="require('@/assets/img/realization/banner.png')" />
		<div class="mainContent">
			<Crumbs :list="crumbsList" />
			<p class="checkInformation">填写并核对订单信息</p>
			<div class="orderInfo">
				<p class="orderInfoTitle">产品信息</p>
				<ul class="productOrderDetails">
					<li>
						<span>产品名称：</span>
						<span>{{data.name}}</span>
					</li>
					<li>
						<span>产品编号：</span>
						<span>{{data.id}}</span>
					</li>
					<li>
						<span>交易供方：</span>
						<span>{{data.companyName}}</span>
					</li>
					<li>
						<span>交付渠道：</span>
						<span>自行交付</span>
					</li>
					<li v-if="data.type==3">
						<span>客户买入到期收益率：</span>
						<span>{{data.rate}}</span>
					</li>
					<li v-if="data.type==3">
						<span>期限：</span>
						<span>{{data.term}}</span>
					</li>
					<li v-if="data.type==3">
						<span>剩余期限：</span>
						<span>{{data.residueTerm}}</span>
					</li>
					<li v-if="data.type==3">
						<span>到期日期：</span>
						<span>{{data.termTime}}</span>
					</li>
					<li v-if="data.type==2">
						<span>票面利率：</span>
						<span>{{data.rate}}</span>
					</li>
					<li v-if="data.type==2">
						<span>发行期限：</span>
						<span>{{data.term}}</span>
					</li>


					<li v-if="data.type!=6&&data.type!=5&&data.type!=4">
						<span>产品价格：</span>
						<span v-if="data.discuss">{{data.discuss}}</span>
						<span v-else>
							<i style="font-size: 20px;color: #E83323;font-weight: bold;">{{method.formateNum(data.price,2)}}</i>
							<i style="color: #E83323;font-weight: bold;vertical-align:bottom;">元/次</i>
						</span>
					</li>
					<li v-if="data.type==6">
						<span>轮次：</span>
						<span>{{data.round}}</span>
					</li>
					<li v-if="data.type==6">
						<span>行业：</span>
						<span>{{data.industry}}</span>
					</li>
					<li v-if="data.type==6">
						<span>地址：</span>
						<span>{{data.address}}</span>
					</li>
					<li v-if="data.type==5">
						<span>年利率：</span>
						<span>{{data.rate}}</span>
					</li>
					<li v-if="data.type==5">
						<span>贷款期限：</span>
						<span>{{data.term}}</span>
					</li>
					<li v-if="data.type==5">
						<span>贷款额度：</span>
						<span>{{data.loan}}</span>
					</li>

				</ul>
			</div>
			<div class="orderInfo">
				<p class="orderInfoTitle">买家联系方式</p>
				<Form ref="telForm" :model="contactWay" :rules="rules" class="formInformation">
					<FormItem prop="contacts" style="width: 70%;">
						<div class="item">
							<label><span class="reRed">*</span>联系人姓名</label>
							<div class="baseRight">
								<Input type="text" v-model="contactWay.contacts" placeholder="请输入" />
							</div>
						</div>
					</FormItem>
					<FormItem prop="phone" style="width: 70%;">
						<div class="item">
							<label><span class="reRed">*</span>联系人手机号</label>
							<div class="baseRight">
								<Input type="number" v-model="contactWay.phone" placeholder="请输入" />
							</div>
						</div>
					</FormItem>
					<FormItem prop="code" style="width: 70%;">
						<div class="item">
							<label><span class="reRed">*</span>短信验证码</label>
							<div class="baseRight" style="display: flex;justify-content: space-between;">
								<Input v-model="contactWay.code" placeholder="请输入验证码" style="width: 410px;" />
								<Button type="primary" class="getCode buttonFill" :disabled="!!cooling"
									@click="getCaptcha(contactWay.phone)">
									{{ cooling ? "重新发送(" + cooling + ")" : "获取验证码" }}
								</Button>
							</div>
						</div>
					</FormItem>
					<FormItem prop="num" class="num" key="num" v-if="!data.discuss&&data.type!=6&&data.type!=5&&data.type!=4">
						<div class="priceInfo">
							<p :style="{'background':hexToRgb($store.state.user.subjectColor.mainColor, '0.3')}">
								<span>价格</span>
								<span>数量</span>
								<span>总价</span>
							</p>
							<p>
								<span>￥{{method.formateNum(data.price,2)}}/次</span>
								<span>
									<Input type="number" v-model="contactWay.num" :min="1" style="width: 150px;" />
								</span>
								<span>￥{{method.formateNum(totalAmount,2)}}</span>
							</p>
						</div>
					</FormItem>
				</Form>
			</div>

			<button class="buttonFill save" @click="save">立即提交</button>

		</div>

		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import SignUp from '@/components/signUp/index.vue'
	import Banner from '@/components/threeLevelPage/detailsBanner.vue'
	import Crumbs from '@/components/crumbs/index.vue'
	import publicMethod from '@/views/publicMethod'

	import qs from 'qs'
	import axios from 'axios'

	import {
		mapActions,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			Banner,
			Crumbs
		},
		data() {
			return {
				crumbsList: [{
					name: '首页',
					path: '/'
				}, {
					name: '数据变现',
					path: '/dataDiscoveryIntroduction'
				}],
				data: {

				},
				contactWay: {
					code: '',
					contacts: '',
					phone: '',
					num: '1'
				},
				cooling: '',

			}
		},
		props: {
			code: {
				type: Array,
				default: function() {
					return [{
							required: true,
							message: '请输入短信验证码',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => this.checkIdentifyingCode(rule, value, callback), // 使用箭头函数
							trigger: 'blur'
						}
					]
				}
			},
			contacts: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入联系人姓名',
						trigger: 'blur'
					}]
				}
			},
			phone: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入联系人手机号',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
						message: '手机号格式不正确',
						trigger: 'blur'
					}]
				}
			},
			num: {
				type: Array,
				default: function() {
					return [{
							required: true,
							message: '请输入数量',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => this.judgeNum(rule, value, callback), // 使用箭头函数
							trigger: 'blur'
						}
					]
				}
			}

		},
		computed: {
			rules() {
				return {
					phone: this.phone,
					code: this.code,
					contacts: this.contacts,
					num: this.num
				}
			},
			totalAmount() {
				return this.contactWay.num * this.data.price
			}

		},
		mounted() {
			this.getDataProduct()

		},
		methods: {
			...mapActions([
				'getDataRealizationtTypeIndex'
			]),
			getDataProduct() {
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProduct', qs.stringify({
						productId: this.$route.query.id
					}))
					.then((res) => {
						this.data = res.data.content
						this.crumbsList.push({
							name: res.data.content.typeName,
							path: '/dataDiscoveryIntroduction'
						}, {
							name: '订单信息',
							path: ''
						})

					});
			},
			save() {
				var this_ = this;

				this.$refs.telForm.validate((valid) => {
					console.log(valid)
					if (valid) {
						console.log('可以保存')
						console.log(this.data)

						var params = {
							productName: this.data.name,
							productId: this.data.id,
							companyName: this.data.companyName,
							deliveryMethod: '自行交付',
							price: this.data.price,
							contacts: this.contactWay.contacts,
							phone: this.contactWay.phone,
							num: this.contactWay.num,
							totalPrice: this.totalAmount,
							discuss:this.data.discuss?this.data.discuss:''
						}

						console.log(params)

						this.$post(this.domain.dataRealization + 'productOrder/saveProductOrder', qs.stringify({
								orderInfo: JSON.stringify(params)
							}))
							.then((res) => {
								if (res.data.code == 200) {
									this.$Message.success('下单成功')
									setTimeout(() => {
										this_.$router.push({
											path: 'myOrder'
										})
									}, 600)

								} else {
									this.$Message.error(res.data.message)
								}
							});
					}
				})

			},
			checkIdentifyingCode(rule, value, callback) {
				this.$post(this.domain.dataRealization + 'dataCustomer/checkIdentifyingCode', qs.stringify({
						phoneNoOrMail: this.contactWay.phone,
						code: this.contactWay.code
					}))
					.then((res) => {
						if (res.data.code != 200) {
							callback(new Error(res.data.message));
						} else {
							callback();
						}
					});
			},
			judgeNum(rule, value, callback) {
				if (value <= 0) {
					callback(new Error('数量必须大于0'));
				} else {
					callback();
				}
			}





		}

	}
</script>

<style scoped lang="less">
	.checkInformation {
		width: 100%;
		height: 58px;
		line-height: 58px;
		background: var(--mainColor);
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		padding-left: 30px;
		margin-top: 30px;
	}

	.orderInfo {
		background: #fff;
		box-shadow: 0px 0px 13px 0px rgba(86, 136, 205, 0.23);
		padding: 30px;
		margin-top: 20px;

		.orderInfoTitle {
			font-size: 20px;
			font-weight: bold;
			color: #282828;
			padding-bottom: 10px;
			border-bottom: 1px solid #CACACA;
		}

		.productOrderDetails {
			overflow: hidden;

			li {
				width: 47%;
				margin-top: 28px;

				span {
					font-size: 14px;
					color: #282828;
				}

				span:last-child {
					width: calc(100% - 141px);
					font-weight: bold;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			li:nth-child(odd) {
				float: left;
			}

			li:nth-child(even) {
				float: right;
			}

		}

		.formInformation {
			// width: 70%;
			padding-left: 0;
		}

		/deep/ .num .ivu-form-item-error-tip {
			width: calc(57% - 4px);
		}

		.priceInfo {
			margin-top: 35px;

			p {
				height: 50px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				span {
					width: 250px;
					text-align: center;
					font-size: 16px;
					color: #282828;
					font-weight: bold;

					/deep/ .ivu-input {
						color: #282828 !important;
					}
				}

			}

			p:first-child {
				border: 1px solid var(--mainColor);
				margin-bottom: 10px;
			}
		}

	}
</style>