<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" category='净利润预测' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">净利润预测</p>
							<div :class="['forecast',years.length==3?'threeYears':'twoYears']">
								<p class="unit">单位：元</p>
								<div v-if="data.products">
									<ul class="forms">
										<i></i>
										<li>
											<span>业务分类</span>
											<span v-for="(item,i) in years">{{item}}<span v-if="i==0" class="reRed">*</span></span>
										</li>
										<li v-for="(operation,i) in data.products">
											<span>{{operation.businessName}}</span>
											<span v-for="(item,j) in operation.prediction">
												<input v-if="item.value!='-'" type="number" v-model="item.value">
												<input v-else type="number" disabled>

												<span v-if="item.value!='-'" class="showInput">
													{{item.value?method.formateNum(item.value,2):''}}
												</span>
												<span v-else class="showInput">-</span>
											</span>
										</li>
										<li>
											<span>合计</span>
											<span v-for="(item,i) in data.productSum.prediction">
												<input type="number" v-model="item.value" disabled>
												<span class="showInput">
													{{item.value?method.formateNum(item.value,2):''}}
												</span>
											</span>
										</li>
										<i></i>
									</ul>
								</div>

								<div v-else>
									<ul class="forms">
										<i></i>
										<li>
											<span>项目</span>
											<span v-for="(item,i) in years">{{item}}<span v-if="i==0" class="reRed">*</span></span>
										</li>
										<li>
											<span>净利润</span>
											<span v-for="(item,i) in data.productSum.prediction">
												<input type="number" v-model="item.value">
												<span class="showInput">
													{{item.value?method.formateNum(item.value,2):''}}
												</span>
											</span>
										</li>
										<i></i>
									</ul>
								</div>

							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				data: {
					productSum: {
						name: '',
						prediction: [{
							value: ''
						}, {
							value: ''
						}, {
							value: ''
						}]
					}
				},
				years: []

			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();

						this.data = JSON.parse(res.data.content.netProfit.netProfit)
						this.years = res.data.content.years
						console.log(this.data)

					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	input {
		pointer-events: none;
	}
</style>