<template>
	<div class="header" id="app">
		<div :style="{'height':method.ratioEquality(1920,140,width-100)+'px'}">
			<router-link class="home" tag="a" to="/home" :style="{'height':method.ratioEquality(185,125,width/11)+'px'}">
				<div>
					<img src="../../assets/img/home/homeIcon.png" />
					<p :style="{'top':width<1500?'-3px':'0'}">首页</p>
				</div>
			</router-link>

			<div class="center" :style="{'paddingTop':(method.ratioEquality(1920,140,width-100))/5+'px'}">
				<img class="logoDV" v-if="logo" :src="logo" :style="{'height':(method.ratioEquality(111,111,width/17))/2.5+'px'}"
					@click="goHome" />
			</div>

			<a class="login" :style="{'height':method.ratioEquality(185,125,width/11)+'px'}">
				<div>
					<img src="../../assets/img/home/loginIcon.png" />
					<p @click="login" :style="{'top':width<1500?'-3px':'0'}">{{logoShow?'登录':username}}</p>
					<ul :class="logoShow?'':'account-sel'">
						<li><router-link tag="a" to="/valuationList">我的报告</router-link></li>
						<li><router-link tag="a" to="/login">退出</router-link></li>
					</ul>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
	import '../../assets/css/myAlertData.css';

	import qs from 'qs'
	import axios from 'axios'

	export default {
		data() {
			return {
				width: window.innerWidth,
				logoShow: true,
				username: localStorage.userName,
				flowUrl: '',
				logo: ''
			}
		},
		computed: {
			top() {
				var top = this.width > 1450 && this.width < 1600 ? 13 : this.width > 1360 && this.width <= 1450 ? 16 : this
					.width <= 1360 ? 19 : 5
				return top
			}
		},
		mounted() {
			this.flowUrl = this.domain.testUrl

			window.addEventListener('resize', () => {
				if (window.innerWidth < 1200) {
					this.width = 1200
				} else {
					this.width = window.innerWidth
				}
			})

			this.isLogin()
			this.getPageSetup()
		},
		methods: {
			getPageSetup() {
				this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
					channelCode: window.channel,
					setupType: 7
				})).then(res => {
					var setupContent = JSON.parse(res.data.content.setupContent)
					this.logo = setupContent.valuation.imgPath
				})
			},
			isLogin() {
				if (!localStorage.userName || !localStorage.userToken) {
					this.logoShow = true
				} else {
					this.logoShow = false
				}
			},
			login() {
				if (this.logoShow) {
					this.$router.push({
						name: 'login',
						path: '/login'
					})
				}
			},
			goHome() {
				this.$router.push({
					name: 'Home',
					path: '/'
				})
			}
		}

	}
</script>

<style scoped lang="less">
	.header {
		width: 100%;
		position: absolute;
	}

	.header>div {
		width: calc(100% - 100px);
		background: url(../../assets/img/home/header.png) center center no-repeat;
		background-size: cover;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		position: relative;
		top: 10px;
		z-index: 2;

		.center {
			width: 76%;
			display: flex;
			justify-content: space-between;
			position: relative;

			img {
				object-fit: contain;
			}

			.headerCenter {
				width: 40vw;
				height: 24vh;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -20vw;
				margin-top: -5.5vh;
			}

			.logoDV {
				width: 9vw;
				object-fit: contain;
				position: absolute;
				left: 50%;
				cursor: pointer;
				transform: translateX(-50%);
			}

			.side {
				width: 40%;
				height: 60px;
				object-fit: contain;
				transform: translateY(40px);
			}

		}

		.home,
		.login {
			width: 10%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 9999;
			// background: rgba(0, 0, 0, .5);

			img {
				width: 50%;
			}
		}

		.home>div,
		.login>div {
			text-align: center;
		}

		.home p,
		.login p {
			width: 32px;
			color: #19ECFF;
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			text-align: center;
			position: relative;
		}

		.login ul {
			width: 120px;
			text-align: center;
			border-top: 0;
			background: #fff;
			border-radius: 5px;
			padding: 6px 0;
			display: none;
			position: absolute;
			top: 80%;
			left: 40px;
			z-index: 999;

			li a {
				width: 100%;
				font-size: 14px;
				line-height: 30px;
				cursor: pointer;
				text-align: left;
				margin-left: 16px;
				box-sizing: content-box;
				color: #1459F4;
			}

			li a:hover {
				color: #002d93;
			}
		}

		.login:hover .account-sel {
			display: block;
		}

	}
</style>