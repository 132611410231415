<template>
	<div id="main" v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="450" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner" :style="{'background':'url('+item.imgPath+') center center no-repeat'}">
					<div>
						<p class="englishName">{{item.englishName}}</p>
						<p class="productName">{{item.productName}}</p>
						<Select v-model="text" size="large" filterable allow-create not-found-text="请输入关键字进行检索"
							@on-select="handleSelect" style="width: 466px;margin-top: 15px;">
							<Option v-for="item in productName" :value="item.value" :key="item.value">{{ item.label }}</Option>
						</Select>

					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<RealizationMenu :name="pageSetup.bannerList[0].productName" @on-select="handleEvent" />
			<div class="list">
				<div v-if="total==0" class="nothing">
					<img src="../../assets/img/img_nothing.png" alt="暂无信息" />
					<p>暂无信息</p>
				</div>
				<ul v-if="type=='6'||type=='5'||type=='2'">
					<li v-for="(item,index) in records">
						<p class="graphic2">
							<img :src="item.logo" alt="" />
						</p>
						<div class="ListTypeTwo">
							<p class="name">{{item.name}}</p>
							<div>
								<!-- 股权融资 -->
								<p v-if="type=='6'">
									<span>
										<i class="iconfont icon-inning" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>轮次：{{item.round}}</span>
								</p>
								<p v-if="type=='6'">
									<span>
										<i class="iconfont icon-xingye" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>行业：{{item.industry}}</span>
								</p>
								<p v-if="type=='6'">
									<span>
										<i class="iconfont icon-dizhi" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>地址：{{item.address}}</span>
								</p>
								<!-- 数据资产质押融资 -->
								<p v-if="type=='5'">
									<span>
										<i class="iconfont icon-shangshengjiantou"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>年利率：{{item.rate}}</span>
								</p>
								<p v-if="type=='5'">
									<span>
										<i class="iconfont icon-a-9xinyongqixianedushenpi"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>贷款期限：{{item.term}}</span>
								</p>
								<p v-if="type=='5'">
									<span>
										<i class="iconfont icon-mianfeitixianedu"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>贷款额度：{{item.loan}}</span>
								</p>
								<!-- 数据资产证券化 -->
								<p v-if="type=='2'">
									<span>
										<i class="iconfont icon-xiangmujine"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>发行金额：{{method.formateNum(item.price,2)}}</span>
								</p>
								<p v-if="type=='2'">
									<span>
										<i class="iconfont icon-a-piaomianxuanzhong"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>票面利率：{{item.rate}}</span>
								</p>
								<p v-if="type=='2'">
									<span>
										<i class="iconfont icon-yingyeqixian"
											:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
									</span>
									<span>发行期限：{{item.term}}</span>
								</p>
							</div>
						</div>
						<button class="buttonFill" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>
				<ul v-else-if="type=='4'||type=='1'">
					<li v-for="(item,index) in records">
						<p class="graphic2">
							<img :src="item.logo" alt="" />
						</p>
						<div class="ListTypeOne" style="border: none;">
							<p class="name">{{item.name}}</p>
							<p class="describe">{{item.profile}}</p>
						</div>
						<button class="buttonFill" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>
				<ul v-else-if="type=='3'">
					<li v-for="(item,index) in records">
						<p class="graphic2">
							<img :src="item.logo" alt="" />
						</p>
						<div class="ListTypeThree">
							<div>
								<span>{{item.name}}</span>
								<span>交易全价：{{method.formateNum(item.price,2)}}元</span>
							</div>
							<div>
								<div class="productInfo">
									<p style="width: 65%;">
										<span>
											<i class="iconfont icon-shouyi"
												:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'14px'}"></i>
										</span>
										<span>客户买入到期收益率：{{item.rate}}</span>
									</p>
									<p style="width: 35%;">
										<span>
											<i class="iconfont icon-a-9xinyongqixianedushenpi"
												:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
										</span>
										<span>贷款期限：{{item.term}}</span>
									</p>
									<p>
										<span>
											<i class="iconfont icon-shengyuliang"
												:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
										</span>
										<span>剩余期限：{{item.residueTerm}}</span>
									</p>
								</div>
								<button class="buttonFill" @click="viewDetails(item.id)">查看详情</button>
							</div>
						</div>
					</li>
				</ul>

				<ul v-else>
					<li v-for="(item,index) in records">
						<p class="graphic">
							<img :src="item.logo" alt="" />
						</p>
						<div class="ListTypeOne">
							<p class="name">{{item.name}}</p>
							<p class="describe">{{item.profile}}</p>
						</div>
						<p class="price" v-if="item.discuss" style="text-align: center;">
							<i style="font-size: 18px;">{{item.discuss}}</i>
						</p>
						<p class="price" v-else>
							<i>{{method.formateNum(item.price,2)}}</i>
							<i>元/次</i>
						</p>
						<button class="buttonFill" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>

				<Page v-model="currentPageNumber" :total="total" :page-size="pageSize" prev-text="上一页" next-text="下一页"
					@on-change="pageChange" />
			</div>
		</div>

		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import RealizationMenu from '@/components/realizationMenu/index.vue'
	import qs from 'qs'
	import axios from 'axios'

	import '../../assets/css/paging.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			RealizationMenu,
		},
		data() {
			return {
				value: 0,
				mainColor: '',
				text: '',
				currentPageNumber: 1, //当前页码
				total: 0, //总页数
				pageSize: 10, //每页条数
				productName: [], //搜索的产品名称列表
				pageSetup: {
					bannerList: [{
						productName: '数据变现'
					}],
				},
				type: '',
				records: []

			}
		},
		mounted() {
			this.getInfo(4)
			this.getDataProductName()
			// 获取根元素的样式
			var rootStyle = getComputedStyle(document.documentElement);
			// 获取--mainColor的值
			var mainColor = rootStyle.getPropertyValue('--mainColor');
			this.mainColor = mainColor


		},
		methods: {
			handleSelect(value) {
				console.log('选中后', value)
				this.viewDetails(value)
			},
			handleChange(value) {
				console.log(value)
			},
			handleEvent(data) {
				console.log('接受的参数---', data)
				this.type = data
				this.findDataProduct(1, this.pageSize, data, '')
			},
			pageChange(data) { //切换页数
				console.log(data)
				this.findDataProduct(data, this.pageSize, this.type, '')
			},
			getDataProductName() { //查询全部的数据产品名称
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProductName', qs.stringify({}))
					.then((res) => {
						this.productName = []
						res.data.content.map(el => {
							this.productName.push({
								value: el.id,
								label: el.name
							})
						})

					});

			},
			viewDetails(id) {
				this.$router.push({
					path: 'dataCashProductDetails',
					query: {
						productId: id
					}
				});
			},
			findDataProduct(pageNum, pageSize, type, status) {
				this.records = []
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProductList', qs.stringify({
						pageNum: pageNum,
						pageSize: pageSize,
						type: type,
						status: '3'
					}))
					.then((res) => {
						console.log(res.data)
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			}




		}

	}
</script>

<style scoped lang="less">
	/deep/ .ivu-select-item-selected,
	/deep/ .ivu-select-item:hover {
		color: var(--mainColor);
		/* 这里可以替换为您想要的颜色值 */
	}

	/deep/ .ivu-select-item {
		width: 466px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/deep/ .ivu-select-visible .ivu-select-selection {
		border-color: var(--mainColor);
		/* 设置边框颜色 */
		box-shadow: 0 0 3px var(--mainColor);
		/* 设置阴影效果 */
		outline: none;
		/* 取消默认的聚焦边框样式 */
	}

	/deep/ .ivu-select-selection-focused,
	/deep/ .ivu-select-selection:hover {
		border-color: var(--mainColor);
	}

	.mainContent {
		display: flex;
		margin-top: 65px;
	}



	.list {
		width: calc(100% - 274px);

		>ul {

			>li {
				width: 100%;
				padding: 25px 30px;
				box-shadow: 0px 0px 13px 0px rgba(86, 136, 205, 0.23);
				margin-bottom: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				>.graphic {
					width: 210px;
					height: 100px;
					background: #fff;
					border: 1px solid #ccc;
					padding: 10px;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				>.graphic2 {
					width: 210px;
					height: 100px;
					border: 1px solid #ccc;
					padding: 10px;
					// background: #1459F4;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				>.price {
					width: 146px;
					color: #FF0000;
					margin: 0 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;

					i:first-child {
						font-size: 18px;
						font-weight: bold;
					}

					i:last-child {
						font-size: 14px;
						font-weight: bold;
					}
				}

				button {
					width: 130px;
					height: 45px;
					font-size: 16px;
				}

				.ListTypeOne {
					width: 40%;
					border-right: 1px solid #DDDDDD;
					padding-left: 24px;
					padding-right: 20px;

					.name {
						width: 400px;
						font-size: 20px;
						font-weight: bold;
						color: #282828;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 15px;
					}

					.describe {
						width: 100%;
						font-size: 14px;
						color: #555555;
						line-height: 24px;
						text-align: justify;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

				}

				.ListTypeTwo {
					padding-left: 24px;
					padding-right: 20px;

					.name {
						width: 413px;
						font-size: 20px;
						font-weight: bold;
						color: #282828;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 15px;
					}

					>div {
						width: 413px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;

						>p {
							width: 50%;
							margin-bottom: 15px;
							line-height: 23px;

							span:first-child {
								width: 19px;
								font-size: 18px;
							}

							span:last-child {
								font-size: 14px;
								color: #282828;
								margin-left: 10px;
							}
						}

						>p:nth-last-child {
							margin-bottom: 0;
						}

					}

				}

				.ListTypeThree {
					width: calc(100% - 264px);

					>div:first-child {
						display: flex;
						justify-content: space-between;

						span {
							font-size: 20px;
							font-weight: bold;
							color: #282828;
						}

						span:first-child {
							width: 68%;
							margin-right: 20px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					>div:last-child {
						display: flex;
						justify-content: space-between;
						margin-top: 14px;

						.productInfo {
							width: calc(100% - 180px);
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;

							p {
								width: 50%;
								margin-bottom: 5px;
								line-height: 23px;

								span:first-child {
									width: 19px;
									font-size: 18px;
								}

								span:last-child {
									font-size: 14px;
									color: #282828;
									margin-left: 10px;
								}
							}

							p:last-child {
								margin-bottom: 0;
							}

						}
					}

				}

			}
		}
	}
</style>