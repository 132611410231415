<template>
	<div class="main">
		<Header />
		<div id="formWidth">
			<div class="fillinTitle">估值报告</div>
			<fillinNav />
			<div class="fillinMain">
				<div>
					<img class="guzhiReport" src="../../assets/img/guzhiReport.png" />
					<div class="reportInfo">
						<p>数据估值报告</p>
						<ul>
							<li>
								<span></span>
								<span><i>智能化：</i>利用人工智能和大数据技术，对数据资产进行智能化评估</span>
							</li>
							<li>
								<span></span>
								<span><i>全面化：</i>从数据的基础指标、风险指标、质量指标、经济指标、流通指标和成本指标等维度深入分析数据价值</span>
							</li>
							<li>
								<span></span>
								<span><i>安全性：</i>采用国密算法，严格的数据安全保护措施，保障数据资产的安全性和隐私性</span>
							</li>
							<li>
								<span></span>
								<span><i>可视化：</i>一键生成可视化报告，帮助企业更好地理解和分析数据资产情况</span>
							</li>
						</ul>

						<div class="price" v-if="finish">
							<div v-if="auditStatus==null&&fixPrice!=0">
								<span>优惠价：￥{{price}}/份</span>
								<span class="fixPrice">原价：￥{{fixPrice}}/份</span>
							</div>
						</div>
						<div class="complete_info" v-else>您的资料未填写完成</div>
						<div class="saveInfo" v-if="auditStatus==='0'" @click="resubmitReview">{{finish?'修改完成重新提交审核':'立即填写'}}</div>
						<div class="saveInfo" v-else @click="pay">{{finish?'立即提交':'立即填写'}}</div>
					</div>
				</div>
			</div>

		</div>




		<!-- 付款成功提示框 -->
		<Modal v-model="paySuccess" class-name="vertical-center-data paySuccess paymentCorrelation"
			@on-visible-change="modelPaySuccess" :mask-closable="false">
			<img src="../../assets/img/fillin/success.png" alt="付款成功-心流">
			<div class="hint-success">
				<p>恭喜您，付款成功！估值报告审核中，请耐心等待</p>
				<p>审核人员会在一个工作日内反馈审核结果。</p>
				<p>如有疑问请拨打客服电话：{{setupContent.serviceTel}}</p>
			</div>
			<div slot="footer" class="hint-sure" @click="jumpLists">确定</div>
		</Modal>

		<!-- 弹出填写发票 -->
		<Modal v-model="payment" class-name="vertical-center-data payment paymentCorrelation"
			@on-visible-change="modelSaveclose" :mask-closable="false">

			<div class="hint">
				<!--报告类型-->
				<div class="type report_style">
					<img src="../../assets/img/fillin/价格@2x.png" alt="线上估值-心流" />
					<p>报告价格： </p>
					<span><i>￥</i>{{price}}</span>
				</div>

				<!--是否开发票-->
				<div class="type is_invoice">
					<img src="../../assets/img/fillin/发票@2x.png" alt="线上估值的发票-心流" />
					<p>开发票:</p>
					<div class="radio yes" data-invoiceFlag="1">
						<input type='radio' id="yes-radio" name="" :checked="!noInvoice?'checked':''" @click="yesRadio" />
						<label for="yes-radio">是</label>
					</div>
					<div class="radio no" data-invoiceFlag="1">
						<input type='radio' id="no-radio" name="" :checked="noInvoice?'checked':''" @click="noRadio" />
						<label for="no-radio">否</label>
					</div>
				</div>
				<!--发票信息-->
				<div class="check_info" v-if="!noInvoice">
					<div class="check_title">
						<span></span>
						<span>电子普通发票<i style="font-size: 12px;">（如需纸质发票请联系客服，客服电话：+86 (10)8444-6599）</i> </span>
					</div>
					<div class="needed invoice-title">
						<span>发票抬头</span>
						<input type="text" name="" id="invoice-title" value="" v-model="invoiceTitle" />
					</div>
					<div class="needed invoice-title">
						<span>纳税人识别号</span>
						<input type="text" name="" id="taxpayer-num" value="" v-model="taxpayerNum" />
					</div>
					<div class="needed invoice-title">
						<span>电子邮件</span>
						<input type="text" name="" id="email" value="" placeholder="推荐使用QQ邮箱" v-model="email" />
					</div>
					<div class="needed invoice-title">
						<span>手机号</span>
						<input type="text" name="" id="mobile" value="" placeholder="" v-model="phoneNo" />
					</div>
				</div>
			</div>
			<div slot="footer" class="pay_now" @click="saveInvoiceInfo">立即付款</div>
		</Modal>

		<!-- 弹出支付码 -->
		<Modal v-model="paymentCode" class-name="vertical-center-data payment paymentCorrelation" :mask-closable="false"
			@on-visible-change="codeAlert">
			<div class="qrcode" ref="qrCodeUrl">

			</div>
			<div class="payPrice" style="font-size: 30px;font-weight: bold;color: #29FFFC;">
				{{price}}元
			</div>
			<p class="selectionMethod" style="margin-top: 10px;">使用支付宝、微信扫码支付</p>
			<div class="payIcon">
				<img src="../../assets/img/guzhi/icon_alipay.png">
				<img src="../../assets/img/guzhi/icon_wechat.png">
			</div>
			<p slot="footer"></p>
		</Modal>

		<dialogBar v-model="auditAlert" :type="popButonType" :title='markedWords' content=""
			@confirmJump="clickConfirmJump()"></dialogBar>
	</div>
</template>

<script>
	import Header from '@/common/header.vue'
	import fillinNav from '../../common/fillin/fillinNav.vue'
	import dialogBar from './popue.vue'
	import qs from 'qs'
	import axios from 'axios'
	import QRCode from 'qrcodejs2'


	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		components: {
			Header,
			fillinNav,
			dialogBar,
		},
		data() {
			return {
				isTestAccount: false,
				finish: false, //必填项是否全部填写完成
				popButonType: '',
				markedWords: '',
				price: '', //现在的价格
				fixPrice: '', //原价
				auditStatus: null, //审核状态0：审核不通过 ， 1：审核通过 ， 2：审核中
				title: '',
				hrefHtml: '/information', //未填写完的跳转路径
				// checkObj: [],
				auditAlert: false, //重新提交审核弹框
				paySuccess: false, //付款成功后的弹框
				payment: false, // 付款信息弹框
				paymentCode: false, //支付的二维码弹框
				noInvoice: false, //false 开发票   true 不开发票
				invoiceTitle: '', //发票抬头
				taxpayerNum: '', //纳税人识别号
				email: '', //电子邮件
				phoneNo: '', //手机号
				invoiceId: '', //发票id
				timer: null,
				projectName: '',
				ishow: true,
				setupContent:{}

			}
		},
		mounted() {
			this.projectName = this.domain.valuationProject
			this.getPay()
			this.getReportPrice()
			this.init()
		},
		methods: {
			init() {
				this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
						channelCode: window.channel,
						setupType: 7
					}))
					.then((res) => {
						this.setupContent = JSON.parse(res.data.content.setupContent)
					});
			},
			getReportPrice: function() { //获取价格
				this.$post(this.domain.evaluation + 'pay/getReportPriceInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.price = res.data.content.price
						this.fixPrice = res.data.content.fixPrice
					})

			},
			pay: function() {
				//测试账号免支付
				if (this.finish) {
					if (this.isTestAccount) {
						this.noPayment();
					} else {
						// 正式号支付流程
						if (this.price == '0' && !this.isTestAccount) { //正式号生成价格为0 不走支付流程
							this.noPayment();
							// console.log("111")
						} else {
							this.payment = true;
							this.queryInvoice();

						}
					}
				} else {
					if (this.$route.query.reportId) {
						this.$router.push({
							path: this.hrefHtml,
							query: {
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							path: this.hrefHtml,
							query: {
								type: this.$route.query.type,
							}
						})
					}
				}
			},
			resubmitReview() {
				if (this.finish){
					this.auditAlert = true
					this.popButonType = 'closeData';
					this.markedWords = '确认修改完成，重新提交审核？';
				}else{
					this.$router.push({
						path: this.hrefHtml,
						query: {
							type: this.$route.query.type,
							reportId: this.$route.query.reportId
						}
					})
				}
			},
			noPayment: function(status) { //测试账号免支付   isTestAccount = true
				this.$post(this.domain.evaluation + 'pay/testAccountPay', qs.stringify({
						reportId: this.$route.query.reportId
					}))
					.then((res) => {
						// 判断是否  默认加载 或 点击支付调用
						//  if(status == 1){
						this.paySuccess = true;
						//  }
						//  this.isTestAccount = res.data.content.isTestAccount
					});
			},
			getPay() { //purpose 1：预支付 0：查看数据填写情况   
				this.$post(this.domain.valuationProject + 'pay/getToPayDataReportInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						purpose: '1'
					}))
					.then((res) => {
						this.isTestAccount = res.data.content.isTestAccount
						this.auditStatus = res.data.content.auditStatus

						if (res.data.content.check) {
							var check = JSON.parse(res.data.content.check)
							if (!check.baseInfoFlag) {
								this.hrefHtml = '/information'
								this.finish = false
							} else if (!check.questionInfoFlag) {
								this.hrefHtml = '/softIndex'
								this.finish = false
							} else if (!check.financialForecastFlag) {
								this.hrefHtml = '/yearForecast'
								this.finish = false
							} else if (!check.dataBookValueFlag) {
								this.hrefHtml = '/carryingValue'
								this.finish = false
							} else if (!check.dataIncomeFlag) {
								this.hrefHtml = '/incomeForecast'
								this.finish = false
							} else {
								this.finish = true
							}
						} else {
							this.finish = false
						}

					})
			},
			queryInvoice: function() { //查询发票信息
				//   this.$Spin.show(); //全局加载中
				this.$post(this.projectName + 'invoice/queryInvoiceByReportId', qs.stringify({
						valuationReportId: this.$route.query.reportId
					}))
					.then((res) => {
						//   this.$Spin.hide();
						console.log(res)
						if (res.data.content) {
							this.invoiceTitle = res.data.content.invoiceTitle;
							this.taxpayerNum = res.data.content.taxpayerNum;
							this.email = res.data.content.email;
							this.phoneNo = res.data.content.phoneNo;
							this.invoiceId = res.data.content.id;
						}
					});
			},
			yesRadio: function() { //开发票
				this.noInvoice = false;
				$('.ivu-modal').removeClass('ivu-active-bg'); //切换背景图
			},
			noRadio: function() { //不开发票
				this.noInvoice = true;
				$('.ivu-modal').addClass('ivu-active-bg'); //切换背景图
			},
			saveInvoiceInfo: function() { //保存发票信息
				var emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/; //邮箱
				var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; //电话
				if (!this.noInvoice) {
					var params = {
						valuationReportId: this.$route.query.reportId,
						invoiceTitle: this.invoiceTitle,
						taxpayerNum: this.taxpayerNum,
						email: this.email,
						phoneNo: this.phoneNo,
						id: this.invoiceId,
						invoiceFlag: !this.noInvoice ? '1' : '0' //1开发票   0不开发票
					}

					if (!this.invoiceTitle) {
						this.$Message.info('请输入发票抬头', 5);
						return false
					}
					if (!this.taxpayerNum) {
						this.$Message.info('请输入纳税人识别号', 5);
						return false
					}
					if (!this.email) {
						this.$Message.info('请输入电子邮件', 5);
						return false
					} else {
						if (!emailReg.test(this.email)) {
							this.$Message.info('请输入电子邮件', 5);
							return false
						}
					}
					if (!this.phoneNo) {
						this.$Message.info('请输入手机号', 5);
						return false
					} else {
						if (!phoneReg.test(this.phoneNo)) {
							this.$Message.info('请输入手机号', 5);
							return false;
						}
					}

				} else {
					var params = {
						valuationReportId: this.$route.query.reportId,
						id: this.invoiceId,
						invoiceFlag: !this.noInvoice ? '1' : '0' //1开发票   0不开发票
					}
				}
				this.$post(this.projectName + 'invoice/insertOrUpdateInvoice', qs.stringify(params))
					.then((res) => {
						console.log(res.data)
						this.payment = false;
						this.paymentCode = true;
						this.creatQrCode()
					});
			},
			creatQrCode: function() { //生成支付的二维码
				$(".qrcode").html('');
				var text = this.domain.testUrl + this.projectName + '/pay/qrcallback?reportId=' + (this.$route.query.reportId);
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: text,
					width: 132,
					height: 132,
					colorDark: "#000000",
					colorLight: "#ffffff",
					//   top:30,
					correctLevel: QRCode.CorrectLevel.H,
				})
				this.payIsSuccess()
			},
			payIsSuccess: function() { //支付信息--轮询
				var this_ = this;
				var id = this.$route.query.reportId;
				this_.timer = setInterval(function() {
					this_.$post(this_.projectName + '/pay/autoFindOrder', qs.stringify({
							id: id
						}))
						.then((res) => {
							console.log(res.data)
							if (res.data == '1') { //订单状态为1表示支付成功 ，0未支付
								clearInterval(this_.timer); //关闭定时器
								this_.paymentCode = false;
								this_.paySuccess = true;
							}
						});
				}, 1000)
			},
			modelSaveclose: function() {
				this.noInvoice = false;
			},
			modelPaySuccess: function() { //监听付款成功的弹框
				if (!this.paySuccess) {
					this.$router.push({
						name: 'valuationList'
					})
				}
			},
			codeAlert: function() { //监听付款码关闭的时候
				if (!this.paymentCode) {
					clearInterval(this.timer); //关闭定时器
				}
			},
			//   成功后跳转  列表界面
			jumpLists: function() {
				this.$router.push({
					name: 'valuationList'
				})
			},
			clickConfirmJump() {
				console.log('地那就的额')
				this.$post(this.domain.dataRealization + 'auditInfo/againDataAudit', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					}))
					.then((res) => {
						this.auditAlert = false
						this.$router.push({
							name: 'valuationList'
						})
					})
			},
		





		}


	}
</script>

<style scoped src="../../assets/css/data.css"></style>
<style scoped lang="less">
	.fillinMain::-webkit-scrollbar {
		border: none;
	}

	.fillinMain {
		width: 51%;
		height: 56%;
		display: flex;
		align-items: center;

		>div {
			display: flex;
			justify-content: space-between;

			>.guzhiReport {
				width: 400px;
				object-fit: contain;
			}

			.reportInfo {
				width: 44%;
				margin-right: 35px;

				>p {
					font-size: 20px;
					font-weight: bold;
					color: #FFFFFF;
					margin-bottom: 19px;
				}

				ul {
					li {
						position: relative;
						margin-bottom: 17px;

						span:first-child {
							width: 9px;
							height: 9px;
							background: #29FFFC;
							transform: rotate(45deg);
							position: absolute;
							top: 8px;
						}

						span:last-child {
							width: calc(100% - 9px);
							font-size: 16px;
							color: #FFFFFF;
							margin-left: 23px;

							i {
								font-weight: bold;
							}

						}
					}
				}

				.complete_info {
					font-size: 18px;
					color: red;
					margin-top: 31px;
				}

				.price {
					margin-top: 31px;

					span {
						font-size: 18px;
						font-weight: bold;
						color: #29FFFC;
					}

					del,
					.fixPrice {
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						margin-left: 25px;
					}

					.fixPrice {
						text-decoration: line-through;
					}
				}

			}
		}
	}

	.hint {
		width: 85%;
		padding: 16px 0 0;
		margin: 0 auto;
	}

	@media screen and (max-width:1500px) and (max-height:700px) {
		.fillinMain>div .reportInfo>p {
			font-size: 22px;
		}

		.fillinMain>div .reportInfo ul li span:last-child {
			font-size: 18px;
			line-height: 25px;
		}
	}
</style>