<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" />

			<div class="userinfo">
				<p class="subhead">安全设置</p>
				<ul class="qss">
					<li>
						<img v-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
							src="../../../assets/img/myCenter/authentication2.png" alt="" />
						<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
							src="../../../assets/img/myCenter/authentication3.png" alt="" />
						<img v-else-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
							src="../../../assets/img/myCenter/authentication4.png" alt="" />
						<img v-else src="../../../assets/img/myCenter/authentication.png" alt="" />
						<span class="name">实名认证</span>
						<p>
							<Icon type="md-checkmark-circle" size="22"
								:color="$store.state.user.customerInfo.auditStatus==1?'#029E06':'#C9312D'" style="margin-right: 4px;" />
							<span
								:style="{'color':$store.state.user.customerInfo.auditStatus==1?'#029E06':'#C9312D'}">{{$store.state.user.customerInfo.auditStatus==1?'已认证':'未认证'}}</span>
						</p>
						<p class="securityDescriptor">保障账户安全，确认用户身份</p>
						<p class="tel"></p>
						<button class="buttonFill" @click="authentication">立即认证</button>
					</li>
					<li>
						<img v-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
							src="../../../assets/img/myCenter/mobilePhone2.png" alt="" />
						<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
							src="../../../assets/img/myCenter/mobilePhone3.png" alt="" />
						<img v-else-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
							src="../../../assets/img/myCenter/mobilePhone4.png" alt="" />
						<img v-else src="../../../assets/img/myCenter/mobilePhone.png" alt="" />
						<span class="name">手机绑定</span>
						<p>
							<Icon type="md-checkmark-circle" size="22" color="#029E06" style="margin-right: 4px;" />
							<span style="color:#029E06;">已绑定</span>
						</p>
						<p class="securityDescriptor">保障账户中重要信息，在设置、修改交易密码、登录密码等都需要手机短信验证</p>
						<p class="tel">{{$store.state.user.userName.slice(0,3)+'****'+$store.state.user.userName.slice(7)}}</p>
						<button class="buttonFill" @click="modifyTel">更换手机号</button>
					</li>
					<li>
						<img v-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
							src="../../../assets/img/myCenter/loginPassword2.png" alt="" />
						<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
							src="../../../assets/img/myCenter/loginPassword3.png" alt="" />
						<img v-else-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
							src="../../../assets/img/myCenter/loginPassword4.png" alt="" />
						<img v-else src="../../../assets/img/myCenter/loginPassword.png" alt="" />
						<span class="name">登录密码</span>
						<p>
							<Icon type="md-checkmark-circle" size="22" color="#029E06" style="margin-right: 4px;" />
							<span style="color:#029E06;">已设置</span>
						</p>
						<p class="securityDescriptor">保障用户登录操作时使用 ，上次登录时间：{{data.record}}</p>
						<p class="tel"></p>
						<button type="button" class="buttonFill" @click="changePassword">修改密码</button>
					</li>
				</ul>


			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,

		},
		data() {
			return {
				data: {}

			}
		},
		mounted() {
			this.getSecureInfo()

		},
		methods: {
			authentication() {
				this.$router.push({
					path: this.$store.state.user.customerInfo.auditStatus === '' || this.$store.state.user.customerInfo
						.auditStatus === 1 ? 'certification' : 'certificationAudit'
				})
			},
			getSecureInfo() {
				this.$post(this.domain.dataRealization + 'secure/getSecureInfo', qs.stringify({}))
					.then((res) => {
						this.data = res.data.content
					});
			},
			changePassword() {
				this.$router.push({
					path: 'changePassword'
				})
			},
			modifyTel() {
				this.$router.push({
					path: 'modifyTel'
				})
			}

		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}

	.qss {
		width: 100%;
		margin-top: 20px;

		li {
			width: 100%;
			height: 90px;
			background: #FFFFFF;
			box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 13px 30px;
			margin-bottom: 10px;

			.name {
				color: #282828;
			}

			span {
				font-size: 14px;
			}

			.securityDescriptor {
				width: 182px;
				font-size: 14px;
				color: #282828;
				line-height: 22px;
				text-align: justify;
			}

			.tel {
				width: 85px;
				font-size: 14px;
				color: #282828;
			}

			button {
				width: 140px;
				height: 40px;
				border-radius: 6px;
				font-size: 14px;
			}
		}
	}
</style>