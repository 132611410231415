<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<div v-if="dataResource.length>0">
								<p class="examineTitle">数据资源</p>
								<div class="resourceList">
									<ul>
										<li v-for="(item,i) in dataResource">
											<div class="detailedDisplay resourceFrame">
												<i></i>
												<div>
													<span>数据资源名称：</span>
													<span>{{item.resourceName}}</span>
												</div>

												<div>
													<p>
														<span>数据资源账面原值：</span>
														<span>{{item.originalValue?method.formateNum(item.originalValue,2)+'元':''}}</span>
													</p>
													<p>
														<span>数据资源账面净值：
															<i
																style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</i>
														</span>
														<span>{{item.netValue?method.formateNum(item.netValue,2)+'元':''}}</span>
													</p>
												</div>
												<div>
													<span>数据资源介绍：</span>
													<span>{{item.resDescribe}}</span>
												</div>
												<i></i>
											</div>
										</li>
										<li class="totalNetWorth">
											<span>数据资源账面净值合计</span>
											<span>{{totalNetWorth?method.formateNum(totalNetWorth,2):''}}</span>
										</li>
									</ul>
								</div>
								<p class="line"></p>
							</div>

							<p class="examineTitle">数据资源贡献程度</p>
							<ul class="contribution">
								<i></i>
								<li>
									<div>序号</div>
									<div style="width: 22%;">无形资产类型</div>
									<div style="width: 19%;">是否有相关资产</div>
									<div class="mark" style="width: 53%;">
										<p>按资产对企业收入贡献影响程度的大小对资产重要性进行打分<i
												style="font-size: 12px;display: inline;font-weight: bold;">(1分为非常重要，10分为非常不重要)</i>
										</p>
										<p style="font-size: 12px;"><!-- 1分为非常重要，10分为非常不重要 --></p>
									</div>
								</li>
								<li v-for="(item,i) in dataContribution">
									<div>{{i+1}}</div>
									<div style="width: 22%;" v-html="item.assetTypeName"></div>
									<div style="width: 19%;">
										<div v-if="item.hasAnyAsset==1" class="are">是</div>
										<div v-else class="are">否</div>
									</div>
									<div class="mark" style="width: 53%;" v-if="item.hasAnyAsset==1">
										<p v-for="f in 10"
											:class="['score','noHover',f==item.contributionDegree?'select':'',f<item.contributionDegree?'background':'']">
											<i>{{f}}分</i>
											<span></span>
											<i v-if="f==1||f==10">{{f}}分</i>
										</p>
									</div>
									<div v-else class="noScoring" style="width: 53%;">此项不需要打分</div>
								</li>
								<i></i>
							</ul>

							<p class="line"></p>

							<p class="examineTitle">无形资产贡献程度</p>
							<ul class="contribution">
								<i></i>
								<li>
									<div>序号</div>
									<div>资产类型</div>
									<div class="mark">
										<p>按资产对企业收入贡献影响程度的大小对资产重要性进行打分</p>
										<p style="font-size: 12px;">(1分为非常重要，10分为非常不重要)</p>
									</div>
								</li>
								<li v-for="(item,i) in iaContribution">
									<div>{{i+1}}</div>
									<div>{{item.assetTypeName}}</div>
									<div class="mark">
										<p v-for="f in 10"
											:class="['score','noHover',f==item.contributionDegree?'select':'',f<item.contributionDegree?'background':'']">
											<i>{{f}}分</i>
											<span></span>
											<i v-if="f==1||f==10">{{f}}分</i>
										</p>
									</div>
								</li>
								<i></i>
							</ul>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [common],
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '15',
				iaContribution: [{ //contributionDegree：得分    hasAnyAsset：是否有相关资产（0：没有，1：有）
					assetTypeName: '货币资金',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '固定资产',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '无形资产',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}], //组合无形资产
				dataContribution: [{ //contributionDegree：得分    hasAnyAsset：是否有相关资产（0：没有，1：有）
					assetTypeName: '数据资源',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '专利权（如发明专利、实用新型专利、外观设计专利）',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '专有技术',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '著作权（如计算机软件著作权、作品著作权等）',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '商标权',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '特许经营权',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}, {
					assetTypeName: '<i>其他（如销售网络、<div class="assetTypeName"><i>客户关系</i><span class="desc-container"><span></span><div class="desc-main"><p>指通过掌握的客户资源和信息，可以与已经存在的客户保持经常性接触、建立业务关系，持续产生预期收益。</p><span><span></div></span></div>、<div class="assetTypeName"><i>合同权益</i><span class="desc-container"><span></span><div class="desc-main"><p>指在企业与供应商签订的保证或优先提供原材料/半成品/外协加工/服务的协议，该协议可以规定固定价格，也可以没有固定价格。</p><span><span></div></span></div>、域名等）</i>',
					hasAnyAsset: 1,
					contributionDegree: 0,
					contributionType: this.contributionType
				}], //数据资源
				dataResource: [{
					valuationReportId: "",
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '' //数据资源介绍
				}],
				totalNetWorth: '',
				createTime: ''

			}
		},
		mounted() {
			this.init()
			this.getData()

		},
		methods: {
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();

						// this.iaContribution = res.data.content.iaContribution
						// this.dataContribution = res.data.content.dataContribution

						if (res.data.content.iaContribution) {
							this.iaContribution.forEach(itemA => {
								const itemB = res.data.content.iaContribution.find(itemB => itemB.assetTypeName === itemA
									.assetTypeName);
								if (itemB) {
									itemA.contributionDegree = itemB.contributionDegree;
									itemA.id = itemB.id
								}
							})
						}

						if (res.data.content.dataContribution) {
							this.dataContribution.forEach(itemA => {

								if (itemA.assetTypeName.indexOf('其他') > -1) {
									var itemB = res.data.content.dataContribution.find(itemB => itemB.assetTypeName.indexOf('其他') > -
										1)
								} else {
									var itemB = res.data.content.dataContribution.find(itemB => itemB.assetTypeName === itemA
										.assetTypeName)
								}

								if (itemB) {
									itemA.contributionDegree = itemB.contributionDegree;
									itemA.hasAnyAsset = itemB.hasAnyAsset
									itemA.id = itemB.id
								}
							})
						}



					})

			},
			getData() {
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then(res => {
						this.createTime = res.data.content.createTime
						if (res.data.content.dataResources) {
							this.dataResource = res.data.content.dataResources
							this.setTotal()
						}
			
					})
			},
			setTotal() {
				var totalNum = 0;
				for (var k = 0; k < this.dataResource.length; k++) {
					console.log(this.dataResource[k].netValue)
					totalNum += Number(this.dataResource[k].netValue)
					this.totalNetWorth = Number(totalNum)
				}
			}
			

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.are {
		border: none !important;
		text-align: center !important;
		margin: 0 auto;
	}

	/deep/ .assetTypeName {
		display: inline-block;
		position: relative;
		top: -1px;

		.desc-container>span {
			width: 10px;
			height: 10px;
			top: -9px;
			left: 2px;
		}

		.desc-main {
			top: 0;
			left: -15px;
		}
	}
</style>