<template>
	<div>
		<div class="softNav">
			<ul>
				<li v-for="(item,index) in list" :key="index" @click="tabs(index)">
					<div :class="[slideIndex === index?'softActive':'']">
						<img v-if="slideIndex === index" :src="item.selectedIcon" />
						<img v-else :src="item.isAccomplish?item.accomplishIcon:item.icon" />
						<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">
							{{item.contentName}}
						</p>
					</div>
					<img v-if="index!=list.length-1" src="../../../assets/img/finance/arrows.png"
						:style="{'margin':width>1500?'0 15px':'0'}" />
				</li>
			</ul>
		</div>


		<Modal v-model="model" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' :src="require('@/assets/img/fillin/unsave.png')">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>

	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		props: {
			currentFinance: {
				type: Number
			},
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},

		},
		data() {
			return {
				width: window.innerWidth,
				slideIndex: this.currentFinance,
				skip: 0,
				model: false,
				list: [{
					contentName: '资产负债表',
					isAccomplish: false,
					icon: require('@/assets/img/finance/assetBlue.png'),
					selectedIcon: require('@/assets/img/finance/assetOrange.png'),
					accomplishIcon: require('@/assets/img/finance/assetGreen.png'),
					url: 'dataLiabilitiesSpecialty'
				}, {
					contentName: '利润表',
					isAccomplish: false,
					icon: require('@/assets/img/finance/profitBlue.png'),
					selectedIcon: require('@/assets/img/finance/profitOrange.png'),
					accomplishIcon: require('@/assets/img/finance/profitGreen.png'),
					url: 'dataProfitSpecialty'
				}, {
					contentName: '会计政策统计表',
					isAccomplish: false,
					icon: require('@/assets/img/finance/accountantBlue.png'),
					selectedIcon: require('@/assets/img/finance/accountantOrange.png'),
					accomplishIcon: require('@/assets/img/finance/accountantGreen.png'),
					url: 'dataAccountingPolicy'
				}],

			}
		},
		computed: {
			checkStatus() {
				return this.$store.state.user.checkStatus;
			}
		},
		watch: {
			checkStatus: {
				handler: function(newValue, oldValue) {
					console.log('预支付---', newValue)
					this.$set(this.list[0], 'isAccomplish', newValue.liabilitiesInfoFlag)
					this.$set(this.list[1], 'isAccomplish', newValue.profitInfoFlag)

				},
				deep: true
			},


		},
		mounted() {
			window.addEventListener('resize', () => {
				this.width = window.innerWidth
			})

			console.log('kkkk---', this.checkStatus)

		},
		methods: {
			...mapMutations(['pageModify']),
			tabs(i) {
				var this_ = this
				this.skip = i;
				if (this.$store.state.user.currentPageModify == 'true' && this.$store.state.user.currentPageSave == false) {
					this.model = true;
				} else {
					this.slideIndex = i
					if (this.$route.query.reportId) {
						this_.$router.push({
							path: this_.list[i].url,
							query: {
								reportId: this_.$route.query.reportId
							}
						})

					} else {
						this_.$router.push({
							path: this_.list[i].url,
						})
					}
				}
			},
			submitDialog() {
				var this_ = this
				this.model = false;
				this.pageModify('false');
				if (this.$route.query.reportId) {
					setTimeout(function() {
						// this_.slideIndex = this.skip
						this_.$router.push({
							path: this_.list[this_.skip].url,
							query: {
								reportId: this_.$route.query.reportId
							}
						})
					}, 500)


				} else {
					setTimeout(function() {
						// this_.slideIndex = this.skip
						this_.$router.push({
							path: this_.list[this_.skip].url,
						})
					}, 500)
				}
			},

		}

	}
</script>

<style scoped lang="less">
	/deep/ .softNav {
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			bottom: -36px;
		}

		>ul {
			margin: 0 auto;
			display: flex;
			justify-content: center;

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				>img {
					width: 15px;
					position: relative;
					top: -9px;
				}

				div {
					position: relative;
					z-index: 2;
					text-align: center;

					img {
						width: 5.5vw;
						max-width: 80px;
					}

					p {
						width: 110px;
						height: 26px;
						text-align: center;
						font-size: 14px;
						font-weight: bold;
						color: #fff;
						// position: absolute;
					}
				}

			}

		}

	}
</style>