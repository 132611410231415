import Vue from 'vue'
import qs from 'qs'
import axios from 'axios'

import {
	mapActions,
	mapMutations
} from 'vuex'

import {
	Modal,
	Input,
	DIV,
	Switch
} from 'iview'

import dialogBar from '@/views/filled/popue.vue'

export default {
	data() {
		return {
			dialogBarInstance: null,
			dialogAlert: false,
			popButonType: 'notEmpty', //弹出框展示对应的状态
			markedWords: '',

		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			if (window.innerWidth < 1200) {
				this.width = 1200
			} else {
				this.width = window.innerWidth
			}
		})

	},
	methods: {
		showDialogBar(isShow, type, hint, auditAlert) {
			// 创建 dialogBar 实例  

			this.dialogAlert = isShow;

			// 初始化事件监听对象  
			const onEvents = {
				cacheTrueMask: this.clickCacheTrueMaskJump,
				cacheMask: this.clickCacheMaskJump,
				closeMaskInfoJump: this.closeMaskInfoJump,
				input: this.updateMyAlert, // 假设 dialogBar 发出一个 input 事件来更新 dialogAlert  
			};

			// 添加 clickConfirmJump 事件  
			if (auditAlert) {
				onEvents['confirmJump'] = this.clickConfirmJump; // 假设这是您要添加的方法  
			}

			console.log(onEvents)

			this.dialogBarInstance = new Vue({
				render: h => h(dialogBar, {
					props: {
						value: isShow,
						type: type,
						title: hint,
					},
					on: onEvents,
				}),
			}).$mount();

			// 将实例挂载到 body 下  
			document.body.appendChild(this.dialogBarInstance.$el);
		},
		hideDialogBar() {
			if (this.dialogBarInstance) {
				// 触发关闭事件或设置 props 来关闭对话框  
				this.dialogAlert = false;

				// 等待对话框确实关闭后再进行清理（可能需要根据实际情况调整）  
				setTimeout(() => {
					// 销毁实例并从 DOM 中移除  
					this.dialogBarInstance.$destroy();
					document.body.removeChild(this.dialogBarInstance.$el);
					this.dialogBarInstance = null;
				}, 500); // 假设对话框关闭动画持续 500 毫秒  
			}
		},
		updateMyAlert(newVal) {
			// 更新 dialogAlert 数据  
			this.dialogAlert = newVal;
		},
		getLastDayOfPreviousMonthInChinese(dateString) { //把传过来的日期变成当前月的前一个月的最后一天，生成的格式为xxxx年xx月xx日
			// 将字符串转换为Date对象    
			let date = new Date(dateString);

			// 获取当前月份并减去1    
			let prevMonth = date.getMonth();

			// 如果月份为0（即一月），则需要将年份也减去1    
			if (prevMonth < 0) {
				prevMonth = 11; // 变为12月    
				date.setFullYear(date.getFullYear() - 1); // 年份减1    
			}

			// 设置日期为0以获取前一个月的最后一天    
			date.setMonth(prevMonth);
			date.setDate(0);

			// 获取年、月、日的值    
			let year = date.getFullYear();
			let month = date.getMonth() + 1; // 月份是从0开始的，所以需要+1    
			let day = date.getDate();

			// 格式化日期为中文格式    
			let monthNames = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
			let formattedDate = `${year}年${monthNames[month - 1]}${day < 10 ? '0' + day : day}日`;

			return formattedDate;
		}

	}

}