<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" />

			<div class="myCenterInfo">
				<div class="integralStatistics">
					<p>我的积分</p>
					<ul>
						<li>
							<img src="../../assets/img/myCenter/integralIcon1.png" />
							<span>
								<i>102222220积分</i>
								<i>总积分</i>
							</span>
						</li>
						<li>
							<img src="../../assets/img/myCenter/integralIcon2.png" />
							<span>
								<i>102222220积分</i>
								<i>消费积分</i>
							</span>
						</li>
						<li>
							<img src="../../assets/img/myCenter/integralIcon3.png" />
							<span>
								<i>102222220积分</i>
								<i>剩余积分</i>
							</span>
						</li>
					</ul>
				</div>

				<ul class="boxhead">
					<li style="width: 20%;">时间</li>
					<li style="width: 20%;">收入/支出</li>
					<li style="width: 60%;">详细说明</li>
				</ul>
				<ul class="dataList">
					<li>
						<p style="width: 20%;">2023-12-21 12:16:01</p>
						<p style="width: 20%;">+100积分</p>
						<p style="width: 60%;">数据估值报告赠送100积分（报告编号：MVESD[20231227]000000023）</p>
					</li>
					<li>
						<p style="width: 20%;">2023-12-21 12:16:01</p>
						<p style="width: 20%;">+100积分</p>
						<p style="width: 60%;">数据估值报告赠送100积分（报告编号：MVESD[20231227]000000023）</p>
					</li>
					
				</ul>


				<Page v-model="currentPageNumber" :total="total" :page-size="pageSize" prev-text="上一页" next-text="下一页"
					@on-change="pageChange" />

			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import qs from 'qs'
	import axios from 'axios'
	import './personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,

		},
		data() {
			return {
				currentPageNumber: 0,
				total: 0,
				pageSize: 5

			}
		},
		mounted() {


		},
		methods: {
			pageChange() {

			}

		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;

		.integralStatistics {
			width: 100%;
			height: 219px;
			background: #FFFFFF;
			box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
			padding: 30px;

			>p {
				font-size: 18px;
				font-weight: bold;
				color: #282828;
				padding-bottom: 10px;
				border-bottom: 1px solid #CACACA;
			}

			ul {
				display: flex;
				justify-content: space-between;
				margin-top: 20px;

				li {
					width: 275px;
					height: 110px;
					border: 1px solid #ABC5FF;
					border-radius: 10px;
					padding: 9px 10px 9px 15px;
					display: flex;
					align-items: center;

					img {
						vertical-align: middle;
						margin-right: 15px;
					}

					span {

						i {
							font-weight: bold;
							color: #282828;
							display: block;
						}

						i:nth-of-type(1) {
							font-size: 18px;
							margin-bottom: 15px;
							font-weight: bold;
						}

						i:nth-of-type(2) {
							font-size: 16px;
						}
					}
				}

				li:nth-of-type(1) {
					background: url(../../assets/img/myCenter/integralBG1.png) center center no-repeat;
					background-size: cover;
				}

				li:nth-of-type(2) {
					background: url(../../assets/img/myCenter/integralBG2.png) center center no-repeat;
					background-size: cover;
				}

				li:nth-of-type(3) {
					background: url(../../assets/img/myCenter/integralBG3.png) center center no-repeat;
					background-size: cover;
				}
			}
		}
	}
</style>