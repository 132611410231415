<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='3' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>贡献程度（选填）</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">
							<div class="inputTabDesc">
								<p class="table-desc-tit">
									<img :src="require('@/assets/img/finance/hint.png')" alt="填表说明" />
									<span>提示</span>
								</p>
								<ul>
									<li>
										<span>
											<i>1、</i>
											<span>
												<i style="width: 100%;">贡献程度表对最终估值结果有重要影响，建议尽量填写。</i>
											</span>
										</span>
									</li>
									<li>
										<span><i>2、</i><i>无形资产不包括土地使用权、矿业权、水域使用权等。</i></span>
									</li>
								</ul>
							</div>
							<ContributionDegreenNav :stage="stage" :type="type" :versions="versions" :currentFinance='1' />
							<PortfolioIntangibleAssets ref="element" :stage="stage" :type="type" :versions="versions" :url="url"
								jumpAddress="dataValuationPaymentFast" />


							<div class="saveBox">
								<router-link v-if="this.$route.query.reportId" tag="span" class="last"
									:to="{ path: '/dataValuationIAFast', query: { reportId:this.$route.query.reportId } }">上一步</router-link>
								<router-link v-else tag="span" class="last" to="/dataValuationIAFast">上一步</router-link>
								<span data-isClick="false" @click="save">下一步</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import ContributionDegreenNav from '../nav/contributionDegreenNav.vue'
	import PortfolioIntangibleAssets from '@/components/dataValuation/contributionDegree/portfolioIntangibleAssets.vue'


	export default {
		components: {
			Header,
			fillinNav,
			ContributionDegreenNav,
			PortfolioIntangibleAssets

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '15',
				versions: '2',



			}
		},
		mounted() {

		},
		methods: {
			save() {
				this.$refs.element.save()
			},



		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
</style>