<template>
	<div>
		<Header />
		<Carousel loop :height="450" arrow="never" dots="none">
			<CarouselItem>
				<div class="banner">
					<div>
						<p class="englishName">Business Model Evaluation</p>
						<p class="productName">数字化企业商业模式测评</p>
						<p class="banner_explain" style="margin-bottom: 12px;">
							全数字化业务转型是运用全数字化技术构建新的商业模式，实现组织变革，进而提高绩效。
						</p>
						<p class="banner_explain" style="margin-bottom: 12px;">
							DBI中心研究显示，全数字化企业提供的客户价值主要有三种类型：成本价值、体验价值和平台价值。
						</p>
						<p class="banner_explain">
							<i style="font-weight: bold;vertical-align:top;">组合式颠覆</i>
							是指通过有效融合成本价值、体验价值和平台价值打造出新密的商业模式，从而实现业绩增长。
						</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<p class="partitionRule">按价值形式划分的全数字化商业模式</p>
			<div id="xqBox">
				<div class="topics">
					<div class="row" v-for="(item,index) in softIndexProblem">
						<p class="quotientProblem">
							<span>{{index+1}}</span>
							<span>
								{{item.content.substring(0,item.content.length-5)}}
								<i style="color: #FF8000;font-weight: bold;vertical-align: baseline;">(可多选)</i>
							</span>
						</p>
						<div>
							<ul>
								<li v-for="(answer, a) in item.options" :class="[answer.chooseFlag=='1'?'active':'']"
									:ref="answer.chooseFlag=='1'?'selected':'Selecte'" :optionId="answer.id"
									:questionId="answer.questionId"
									:style="{'background':answer.chooseFlag=='1'?hexToRgb($store.state.user.subjectColor.mainColor, '0.1'):'#fff'}"
									@mouseover="changeBackgroundColor($event)" @mouseout="resetBackgroundColor($event)"
									@click="selectTopic(index, a)">
									<i class="iconfont icon-duigou"></i>
									<label>{{answer.content}}</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>


			<button class="buttonFill save" @click="save">立即提交</button>

		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	import {
		Modal,
		Input,
		DIV,
		Switch
	} from 'iview'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				isSave: false,
				softIndexProblem: [],
				selectedQuestion: []

			}
		},
		mounted() {
			this.getDigitalBusiness()
			// http://localhost:8081/?#/businessEvaluationResults?id=38212c1f7308423b8ac799d86ae075e7

		},
		methods: {
			changeBackgroundColor(e) {
				e.currentTarget.style.background = this.hexToRgb(this.$store.state.user.subjectColor.mainColor, '0.1');
			},
			resetBackgroundColor(e) {
				if (e.currentTarget.classList.contains('active')) {
					e.currentTarget.style.background = this.hexToRgb(this.$store.state.user.subjectColor.mainColor, '0.1');
				} else {
					e.currentTarget.style.background = 'white'
				}
			},
			getDigitalBusiness() {
				this.$post(this.domain.dataRealization + 'digitalBusiness/getDigitalBusiness', qs.stringify({
						businessId: ''
					}))
					.then((res) => {
						this.softIndexProblem = res.data.content
					});
			},
			selectTopic(i, j) {
				this.isSave = false

				if (this.softIndexProblem[i].options[j].chooseFlag == 1) {
					this.$set(this.softIndexProblem[i].options[j], 'chooseFlag', '0')
				} else {
					this.$set(this.softIndexProblem[i].options[j], 'chooseFlag', '1')
				}

				$('.quotientProblem').removeClass('no-choose-red')

				this.$forceUpdate()

			},
			save() {
				var selectedQuestion = this.$refs.selected ? this.$refs.selected : [];
				let hasError = false;

				$(".row").each(function() {
					if ($(this).find(".active").length <= 0) {
						hasError = true;
						return false; // 立即退出循环
					}
				});

				if (hasError) {
					Modal.warning({
						title: '提示',
						content: '所有题皆为必填项',
						onOk: () => {
							$(".row").each(function() {
								if ($(this).find(".active").length <= 0) {
									window.scrollTo({
										top: $(this).find("ul")[0].offsetTop - 140,
										behavior: 'smooth'
									});
									$(this).find('.quotientProblem').addClass('no-choose-red');
									return false
								}
							});
						}
					});
				} else {
					for (var i = 0; i < selectedQuestion.length; i++) {
						this.selectedQuestion.push({
							optionId: selectedQuestion[i].getAttribute('optionId'),
							questionId: selectedQuestion[i].getAttribute('questionId')
						})
					}
					this.saveTopic()
				}

			},
			saveTopic() {
				this.$post(this.domain.dataRealization + 'digitalBusiness/saveDigitalBusiness', qs.stringify({
					softJArray: JSON.stringify(this.selectedQuestion),
					businessId: ''
				})).then(res => {
					this.$router.push({
						name: 'businessEvaluationResults',
						query: {
							id: res.data.content
						}
					})
				})

			},


		}


	}
</script>

<style scoped lang="less">
	.banner {
		background: url(../../assets/img/numberQuotient/banner.png) center center no-repeat;

		.banner_explain {
			width: 675px;
		}
	}

	.no-choose-red {
		span:last-child {
			color: red !important;
		}
	}

	.partitionRule {
		font-size: 28px;
		color: #282828;
		font-weight: bold;
		text-align: center;
		margin: 74px auto 40px;
	}

	.introductoryQuestion {
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
		padding: 28px 40px;
		margin: 74px auto 40px;

		p:first-child {
			font-size: 16px;
			color: #282828;
			margin-bottom: 20px;
		}

		p:not(:first-child) {
			font-size: 14px;
			color: #282828;
			line-height: 24px;
		}
	}

	.topics {
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
		padding-top: 10px;
		padding-bottom: 30px;
		margin-bottom: 40px;

		>P {
			font-size: 18px;
			color: #282828;
			line-height: 24px;
			padding: 25px 40px 10px;
			border-bottom: 1px solid #CACACA;
		}

		>div {
			.quotientProblem {
				margin-top: 34px;
				padding: 0 36px;
				display: flex;

				span:first-child {
					width: 34px;
					height: 34px;
					line-height: 34px;
					background: var(--mainColor);
					border-radius: 50%;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #fff;
					display: inline-block;
					margin-right: 23px;
				}

				span:last-child {
					width: calc(100% - 57px);
					font-size: 16px;
					font-weight: bold;
					color: #282828;
					line-height: 24px;
					position: relative;
					top: 3px;
				}
			}

			.quotientProblem+div {

				ul {
					padding: 0 36px 0 74px;

					li {
						height: 32px;
						line-height: 32px;
						padding: 0 50px;
						margin-top: 15px;
						cursor: pointer;
						position: relative;
						border-radius: 16px;

						i {
							display: none;
							color: var(--mainColor);
							font-size: 12px;
							font-weight: bold;
							position: absolute;
							top: 1px;
							left: 52px;
						}

						label {
							font-size: 14px;
							color: #282828;
							display: flex;
							align-items: center;
							cursor: pointer;
						}

						label::before {
							content: '';
							width: 16px;
							height: 16px;
							border-radius: 4px;
							border: 2px solid var(--mainColor);
							display: inline-block;
							margin-right: 14px;
						}

					}

					li.active {
						border-radius: 16px;

						i {
							display: block;
						}

						label {
							color: var(--mainColor);
						}


					}
				}

			}

		}
	}
</style>