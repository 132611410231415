<template>
	<div class="sidebar">
		<img class="arc" :src="require('@/assets/img/dataEvaluation/filled/arc.png')" />
		<ul>
			<li v-for="(item,index) in list" :key="index" :class="['navItem', slideIndex === index?'active':'']"
				@click="switchNav(index,item.url)">
				<div>
					<p style="position: relative;">
						<img class="circle" :src="require('@/assets/img/dataEvaluation/filled/circle.png')" />
						<img class="icon" :src="slideIndex === index?item.selectIcon:item.icon" />
					</p>
					<p>{{item.name}}</p>
				</div>
				<img v-if="slideIndex === index" class="arrows"
					:src="require('@/assets/img/dataEvaluation/filled/arrows.png')" />
			</li>
		</ul>

		<Modal v-model="myAlert" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' :src="require('@/assets/img/fillin/unsave.png')">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>

	</div>
</template>

<script>
	import common from '../common'
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		mixins: [common],
		data() {
			return {
				width: window.innerWidth,
				slideIndex: 0,
				myAlert: false,
				skip: 0,
				list: [{
					name: '基本信息',
					icon: require('@/assets/img/fillin/unselectedLTicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedLT.png'),
					url: 'basicInformationEvaluation'
				}, {
					name: '软性指标',
					icon: require('@/assets/img/fillin/unselectedRTicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedRT.png'),
					url: 'evaluationSoftIndicators'
				}, {
					name: '测评报告',
					icon: require('@/assets/img/fillin/unselectedRBicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedRB.png'),
					url: 'evaluationValuationReport'
				}],


			}
		},
		mounted() {

			if (this.$route.path == '/basicInformationEvaluation') {
				this.slideIndex = 0;
			} else if (this.$route.path == '/evaluationSoftIndicators') {
				this.slideIndex = 1;
			} else if (this.$route.path == '/evaluationValuationReport') {
				this.slideIndex = 2;
			}

		},
		methods: {
			...mapMutations(['pageModify', 'setCheckStatus']),
			switchNav(i, url) {
				console.log(i)
				if (this.$store.state.user.currentPageModify == 'true' && this.$store.state.user.currentPageSave == false && i !=
					this.slideIndex) {
					this.myAlert = true;
					this.skip = i;
				} else {
					this.slideIndex = i;
					if (this.$route.query.reportId) {
						this.$router.push({
							path: this.list[i].url,
							query: {
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							path: this.list[i].url,
							query: {
								type: this.$route.query.type,
							}
						})
					}
				}

			},
			submitDialog() {
				this.myAlert = false;
				if (this.$route.query.reportId) {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							type: this.$route.query.type,
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							type: this.$route.query.type
						}
					})
				}
			},


		},



	}
</script>

<style scoped lang="less">
	.sidebar {
		width: calc(18vh + 100px);
		height: 100%;
		position: relative;

		.arc {
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
		}

		ul {
			width: 70%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;

			li {
				width: 100%;
				height: 26%;
				margin-top: 10%;
				align-self: stretch;
				position: relative;
				cursor: pointer;

				.arrows {
					width: 30px;
					position: absolute;
					right: -25px;
					top: 50%;
					margin-top: -30px;
				}

				>div {
					text-align: center;

					>p {
						background: #fff;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-weight: bold;
						font-size: 3vh;
					}
				}

				.icon {
					width: 5vh;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				.circle {
					height: 18vh;
					object-fit: contain;
				}

			}

			li:first-child {
				margin-top: 0;
			}

			li.active {

				p {
					background: linear-gradient(180deg, #09F6FE 27%, #0071F8 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}

		}

	}
</style>