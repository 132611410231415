<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" />

			<div class="userinfo">
				<div class="change">
					<p class="title">修改密码</p>
					<div class="account">
						<span>账户</span>
						<span>{{$store.state.user.userName.slice(0,3)+'****'+$store.state.user.userName.slice(7)}}</span>
					</div>
					<Form ref="loginForm" :model="data" :rules="rules" class="formInformation">
						<FormItem prop="originalPassword">
							<div class="item">
								<label for="originalPassword"><span class="reRed">*</span>当前密码</label>
								<div class="baseRight">
									<Input type="password" id="originalPassword" v-model="data.originalPassword" placeholder="请输入" />
								</div>
							</div>
						</FormItem>
						<FormItem prop="password">
							<div class="item">
								<label for="password"><span class="reRed">*</span>新登录密码</label>
								<div class="baseRight">
									<p class="suggest">
										<i>!</i>
										<i>密码长度6-16位，支持数字、字母、字符</i>
									</p>
									<Input type="password" id="password" v-model="data.password" placeholder="请输入" />
								</div>
							</div>
						</FormItem>
						<FormItem prop="password2">
							<div class="item">
								<label for="password2"><span class="reRed">*</span>确认新密码</label>
								<div class="baseRight">
									<Input type="password" id="password2" v-model="data.password2" placeholder="请输入" />
								</div>
							</div>
						</FormItem>
						<FormItem prop="imgCode">
							<div class="item">
								<label for="imgCode"><span class="reRed">*</span>验证码</label>
								<div class="baseRight imageVerification">
									<Input id="imgCode" v-model="data.imgCode" placeholder="请输入" />
									<img class="picture" alt="图形验证码" :src="uuPicCode">
									<span class="pictureCodeBtn" @click="getPictureCode()">换一张</span>
								</div>
							</div>
						</FormItem>
						<FormItem class="operationBtn">
							<button type="button" class="buttonFill" @click="save">确定</button>
							<button type="button" class="buttonBorderTransparency" @click="cancel">取消</button>
						</FormItem>
					</Form>

				</div>
			</div>
		</div>


		<SignUp />
		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import qs from 'qs'
	import axios from 'axios'
	import '../personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
		},
		props: {
			originalPasswordRules: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '当前密码不能为空',
						trigger: 'blur'
					}]
				}
			},
			passwordRules: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '请输入新登录密码',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^[A-Za-z0-9@#$%]{6,16}$/,
						message: '新密码格式不正确',
						trigger: 'blur'
					}]
				}
			},
			confirmPassword: {
				type: Array,
				default: function() {
					return [{
							required: true,
							message: '请再次输入密码',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => this.validateConfirmPassword(rule, value, callback), // 使用箭头函数
							trigger: 'blur'
						}
					]
				}
			},
			imgCode: {
				type: Array,
				default: () => {
					return [{
						required: true,
						message: '验证码不能为空',
						trigger: 'blur'
					}]
				}
			}
		},
		computed: {
			rules() {
				return {
					originalPassword: this.originalPasswordRules,
					password: this.passwordRules,
					password2: this.confirmPassword,
					imgCode: this.imgCode
				}
			}
		},
		data() {
			return {
				data: {
					originalPassword: '', //原密码
					password: '', //新密码
					password2: '',
					imgCode: '', //图片验证码
					uuid: '' //图片验证码uuid
				},
				uuPicCode: '',


			}
		},
		created() {

		},
		mounted() {
			this.getPictureCode()


		},
		methods: {
			validateConfirmPassword(rule, value, callback) {
				if (value !== this.data.password) {
					callback(new Error('两次密码输入不一致'));
				} else {
					callback();
				}
			},
			getPictureCode: function() {
				this.$get(this.domain.dataRealization + "dataCustomer/getVerifiCode", "").then((res) => {
					console.log(res)
					if (res.code == 200 && res.content) {
						this.data.uuid = res.content.uuid;
						this.uuPicCode = res.content.image;
					}
				})
			},
			save() {
				this.$refs.loginForm.validate((valid) => {
					console.log(valid)
					if (valid) {
						console.log('可以保存')
						console.log(this.data)
						this.$post(this.domain.dataRealization + 'secure/modifyPassword', qs.stringify(this.data))
							.then((res) => {
								if (res.data.code == 200) {
									this.$Message.success('修改成功')
									this.$router.push({
										path: 'securitySettings'
									})
								}else{
									this.$Message.error(res.data.message)
								}
							});
					}
				})

			},
			cancel() {
				this.$router.push({
					path: 'securitySettings'
				})
			}

		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}

	.suggest {
		line-height: 12px;
		margin-bottom: 5px;

		i:first-child {
			// padding: 1px 5px;
			// font-size: 12px;
			width: 14px;
			height: 14px;
			line-height: 14px;
			display: inline-block;
			border: 1px solid #999;
			border-radius: 50%;
			text-align: center;
			color: #999;
			vertical-align: middle;
			margin-right: 4px;
		}

		i:last-child {
			color: #999;
			vertical-align: middle;
		}
	}
</style>