<template>
	<div class="softNav">
		<ul>
			<li v-for="(item,index) in list" :key="index" @click="softIndicator(index)"
				:class="[slideIndex === index?'softActive':'']">
				<img v-if="slideIndex === index" :src="require('@/assets/img/dataEvaluation/filled/softIndexOrange.png')" />
				<img v-else
					:src="item.isAccomplish?require('@/assets/img/dataEvaluation/filled/softIndexGreen.png'):require('@/assets/img/dataEvaluation/filled/softIndexBlue.png')" />
				<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">
					{{item.contentName}}
				</p>
			</li>
		</ul>



		<Modal v-model="myAlert" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' :src="require('@/assets/img/fillin/unsave.png')">
			<p>当前数据尚未保存，是否确定离开？</p>
			<!-- <div slot="footer" class="ivu-sure" @click="submitDialog">确定</div> -->
		</Modal>
	</div>
</template>

<script>
	import dialogBar from '@/views/filled/popue.vue'
	import qs from 'qs'
	import axios from 'axios'
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		components: {
			dialogBar
		},
		props: ['getSoftIndex'],
		data() {
			return {
				slideIndex: 0,
				skip: 0,
				myAlert: false,
				markedWords: '当前数据尚未保存，是否确定离开？',
				list: [{
					contentName: '基础指标',
					isAccomplish: false,
				}, {
					contentName: '风险指标',
					isAccomplish: false,
				}, {
					contentName: '质量指标',
					isAccomplish: false,
				}, {
					contentName: '流通指标',
					isAccomplish: false,
				}, {
					contentName: '经济指标',
					isAccomplish: false,
				}]
			}
		},
		mounted() {
			this.getQuestion()

		},
		methods: {
			updateChildData(updatedData) {
				var this_ = this
				// console.log('子组件接收到修改后的数据：', updatedData);

				this.list.map((el, i) => {
					if (updatedData.index == el.contentType) {
						this.$set(this.list[i], 'contentDetails', updatedData.softIndexProblem)
						if (updatedData.nextModule) {
							this.slideIndex = i + 1
							this.skip = i + 1
							this.$emit('softIndexSelected', JSON.stringify(this.list[this.skip])) // 触发自定义事件并传递数据
						} else {
							this.slideIndex = i
							this.skip = i
						}
					}
				})

				setTimeout(function() {
					this_.getQuestionFlag(1)
				}, 100)


			},
			softIndicator(i) {
				this.skip = i;
				if (this.$store.state.user.currentPageModify == 'true' && this.$store.state.user.currentPageSave == false) {
					this.myAlert = true;
				} else {
					this.slideIndex = i
					this.$emit('softIndexSelected', JSON.stringify(this.list[i])) // 触发自定义事件并传递数据
					$('.substance').scrollTop(0)
				}

			},
			submitDialog() {
				this.myAlert = false;
				this.slideIndex = this.skip
				this.$emit('softIndexSelected', JSON.stringify(this.list[this.skip])) // 触发自定义事件并传递数据
			},
			getQuestion(isSend) {
				this.$Spin.show(); //全局加载中
				var url = this.domain.evaluation + 'question/getQuestion';
				this.$post(url, qs.stringify({
						stage: sessionStorage.stage,
						reportType: 14,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: sessionStorage.versions
					}))
					.then((res) => {
						this.$Spin.hide();

						res.data.content.map((el, i) => {
							this.list[i]['contentDetails'] = el.contentDetails
							this.list[i]['contentType'] = el.contentType

							el.contentDetails.map((els, j) => {
								els['id'] = els.options[0].questionId
							})

						})

						this.getQuestionFlag(isSend)
					})
			},
			getQuestionFlag(isSend) {
				var url = this.domain.evaluation + 'question/getQuestionFlag';
				this.$post(url, qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.list[0].isAccomplish = res.data.content.questionBasicFlag
						this.list[1].isAccomplish = res.data.content.questionRiskDataFlag
						this.list[2].isAccomplish = res.data.content.questionQualityFlag
						this.list[3].isAccomplish = res.data.content.questionCirculateFlag
						this.list[4].isAccomplish = res.data.content.questionEconomyFlag

						if (isSend != 1) this.$emit('softIndexSelected', JSON.stringify(this.list[0])) // 触发自定义事件并传递数据

					})
			}

		}
	}
</script>

<style scoped lang="less">
	.softNav {
		width: 55%;
		min-width: 521px;
		height: 100%;

		>ul {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			li {
				width: 80px;
				height: 27px;
				position: relative;
				cursor: pointer;

				img {
					height: 100%;
				}

				p {
					width: 100%;
					height: 100%;
					line-height: 29px;
					font-size: 14px;
					font-weight: bold;
					text-align: center;
					padding-left: 8px;
					position: absolute;
					top: 0;

				}
			}

		}

	}
</style>