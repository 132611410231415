<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" />

			<div class="userinfo">
				<p class="subhead">实名认证</p>
				<ul class="formInformation">
					<li>
						<label for="name"><span class="reRed">*</span>企业名称</label>
						<div class="baseRight">
							<Input id="name" v-model="authInfo.name" placeholder="请输入企业名称" :disabled="readonly" />
						</div>
					</li>
					<li>
						<label><span class="reRed">*</span>营业执照</label>
						<div class="baseRight" style="display: flex;align-items: center;">
							<div class="upBox" @click="showUp">
								<img v-if="authInfo.businessLicense" :src="authInfo.businessLicense" alt="营业执照" />
								<Icon v-else type="ios-add" size="60" color="#BFBFBF" />
							</div>
							<span class="infoAlert">
								<img src="../../assets/img/myCenter/alert.png" style="vertical-align: top;" />
								<i>正副本均可，需加盖公章；</br>
									格式为JPG、JPEG、PNG，大小不超过2MB</i>
							</span>
						</div>
					</li>
					<li>
						<label for="creditCode"><span class="reRed">*</span>统一信用代码</label>
						<div class="baseRight">
							<Input id="creditCode" v-model="authInfo.creditCode" placeholder="请输入统一信用代码" :disabled="readonly" />
						</div>
					</li>
					<li>
						<label for="regCapital" class="notFill">注册资本</label>
						<div class="baseRight">
							<Input type="number" id="regCapital" ref="regCapital" v-model="authInfo.regCapital" placeholder="请输入注册资本"
								@on-blur="displayThousandths($event,authInfo.regCapital,'regCapital')" :disabled="readonly">
							<template #append>
								<span>万元</span>
							</template>
							</Input>
							<div class="showInput" @click="inputFocus($event)" :style="{'background':readonly?'#f3f3f3':'#fff'}">
								{{method.formateNum(authInfo.regCapital,2)}}
							</div>
						</div>
					</li>
					<li>
						<label for="legal"><span class="reRed">*</span>法定代表人</label>
						<div class="baseRight">
							<Input id="legal" v-model="authInfo.legal" placeholder="请输入法定代表人" :disabled="readonly" />
						</div>
					</li>
					<li>
						<label for="legalIdNumber"><span class="reRed">*</span>法人身份证号</label>
						<div class="baseRight">
							<Input id="legalIdNumber" v-model="authInfo.legalIdNumber" placeholder="请输入法人身份证号" :disabled="readonly" />
						</div>
					</li>
				</ul>
				<p class="contactInfo">联系人信息</p>
				<ul class="formInformation">
					<li>
						<label for="contacts"><span class="reRed">*</span>联系人姓名</label>
						<div class="baseRight">
							<Input id="contacts" v-model="authInfo.contacts" placeholder="请输入联系人姓名" :disabled="readonly" />
						</div>
					</li>
					<li>
						<label for="contactUs"><span class="reRed">*</span>联系人手机号</label>
						<div class="baseRight">
							<Input type="tel" id="contactUs" v-model="authInfo.contactUs" placeholder="请输入联系人手机号" :maxlength="11"
								@on-blur="telVerify" :disabled="readonly" />
						</div>
					</li>
					<li v-if="!readonly">
						<label for="code"><span class="reRed">*</span>验证码</label>
						<div class="baseRight" style="display: flex;justify-content: space-between;">
							<Input id="code" v-model="code" placeholder="请输入验证码" style="width: 410px;" />
							<Button type="primary" class="getCode buttonFill" :disabled="!!cooling"
								@click="getCaptcha(authInfo.contactUs)">
								{{ cooling ? "重新发送(" + cooling + ")" : "获取验证码" }}
							</Button>
						</div>
					</li>
					<li>
						<label for="contactTitle"><span class="reRed">*</span>联系人职务</label>
						<div class="baseRight">
							<Input id="contactTitle" v-model="authInfo.contactTitle" placeholder="请输入联系人职务" :disabled="readonly" />
						</div>
					</li>
					<li>
						<label for="contactIdNumber"><span class="reRed">*</span>身份证号</label>
						<div class="baseRight">
							<Input id="contactIdNumber" v-model="authInfo.contactIdNumber" placeholder="请输入身份证号"
								:disabled="readonly" />
						</div>
					</li>
				</ul>

				<button class="save buttonFill" v-if="authInfo.auditStatus!=2" v-preventReClick
					@click="save">{{btnText}}</button>

			</div>
		</div>

		<!-- @on-ok="ok" @on-cancel="cancel" -->
		<Modal v-model="modal" title="上传图片" width="640">

			<Upload class="upload" type="drag" :action="action" name="upfile" :data="imgData" :headers="headers"
				:show-upload-list="false" accept="image/png,image/jpg,image/jpeg,image/gif" :max-size="2048"
				:on-exceeded-size="handleMaxSize" :on-success="setBusinessLicense">
				<div class="dragDropUpload">
					<div>
						<img src="../../assets/img/myCenter/upIcon.png" />
						<p class="hint">拖拽图片或点击上传</p>
					</div>
				</div>
			</Upload>
			<p style="font-size: 18px;color: #282828;font-weight: bold;margin: 20px 0 15px;text-align: center;">图片示例</p>
			<div style="display: flex;justify-content: space-between;align-items: center;">
				<img src="../../assets/img/myCenter/businessLicense1.png" />
				<img src="../../assets/img/myCenter/businessLicense2.png" />
				<img src="../../assets/img/myCenter/tetragonalIntegrity.png"
					style="width: 44px;height: 34px; object-fit: contain;" />
				<img src="../../assets/img/myCenter/uniformIllumination.png"
					style="width: 44px;height: 34px; object-fit: contain;" />
				<img src="../../assets/img/myCenter/clearPhoto.png" style="width: 44px;height: 34px; object-fit: contain;" />
			</div>
			<p style="margin-top: 14px;">
				<span class="reRed">*</span>
				<i style="font-size: 14px;color: #282828;">提示：请上传小于2M的图片</i>
			</p>

			<div slot="footer"></div>
		</Modal>

		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		Modal,
		Input,
		DIV,
		Switch
	} from 'iview'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,

		},
		data() {
			return {
				action: this.domain.testUrl + this.domain.dataRealization + 'dataAssetUpload/uploadFile',
				imgData: {
					type: 4
				},
				headers: {
					Authorization: localStorage.userToken,
					SecurityCode: window.channel,
					ChannelId: window.channelId
				},
				modal: false,
				cooling: '',
				readonly: false, //是否能修改
				authInfo: {
					customerId: '',
					name: '',
					businessLicense: '',
					creditCode: '',
					regCapital: 0, //注册资本
					legal: '',
					legalIdNumber: '',
					contacts: '',
					contactUs: '',
					contactTitle: '',
					contactIdNumber: '',
				},
				code: '', //验证码
				isSave: false,
				btnText: ''

			}
		},
		mounted() {
			this.getAuthInfo()

		},
		methods: {
			showUp() {
				if (!this.readonly) {
					this.modal = true
				}
			},
			setBusinessLicense(res) {
				this.authInfo.businessLicense = this.domain.testUrl + this.domain.dataRealization + res.content.url
				this.modal = false
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'table';
				this.$refs.regCapital.focus()
			},
			displayThousandths: function(e, val, key) { //是否是数据资源列  1：不是   0:是
				this.isSave = false
				e.currentTarget.style.borderColor = "#BFBFBF";
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val <= 2147483647 && val >= -2147483648) {
					console.log('符合')
				} else {
					this.authInfo[key] = ''
					this.alert('请输入合理数值', true, key)
					return false;
				}

				$(e.currentTarget).parents('.baseRight').find('.showInput').css('display', 'block')


			},
			telVerify() {
				var phoneReg = /^1[3|4|5|6|7|8|9]\d{9}$/; //电话
				if (!phoneReg.test(this.authInfo.contactUs)) {
					this.alert('请输入正确的手机号', true, 'contactUs')
					return false
				}
			},
			save() {
				var creditCodeReg = /^[0-9A-Z]{18}$/; //统一社会信用代码
				var regex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|X)$/; //身份证号

				if (this.btnText == '修改') {
					this.readonly = false
					this.btnText = '提交认证'
				} else {
					if (!this.authInfo.name) {
						this.alert('请输入企业名称')
						return false
					}
					if (!this.authInfo.businessLicense) {
						this.alert('请上传营业执照')
						return false
					}
					if (!this.authInfo.creditCode) {
						this.alert('请输入统一信用代码')
						return false
					} else if (!creditCodeReg.test(this.authInfo.creditCode) && this.authInfo.creditCode.length != 18) {
						this.alert('信用代码输入有误')
						return false
					}
					if (!this.authInfo.legal) {
						this.alert('请输入法定代表人')
						return false
					}

					if (!this.authInfo.legalIdNumber) {
						this.alert('请输入法人身份证号')
						return false
					} else if (this.authInfo.legalIdNumber.length != 18 || !regex.test(this.authInfo.legalIdNumber)) {
						this.alert('法人身份证号有误')
						return false
					}

					if (!this.authInfo.contacts) {
						this.alert('请输入联系人姓名')
						return false
					}
					if (!this.authInfo.contactUs) {
						this.alert('请输入联系人手机号')
						return false
					}
					if (!this.authInfo.contactTitle) {
						this.alert('请输入联系人职务')
						return false
					}
					if (!this.authInfo.contactIdNumber) {
						this.alert('请输入联系人身份证号')
						return false
					} else if (this.authInfo.contactIdNumber.length != 18 || !regex.test(this.authInfo.contactIdNumber)) {
						this.alert('联系人身份证号有误')
						return false
					}

					if (!this.code) {
						this.alert('请输入验证码')
						return false
					}

					console.log('点击了一次')

					this.$post(this.domain.dataRealization + 'authentication/saveAuthInfo', qs.stringify({
							authInfo: JSON.stringify(this.authInfo),
							identifyingCode: this.code
						}))
						.then((res) => {
							if (res.data.code == 200) {
								this.$router.push({
									path: 'certificationAudit'
								})
							} else {
								this.alert(res.data.message)
							}
						});
				}

			},
			getAuthInfo() {
				this.$post(this.domain.dataRealization + 'authentication/getAuthInfo', qs.stringify({}))
					.then((res) => {
						this.authInfo = res.data.content
						this.readonly = res.data.content.auditStatus == 2 || res.data.content.auditStatus == 1 ? true : false
						this.btnText = res.data.content.auditStatus == 1 ? '修改' : '提交认证'
					});
			}


		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;

		>div:last-child {
			width: 926px;

		}
	}

	.formInformation {
		width: 89%;
	}

	.contactInfo {
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #F2F2F2;
		font-size: 16px;
		font-weight: bold;
		color: #282828;
		padding-left: 30px;
	}

	/deep/ .ivu-modal-footer {
		border: none;
	}

	/deep/ .ivu-icon-ios-close:before {
		content: "\f178" !important;
	}
</style>