<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='1' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>软性指标</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">

							<SoftIndex ref="soft" :stage="stage" :type="type" :versions="versions" :url="url" jumpAddress="dataLiabilitiesSpecialty"
								backAddress="dataInfoSpecialty" />

						<!-- 	<div class="position-tit-container">
									<span>1</span>
									<div v-html="str"></div>
								</div> -->
								
								
								
						</div>
					</div>
				</div>
			</div>
		</div>


		<Modal v-model="alert" class-name="vertical-center-modal videoAlert" :mask-closable="false"
			@on-visible-change="codeAlert">
			<div style="height: 315px;">
				<video :src="video" crossorigin="anonymous"
					class="example-video video-js vjs-big-play-centered vjs-fluid" controls preload="auto"
					style="height: 100%;"></video>
					
			</div>
			<p slot="footer"></p>
		</Modal>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import SoftIndex from '@/components/dataValuation/softIndex/index.vue'
	import qs from 'qs'
	import axios from 'axios';


	export default {
		components: {
			Header,
			fillinNav,
			SoftIndex

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '13',
				versions: '2',
				alert: false,
				video:'',
				str: '元素填充率<div class="terminology explanatoryVideo"><i class="playFAQVideo"></i><div class="nameInterpretation"><span><i class="viewVideo" data-url="../../../assets/flow.mp4">查看视频</i></span></div></div>：（<div class="terminology"><i>元素</i><div class="nameInterpretation"><span>是组成数据源中记录或者数据项的最小单元</span></div></div>填充率=<div class="terminology"><i>数据集</i><div class="nameInterpretation"><span>是顶顶顶顶顶顶顶顶顶<i class="viewVideo" data-url="https://test.flcccc.com/data/flow.mp4">查看视频</i></span></div></div>赋值的元素数量/元素总数量）'

			}
		},
		mounted() {
			var this_=this

			$('.viewVideo').click(function() {
				this_.alert=true
				this_.video=$(this).attr('data-url')
			})

		},
		computed: {


		},
		methods: {
			codeAlert() {

			}

		}



	}
</script>


<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
</style>