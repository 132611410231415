<template>
	<div>
		<ul class="valuationTopNav specialty" v-if="type==13">
			<li v-for="(item,index) in list" :class="[slideIndex === index?'active':'']">
				<p class="children unfold" @click="toggleSubMenu(item)" v-if="item.children">{{item.name}}</p>
				<p @click="switchNav(index,item.url)" v-else>{{item.name}}</p>


				<transition name="slide-down">
					<ul class="second" v-if="item.children&&item.subMenuVisible">
						<li v-for="(child,c) in item.children" :class="[childrenCategory == child.name ?'financeActive':'']"
							@click="selectiveFinancial(child.name,child.url,index)">{{child.name}}</li>
					</ul>
				</transition>




			</li>
		</ul>
		<ul class="valuationTopNav" v-else>
			<li v-for="(item,index) in list" :class="[slideIndex === index?'active':'']">
				<p @click="switchNav(index,item.url)">{{item.name}}</p>
			</li>
		</ul>
	</div>
</template>

<script>
	import '@/assets/css/animate.css'

	export default {
		props: {
			type: {
				type: [String, Number],
				required: false
			},
			category: {
				type: [String, Number],
				required: false
			}
		},
		data() {
			return {
				height: window.innerHeight,
				slideIndex: 0,
				childrenCategory: this.category,
				fast: [{
					name: '基本信息',
					url: '/dataValuationEInfoFast'
				}, {
					name: '软性指标',
					url: '/dataValuationESoftFast'
				}, {
					name: '财务指标',
					url: '/dataValuationEFinancialFast'
				}, {
					name: '数据资源',
					url: '/dataValuationEContributionFast'
				}],
				specialty: [{
					name: '基本信息',
					url: '/memberbasicTitle'
				}, {
					name: '软性指标',
					url: '/viewSoftIndicators'
				}, {
					name: '财务指标',
					url: '',
					subMenuVisible: true,
					children: [{
						name: '资产负债表',
						url: '/examineAssetLiability'
					}, {
						name: '利润表',
						url: '/examineProfit'
					}, {
						name: '会计政策统计表',
						url: '/examineAccountingPolicy'
					}]
				}, {
					name: '数据资源',
					url: '',
					subMenuVisible: true,
					children: [{
						name: '数据资源',
						url: '/examineDataAsset'
					}, {
						name: '营业收入预测',
						url: '/examineIncomeForecast'
					}, {
						name: '净利润预测',
						url: '/examineNetProfitForecast'
					}, {
						name: '贡献程度',
						url: '/viewContributionLevel'
					}]
				}],
				list: [],
				visible: true


			}
		},
		mounted() {

			if (this.type == 13) {
				this.list = this.specialty
			} else {
				this.list = this.fast
			}


			if (this.$route.query.auditStatus != '1') {
				this.list.unshift({
					name: '审核状态',
					url: this.type == 13 ? '/dataAuditSpecialty' : 'dataValuationAuditFast'
				})

			}

			this.getCurrentPage()

		},
		methods: {
			switchNav(i, url) {
				this.slideIndex = i
				this.$router.push({
					path: url,
					query: {
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
						auditStatus: this.$route.query.auditStatus
					}
				})
			},
			toggleSubMenu(item) {
				item.subMenuVisible = !item.subMenuVisible;
				// 如果需要保持只有一个子菜单可同时打开，则在此处关闭其他子菜单
				// this.list.forEach(menuItem => {
				// 	if (menuItem !== item) {
				// 		menuItem.subMenuVisible = false;
				// 	}
				// });
			},
			selectiveFinancial(name, url, index) {
				this.childrenCategory = name
				this.$router.push({
					path: url,
					query: {
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
						auditStatus: this.$route.query.auditStatus
					}
				})
			},
			getCurrentPage() {
				for (var i = 0; i < this.list.length; i++) {
					if (this.$route.path == this.list[i].url) {
						this.slideIndex = i
						break
					}

					if (this.list[i].children) {
						for (var j = 0; j < this.list[i].children.length; j++) {
							if (this.$route.path == this.list[i].children[j].url) {
								this.slideIndex = i
								break
							}
						}
					}

				}
			}


		}

	}
</script>

<style scoped lang="less">
	/* 定义进入和离开的过渡效果 */
	.slide-down-enter-active,
	.slide-down-leave-active {
		transition: all 0.3s ease;
		/* 调整动画持续时间和曲线 */
	}

	/* 定义进入前的状态 */
	.slide-down-enter-from {
		transform: translateY(-100%);
		/* 从上方开始 */
		opacity: 0;
	}

	/* 定义离开后最终的状态 */
	.slide-down-leave-to {
		transform: translateY(-100%);
		/* 向上滑出 */
		opacity: 0;
	}

	/* 可选：定义进入后的状态，确保过渡完成后元素恢复正常 */
	.slide-down-enter-to {
		transform: translateY(0);
		opacity: 1;
	}



	.specialty {
		>li {
			margin-bottom: 16px !important;
		}

		.second {
			padding: 10px 15px 0 0;
			margin-left: 28px;
			// display: none;

			>li {
				height: 28px;
				padding-left: 17px;
				margin-bottom: 10px;
				font-size: 14px;
				color: #fff;
				cursor: pointer;
			}

			.financeActive {
				background: url(../../../assets/img/examine/titleBG.png) center center no-repeat;
				background-size: 100% 100%;
				text-shadow: 0px 1px 9px #29FFFC;
				color: #29FFFC;
			}

		}
	}

	.specialty::-webkit-scrollbar {
		width: 6px;
		padding: 2px;
		background: #1237c6;
	}

	.specialty::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #30ECEE;
	}


	.valuationTopNav {
		position: fixed;
		padding-bottom: 10px;

		>li:last-child {
			margin-bottom: 0;
		}

		>li {
			margin-bottom: 36px;

			p {
				width: 127px;
				height: 33px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				cursor: pointer;
				position: relative;
			}

			p::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -9px;
			}

			p:not(.unfold)::after {
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid #fff;
			}

			.unfold::after {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid #fff;
			}

		}

		>li.active p {
			text-shadow: 0px 1px 9px #29FFFC;
			color: #29FFFC;
		}

		>li.active p:not(.children) {
			background: url(../../../assets/img/examine/titleBG.png) center center no-repeat;
		}

		>li.active p:not(.unfold)::after {
			border-left: 5px solid #29FFFC;
		}


		>li.active p.unfold::after {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #29FFFC;
		}

	}

	@media screen and (max-height:680px) {
		.specialty {
			height: 59%;
			overflow-y: scroll;
		}

	}
</style>