<template>
	<div class="main">
		<Header />
		<div id="formWidth">
			<div class="fillinTitle">财务指标</div>
			<fillinNav />
			<financeNav />
			<div class="fillinSubtitle">收入预测表</div>
			<div class="fillinMain">
				<div class="fillinContent">
					<p class="tips">提示：此处填写的数据资源带来的收入，对收入无贡献的数据资源不在此范围内。</p>
					<div class="yearLimit">
						<label for="totalYear"><span class="reRed">*</span>预计未来经济寿命年限</label>
						<div class="baseRight">
							<input type="number" id="totalYear" autocomplete="off" v-model="data.totalYear" @blur="changeYear"
								@wheel="method.disableWheel($event)">
							<span class="measure">年</span>
						</div>
						<p>（年限小于或等于10年）</p>
					</div>
					<p class="lineBetween"></p>
					<p class="unit">单位：万元</p>
					<ul class="annualIncome">
						<li>
							<span>项目/年份</span>
							<span>收入</span>
						</li>
						<li v-for="(item,index) in data.income">
							<span>{{Number(data.year)+index}}年</span>
							<span>
								<input type="number" placeholder="请输入" v-model="item.value"
									@change="changeData($event,item.value,index)" @blur="blur($event,item.value,index)"
									@focus="focus($event)" @wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{method.formateNum(item.value, 2)}}
								</span>
							</span>
						</li>
					</ul>

					<div class="saveInfo" @click="save">下一步</div>
				</div>
			</div>

		</div>

		<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content=""
			@cacheTrueMask="clickCacheTrueMaskJump()" @cacheMask="clickCacheMaskJump()" @confirmJump="clickConfirmJump()">
		</dialogBar>
	</div>
</template>

<script>
	import Header from '@/common/header.vue'
	import fillinNav from '../../common/fillin/fillinNav.vue'
	import financeNav from '../../common/fillin/financeNav.vue'
	import dialogBar from './popue.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		components: {
			Header,
			fillinNav,
			dialogBar,
			financeNav
		},
		data() {
			return {
				myAlert: false,
				popButonType: 'notEmpty',
				markedWords: '',
				incomeForecast: '', //预测的第一年的收入不能大于当前年的总收入
				isCache: '0',
				data: {
					year: '',
					totalYear: '',
					income: [{
						value: '',
					}, {
						value: '',
					}, {
						value: '',
					}, {
						value: '',
					}, {
						value: '',
					}]
				},
				isTrue: false

			}
		},
		mounted() {
			this.init()

		},
		methods: {
			...mapMutations(['pageModify']),
			changeYear() {
				this.pageModify('true');
				if (this.data.totalYear > 10 || this.data.totalYear < 1) {
					this.data.totalYea = '';
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请输入正确的年限';
					return false
				} else {
					if (this.data.totalYear < this.data.income.length) {
						this.data.income.splice('-' + (this.data.income.length - this.data.totalYear))
					} else {
						var num = this.data.totalYear - this.data.income.length;
						for (var i = 0; i < num; i++) {
							this.data.income.push({
								value: ''
							})
						}
					}
				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCache == '0')) {
					this.method.caching("financialIncome", JSON.stringify(this.data), this.$route.query.type, '', this)
				}

			},
			changeData(e, val, i) {
				this.pageModify('true');
				if (this.incomeForecast == -1) {
					if (val > 214748364777) {
						this.data.income[i].value = "";
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '请输入合理数值';
						return false;
					} else {
						e.currentTarget.nextSibling.style.display = 'block';
					}
				} else {
					if (i == 0) {
						console.log(val, this.incomeForecast)
						if (val > this.incomeForecast) {
							this.data.income[i].value = "";
							this.myAlert = true;
							this.popButonType = 'notEmpty';
							this.markedWords = this.data.year + '年数据资源带来的收入不能大于营业收入';
							return false;
						} else {
							e.currentTarget.nextSibling.style.display = 'block';
						}
					} else {
						if (val <= 2147483647 && val >= -2147483648) {
							// console.log('符合')
							e.currentTarget.nextSibling.style.display = 'block';
						} else {
							this.data.income[i].value = "";
							this.myAlert = true;
							this.popButonType = 'notEmpty';
							this.markedWords = '请输入合理数值';
							return false;
						}
					}

				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCache == '0')) {
					this.method.caching("financialIncome", JSON.stringify(this.data), this.$route.query.type, '', this)
				}
			},
			ensureIncreasingOrder(arr) {
				for (let i = 1; i < arr.length; i++) {
					if (i != 0) {
						if (Number(arr[i].value) <= Number(arr[i - 1].value)) {
							// 如果当前元素小于等于前一个元素，调整当前元素的值
							// arr[i] = arr[i - 1] + 1;
							this.isTrue = true
						}
					}
				}
				// return arr;
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			blur(e, val, i) {
				e.currentTarget.setAttribute("placeholder", "请输入")
				this.isTrue = false
				if (val <= 0 && val != '' && val != null) {
					this.data.income[i].value = "";
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '数值必须大于0';
					e.currentTarget.nextSibling.style.display = 'none';
					return false;
				} else {
					if (val != '') e.currentTarget.nextSibling.style.display = 'block';
				}
			},
			init() {
				this.$post(this.domain.valuationProject + 'dataFinance/getFinancialDataIncome', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {

						this.data.year = res.data.content.year;
						this.incomeForecast = res.data.content.incomeForecast
						this.isCache = res.data.content.isCache
						// //0：是缓存数据，1：不是缓存数据
						if (res.data.content.isCache == '0' && !sessionStorage.isHistoryCache) {
							this.myAlert = true;
							this.popButonType = 'cacheType';
							this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';
						} else {
							if (res.data.content.id) {
								this.data.totalYear = res.data.content.totalYear
								this.data.income = JSON.parse(res.data.content.income)
								if (this.data.totalYear) $('.showInput').css('display', 'block')
							}
						}
					})
			},
			save() {
				var isSave = true
				this.ensureIncreasingOrder(this.data.income)
				console.log(this.isTrue)

				if (!this.data.totalYear) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请输入预计未来经济寿命年限';
					return false
				}


				for (var i = 0; i < this.data.income.length; i++) {
					if (!this.data.income[i].value) {
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '所有年份的收入都是必填项';
						isSave = false
						break
					}
				}

				if (!isSave) {
					return false
				}

				if (this.isTrue) {
					this.myAlert = true;
					this.popButonType = 'yesNo';
					this.markedWords = '收入应该逐年呈上涨趋势，是否继续保存';
					return false
				}

				this.saveFinancialData()

			},

			saveFinancialData() {
				this.$post(this.domain.valuationProject + 'dataFinance/saveFinancialDataIncome', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						financialIncome: JSON.stringify(this.data)
					}))
					.then((res) => {
						this.$router.push({
							path: '/payment',
							query: {
								type: this.$route.query.type,
								reportId: res.data.content.valuationReportId
							}
						})
					})
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.init();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type, '', this, 'financialIncome')
			},

			clickConfirmJump() {
				console.log('是')
				this.saveFinancialData()
			}


		}
	}
</script>

<style scoped src="../../assets/css/data.css"></style>
<style scoped lang="less">
	.tips {
		font-size: 14px;
		font-weight: 400;
		color: #29FFFC;
		margin-top: 10px;
		margin-left: 30px;
	}

	.unit {
		width: 612px;
		font-size: 18px;
		color: #FFFFFF;
		text-align: right;
		margin: 0 auto;
	}

	.lineBetween {
		border-top: 2px #fff dashed;
		margin-top: 20px;
		margin-bottom: 25px;
	}

	.yearLimit {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 30px;
		margin-left: 21px;

		>p {
			font-size: 17px;
			color: #fff;
		}

		label {
			width: 226px;
			font-size: 18px;
			color: #fff;
		}

		input,
		.baseRight,
		.showInput {
			width: 242px;
		}

		input {
			height: 40px;
			border: 2px solid #19ECFF;
			font-size: 18px;
			color: #fff;
			background: transparent;
			padding: 0 19px;
			box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
		}

		input:focus {
			border-color: #fff;
		}

		.showInput {
			height: 38px;
			line-height: 40px;
			padding: 0 19px;
			color: #fff;
			font-size: 18px;
			position: absolute;
			top: 0;
			left: 0;
			display: none;
		}

		.measure {
			width: 45px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			color: #fff;
			font-size: 18px;
		}

		.baseRight {
			height: 40px;
			position: relative;
		}
	}

	.annualIncome::before {
		content: '';
		width: 100%;
		height: 10px;
		display: block;
		background: url(../../assets/img/finance/line.png) center center no-repeat;
		position: absolute;
		top: -3px;
		left: 0;
	}

	.annualIncome::after {
		content: '';
		width: 100%;
		height: 10px;
		display: block;
		background: url(../../assets/img/finance/line.png) center center no-repeat;
		position: absolute;
		bottom: -3px;
		left: 0;
	}

	.annualIncome {
		width: 612px;
		background: #142FC1;
		box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
		position: relative;
		margin: 15px auto;


		li {
			width: 95%;
			margin: 0 auto;
			padding: 16px 0;
			text-align: center;
			position: relative;

			input {
				width: 100%;
				padding: 0 10px;
				font-size: 16px;
				background: #142FC1;
				color: #fff;
				text-align: center;
			}

			span:first-child {
				width: 40%;
				font-size: 16px;
				font-weight: bold;
				color: #fff;
				border-right: 2px solid #29FFFC;
			}

			span:last-child {
				width: 60%;
				color: #fff;
				position: relative;

				.showInput {
					width: 100%;
					height: 100%;
					font-size: 16px;
					padding: 0 10px;
					color: #fff;
					text-align: center;
					position: absolute;
					top: 0;
					left: 0;
					background: #142FC1;
					display: none;
				}
			}
		}

		li:nth-of-type(1) {
			padding: 20px 0;

			span {
				font-size: 18px;
				font-weight: bold;
			}

		}

		li:not(:first-child)::before {
			content: '';
			width: 100%;
			height: 10px;
			display: block;
			background: url(../../assets/img/finance/badge.png) center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

	}

	@media screen and (max-width:1500px) and (max-height:700px) {
		.tips {
			font-size: 16px;
		}

		.yearLimit label {
			width: 221px;
			font-size: 20px;
		}

		.yearLimit .baseRight {
			margin-left: 8px;
		}

		.unit,
		.annualIncome li span,
		.annualIncome li input,
		.annualIncome li span:last-child .showInput {
			font-size: 20px !important;
		}
	}
</style>