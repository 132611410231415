<template>
	<div>
		<table class="table" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th>项目</th>
					<th v-for="(item,index) in year" :key="index - 1">{{item.substring(0,4)}}-12-31</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="trTwo">资产负债率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.liabilitiesRatio[index]?financeRatio.liabilitiesRatio[index]:'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">流动比率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.curAssetsRatio[index]?method.formateNum(financeRatio.curAssetsRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">EBITl利息保障倍数</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.ebitMulRatio[index]?method.formateNum(financeRatio.ebitMulRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">毛利率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.grossProfitRatio[index]?financeRatio.grossProfitRatio[index]:'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">净利润率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.netProfitRatio[index]?financeRatio.netProfitRatio[index]:'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">主营业务收入增长率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.incomeRatio[index]?financeRatio.incomeRatio[index]:'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">净资产收益率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.netAssetsRatio[index]?financeRatio.netAssetsRatio[index]:'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应收账款周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.receivableRatio[index]?method.formateNum(financeRatio.receivableRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">存货周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.inventoryRatio[index]?method.formateNum(financeRatio.inventoryRatio[index],2):'-'}}
					</td>
				</tr>


			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: [
			'financeRatio', 'year', 'versions'
		],
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
		},
		methods: {

		}
	})
</script>
<style scoped lang="less">
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #1416F4;
	}

	table tr th {
		height: 50px;
		border: 1px solid #8A8BFA;
		background: #1416F4;
		font-size: 18px;
		color: #fff;
	}

	table tr td {
		width: 27.5%;
		height: 43px;
		border: 1px solid #8A8BFA;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-of-type {
		width: 45%;
		text-align: left;
		padding-right: 10px;
	}

	.total {
		background: rgba(20, 22, 244, 0.1);
	}

	.trOne {
		padding-left: 40px;
		font-weight: bold;
	}

	.trTwo {
		padding-left: 70px;
	}

	.trThree {
		padding-left: 101px;
	}

	.trFour {
		padding-left: 131px;
	}

	.trFive {
		padding-left: 178px;
	}

	.trSix {
		padding-left: 116px;
	}

	.trSeven {
		padding-left: 122px;
	}

	.title {
		margin: 0 !important;
	}

	table>tbody>tr {
		td:not(:first-child) {
			text-align: right;
			padding-right: 38px;
		}
	}
</style>