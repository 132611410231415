<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">基本信息</p>
							<ul class="dataValuationInfo">
									<li>
										<span>公司全称</span>
										<span>{{data.companyName}}</span>
									</li>
									<li>
										<span>所属行业</span>
										<span>{{data.industryName+'- -'+data.industryChildName}}</span>
									</li>
									<li>
										<span>注册资本</span>
										<span>{{this.method.formateNum(data.regCapital,2)}}万元</span>
									</li>
									<li>
										<span>法定代表人</span>
										<span>{{data.legalPeople}}</span>
									</li>
									<li>
										<span>成立日期</span>
										<span>{{data.establishDate}}</span>
									</li>
									<li>
										<span>统一社会信用代码</span>
										<span>{{data.creditCode}}</span>
									</li>
									<li>
										<span>详细地址</span>
										<span>{{data.address}}</span>
									</li>
									<li>
										<span>数据类型</span>
										<span>{{dataType?dataType:'暂无数据'}}</span>
									</li>
									<li>
										<span>{{year}}年公司营业收入(预测)</span>
										<span>{{this.method.formateNum(data.income,2)}}万元</span>
									</li>
									<li>
										<span>{{year}}年公司净资产(预测)</span>
										<span>{{this.method.formateNum(data.netAsset,2)}}万元</span>
									</li>
									<li>
										<span>{{year}}年公司净利润(预测)</span>
										<span>{{this.method.formateNum(data.netProfit,2)}}万元</span>
									</li>
									<li>
										<span>{{year}}年公司息税折旧摊销前利润(预测)</span>
										<span>{{this.method.formateNum(data.amortizeProfit,2)}}万元</span>
									</li>
									<li>
										<span>第一股东</span>
										<span>{{data.firstHolderRatio?data.firstHolderRatio+'%':'暂无数据'}}</span>
									</li>
									<li>
										<span>第二股东</span>
										<span>{{data.secHolderRatio?data.secHolderRatio+'%':'暂无数据'}}</span>
									</li>
									<li>
										<span>第三股东</span>
										<span>{{data.thirdHolderRatio?data.thirdHolderRatio+'%':'暂无数据'}}</span>
									</li>
									<li>
										<span>第四股东</span>
										<span>{{data.fourHolderRatio?data.fourHolderRatio+'%':'暂无数据'}}</span>
									</li>
									<li>
										<span>其他股东</span>
										<span>{{data.otherHolderRatio?data.otherHolderRatio+'%':'暂无数据'}}</span>
									</li>
								</ul>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type:'15',
				year:'',
				data: {
					companyName: '',
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					regCapital: '', //注册资本
					legalPeople: '', //法定代表人
					establishDate: '', //成立时间
					creditCode: '', // 统一社会信用代码
					address: '', // 详细地址
					dataDesc: '', //数据资产介绍
					dataResourceType: [], //数据类型
					income: '', //公司营业收入(预测)
					netAsset: '', //公司净资产(预测)
					netProfit: '', //公司净利润（预测）
					amortizeProfit: '', //公司息税折旧摊销前利润(预测)
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
				},
				dataType: ''
			}
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}

			if (this.$route.query.encipher) {
				localStorage.userName = atob(this.$route.query.encipher)
			}

		},
		mounted() {
			sessionStorage.versions = 2
			sessionStorage.stage = ''
			sessionStorage.reportType = this.$route.query.type
			this.getBaseInfo()


		},
		methods: {
			getBaseInfo() {
				this.$post(this.domain.valuationProject + 'auditInfoShow/getBaseInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.data = res.data.content
						this.year = res.data.content.createTime.substring(0,4)
						JSON.parse(res.data.content.dataResourceType).map((el, i) => {
							if (i == JSON.parse(res.data.content.dataResourceType).length - 1) {
								this.dataType += el
							} else {
								this.dataType += el + '、'
							}
						})

					})
			}
		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.dataValuationInfo {
		width: 90%;
		margin: 0 auto;
	}

	.dataValuationInfo li:nth-of-type(1) span {
		font-size: 14px;
		font-weight: bold;
	}

	.dataValuationInfo li span:last-child {
		text-align: left;
		padding-left: 6%;
	}
</style>