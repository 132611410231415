<template>
	<div :class="['financeTabulation','forecast',forecastData.years.length==3?'threeYears':'twoYears']">
		<p class="unit">单位：元</p>

		<ul class="forms">
			<i></i>
			<li>
				<span>业务分类</span>
				<span v-for="(item,i) in forecastData.years">{{item}}<span v-if="i==0" class="reRed">*</span></span>
			</li>
			<li v-for="(operation,i) in forecastData[key].products">
				<span>{{operation.businessName}}</span>
				<span v-for="(item,j) in operation.prediction">
					<input v-if="item.value!='-'" type="number" v-model="item.value" @change="change(i,j)"
						@blur="((ev)=>{displayThousandths(ev,item.value,'products',i,j)})" @focus="focus($event)"
						@wheel="method.disableWheel($event)">
					<input v-else type="number" disabled>

					<span v-if="item.value!='-'" class="showInput" @click="inputFocus($event)">
						{{item.value?method.formateNum(item.value,2):''}}
					</span>
					<span v-else class="showInput">-</span>

				</span>
			</li>
			<li>
				<span>合计</span>
				<span v-for="(item,i) in forecastData[key].productSum.prediction">
					<input type="number" v-model="item.value" disabled>
					<span class="showInput">
						{{item.value?method.formateNum(item.value,2):''}}
					</span>
				</span>
			</li>

			<i></i>
		</ul>

	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			queryMethod: String, //查询接口的方法
			saveMethod: String, //保存接口的方法
			jumpAddress: String, //保存之后的跳转地址
			dataInfo: Object,
			tableType: {
				type: [String, Number] // 0: 营业收入预测   1：净利润
			}


		},
		data() {
			return {
				forecastData: this.dataInfo,
				isSave: false,
				isTrue: false,
				businessIdx: 0,
				yearIdx: 0,
				text: this.tableType == 1 ? '净利润' : '营业收入',
				key: this.tableType == 1 ? 'netProfit' : 'income',
				basicInfoPrediction: this.tableType == 1 ? 'baseInfoNetProfit' : 'baseInfoIncome',
				hint: false


			}
		},
		watch: {
			dataInfo: {
				handler: function(newValue, oldValue) {
					this.$set(this, 'forecastData', newValue)
					// console.log('新数据--', newValue)
				},
				immediate: true, // 在初始加载时也会触发监听函数
				deep: true
			}

		},
		mounted() {
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.focus();
			},
			change(i, j) {
				this.pageModify('true');
				if (j != 0 && this.forecastData[this.key].products[i].prediction[j].value < this.forecastData[this.key].products[
						i]
					.prediction[j - 1].value) {
					this.showDialogBar(true, 'notEmpty-info', this.forecastData.years[j] + this.forecastData[this.key].products[i]
						.businessName + this.text + '预测数据较前一年度呈下降趋势，请核实是否符合公司实际经营情况。');
				}
			},
			displayThousandths: function(e, val, key, i, j) {
				this.isSave = false
				this.isTrue = false
				e.currentTarget.setAttribute("placeholder", "请输入")
				e.currentTarget.nextSibling.style.display = 'block';
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				console.log(val.length)
				
				if (val.length < 20) {

				} else {
					this.forecastData[this.key].products[i].prediction[j].value = ''

					this.$Message.error({
						background: true,
						content: '请输入合理数据'
					});
					return false;
				}

				var totalNum = 0;
				for (var k = 0; k < this.forecastData[this.key].products.length; k++) {
					totalNum += this.forecastData[this.key].products[k].prediction[j].value == '-' ? 0 : Number(this
						.forecastData[this.key].products[k].prediction[j].value)
					this.forecastData[this.key].productSum.prediction[j].value = Number(totalNum)
				}

				//调用缓存共用方法
				// if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0')) {
				// 	this.method.caching("financialBookValue", JSON.stringify(this.data), this.$route.query.type, '', this)
				// }
			},
			save() {
				var this_ = this
				var shouldBreak = false; // 标志变量

				if (this.tableType != 1) {
					for (var i = 0; i < this.forecastData[this.key].products.length && !shouldBreak; i++) {
						for (var j = 0; j < this.forecastData[this.key].products[i].prediction.length && !shouldBreak; j++) {
							if (this.forecastData[this.key].products[i].prediction[j].value && this.method.numRound(this.forecastData[
									this.key].products[i].prediction[j].value) < 0) {
								this.showDialogBar(true, 'notEmpty-info', this.text + '必须大于零');
								shouldBreak = true;
								break;
							} else if (!this.forecastData[this.key].products[i].prediction[0].value) {
								this.showDialogBar(true, 'notEmpty-info', this.forecastData.years[0] + this.text + '为必填');
								shouldBreak = true;
							}
						}
						if (shouldBreak) {
							break;
						}
					}

				} else {
					for (var i = 0; i < this.forecastData[this.key].products.length && !shouldBreak; i++) {
						for (var j = 0; j < this.forecastData[this.key].products[i].prediction.length && !shouldBreak; j++) {
							if (!this.forecastData[this.key].products[i].prediction[0].value) {
								this.showDialogBar(true, 'notEmpty-info', this.forecastData.years[0] + this.text + '为必填');
								shouldBreak = true;
							}
						}
						if (shouldBreak) {
							break;
						}
					}


				}


				if (shouldBreak) {
					return false
				}


				if (this.forecastData[this.basicInfoPrediction] * 10000 != '' && parseInt(Number(this.forecastData[this.key]
						.productSum.prediction[0].value) / 10000) > parseInt(Number(this.forecastData[this.basicInfoPrediction]))) {
					this.hint = true
					this.showDialogBar(true, 'notEmpty-info', '请核实' + this.forecastData.years[0] + this.text + '应与基本信息中' + this
						.text + '预测一致');
					return false
				}


				this.saveFinancialData()

			},
			saveFinancialData() {
				var this_ = this
				if (!this.isSave) {
					this.isSave = true

					var incomeJson = {
						productSum: this.forecastData[this.key].productSum,
						products: this.forecastData[this.key].products,
						years: this.forecastData.years
					}

					var parame = {
						stage: '',
						versions: '',
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						id: this.forecastData.id ? this.forecastData.id : '',
					}

					if (this.tableType == 1) {
						parame.netProfitJson = JSON.stringify(incomeJson)
					} else {
						parame.incomeJson = JSON.stringify(incomeJson)
					}

					console.log('保存--', incomeJson)

					this.$post(this.saveMethod, qs.stringify(parame))
						.then(res => {
							setTimeout(function() {
								this_.$router.push({
									name: this_.jumpAddress,
									query: {
										reportId: JSON.parse(res.data.content).reportId
									}
								})
							}, 500)

						})

				}
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
				if (this.hint) {
					this.saveInterface()
				}
			}






		}


	}
</script>

<style>
</style>