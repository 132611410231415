import qs from 'qs'
import axios from 'axios'

import {
	mapActions,
	mapMutations
} from 'vuex'

import {
	Modal,
	Input,
	DIV,
	Switch
} from 'iview'

export default{
	mounted(){
		window.addEventListener('resize', () => {
			if (window.innerWidth < 1200) {
				this.width = 1200
			} else {
				this.width = window.innerWidth
			}
		})
		
	},
	methods:{
		
		
	}
	
}