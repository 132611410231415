<template>
	<div class="liabilitySpecialty">
		<div class="newCategory">资产负债表</div>
		<div class="inputTabDesc">
			<p class="table-desc-tit">
				<img :src="require('@/assets/img/finance/hint.png')" alt="填表说明" />
				<span>填表说明</span>
			</p>
			<ul>
				<li>
					<span><i>1、</i><i>资产负债表请务必按实际情况填报完整，各明细科目合计应与该一级科目填报数相等，所有者权益应等于总资产减去总负债。</i></span>
				</li>
				<li>
					<span><i>2、</i><i>灰色为自动算出项，不可填写。</i></span>
				</li>
				<li>
					<span><i>3、</i><i>如果存在明细科目，本表没有对应科目的情况，请将合计数填在对应一级科目的其他处。</i></span>
				</li>
			</ul>
		</div>

		<div class="specialtyFinance">
			<p class="unit">单位：元</p>
			<div>
				<ul class="yeaAccount">
					<i></i>
					<li>
						<span>项目</span>
						<span v-for="(item,i) in yearList">
							{{item.replace("年", "-12-31")}}
							<i v-if="i==yearList.length-1" class="reRed" style="position: absolute;">*</i>
						</span>
					</li>
					<li>
						<span>项目</span>
						<span v-for="(item,i) in yearList">
							{{item.replace("年", "-12-31")}}
							<i v-if="i==yearList.length-1" class="reRed" style="position: absolute;">*</i>
						</span>
					</li>
					<i></i>
				</ul>

				<div class="tabulation">
					<ul class="professionalForm">
						<li>
							<span class="trOne">流动资产：</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="trTwo">货币资金</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.monetaryCapital[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('monetaryCapital',i)?method.formateNum(liabilities.monetaryCapital[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">交易性金融资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.tradingAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'tradingAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('tradingAssets',i)?method.formateNum(liabilities.tradingAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">衍生金融资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.derivativeAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'derivativeAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('derivativeAssets',i)?method.formateNum(liabilities.derivativeAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应收票据</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.billReceivable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'billReceivable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('billReceivable',i)?method.formateNum(liabilities.billReceivable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应收账款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.receivables[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('receivables',i)?method.formateNum(liabilities.receivables[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应收账款融资</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.accountReceivable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'accountReceivable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('accountReceivable',i)?method.formateNum(liabilities.accountReceivable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">预付款项</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.prepayment[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'prepayment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('prepayment',i)?method.formateNum(liabilities.prepayment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他应收款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherReceivables[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherReceivables',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherReceivables',i)?method.formateNum(liabilities.otherReceivables[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">存货</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.inventory[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('inventory',i)?method.formateNum(liabilities.inventory[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">合同资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.contractualAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'contractualAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('contractualAssets',i)?method.formateNum(liabilities.contractualAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">持有待售资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.saleForAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'saleForAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('saleForAssets',i)?method.formateNum(liabilities.saleForAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">一年内到期的非流动资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.illiquidAssetsOne[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'illiquidAssetsOne',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('illiquidAssetsOne',i)?method.formateNum(liabilities.illiquidAssetsOne[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">
								其他流动资产
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>此处应填写未在本会计报表内统计的所有流动资产科目汇总金额。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherCurrentAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherCurrentAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherCurrentAssets',i)?method.formateNum(liabilities.otherCurrentAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">流动资产合计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalCurrentAssets[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalCurrentAssets',i)?method.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">非流动资产：</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="trTwo">债权投资</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.debtInvestment[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'debtInvestment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('debtInvestment',i)?method.formateNum(liabilities.debtInvestment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他债权投资</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherDebtInvestment[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherDebtInvestment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherDebtInvestment',i)?method.formateNum(liabilities.otherDebtInvestment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">长期应收款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.longAeceivables[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'longAeceivables',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('longAeceivables',i)?method.formateNum(liabilities.longAeceivables[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">长期股权投资</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.longInvestment[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'longInvestment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('longInvestment',i)?method.formateNum(liabilities.longInvestment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他权益工具投资</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherEquityInvestment[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherEquityInvestment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherEquityInvestment',i)?method.formateNum(liabilities.otherEquityInvestment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他非流动金融资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherIlliquidAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherIlliquidAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherIlliquidAssets',i)?method.formateNum(liabilities.otherIlliquidAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">投资性房地产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.investmentProperty[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'investmentProperty',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('investmentProperty',i)?method.formateNum(liabilities.investmentProperty[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">
								固定资产
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>此处指固定资产期末净值。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.fixedAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('fixedAssets',i)?method.formateNum(liabilities.fixedAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">在建工程</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.constructionProjects[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'constructionProjects',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('constructionProjects',i)?method.formateNum(liabilities.constructionProjects[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">生产性生物资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.productiveAsset[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'productiveAsset',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('productiveAsset',i)?method.formateNum(liabilities.productiveAsset[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">油气资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.oilAndGasAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'oilAndGasAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('oilAndGasAssets',i)?method.formateNum(liabilities.oilAndGasAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">使用权资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.rightAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'rightAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('rightAssets',i)?method.formateNum(liabilities.rightAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">无形资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.netWorth[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'netWorth',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('netWorth',i)?method.formateNum(liabilities.netWorth[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">开发支出</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.developExpense[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'developExpense',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('developExpense',i)?method.formateNum(liabilities.developExpense[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">商誉</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.goodwill[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'goodwill',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('goodwill',i)?method.formateNum(liabilities.goodwill[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">长期待摊费用</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.longExpenses[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'longExpenses',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('longExpenses',i)?method.formateNum(liabilities.longExpenses[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">递延所得税资产</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.deferredAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'deferredAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('deferredAssets',i)?method.formateNum(liabilities.deferredAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">
								其他非流动资产
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>此处应填写未在本会计报表内统计的所有非流动资产科目汇总金额。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherNonCurrentAssets[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherNonCurrentAssets',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherNonCurrentAssets',i)?method.formateNum(liabilities.otherNonCurrentAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li class="vacancy"></li>
						<li class="vacancy"></li>
						<li class="vacancy"></li>
						<li class="vacancy"></li>
						<li class="vacancy"></li>
						<li class="vacancy"></li>
						<li>
							<span class="trOne">非流动资产合计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalNonCurrentAssets[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalNonCurrentAssets',i)?method.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
								</span>
							</span>
						</li>
						<li class="vacancy"></li>
						<li>
							<span class="trOne">资产总计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalAssets[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalAssets',i)?method.formateNum(liabilities.totalAssets[i],2):'-'}}
								</span>
							</span>
						</li>
					</ul>


					<ul class="professionalForm">
						<li>
							<span class="trOne">流动负债：</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="trTwo">短期借款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.shortLoan[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('shortLoan',i)?method.formateNum(liabilities.shortLoan[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">交易性金融负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.transactionLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'transactionLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('transactionLiabilities',i)?method.formateNum(liabilities.transactionLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">衍生金融负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.derivativeLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'derivativeLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('derivativeLiabilities',i)?method.formateNum(liabilities.derivativeLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应付票据</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.notesPayable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'notesPayable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('notesPayable',i)?method.formateNum(liabilities.notesPayable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应付账款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.accountsPayable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('accountsPayable',i)?method.formateNum(liabilities.accountsPayable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">预收款项</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.advanceReceipt[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'advanceReceipt',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('advanceReceipt',i)?method.formateNum(liabilities.advanceReceipt[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">合同负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.contractualLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'contractualLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('contractualLiabilities',i)?method.formateNum(liabilities.contractualLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应付职工薪酬</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.payrollPayable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'payrollPayable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('payrollPayable',i)?method.formateNum(liabilities.payrollPayable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应交税费</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.taxPayable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'taxPayable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('taxPayable',i)?method.formateNum(liabilities.taxPayable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他应付款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherPayables[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherPayables',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherPayables',i)?method.formateNum(liabilities.otherPayables[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">持有待售负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.saleForLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'saleForLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('saleForLiabilities',i)?method.formateNum(liabilities.saleForLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">一年内到期的非流动负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.illiquidLiabilitiesOne[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'illiquidLiabilitiesOne',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('illiquidLiabilitiesOne',i)?method.formateNum(liabilities.illiquidLiabilitiesOne[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">
								其他流动负债
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>此处应填写未在本会计报表内统计的所有流动负债科目汇总金额。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherNonLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherNonLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherNonLiabilities',i)?method.formateNum(liabilities.otherNonLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">流动负债合计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalNonLiabilities[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalNonLiabilities',i)?method.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">非流动负债：</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="trTwo">长期借款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.longLoan[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('longLoan',i)?method.formateNum(liabilities.longLoan[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">应付债券</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.bondsPayable[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'bondsPayable',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('bondsPayable',i)?method.formateNum(liabilities.bondsPayable[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trThree">其中：优先股</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.bondsPreferredStock[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'bondsPreferredStock',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('bondsPreferredStock',i)?method.formateNum(liabilities.bondsPreferredStock[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trFour">永续债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.bondsPerpetuaDebt[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'bondsPerpetuaDebt',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('bondsPerpetuaDebt',i)?method.formateNum(liabilities.bondsPerpetuaDebt[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">租赁负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.leaseLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'leaseLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('leaseLiabilities',i)?method.formateNum(liabilities.leaseLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">长期应付款</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.longPayment[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'longPayment',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('longPayment',i)?method.formateNum(liabilities.longPayment[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">预计负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.estimatedLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'estimatedLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('estimatedLiabilities',i)?method.formateNum(liabilities.estimatedLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">递延收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.deferredIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'deferredIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('deferredIncome',i)?method.formateNum(liabilities.deferredIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">递延所得税负债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.deferredLiability[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'deferredLiability',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('deferredLiability',i)?method.formateNum(liabilities.deferredLiability[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">
								其他非流动负债
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>此处应填写未在本会计报表内统计的所有非流动负债科目汇总金额。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherFixedLiabilities[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherFixedLiabilities',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherFixedLiabilities',i)?method.formateNum(liabilities.otherFixedLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">非流动负债合计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalFixedLiabilities[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalFixedLiabilities',i)?method.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">负债总计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalLiabilities[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalLiabilities',i)?method.formateNum(liabilities.totalLiabilities[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">
								所有者权益(或股东权益)：
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>是指所有者向企业投入的资本总和。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList"></span>
						</li>
						<li>
							<span class="trTwo">实收资本(或股本)</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.paidUpCapital[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'paidUpCapital',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('paidUpCapital',i)?method.formateNum(liabilities.paidUpCapital[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他权益工具</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherEquity[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherEquity',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherEquity',i)?method.formateNum(liabilities.otherEquity[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trThree">其中：优先股</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.equityPreferredStock[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'equityPreferredStock',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('equityPreferredStock',i)?method.formateNum(liabilities.equityPreferredStock[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trFour">永续债</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.equityPerpetuaDebt[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'equityPerpetuaDebt',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('equityPerpetuaDebt',i)?method.formateNum(liabilities.equityPerpetuaDebt[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">资本公积</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.capitalReserve[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'capitalReserve',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('capitalReserve',i)?method.formateNum(liabilities.capitalReserve[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">减：库存股</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.treasuryShare[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'treasuryShare',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('treasuryShare',i)?method.formateNum(liabilities.treasuryShare[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">其他综合收益</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.otherIncome[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'otherIncome',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('otherIncome',i)?method.formateNum(liabilities.otherIncome[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">专项储备</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.appropriativeReserve[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'appropriativeReserve',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('appropriativeReserve',i)?method.formateNum(liabilities.appropriativeReserve[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">盈余公积</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.earnedSurplus[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'earnedSurplus',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('earnedSurplus',i)?method.formateNum(liabilities.earnedSurplus[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">未分配利润</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.undistributedProfit[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'undistributedProfit',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('undistributedProfit',i)?method.formateNum(liabilities.undistributedProfit[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trTwo">
								归属于母公司所有者权益合计
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>是指公司集团的所有者权益中归属于母公司所有者权益的部分。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalParentEquity[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalParentEquity',i)?method.formateNum(liabilities.totalParentEquity[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">
								少数股东权益
								<span class="desc-container">
									<span></span>
									<div class="desc-main">
										<span></span>
										<p>是指母公司以外的的人拥有的权益，此项针对合并报表企业，如果无少数股东权益或非合并报表企业，则填零。</p>
									</div>
								</span>
							</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.minorityEquity[i]" @change="change"
									@blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})" @focus="focus($event)"
									@wheel="method.disableWheel($event)">
								<span class="showInput" @click="inputFocus($event)">
									{{judgingCondition('minorityEquity',i)?method.formateNum(liabilities.minorityEquity[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">所有者权益（或股东权益)合计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalOwnershipInterest',i)?method.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
								</span>
							</span>
						</li>
						<li>
							<span class="trOne">负债和所有者权益(或股东权益)总计</span>
							<span v-for="(item,i) in yearList">
								<input type="number" v-model="liabilities.totalLiabilitiesEquity[i]" disabled>
								<span class="showInput">
									{{judgingCondition('totalLiabilitiesEquity',i)?method.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
								</span>
							</span>
						</li>

					</ul>

					<p class="line"></p>
				</div>


			</div>

		</div>




	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址


		},
		data() {
			return {
				yearList: ['xxxx年', 'xxxx年'],
				id: '',
				isCacheValue: '',
				liabilities: {
					monetaryCapital: [],
					tradingAssets: [],
					derivativeAssets: [],
					billReceivable: [],
					receivables: [],
					accountReceivable: [],
					prepayment: [],
					otherReceivables: [],
					inventory: [],
					contractualAssets: [],
					saleForAssets: [],
					illiquidAssetsOne: [],
					otherCurrentAssets: [],
					totalCurrentAssets: [],
					debtInvestment: [],
					otherDebtInvestment: [],
					longAeceivables: [],
					longInvestment: [],
					otherEquityInvestment: [],
					otherIlliquidAssets: [],
					investmentProperty: [],
					fixedAssets: [],
					constructionProjects: [],
					productiveAsset: [],
					oilAndGasAssets: [],
					rightAssets: [],
					netWorth: [],
					developExpense: [],
					goodwill: [],
					longExpenses: [],
					deferredAssets: [],
					otherNonCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					shortLoan: [],
					transactionLiabilities: [],
					derivativeLiabilities: [],
					notesPayable: [],
					accountsPayable: [],
					advanceReceipt: [],
					contractualLiabilities: [],
					payrollPayable: [],
					taxPayable: [],
					otherPayables: [],
					saleForLiabilities: [],
					illiquidLiabilitiesOne: [],
					otherNonLiabilities: [],
					totalNonLiabilities: [],
					longLoan: [],
					bondsPayable: [],
					bondsPreferredStock: [],
					bondsPerpetuaDebt: [],
					leaseLiabilities: [],
					longPayment: [],
					estimatedLiabilities: [],
					deferredIncome: [],
					deferredLiability: [],
					otherFixedLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					paidUpCapital: [],
					otherEquity: [],
					equityPreferredStock: [],
					equityPerpetuaDebt: [],
					capitalReserve: [],
					treasuryShare: [],
					otherIncome: [],
					appropriativeReserve: [],
					earnedSurplus: [],
					undistributedProfit: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalOwnershipInterest: [],
					totalLiabilitiesEquity: []

				},
				isSave: false,
				liabilitiesData: {},
				hint: false //弹框提示后仍然可以保存

			}
		},
		mounted() {
			this.getBalanceSheet()
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.pageModify('true');
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.id)) {
					this.method.caching("liabilitiesInfo", JSON.stringify(this.liabilities), this.type, this.stage, this)
				}
			},
			inputFocus: function(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.style.borderColor = '#fff';
				e.currentTarget.previousSibling.focus();
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")

			},
			judgingCondition: function(key, i, index) {
				if (this.liabilities[key][i] === 0) {
					return true
				} else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：资产负债表的字段   name：具体的科目名称   num：科目的具体下标
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'block';
				e.currentTarget.style.borderColor = '#29FFFC';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');
				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.liabilities[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 20) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.liabilities[name], num, '')
						} else {
							this.$set(this.liabilities[name], num, Number(data[name][num]))
						}
					}
				} else {
					this.$set(this.liabilities[name], num, '')
				}

				for (var i = 0; i < this.yearList.length; i++) {
					// 流动资产合计
					this.liabilities.totalCurrentAssets[i] = Number(this.liabilities.monetaryCapital[i]) + Number(this
							.liabilities
							.tradingAssets[i]) + Number(this.liabilities.derivativeAssets[i]) + Number(this.liabilities
							.billReceivable[i]) +
						Number(this.liabilities.receivables[i]) + Number(this.liabilities.accountReceivable[i]) +
						Number(this.liabilities
							.prepayment[i]) + Number(this.liabilities.otherReceivables[i]) + Number(this.liabilities
							.inventory[i]) + Number(this
							.liabilities.contractualAssets[i]) + Number(this.liabilities.saleForAssets[i]) + Number(
							this.liabilities
							.illiquidAssetsOne[i]) + Number(this.liabilities.otherCurrentAssets[i]);

					// 非流动资产:
					this.liabilities.totalNonCurrentAssets[i] = Number(this.liabilities.debtInvestment[i]) + Number(
							this.liabilities
							.otherDebtInvestment[i]) + Number(this.liabilities.longAeceivables[i]) + Number(this
							.liabilities
							.longInvestment[i]) + Number(this.liabilities.otherEquityInvestment[i]) + Number(this
							.liabilities
							.otherIlliquidAssets[i]) + Number(this.liabilities.investmentProperty[i]) + Number(this
							.liabilities
							.fixedAssets[i]) + Number(this.liabilities.constructionProjects[i]) + Number(this
							.liabilities.productiveAsset[
								i]) + Number(this.liabilities.oilAndGasAssets[i]) + Number(this.liabilities
							.rightAssets[i]) + Number(this
							.liabilities.netWorth[i]) + Number(this.liabilities.developExpense[i]) + Number(this
							.liabilities.goodwill[i]) +
						Number(this.liabilities.longExpenses[i]) + Number(this.liabilities.deferredAssets[i]) + Number(
							this.liabilities
							.otherNonCurrentAssets[i]);

					// 资产总计
					this.liabilities.totalAssets[i] = Number(this.liabilities.totalCurrentAssets[i]) + Number(this
						.liabilities.totalNonCurrentAssets[i]);

					// 流动负债合计
					this.liabilities.totalNonLiabilities[i] = Number(this.liabilities.shortLoan[i]) + Number(this
							.liabilities
							.transactionLiabilities[i]) + Number(this.liabilities.derivativeLiabilities[i]) + Number(
							this.liabilities
							.notesPayable[i]) + Number(this.liabilities.accountsPayable[i]) + Number(this.liabilities
							.advanceReceipt[i]) +
						Number(this.liabilities.contractualLiabilities[i]) + Number(this.liabilities.payrollPayable[
							i]) + Number(this
							.liabilities.taxPayable[i]) + Number(this.liabilities.otherPayables[i]) + Number(this
							.liabilities
							.saleForLiabilities[i]) + Number(this.liabilities.illiquidLiabilitiesOne[i]) + Number(this
							.liabilities
							.otherNonLiabilities[i]);

					// 非流动负债合计
					this.liabilities.totalFixedLiabilities[i] = Number(this.liabilities.longLoan[i]) + Number(this
						.liabilities
						.bondsPayable[i]) + Number(this.liabilities.bondsPreferredStock[i]) + Number(this
						.liabilities
						.bondsPerpetuaDebt[i]) + Number(this.liabilities.leaseLiabilities[i]) + Number(this
						.liabilities.longPayment[
							i]) + Number(this.liabilities.estimatedLiabilities[i]) + Number(this.liabilities
						.deferredIncome[i]) + Number(this
						.liabilities.deferredLiability[i]) + Number(this.liabilities.otherFixedLiabilities[i]);

					// 负债总计
					this.liabilities.totalLiabilities[i] = Number(this.liabilities.totalNonLiabilities[i]) + Number(
						this.liabilities.totalFixedLiabilities[i]);

					// 归属于母公司所有者权益合计
					this.liabilities.totalParentEquity[i] = Number(this.liabilities.paidUpCapital[i]) + Number(this
						.liabilities
						.otherEquity[i]) + Number(this.liabilities.equityPreferredStock[i]) + Number(this
						.liabilities
						.equityPerpetuaDebt[i]) + Number(this.liabilities.capitalReserve[i]) - Number(this
						.liabilities.treasuryShare[
							i]) + Number(this.liabilities.otherIncome[i]) + Number(this.liabilities
						.appropriativeReserve[i]) + Number(this.liabilities.earnedSurplus[i]) + Number(this
						.liabilities
						.undistributedProfit[i]);

					// 所有者权益（或股东权益)合计
					this.liabilities.totalOwnershipInterest[i] = Number(this.liabilities.totalParentEquity[i]) +
						Number(this.liabilities.minorityEquity[i]);

					// 负债和所有者权益(或股东权益)总计
					this.liabilities.totalLiabilitiesEquity[i] = Number(this.liabilities.totalLiabilities[i]) + Number(
						this.liabilities.totalOwnershipInterest[i]);


				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.id)) {
					this.method.caching("liabilitiesInfo", JSON.stringify(this.liabilities), this.type, this.stage, this)
				}


			},
			getBalanceSheet() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'financialLiabilitiesDetail/getLiabilitesInfo', qs.stringify({
						stage: this.stage,
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						this.yearList = res.data.content.year;
						this.id = res.data.content.id;
						this.isCacheValue = res.data.content.isCache
						this.liabilitiesData = res.data.content

						if (res.data.content.liabilities) {
							this.liabilities = JSON.parse(res.data.content.liabilities)
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.showDialogBar(true, 'cacheType', '您上次填写过估值报告，是否载入缓存信息？')


								// 清除 0.00
								var dataKey = Object.keys(this.liabilities); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.liabilities[key].push("")
									})
								})

							} else { //正式数据
								var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content
									.liabilities)); //后台数据返回的资产负债表对象的 key
								liabilitiesKey.forEach((elem, index) => {
									returnliabilitiesKey.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.liabilities[elem][y] = JSON.parse(
													res.data.content.liabilities)[
													elem][y];
											} else if (!this_.liabilities[elem]) {
												if (this_.yearList.length == 2) {
													this_.liabilities[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.liabilities[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
							console.log(this.liabilities)
						} else {
							var dataKey = Object.keys(this.liabilities); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.liabilities[key].push("")
								})
							})


						}



					})
			},
			save() {
				var this_ = this
				// 资产总计不能为零
				// 资产总计=负债和所有者权益(或股东权益)总计
				// “所有者权益（或股东权益）-实收资本(或股本)”科目金额小于或等于基本信息中公司注册资本金额
				console.log(this.yearList[0] + '---', this.liabilities.totalAssets[0], '----', this.liabilities
					.totalLiabilitiesEquity[0], this.liabilities.totalAssets[0] != this.liabilities.totalLiabilitiesEquity[0])
				console.log(this.yearList[1] + '---', this.liabilities.totalAssets[1], '----', this.liabilities
					.totalLiabilitiesEquity[1], this.liabilities.totalAssets[1] != this.liabilities.totalLiabilitiesEquity[1])



				if (!this.liabilities.totalAssets[1]) {
					this.showDialogBar(true, 'notEmpty-info', this.yearList[this.yearList.length - 1] + '资产总计不能为零')
					return false;
				}


				if (this.method.formateNum(this.liabilities.totalAssets[0],2) != this.method.formateNum(this.liabilities.totalLiabilitiesEquity[0],2)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实' + this.yearList[0] + '资产总计、负债和所有者权益(或股东权益)总计的数据')
					return false;
				}

				if (this.method.formateNum(this.liabilities.totalAssets[1],2) != this.method.formateNum(this.liabilities.totalLiabilitiesEquity[1],2)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实' + this.yearList[1] + '资产总计、负债和所有者权益(或股东权益)总计的数据')
					return false;
				}

				if (this.liabilitiesData.founderEdu != '' && this.method.numRound(this.liabilities.totalOwnershipInterest[1]) -
					this.method.numRound(this.liabilities.paidUpCapital[1]) > this.method.numRound(this.liabilitiesData
						.founderEdu * 10000)) {
					this.hint = true
					this.showDialogBar(true, 'notEmpty-info', '请核实' + this.yearList[1] +
						'“所有者权益（或股东权益）-实收资本(或股本)”科目金额应与基本信息中注册资本金额一致')
					return false;
				}

				// if (this.liabilitiesData.iaBookValueTotal) {
				// 	if (Number(this.liabilitiesData.iaBookValueTotal) > Number(this.liabilities.netWorth[1])) {
				// 		this.hint = true
				// 		this.showDialogBar(true, 'notEmpty-info', '请核实' + this.yearList[1] + '无形资产应与会计政策统计表中无形资产账面净值统计表的总计一致')
				// 		return false;
				// 	}
				// } else {
				// 	if (this.liabilitiesData.dateResourceTotal && Number(this.liabilitiesData.dateResourceTotal) > Number(this
				// 			.liabilities.netWorth[1])) {
				// 		this.hint = true
				// 		this.showDialogBar(true, 'notEmpty-info', '请核实' + this.yearList[1] + '无形资产应与数据资产表中的账面净值的合计一致')
				// 		return false;
				// 	}
				// }



				console.log(this.liabilities)

				this.saveInterface()


			},
			saveInterface() {
				var this_ = this
				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'financialLiabilitiesDetail/save', qs.stringify({
							stage: this.stage,
							reportType: this.type,
							id: this.id ? this.id : '',
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							liabilitiesInfo: JSON.stringify(this.liabilities),
						}))
						.then((res) => {
							if (res.data.code == 200) {
								setTimeout(function() {
									this_.$router.push({
										name: this_.jumpAddress,
										query: {
											reportId: res.data.content.valuationReportId
										}
									})
								}, 500)

							}
						})
				}
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getBalanceSheet();
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, this.stage, this, 'getBalanceSheet')
				this.getBalanceSheet();
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
				if (this.hint) {
					this.saveInterface()
				}
			}



		}


	}
</script>

<style>
</style>