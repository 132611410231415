<template>
	<div>
		<div class="headCategory">
			<div class="reportCatalog">
				<span class="select">{{titleInfo.name}}</span>
				<button v-if="titleInfo.type==5" class="addProduct buttonFill" @click="addProduct">+添加数据产品</button>
			</div>
			<div class="statusBar">
				<div :class="['payStatus',state_payment?'flexible':'']" @click="state_payment=!state_payment">
					<p>{{allStatus[state_payment_index].state}}<i> > </i></p>
					<div v-if="state_payment">
						<!-- v-on:click.stop阻止事件冒泡  避免触发父级的点击事件 -->
						<span :class="{select:index==state_payment_index}" v-for="(item, index) in allStatus"
							v-on:click.stop="payment(index)">{{item.state}}</span>
					</div>
				</div>
				<div class="search">
					<input type="text" placeholder="请输入" v-model="companyName" @blur="search">
					<Icon type="md-search" size="20" color="#BFBFBF" style="position: absolute;top: 10px;right: 10px;" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import '@/views/myCenter/personalCenter.less'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		props: {
			titleInfo: {
				type: Object,
				required: true
			},
			url: {
				type: String,
				required: false
			},
			parameter: {
				type: Object,
				required: false
			},
			allStatus: {
				type: Array,
				required: false
			}
		},
		data() {
			return {
				state_payment: false, //支付状态  true显示3中支付状态  默认全部状态
				state_payment_index: 0,
				noData: true, //默认展示
				statusCode: '',
				valuationList: [],
				current: 1,
				companyName: '',
				myAlert: false,
				reportId: '',
				liIndex: 0,
				diagnosisAlert: false, //跳转新版诊断的提示
				loading: true,
				scrollNum: 1,


			}
		},
		mounted() {

		},
		methods: {
			addProduct() {
				if (this.$store.state.user.customerInfo.auditStatus != 1) {
					this.$Message.warning('请先进行实名认证')
				} else {
					this.$router.push({
						path: 'releaseProduct'
					})
				}

			},
			payment: function(index) {
				this.scrollNum = 1;
				this.current = 1;
				this.state_payment = !this.state_payment;
				this.state_payment_index = index;
				this.statusCode = this.allStatus[index].statusCode;
				this.valuationList = [];


				this.search()

			},
			search() {
				this.parameter.name = this.companyName
				this.parameter.status = this.statusCode

				console.log(this.url)
				this.$post(this.url, qs.stringify(this.parameter))
					.then((res) => {
						console.log(res.data)
						const data = {
							records: res.data.content.records,
							name: this.companyName,
							status: this.statusCode,
							total: res.data.content.total
						}
						this.$emit('getList', data)
					});
			}

		}

	}
</script>

<style scoped lang="less">

</style>