<template>
	<div>
		<div class="softNav">
			<ul>
				<li v-for="(item,index) in list" :key="index" @click="tabs(index)">
					<div :class="[slideIndex === index?'softActive':'']">
						<img v-if="slideIndex === index" :src="item.selectedIcon" />
						<img v-else :src="item.isAccomplish?item.accomplishIcon:item.icon" />
						<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">
							{{item.contentName}}
						</p>
					</div>
					<img v-if="index!=list.length-1" src="../../../assets/img/contribution/arrows.png" />
				</li>
			</ul>
		</div>


		<Modal v-model="model" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' :src="require('@/assets/img/fillin/unsave.png')">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>

	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		props: {
			currentFinance: {
				type: Number
			},
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},

		},
		data() {
			return {
				slideIndex: this.currentFinance,
				skip: 0,
				model: false,
				list: [{
					contentName: '无形资产-数据资源贡献程度',
					isAccomplish: false,
					icon: require('@/assets/img/contribution/contributionBlue.png'),
					selectedIcon: require('@/assets/img/contribution/contributionOrange.png'),
					accomplishIcon: require('@/assets/img/contribution/contributionGreen.png'),
					url: this.type == 13 ? 'dataIASpecialty' : 'dataValuationIAFast'
				}, {
					contentName: '无形资产贡献程度',
					isAccomplish: false,
					icon: require('@/assets/img/contribution/contributionBlue.png'),
					selectedIcon: require('@/assets/img/contribution/contributionOrange.png'),
					accomplishIcon: require('@/assets/img/contribution/contributionGreen.png'),
					url: this.type == 13 ? 'dataPIASpecialty' : 'dataValuationPIAFast'
				}]


			}
		},
		computed: {
			checkStatus() {
				return this.$store.state.user.checkStatus;
			}
		},
		watch: {
			checkStatus: {
				handler: function(newValue, oldValue) {
					this.$set(this.list[0], 'isAccomplish', newValue.dataContribution) //数据资源
					this.$set(this.list[1], 'isAccomplish', newValue.iAContribution) //组合无形资产

				},
				deep: true
			}

		},
		mounted() {


		},
		methods: {
			...mapMutations(['pageModify']),
			tabs(i) {
				this.skip = i;
				if (this.$store.state.user.currentPageModify == 'true' && this.$store.state.user.currentPageSave == false) {
					this.model = true;
				} else {
					this.slideIndex = i
					if (this.$route.query.reportId) {
						this.$router.push({
							name: this.list[i].url,
							query: {
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							name: this.list[i].url,
						})
					}
				}
			},
			submitDialog() {
				this.model = false;
				this.slideIndex = this.skip
				this.pageModify('false');
				if (this.$route.query.reportId) {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						path: this.list[this.skip].url,
					})
				}
			},

		}

	}
</script>

<style scoped lang="less">
	/deep/ .softNav {
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			bottom: -36px;
		}

		>ul {
			margin: 0 auto;
			display: flex;
			justify-content: center;

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				>img {
					width: 24px;
					margin: 0 30px;
				}

				div {
					position: relative;
					z-index: 2;

					img {
						width: 262px;
						height: 72px;
					}

					p {
						width: 100%;
						text-align: center;
						font-size: 16px;
						font-weight: bold;
						color: #fff;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}

			}

		}

	}
</style>