<template>
	<div id="main" v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="450" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner" :style="{'background':'url('+item.imgPath+') center center no-repeat'}">
					<div>
						<p class="englishName">{{item.englishName}}</p>
						<p class="productName">{{item.productName}}</p>
						<p class="subheading">{{item.subheading}}</p>
						<p class="banner_text">{{item.describe}}</p>
						<!-- <button class="buttonFill" @click="nowFill(13)">立即估值</button>
						<button class="buttonBorder" @click="openPdf(item.pdf)">查看报告示例</button> -->
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent" style="margin-bottom: 0;">

			<div v-if="homeContent.showDataSpecialty==1||homeContent.showDataFast==1">
				<ul class="dataValuationEntry">
					<li v-if="homeContent.showDataFast==1&&homeContent.dataValuationContent.fast.enabled">
						<div class="name"
							:style="{'background':'url('+homeContent.dataValuationContent.fast.imgPath+') center center no-repeat'}">
							{{homeContent.dataValuationContent.fast.title}}
						</div>
						<div class="describe">
							<p>{{homeContent.dataValuationContent.fast.describe}}</p>
							<div>
								<button class="buttonFill" @click="nowFill(15)">立即估值</button>
								<button class="buttonBorder" @click="openPdf(homeContent.dataValuationContent.fast.pdf)">查看报告示例</button>
							</div>
						</div>
					</li>
					<li v-if="homeContent.showDataSpecialty==1&&homeContent.dataValuationContent.specialty.enabled">
						<div class="name"
							:style="{'background':'url('+homeContent.dataValuationContent.specialty.imgPath+') center center no-repeat'}">
							{{homeContent.dataValuationContent.specialty.title}}
						</div>
						<div class="describe">
							<p>{{homeContent.dataValuationContent.specialty.describe}}</p>
							<div>
								<button class="buttonFill" @click="nowFill(13)">立即估值</button>
								<button class="buttonBorder"
									@click="openPdf(homeContent.dataValuationContent.specialty.pdf)">查看报告示例</button>
							</div>
						</div>
					</li>
				</ul>
			</div>


			<div>
				<p class="moduleTitle">{{pageSetup.reasonName}}</p>
				<ul class="reason">
					<li v-for="(item,index) in pageSetup.reason"
						:style="{'borderColor':hexToRgb($store.state.user.subjectColor.mainColor, '0.3'),'background':'linear-gradient(180deg, '+hexToRgb($store.state.user.subjectColor.mainColor, '0.1')+', #FFFFFF)'}">
						<p class="serialNumber" :style="{'color':hexToRgb($store.state.user.subjectColor.mainColor, '0.1')}">
							0{{index+1}}</p>
						<img :src="item.imgPath" />
						<p class="title">{{item.title}}</p>
						<p class="describe">{{item.describe}}</p>
					</li>
				</ul>
			</div>
			<div>
				<p class="moduleTitle">{{pageSetup.advantageName}}</p>
				<ul class="advantage">
					<li v-for="(item,index) in pageSetup.superiority" style="background: transparent;box-shadow: none;">
						<img :src="item.imgPath" alt="" />
						<p>{{item.title}}</p>
						<p>{{item.describe}}</p>
					</li>
				</ul>
			</div>
		</div>
		<div style="width: 100%;background: #F1F4FC;padding: 59px 0 65px;box-sizing: border-box;">
			<div style="margin: 0 auto;">
				<p class="moduleTitle" style="margin-top: 0;">{{pageSetup.frameworkName}}</p>
				<div class="framework" style="height: 688px;">
					<img :src="pageSetup.productArchitecture.imgPath" />
				</div>
			</div>
		</div>
		<div class="reportExampleList">
			<p class="moduleTitle">{{pageSetup.reportExampleName}}</p>
			<div>
				<div class="theSwiper">
					<swiper :options="swiperOption">
						<swiper-slide v-for="(item,index) in pageSetup.reportExample">
							<img :src="item.imgPath" alt="" />
						</swiper-slide>
					</swiper>
					<!-- 添加自定义按钮容器 -->
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</div>
			</div>
		</div>
		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/swiper.css";

	import '../dataEvaluation/common.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			swiper,
			swiperSlide
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					bannerList: [],
					reason: [],
					superiority: [],
					productArchitecture: {},
					reportExample: []
				},
				swiperOption: {
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
					slidesPerView: 3, //页面中的板块数量
					spaceBetween: 47, //板块间隔
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				},
				homeContent: {}

			}
		},
		mounted() {
			this.getInfo(3)
			this.getHome()
		},
		methods: {
			nowFill(type) {
				this.$router.push({
					path: type == 13 ? 'dataInfoSpecialty' : type == 14 ? 'basicInformationEvaluation' :
						'dataValuationInfoFast',
					query: {
						type: type
					},
				});
			},
			getHome() {
				this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
						channelCode: window.channel,
						setupType: 1
					}))
					.then((res) => {
						var this_ = this
						this.homeContent = JSON.parse(res.data.content.setupContent)

					});
			}


		}
	}
</script>

<style scoped lang="less">
	.dataValuationEntry {
		display: flex;
		justify-content: space-between;
		margin-top: 74px;

		li {
			width: 590px;
			border: 2px solid #FFFFFF;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);

			.name {
				width: 100%;
				height: 130px;
				line-height: 130px;
				padding: 0 30px;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				background-size: cover !important;
				border-bottom: 2px solid #FFFFFF;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.describe {
				background: #F4F7FA;
				padding: 20px 28px 30px 28px;

				p {
					line-height: 27px;
					font-size: 14px;
					color: #282828;
					margin-bottom: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}

				>div {

					button {
						width: 255px;
						height: 36px;
					}

					button:first-child {
						margin-right: 20px;
					}
				}
			}

		}
	}

	.reason {
		display: flex;
		flex-wrap: wrap;
		margin-top: 99px;

		li:nth-child(-n+2) {
			margin-top: 0;
		}

		li {
			width: 590px;
			height: 218px;
			background: linear-gradient(180deg, #E0E6FB, #FFFFFF);
			border: 2px solid #fff;
			margin-right: 20px;
			margin-top: 73px;
			position: relative;
			padding: 10px 30px 34px;
			box-sizing: border-box;

			.serialNumber {
				// position: absolute;
				font-size: 70px;
				font-weight: bold;
				color: #D5DEFC;
			}

			.title {
				width: 67%;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				position: absolute;
				top: 65px;
				left: 30px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.describe {
				font-size: 14px;
				color: #282828;
				line-height: 24px;
				text-align: justify;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				margin-top: 10px;
			}

			>img {
				width: 149px;
				height: 163px;
				object-fit: contain;
				position: absolute;
				top: -59px;
				right: 8px;
			}


		}

		li:nth-child(2n) {
			margin-right: 0;
		}
	}
</style>