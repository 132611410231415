<template>
	<div>
		<header class="back-f1">
			<div class="flow-header-container toptwo">
				<div class="logo leave"
					:style="{'background':'url('+this.$store.state.user.generalSetting.webLogo+') center center no-repeat','float':'none'}">
					<router-link tag="a" to="/"></router-link>
				</div>

				<div class="header-one-right">
					<ul v-if="moreNav.firstPart.length>0" ref="nav" :style="{'width':headerWidth+'px'}">
						<li
							:class="['nav-item',item.overtop?'moreNav':'',item.setupType==$route.meta.setupType&&item.setupType?'active':'']"
							v-for="(item,index) in moreNav.firstPart" :key="index" v-if="item.barStatus==1">
							<a v-if="item.linkType==1" :href="item.link" target="_blank">{{item.barName}}</a>
							<router-link v-else tag="a" :to="item.link">{{item.barName}}</router-link>
							<div class="overtop" v-if="item.overtop">
								<p v-for="(level,v) in moreNav.secondPart" v-if="level.barStatus==1">
									<a v-if="level.linkType==1" :href="level.link" target="_blank">{{level.barName}}</a>
									<router-link v-else tag="a" :to="level.link">{{level.barName}}</router-link>
								</p>
							</div>
						</li>
					</ul>
					<ul ref="nav" v-else>
						<li class="nav-item" v-for="(item,index) in navList" :key="index"
							:class="[item.setupType==$route.meta.setupType?'active':'']" v-if="item.barStatus==1">
							<a v-if="item.linkType==1" :href="item.link" target="_blank">{{item.barName}}</a>
							<router-link v-else tag="a" :to="item.link">{{item.barName}}</router-link>
						</li>



						<!-- <li class="nav-item">
							<div class="product_service">
								<div class="product_servicefont">
									<span>融资</span>
								</div>
								<div class="product_sel">
									<ul>
										<li><a :href="[flowUrl+'pages/bondFinancing/bondFinancing.html']">债权融资</a></li>
										<li><a :href="[flowUrl+'pages/roadShow/pastActivities.html']">路演活动</a></li>
										<li><a :href="[flowUrl+'pages/roadShow/projectLibrary.html']">项目库</a></li>
									</ul>
								</div>
							</div>
						</li> -->
					</ul>
					<div style="width: 200px;">
						<div :class="['index-my-container','login-con',logoShow?'':'hide']">
							<div class="index-reg">
								<a @click="logo()">登录</a>
								<a @click="register()">注册</a>
							</div>
						</div>
						<div :class="['index-my-container','user-con',valuationShow?'':'hide']"
							style="height: 58px;position: relative;">
							<div class="index-my">
								<span>
									<img
										:src="$store.state.user.avatarImgPath?$store.state.user.avatarImgPath:require('@/assets/img/avatarImg.png')"
										alt="" />
								</span>
								<span>{{username}}</span>
							</div>
							<div class="account-sel" style="display: none;">
								<ul>
									<li>
										<router-link tag="a" style="width: 100%;" class="my-valu-log leave"
											to="/myCenter">个人中心</router-link>
									</li>
									<li><span style="width: 100%;" class="exit" @click="exit()">退出</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>

		<div id="aliyun-module" class="navigation-box">
			<div class="navigation-top hide">
				<img src="../../assets/img/mobileClient/navIcon.png" class="navIcon" @click="menu">
				<img src="../../assets/img/logo-08.svg" class="mini_logo" @click="toHome">
				<img src="../../assets/img/mobileClient/my.png" class="topbar-icon" @click="personalCenter">
			</div>
			<div class="navigation-rightside-menu" v-if="navigation">
				<div class="mask"></div>
				<div class="dropdown-wrap">
					<div class="dropdown">
						<div class="dropdown-content">
							<div class="menu-top">
								<img src="../../assets/img/mobileClient/close.png" class="closeMenu" @click="close" v-if="menuShow">
								<img src="../../assets/img/logo-08.svg" class="mini_logo" @click="toHome">
								<img src="../../assets/img/mobileClient/close.png" class="closeMenu" @click="close" v-if="personal">
							</div>
							<div class="header-container-clearfix" v-if="menuShow">
								<ul>
									<li>
										<router-link tag="a" to="/">首页</router-link>
									</li>
									<li>
										<router-link tag="a" to="/product">精选服务</router-link>
									</li>
									<li>
										<router-link tag="a" to="/consultation">咨询服务</router-link>
									</li>
									<li>
										<router-link tag="a" to="/enterpriseTrain">助企培训</router-link>
									</li>
									<li>
										<router-link tag="a" :to="{path:'/policyService',query:{serviceType:1,current:1}}">政策服务
										</router-link>
									</li>
									<li>
										<router-link tag="a" to="/specializationUs">关于我们</router-link>
									</li>
									<li><a :href="flowUrl">返回心流网</a></li>
								</ul>
							</div>
							<div class="personal" v-if="personal">
								<div class="login-register" v-if="loginRegister">
									<router-link tag="a" to="/login">登录</router-link>
									<router-link tag="a" to="/register">注册</router-link>
								</div>
								<ul>
									<li @click="myReport">个人中心</li>
									<router-link tag="li" to="/login" v-if="!loginRegister"
										style="color: #282828;opacity: 1;">退出</router-link>
									<li>客服电话：17610076778</li>
									<li>邮箱：service@flcccc.com</li>
									<li>微信号：flcccc888</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import navFooterInfo from '../navFooterInfo'
	import qs from 'qs'
	import axios from 'axios';


	export default ({
		mixins: [navFooterInfo],
		data() {
			return {
				username: '',
				logoShow: false, //登录注册的显示隐藏    false 隐藏    true展示
				valuationShow: false, //我的估值显示隐藏   false 隐藏    true展示
				active: "",
				navigation: false,
				menuShow: false, //移动端导航菜单的展示
				personal: false, //移动端导航  我的个人中心的展示
				loginRegister: true, //移动端登录注册的按钮    默认显示
				flowUrl: this.domain.testUrl,
				productStatus: 0,
				navList: [],
				path: '/home',
				moreNav: {
					firstPart: [],
					secondPart: []
				}

			}
		},

		mounted() {

			this.getLogin()
			this.getNav()
			this.getLogo()

			window.addEventListener('resize', () => {
				this.showNav(this.navList)
			})


		},
		methods: {
			routeClick: function(e) { //跳转外部链接
				console.log(e)
				window.location.href = this.domain.testUrl + e;
			},
			getLogin() {
				this.username = localStorage.userName ? localStorage.userName : "用户名";
				if (!localStorage.userName || !localStorage.userToken) {
					this.logoShow = true;
					this.valuationShow = false;
					this.loginRegister = true;
				} else {
					this.logoShow = false;
					this.valuationShow = true;
					this.loginRegister = false;
				}
				// console.log(this.$router)
				this.active = this.$router.history.current.path;
			},
			logo: function() {
				this.$router.push({
					name: 'login',
					path: '/login'
				})
			},
			register: function() {
				this.$router.push({
					name: 'register',
					path: '/register'
				})
			},
			exit: function() {
				localStorage.removeItem("userToken");
				localStorage.removeItem("userName");
				this.$router.push({
					name: 'login',
					path: '/login'
				})
			},
			menu: function() {
				this.menuShow = true;
				this.navigation = true;
			},
			close: function() {
				this.menuShow = false;
				this.navigation = false;
				this.personal = false;
			},
			personalCenter: function() {
				this.personal = true;
				this.navigation = true;
			},
			myReport: function() {
				if (!localStorage.userName || !localStorage.userToken) {
					this.$router.push({
						name: 'login',
						path: '/login'
					})
				} else {
					this.$router.push({
						name: 'myCenter',
						path: '/myCenter'
					})
				}
			},
			toHome: function() {
				this.$router.push({
					path: '/'
				})
			},
			onMouseOver: function(i) {
				this.productStatus = i;
			},
			onMouseOut: function(i) {
				this.productStatus = 0;
			},



		}
	})
</script>

<style scoped>
	/*导航栏二*/
	header.back-white {
		background: #2940E5;
		height: 80px;
		border: none;
	}

	header.back-white .country-container>div.active span {
		color: #000;
	}

	header.back-white .header-two-right>a {
		color: #000;
	}

	.back-f1 {
		/* background: #02428B; */
		background: #F5F7FA;
		color: #fff;
	}

	header {
		height: 72px;
		width: 100%;
		min-width: 1200px;
		background: #F5F7FA;
		border-bottom: 1px solid rgba(255, 255, 255, .2);
	}

	footer {
		height: 399px;
		min-width: 1200px;
		width: 100%;
		background: #F5F7FA;
	}

	.newIcon {
		position: absolute;
		top: 15px;
		left: 58px;
	}

	.flow-header-container {
		width: calc(100% - 30px);
		height: 100%;
		margin: 0 auto;
		display: flex;
	}

	.topone>div.logo>a {
		height: 100%;
		width: 100%;
	}

	.toptwo>div.logo>a {
		width: 100%;
		height: 100%;
	}

	.flow-header-container>div.header-two-right {
		float: right;
		width: 130px;
		height: 50px;
		line-height: 50px;
		/*margin: 20px 0;*/
		margin: 15px 0;
	}

	.header-two-right>span {
		display: inline-block;
		font-size: 14px;
	}

	.country-container {
		float: left;
		cursor: pointer;
		width: 62px;
		font-size: 0;
	}

	.country-container>div.active span {
		font-size: 14px;
	}

	.country-container span {
		display: none;
	}

	.country-select span {
		display: none;
	}

	.country-container .active span {
		display: inline-block;
	}

	.country-select .active span {
		display: inline-block;
	}

	.header-two-right .country-img,
	.header-one-right .country-img {
		height: 15px;
		width: 24px;
		background: url(../../assets/img/login/@2x.svg) top no-repeat;
		background-size: 12px 15px;
		margin-left: 8px;
	}

	.header-two-right>a {
		float: right;
		color: #282828;
	}

	.country-select {
		position: absolute;
		height: 40px;
		top: 60px;
		border: 1px solid #333;
		width: 100px;
		background: #fff;
		opacity: 1;
		display: none;
		font-size: 0;
	}

	.country-select>div.active span {
		font-size: 14px;
	}

	.country-select .international .country-name {
		text-align: center;
		width: 100%;
	}

	.country-select>div {
		text-align: center;
	}


	/* 导航栏一*/

	.header-one-right {
		width: calc(100% - 230px - 80px);
		margin-left: 80px;
		display: flex;
		justify-content: space-between;
	}

	.header-one-right>ul {
		/* 	width: 80%;
		max-width: 1385px; */
		line-height: 40px;
	}

	.header-one-right .active,
	.header-one-right .active>a {
		color: var(--mainColor);
	}

	.header-one-right>ul>li {
		float: left;
		padding: 0 17px;
		font-size: 14px;
		line-height: unset;
		position: relative;
	}

	.header-one-right>ul>li i {
		position: absolute;
		width: 16px;
		height: 2px;
		background: #fff;
		top: 70px;
		left: 50%;
		margin-left: -8px;
		display: none;
	}

	.header-one-right>ul>li a,
	.header-one-right>ul>li span {
		color: #282828;
	}

	.header-one-right>ul>li a.my-valu-log>img {
		margin: 10px 5px 10px 0;
		float: left;
	}

	.header-one-right>ul>li .country-select span,
	.account-sel a,
	.account-sel span {
		color: #333;
	}

	.index-reg {
		width: 200px;
		height: 72px;
		line-height: 72px;
	}

	.index-reg>a {
		height: 100%;
		text-align: center;
		font-size: 14px;
	}

	.index-reg>a:first-child {
		width: 74px;
		color: #282828;
	}

	.index-reg>a:last-child {
		width: calc(100% - 74px);
		background: var(--mainColor);
		color: #fff;
	}

	.index-my {
		cursor: pointer;
		height: 30px;
		color: #fff;
		margin: 20px 0;
		display: flex;
		justify-content: flex-end;
	}

	.index-my>span:first-of-type {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		background-size: cover !important;
		border: 2px solid #ccc;
		position: relative;
		top: -6px;
		right: 3px;
	}
	
	.index-my>span:first-of-type  img{
		width: 70%;
		height: 70%;
		object-fit: contain;
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	.index-my>span:last-of-type {
		color: #282828;
		max-width: 61px;
		line-height: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
		position: relative;
		top: 2px;
	}

	.account-sel {
		width: 115px;
		display: block;
		text-align: center;
		border-top: 0;
		background: #f5f7fa;
		display: none;
		position: absolute;
		right: 0;
		z-index: 999;
	}

	.account-sel>ul>li {
		padding: 0 5px;
		height: 35px;
		line-height: 35px;
		cursor: pointer;
		text-align: left;
		margin-left: 16px;
		box-sizing: content-box;
	}

	.account-sel>ul>li:first-child {
		padding-top: 6px;
	}

	.account-sel>ul>li:last-child {
		padding-bottom: 6px;
	}

	.account-sel>ul>li:hover a,
	.account-sel>ul>li:hover span,
	.header-one-right>ul>li:hover>a.leave {
		color: var(--mainColor);
	}

	.account-sel>ul>li>span {
		max-width: 90px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.header-one-right>ul>li.active>a.leave,
	.header-one-right>ul>li:hover>a,
	.index-my-container:hover .index-my>span:last-of-type {
		color: var(--mainColor);
	}

	.index-my-container:hover .account-sel {
		display: block !important;
	}

	.header-one-right>ul>li.nav-item:hover .product_servicefont span {
		color: #1459F4;
	}

	.header-one-right>ul>li.nav-item.active>i {
		display: inline-block;
	}

	.header-one-right>ul>li.nav-item:hover .product_sel>ul>li>a.leave {
		color: #000;
	}

	.header-one-right>ul>li.nav-item {
		height: 72px;
		line-height: 72px;
	}

	.header-one-right>ul>li:not(.nav-item) {
		padding-right: 0;
	}

	.header-one-right>ul>li:not(.nav-item):last-of-type {
		line-height: 1;
	}

	.product_service:hover .product_sel {
		display: block !important;
	}

	.product_service .product_sel>ul>li:hover a {
		color: #1459F4;
	}

	.product_sel {
		width: 58px;
		padding-left: 20px;
		padding-right: 17px;
		position: absolute;
		top: 60px;
		left: 50%;
		margin-left: -44px;
		background: #F5F7FA;
		opacity: 1;
		display: none;
		color: #282828;
		box-sizing: content-box;
		z-index: 999;
	}

	.product_sel>ul>li {
		height: 35px;
		line-height: 35px;
		cursor: pointer;
		box-sizing: content-box;
	}

	.product_sel>ul>li:first-child {
		padding-top: 10px;
	}

	.product_sel>ul>li:last-child {
		padding-bottom: 10px;
	}

	/* 产品导航总类 */

	.toca {
		width: 1152px;
		height: 524px;
		line-height: initial;
		padding-right: 80px;
		background: #FFFFFF;
		box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.15), 0px 5px 32px 0px rgba(221, 225, 235, 0.74);
		position: absolute;
		top: 72px;
		left: 50%;
		margin-left: -576px;
		display: none;
		z-index: 2222;
	}

	.header-one-right>ul>li:nth-of-type(2):hover .toca {
		display: flex;
	}

	.toca>img {
		width: 300px;
		height: 524px;
		margin-right: 92px;
	}

	.flowProductType {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding-top: 23px;
	}

	.flowProductName img {
		vertical-align: middle;
		margin-right: 10px;
	}

	.flowProductName>p {
		border-bottom: 1px solid #BFBFBF;
		padding-bottom: 6px;
	}

	.flowProductName>p>span {
		font-size: 18px;
		font-weight: bold;
		color: #1459F4 !important;
	}

	.flowProductName ul li {
		font-size: 14px;
		color: #282828;
		padding: 6px 0;
	}

	.flowProductName ul li:last-child {
		padding-bottom: 0;
	}

	.flowProductName:nth-of-type(2)>p:nth-of-type(2),
	.flowProductName:nth-of-type(3)>p:nth-child(n+2) {
		margin-top: 20px;
		margin-bottom: 6px;
	}

	.toca .caption {
		font-size: 16px;
		font-weight: 400;
		color: #1459F4;
		line-height: 16px;
		border-bottom: 1px solid #BFBFBF;
		padding-bottom: 8px;
		margin-top: 17px;
		margin-bottom: 11px;
	}

	.toca>div ul li:hover a {
		color: #1459F4;
	}

	.header-one-right>ul>li:nth-of-type(2):hover .toca {
		display: flex;
	}

	#aliyun-module {
		display: none;
	}


	@media screen and (max-width: 750px) {
		header {
			/* display: none; */
		}

		#aliyun-module {
			/* display: block; */
			width: 100%;
			height: 76px;
			position: fixed;
			top: 0;
			z-index: 9999;
			background: #fff;
			box-shadow: 0 4px 16px 0 rgb(0 0 0 / 6%);
		}

		#aliyun-module img {
			vertical-align: middle;
		}

		.navigation-top {
			display: flex;
			justify-content: space-between;
			padding: 0 20px;
		}

		.navIcon {
			width: 30px;
			height: 55px;
			padding-top: 28px;
		}

		.topbar-icon {
			width: 45px;
			height: 60px;
			padding-top: 15px;
		}

		.mini_logo {
			width: 180px;
			position: absolute;
			top: 10px;
			left: 50%;
			margin-left: -90px;
		}

		.navigation-rightside-menu {
			overflow: hidden;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 399;
			background-color: rgba(55, 61, 65, .4);
		}

		.navigation-box .mask {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: rgba(0, 0, 0, .5);
			z-index: auto;
		}

		.dropdown-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 399;
			opacity: 1
		}

		.dropdown {
			overflow: hidden;
			width: 100%;
			height: 100%;
		}

		.dropdown-content {
			padding-bottom: 35px;
			box-sizing: border-box;
			height: 100%;
			overflow: auto;
			background-color: rgba(0, 0, 0, 0.28);
			display: block;
			position: relative;
			overflow: hidden;
		}

		.menu-top {
			z-index: 10;
			position: fixed;
			background: #f7f8fc;
			width: 100%;
			height: 76px;
			border-bottom: 1px solid #e2e2e2;
			padding: 0 20px;
		}

		.menu-top img.closeMenu {
			height: 60px;
			padding-top: 20px;
		}

		.menu-top img.closeMenu:nth-of-type(2) {
			position: absolute;
			right: 20px;
		}

		.header-container-clearfix {
			margin-top: 76px;
			background: #F6F8FB;
			width: 85%;
			height: 100%;
		}

		.header-container-clearfix li {
			height: 90px;
			line-height: 90px;
			font-weight: bold;
			color: #282828;
			font-size: 16PX;
			border-bottom: 1px solid #ECEEF5;
			padding-left: 20px;
		}

		.header-container-clearfix li a {
			color: #282828;
		}

		.personal {
			margin-top: 2.026667rem;
			background: #F6F8FB;
			width: 100%;
			height: 100%;

		}

		.login-register {
			width: 80%;
			margin: 0 auto;
			padding-top: 50px;
			overflow: hidden;
		}

		.login-register a {
			width: 45%;
			height: 55px;
			line-height: 55px;
			display: block;
			float: left;
			text-align: center;
			font-size: 14PX;
			color: #fff;
			border-radius: 25px;
			background-color: #1459F4;
		}

		.login-register a:last-child {
			float: right;
		}

		.personal ul {
			width: 80%;
			margin: 0 auto;
			padding-top: 40px;
		}

		.personal ul li {
			height: 55px;
			line-height: 55px;
			font-size: 14PX;
			color: #191C3D;
			opacity: .5;
		}

		.personal ul li:first-child {
			color: #282828;
			opacity: 1;
		}

		.personal ul li:nth-of-type(1) {
			padding-bottom: 80px;
			border-bottom: 1PX solid #ccc;
		}

		.personal ul li:nth-of-type(2) {
			padding: 20px 0 80px;
			border-bottom: 1PX solid #ccc;
		}

		.personal ul li:nth-of-type(3) {
			margin-top: 30px;
		}

	}

	.moreNav:hover .overtop {
		display: block;
	}

	.overtop {
		width: 130px;
		padding: 0 15px 10px 15px;
		position: absolute;
		top: 70px;
		left: 0;
		background: #F5F7FA;
		z-index: 2;
		display: none;

		>p {
			line-height: 24px;
			margin-top: 10px;
		}

		>p:hover a {
			color: var(--mainColor) !important;
		}
	}
</style>