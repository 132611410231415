<template>
	<div style="position: relative;">
		<div class="softNav">
			<ul>
				<li v-for="(item,index) in softIndexProblem" :key="index" @click="softIndicator(index)">
					<div :class="[slideIndex === index?'softActive':'']">
						<img v-if="slideIndex === index" :src="item.selectedIcon" />
						<img v-else :src="item.isAccomplish?item.accomplishIcon:item.icon" />
						<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">
							{{item.contentName}}
						</p>
					</div>
					<img v-if="index!=softIndexProblem.length-1" src="../../../assets/img/softIndex/arrows.png" />
				</li>
			</ul>
		</div>

		<ul class="softFAQList" v-if="showFAQ">
			<img src="../../../assets/img/dataValutaion/close.png" alt="关闭" @click="closeFAQ"/>
			<li v-for="item in FAQ">
				<p class="problemFAQ">{{item.question}}</p>
				<p class="answerFAQ">{{item.asked}}</p>
			</li>
		</ul>

		<div class="softIndex" id="xqBox">
			<div class="newCategory">
				{{softIndexProblem[slideIndex].contentName}}
				<div class="softFAQ" v-if="FAQ.length>0" @click="viewFAQ">
					<p>点击查看</p>
					<p>FAQ</p>
				</div>
			</div>
			<ul>
				<li v-for="(item,index) in softIndexProblem[slideIndex].contentDetails">
					<div class="position-tit-container">
						<span>{{index+1}}</span>
						<div v-html="item.content"></div>
						<span class="desc-container" v-if="item.canExplain">
							<span></span>
							<div class="desc-main">
								<p v-html="item.explaination.replace(/\\n/g,'<br>')"></p>
								<span></span>
							</div>
						</span>
					</div>
					<div class="options-container">
						<ul>
							<li v-for="(answer, a) in item.options" @click="selectTopic(index,a)"
								:class="[answer.chooseFlag=='1'?'active':'']" ref="soft">
								<label :for="answer.id" :class="answer.chooseFlag=='1'?'yes':'no'">{{answer.content}}</label>
								<div class="desc-container" v-if="answer.canExplain!=false">
									<span></span>
									<div class="desc-main">
										<p v-html="answer.explaination.replace(/\\n/g,'<br>')"></p>
										<span></span>
									</div>
								</div>
							</li>
						</ul>

					</div>

				</li>
			</ul>
		</div>


		<div class="saveBox">
			<span @click="goBack">上一步</span>
			<span @click="save">下一步</span>
		</div>


		<Modal v-model="model" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../../assets/img/fillin/unsave.png">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>


		<Modal v-model="alert" class-name="vertical-center-data videoAlert" :mask-closable="false">
			<div style="height: 315px;">
				<video :src="video" crossorigin="anonymous" class="example-video video-js vjs-big-play-centered vjs-fluid"
					controls preload="auto" style="height: 100%;"></video>

			</div>
			<p slot="footer"></p>
		</Modal>

	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios'
	import {
		mapState,
		mapMutations
	} from 'vuex'

	import {
		merge
	} from 'lodash'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: {
				type: String
			},
			jumpAddress: String, //保存之后的跳转地址
			backAddress: String //返回的跳转地址


		},
		data() {
			return {
				isSave: false,
				slideIndex: 0,
				skip: 0,
				model: false,
				selectedQuestion: [],
				softJArray: [],
				softIndexProblem: [{
					contentName: '基础指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/infoBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/infoOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/infoGreen.png'),
					contentDetails: []
				}, {
					contentName: '风险指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/riskBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/riskOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/riskGreen.png'),
					contentDetails: []
				}, {
					contentName: '质量指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/qualityBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/qualityOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/qualityGreen.png'),
					contentDetails: []
				}, {
					contentName: '流通指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/circulateBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/circulateOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/circulateGreen.png'),
					contentDetails: []
				}, {
					contentName: '经济指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/economicsBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/economicsOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/economicsGreen.png'),
					contentDetails: []
				}],
				alert: false,
				video: '',
				FAQ: [],
				showFAQ: false

			}
		},
		mounted() {
			var this_ = this
			this.getQuestion()
			this.pageModify('false');


			$(document).on('click', '.viewVideo', function() {
				this_.alert = true
				this_.video = this_.domain.testUrl + $(this).attr('data-url')
				console.log(this_.video)
			})


		},
		methods: {
			...mapMutations(['pageModify']),
			softIndicator(i) {
				this.skip = i;
				if (this.$store.state.user.currentPageModify == 'true' && this.$store.state.user.currentPageSave == false) {
					this.model = true;
				} else {
					this.slideIndex = i
					$('.fillinMain').scrollTop(0)
					this.setSelectQuestion()

					if (this.$route.query.reportId) {
						this.$router.push({
							query: merge({}, {
								'reportId': this.$route.query.reportId,
								'soft': this.slideIndex
							})
						})
					} else {
						this.$router.push({
							query: merge({}, {
								'soft': this.slideIndex
							})
						})
					}

					this.findFAQ()

				}

			},
			submitDialog() {
				this.model = false;
				this.slideIndex = this.skip
				this.setSelectQuestion()
				this.pageModify('false');
			},
			setSelectQuestion() {
				this.selectedQuestion = []
				this.softIndexProblem[this.slideIndex].contentDetails.map((el, i) => {
					el.options.map((item, j) => {
						if (item.chooseFlag == '1') {
							this.selectedQuestion.push(item.questionId)
						}
					})
				})
			},
			getQuestion(isSend) {
				this.$Spin.show(); //全局加载中
				this.$post(this.url + 'question/getQuestion', qs.stringify({
						stage: this.stage,
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: this.versions
					}))
					.then((res) => {
						this.$Spin.hide();

						if (this.$route.query.soft != undefined) {
							this.slideIndex = Number(this.$route.query.soft)
							this.skip = Number(this.$route.query.soft)
						}


						res.data.content.map((el, i) => {
							this.softIndexProblem[i]['contentDetails'] = el.contentDetails
							this.softIndexProblem[i]['contentType'] = el.contentType

							el.contentDetails.map((els, j) => {
								els['id'] = els.options[0].questionId
							})
						})

						console.log('ooo----', this.softIndexProblem)


						if (res.data.content[0].contentDetails[0].options[0].isCache === "0" && !sessionStorage.isHistoryCache) {
							this.showDialogBar(true, 'cacheType', '您上次填写过估值报告，是否载入缓存信息？')
							return false
						}

						this.setSelectQuestion()
						this.getQuestionFlag()
						this.findFAQ()
					})
			},
			getQuestionFlag() {
				this.$post(this.url + 'question/getQuestionFlag', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.type
					}))
					.then((res) => {
						this.softIndexProblem[0].isAccomplish = res.data.content.questionBasicFlag
						this.softIndexProblem[1].isAccomplish = res.data.content.questionRiskDataFlag
						this.softIndexProblem[2].isAccomplish = res.data.content.questionQualityFlag
						this.softIndexProblem[3].isAccomplish = res.data.content.questionCirculateFlag
						this.softIndexProblem[4].isAccomplish = res.data.content.questionEconomyFlag


					})
			},
			selectTopic(i, j) {
				this.isSave = false
				this.pageModify('true');
				console.log('点击了2')
				this.softIndexProblem[this.slideIndex].contentDetails[i].options.map((el, b) => {
					if (j == b) {
						this.$set(this.softIndexProblem[this.slideIndex].contentDetails[i].options[j], 'chooseFlag', '1')
					} else {
						el.chooseFlag = 0
					}
				})

				$('.position-tit-container').removeClass('no-choose-red')

				this.selectedQuestion.push(this.softIndexProblem[this.slideIndex].contentDetails[i].options[j].questionId)
				this.$forceUpdate()
				// 缓存 ======================
				this.$nextTick(() => {
					this.getSaveData()
					//调用缓存共用方法
					if (!this.softIndexProblem[this.slideIndex].contentDetails[i].options[j].isCache) {
						this.method.caching("softJArray", this.softJArray, this.type, '', this)
					}
				})
				// 缓存 =======================

			},
			getSaveData() {
				var softJArray = []
				this.softIndexProblem[this.slideIndex].contentDetails.map((el, i) => {
					el.options.map((els, j) => {
						if (els.chooseFlag == '1') {
							softJArray.push({
								questionId: els.questionId,
								optionId: els.id,
								contentType: el.contentType
							})
						}
					})
				})
				// console.log('777-----',softJArray)
				this.softJArray = JSON.stringify(softJArray)

			},
			findFirstMissingValue(a, b) {
				var isContained = b.find(objB => !a.some(objA => objA == objB.id));
				console.log(isContained)
				if (isContained) {
					return b.findIndex(obj => obj.id === isContained.id);
				} else {
					return isContained
				}
			},
			save() {
				let newList = Array.from(new Set(this.selectedQuestion))

				console.log(this.softIndexProblem[this.slideIndex].contentDetails, newList)

				const missingValue = this.findFirstMissingValue(newList, this.softIndexProblem[this.slideIndex].contentDetails);
				if (missingValue !== undefined) {
					console.log(`数组 A 中第一个未包含在数组 B 中的值是: ${missingValue}`);
					this.missingValue = missingValue

					this.showDialogBar(true, 'notEmpty-info', '您有必填项未填写，请填写完成后再保存')

					return false

				} else {
					console.log('数组 A 包含数组 B 中的所有值');
					if (!this.isSave) {
						this.isSave = true
						this.saveSoft()
					}

				}

			},
			saveSoft() {
				this.getSaveData()
				console.log(this.softJArray)
				this.$post(this.url + 'question/saveSoftIndex', qs.stringify({
						stage: '',
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						softJArray: this.softJArray,
						version: 2
					}))
					.then((res) => {

						if (this.softIndexProblem.length - 1 == this.slideIndex) {
							this.$router.push({
								name: this.jumpAddress,
								query: {
									reportId: res.data.content
								}
							})
						} else {
							this.isSave = false;
							this.pageModify('false');
							this.slideIndex = this.slideIndex + 1

							this.setSelectQuestion()

							this.$router.push({
								query: merge({}, {
									'reportId': res.data.content,
									'soft': this.slideIndex
								})
							})

							$('html, body').animate({
								scrollTop: 0
							}, 500)
						}

						this.getQuestionFlag()

					})

			},
			goBack() {
				if (this.slideIndex == 0) {
					if (this.$route.query.reportId) {
						this.$router.push({
							name: this.backAddress,
							query: {
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							name: this.backAddress,
						})
					}
				} else {
					this.slideIndex = this.slideIndex - 1
					this.setSelectQuestion()
					$('html, body').animate({
						scrollTop: 0
					}, 500)
				}

			},
			findFAQ() {
				this.$post(this.url + 'faq/getFAQ', qs.stringify({
						reportType: this.type,
						tableType: 2, //导航栏（1：基本信息，2：软性指标， 3：财务指标）
						childTableType: this.softIndexProblem[this.slideIndex].contentType,
						key:'',
						pageNum:'-1',
						pageSize:'-1'
					}))
					.then(res => {
						this.FAQ = res.data.content
					})
			},
			viewFAQ() {
				this.showFAQ = true
			},
			closeFAQ(){
				this.showFAQ = false
			},

			closeMaskInfoJump() {
				$('.position-tit-container').eq(this.missingValue).addClass('no-choose-red').parent('li').siblings().find(
					'.position-tit-container').removeClass('no-choose-red')
				$('html, body').animate({
					scrollTop: $('.position-tit-container').eq(this.missingValue)[0].offsetTop
				}, 500)
				this.hideDialogBar()
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getQuestion();
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				var this_ = this
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, '', this, 'softJArray')
				setTimeout(function() {
					this_.getQuestion();
				}, 100)
				this.hideDialogBar()
			},






		}





	}
</script>

<style scoped lang="less">
	/deep/ .softNav {
		width: 600px;
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			bottom: -36px;
		}

		>ul {
			margin: 0 auto;
			display: flex;
			justify-content: space-between;

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				>img {
					width: 24px;
					height: 29px;
					margin-left: 14px;
				}

				div {
					position: relative;
					z-index: 2;

					img {
						width: 77px;
					}

					p {
						width: 100%;
						text-align: center;
						font-size: 16px;
						font-weight: bold;
						color: #fff;
					}
				}

			}

		}

	}

	.position-tit-container>span {
		font-size: 16px;
	}

	.options-container label {
		font-size: 14px;
		text-indent: -2.1em;
	}

	.options-container li {
		margin-bottom: 5px;
	}

	.softIndex {
		>ul {

			>li {
				margin-bottom: 15px;
			}
		}
	}
</style>