<template>
	<div class="my">
		<div class="headPortrait">
			<div>
				<div class="profilePhoto">
					<img
						:src="$store.state.user.avatarImgPath?$store.state.user.avatarImgPath:require('@/assets/img/avatarImg.png')"
						alt="头像" />
					<div :style="{'background':hexToRgb($store.state.user.subjectColor.mainColor, '0.4')}">
						<div>
							<img src="../../assets/img/myCenter/amendIcon.png" alt="" />
							<p>修改头像</p>
							<Upload class="upload" :action="action" name="upfile" :data="imgData" :headers="headers"
								:show-upload-list="false" accept="image/png,image/jpg,image/jpeg,image/gif" :max-size="2048"
								:on-exceeded-size="handleMaxSize" :on-success="setUpAvatar">
								<Button style="opacity: 0;width: 100px;height: 100px;position: absolute;top: 0;left: 0;">修改头像</Button>
							</Upload>
						</div>
					</div>

				</div>
				<p>{{$store.state.user.userName}}</p>
			</div>
		</div>
		<ul class="menu" v-if="isShowList">
			<li v-for="(item,index) in myList" :class="[activeNameData == item.path?'active':'']"
				:style="{'background':activeNameData == item.path?hexToRgb($store.state.user.subjectColor.mainColor, '0.1'):'transparent'}"
				@click="choice(item.path,item.twoPath,index)" v-if="item.status==1">
				<span>
					<i :class="['iconfont',item.icon]"
						:style="{'color':activeNameData == item.path?$store.state.user.subjectColor.mainColor:'#282828','fontSize':'24px'}"></i>
				</span>
				<span>{{item.name}}</span>
			</li>
		</ul>
	</div>
</template>

<script>
	import publicMethod from '@/views/publicMethod'
	import navFooterInfo from '@/common/navFooterInfo'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapActions,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [publicMethod, navFooterInfo],
		props: {
			activeName: {
				type: String
			},
		},
		data() {
			return {
				isShowList: false,
				action: this.domain.testUrl + this.domain.dataRealization + 'dataAssetUpload/uploadFile',
				imgData: {
					type: 5
				},
				headers: {
					Authorization: localStorage.userToken,
					SecurityCode: window.channel,
					ChannelId: window.channelId
				},
				activeNameData: this.activeName,
				myList: [{
					name: '数据测评报告',
					type: 1,
					icon: 'icon-shuju_jichushuju',
					status: true
				}, {
					name: '数据估值报告',
					type: 2,
					icon: 'icon-shuju',
					status: true
				}, {
					name: '我的订单',
					type: 3,
					icon: 'icon-wodedingdan',
					status: true
				}, {
					name: '实名认证',
					type: 4,
					icon: 'icon-shimingrenzheng',
					status: true
				}, {
					name: '数据产品管理',
					type: 5,
					icon: 'icon-chanpinku',
					status: true
				}, {
					name: '数据产品交易',
					type: 6,
					icon: 'icon-igw-l-date-trade',
					status: true
				}, {
					name: '我的积分',
					type: 7,
					icon: 'icon-jifen',
					status: true
				}, {
					name: '安全设置',
					type: 8,
					icon: 'icon-anquanshezhi',
					status: true
				}],
				auditStatus: this.$store.state.user.customerInfo.auditStatus


			}
		},
		created() {

		},
		mounted() {

			this.$nextTick(() => {
				this.getPersonal()
			})


		},
		watch: {
			auditStatus: {
				handler: function(newValue, oldValue) {
					this.$set(this, 'auditStatus', newValue)
				},
				deep: true
			}

		},
		methods: {
			...mapActions([
				'getCustomer'
			]),
			choice(value, twoPath, i) {
				console.log(value)
				this.activeNameData = value
				this.$router.push({
					path: twoPath ? twoPath : value,
				});
				const data = {
					name: this.myList[i].name,
					type: this.myList[i].type
				}
				this.$emit('getName', data)
			},
			getPersonal() {
				var this_ = this
				this_.$post(this_.domain.dataRealization + 'barSetup/getPersonalFunctions', qs.stringify({}))
					.then((res) => {
						// status 1：开启   0：关闭
						this_.myList = res.data.content


						this_.$post(this_.domain.dataRealization + 'authentication/getAuthInfo', qs.stringify({}))
							.then((info) => {
								const data = info.data.content
								this_.getCustomer({
									data
								})

								console.log('审核状态---', this_.$store.state.user.customerInfo.auditStatus)

								this_.myList.map((el, i) => {
									if (el.type == '1') {
										this.$set(this_.myList[i], 'icon', 'icon-shuju_jichushuju')
										this.$set(this_.myList[i], 'path', 'evaluationList')
									} else if (el.type == '2') {
										this.$set(this_.myList[i], 'icon', 'icon-shuju')
										this.$set(this_.myList[i], 'path', 'valuationList')
									} else if (el.type == '3') {
										this.$set(this_.myList[i], 'icon', 'icon-wodedingdan')
										this.$set(this_.myList[i], 'path', 'myOrder')
									} else if (el.type == '4') {
										let status = this_.$store.state.user.customerInfo.auditStatus === '' || this_.$store.state
											.user.customerInfo.auditStatus === 1 || this_.$store.state.user.customerInfo
											.auditStatus === null ? 'certification' : 'certificationAudit'

										this.$set(this_.myList[i], 'icon', 'icon-shimingrenzheng')
										this.$set(this_.myList[i], 'path', 'certification')
										this.$set(this_.myList[i], 'twoPath', status)
									} else if (el.type == '5') {
										this.$set(this_.myList[i], 'icon', 'icon-chanpinku')
										this.$set(this_.myList[i], 'path', 'productManagement')
									} else if (el.type == '6') {
										this.$set(this_.myList[i], 'icon', 'icon-igw-l-date-trade')
										this.$set(this_.myList[i], 'path', 'commoditiesTrading')
									} else if (el.type == '7') {
										this.$set(this_.myList[i], 'icon', 'icon-jifen')
										this.$set(this_.myList[i], 'path', 'myIntegral')
									} else if (el.type == '8') {
										this.$set(this_.myList[i], 'icon', 'icon-anquanshezhi')
										this.$set(this_.myList[i], 'path', 'securitySettings')
									}

									if (this_.activeNameData == el.path) {
										const data = {
											name: el.name,
											type: el.type
										}
										this_.$emit('getName', data)
									}

								})

								this_.isShowList = true
								console.log('7777777777-------------', this_.myList)


							});


					});
			},




		},




	}
</script>

<style scoped lang="less">
	.my {
		width: 244px;
	}

	.headPortrait {
		width: 100%;
		height: 193px;
		background: var(--mainColor);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		.profilePhoto {
			width: 100px;
			height: 100px;
			background: #f5f5f5;
			border-radius: 50%;
			margin-bottom: 8px;
			position: relative;
			border:4px solid #fff;

			>div {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				cursor: pointer;
				position: absolute;
				left: 0;
				top: 0;
				display: none;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				p {
					width: 100%;
					font-size: 14px;
					text-align: center;
					margin-top: 8px;
				}

				img {
					width: 18px;
					display: block;
					margin: 15px auto 0;
				}
			}



			>img {
				width: 70%;
				height: 70%;
				object-fit: contain;
				cursor: pointer;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}

		.profilePhoto:hover>div {
			display: flex;
		}

		p {
			font-size: 16px;
			font-weight: bold;
			color: #FFFFFF;
			text-align: center;
		}
	}

	.menu {
		width: 100%;
		height: 541px;
		background: #FFFFFF;
		box-shadow: 2px 2px 13px 0px rgba(32, 76, 151, 0.23);
		margin-top: 10px;

		li {
			height: 63px;
			line-height: 63px;
			padding-left: 20px;
			font-size: 16px;
			color: #282828;
			border-left: 2px solid #fff;
			cursor: pointer;

			span {
				margin-left: 12px;
				color: #282828;
			}
		}

		li.active {
			border-color: var(--mainColor);

			span {
				color: var(--mainColor);
			}
		}
	}
</style>