<template>
	<div class="draw3dPie">
		<div v-if="chartData[0].y==100" class="chartsImg">
			<img src="../../assets/img/report/charts.png" alt="第一股东-心流">
			<img src="../../assets/img/report/line.svg" alt="心流">
			<span>第一股东：100%</span>
		</div>
		<div v-else :id="id"></div>
	</div>
</template>
<script>
	import * as Highcharts from 'highcharts'
	import * as Exporting from 'highcharts/modules/exporting'
	import Highcharts3D from 'highcharts/highcharts-3d'
	Exporting(Highcharts)
	Highcharts3D(Highcharts)



	export default ({
		props: {
			chartData: Array,
			id: String,
			type: {
				type: [String, Number],
				required: false
			},

		},
		data() {
			return {
				data: []
			}
		},
		mounted() {
			var dataLen = this.chartData.length - 1;
			for (var i = dataLen; i >= 0; i--) {
				if (this.chartData[i].y == 0) {
					this.chartData.splice(i, 1);
				}
			}
			// console.log(this.chartData)

			if (this.chartData.length != 1) {
				this.init()
			}

		},
		methods: {
			init: function() {
				var chartData = this.data;

				var color1 = ['#42A2F4', '#4F53FA', '#61ECF8', '#9497FF', '#69E0B5'];
				var color2 = ['#4F53FA', '#42A2F4', '#61ECF8', '#9497FF', '#69E0B5'];

				Highcharts.chart(this.id, {
					chart: {
						type: 'pie',
						backgroundColor: 'rgba(255, 255, 255, 0)', // 设置背景颜色透明 默认是白色
						options3d: {
							enabled: true,
							alpha: 78,
							beta: 0
						}
					},
					credits: {
						enabled: false
					},
					exporting: {
						enabled: false
					},
					title: {
						text: ''
					},
					subtitle: {
						// text: ''
					},
					labels: {
						style: {
							fontSize: 18
						}
					},
					plotOptions: {
						pie: {
							innerSize: 200,
							depth: 100,
							size: 320,
							allowPointSelect: true,
							cursor: 'pointer',
							colors: color2,
							dataLabels: {
								enabled: true,
								style: {
									fontSize: "18px",
									textOutline: 'none' //去掉文字带白边
								},
								formatter: function() {
									return '<b>' + this.point.name + '：' + Highcharts.numberFormat(this.point.y, 2) +
										' %';
								}
								// format: '{point.name}:{point.y}%'
							},

						}
					},
					series: [{
						data: this.chartData,
					}]
				})

			}
		}
	})
</script>
<style scoped lang="less">
	/* ----设置饼图连接线的样式---- */

	/deep/ .highcharts-pie-series .highcharts-data-label-connector {
		stroke-dasharray: 2, 2;
		stroke-width: 2px;
	}

	/*----设置饼图连接线的样式---- */
	.draw3dPie {
		width: 100%;
		height: 550px;
		position: relative;
	}

	#stockStructurePie {
		width: 100%;
		height: 650px;
		position: absolute;
		top: -45px;
		margin-left: 12px;
	}

	.chartsImg img {
		position: absolute;
	}

	.chartsImg img:nth-of-type(1) {
		width: 326px;
		top: 244px;
		left: 360px;
	}

	.chartsImg img:nth-of-type(2) {
		top: 58%;
		left: 62%;
	}

	.chartsImg span {
		font-size: 18px;
		color: #000;
		font-weight: bold;
		position: absolute;
		top: 66%;
		right: 6%;
	}
</style>